import { useTranslation } from 'react-i18next';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

function TermsAndConditionsScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'termsAndConditionsScreen' });
  return (
    <>
      <TitleAndDescription screenName="termsAndConditionsScreen" />
      <section>
        <div className="bg-primary-light py-14">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <h1 className="text-2xl md:text-4xl text-grey-50 font-bold">{t('title')}</h1>
          </div>
        </div>
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="py-14 text-grey-50 text-sm md:text-base max-w-5xl text-entry text-entry-info">
            <div className="mb-6">
              <b>Stand: 21.9.2022</b>
            </div>
            <h2 className="font-medium">1. HektarNektar</h2>
            <p>
              Die Hektar Nektar GmbH, FN 473290s, Agnesstraße 12/4/6, 3400 Klosterneuburg,
              („HektarNektar“), Mitglied der Österreichischen Wirtschaftskammer, (zuständige Behörde
              gem ECG BH Tulln), ist Diensteanbieter, Medieninhaber und Herausgeber von
              hektarnektar.com, hectarenectare.com, hectarenectar.com, hectarnectar.com,
              nectarhectare.com, nektarhektar.com, bzw weiteren Domains, auf denen Inhalte angeboten
              werden, („Websites“). Diese Allgemeinen Geschäftsbedingungen („AGB“) gelten für alle
              Eintragungen auf den Websites von HektarNektar. Von diesen Bedingungen abweichende
              Regelungen werden nicht anerkannt.
            </p>
            <h2 className="font-medium">2. Interessenten</h2>
            <p>
              HektarNektar bietet Personen, die an Bienen, Bienenprodukten, Zubehör, usw,
              interessiert sind, also insbesondere Imkern / Bienenzüchtern, Landwirten, Unternehmen,
              privaten Haushalten („Interessenten“), eine webbasierte Plattform, um sich
              untereinander auszutauschen, zu vernetzen und miteinander in Geschäftskontakt zu
              treten
            </p>
            <h2 className="font-medium">3. Daten</h2>
            <p>
              Um den Austausch der Interessenten untereinander zu ermöglichen, geben diese Daten auf
              den Websites von HektarNektar ein. Es handelt sich bei diesen Daten um Name/Firma,
              Firmenbuchnummer, UID, Adresse, Geburtsdatum, E-Mail-Adresse, Website, Telefonnummer,
              Profilfoto („Personendaten“), Angaben weshalb der Interessent Interesse an Bienen,
              Bienenprodukten, Zubehör, usw, („Interessedaten“) hat.
            </p>
            <h2 className="font-medium">4. Rechteübertragung</h2>
            <p>
              Der Interessent garantiert HektarNektar, Inhaber sämtlicher Rechte an den Daten zu
              sein. Der Interessent überträgt HektarNektar das nichtausschließliche Recht zur
              Nutzung der Daten und zwar zur Zurverfügungstellung auf den Websites von HektarNektar,
              Kennzeichnung mit dem Copyright © hektarnektar, zur Weitergabe an andere
              Interessenten, die diese AGB ebenfalls akzeptiert haben, nicht jedoch zur Weitergabe
              an sonstige Dritte, die nicht Interessenten sind.
            </p>
            <h2 className="font-medium">5. Datenschutz - Einwilligung</h2>
            <p>
              Der Interessent ist damit einverstanden, dass seine Daten auf den Servern von
              HektarNektar gespeichert und verarbeitet werden laut den Datenschutzbestimmungen, die
              man hier nachlesen kann: Datenschutzbestimmungen
            </p>
            <h2 className="font-medium">6. Haftung</h2>
            <p>
              Der Interessent ist für die von ihm eingegebenen Daten verantwortlich. Er garantiert
              für deren Richtigkeit. Er wird weder gesetzwidrige, Rechte Dritte verletzende,
              moralisch verwerfliche, noch den guten Sitten widerstreitende Daten eingeben. Für
              Ansprüche, die von dritter Seite auf Grund der Daten des Interessenten gegenüber
              HektarNektar gestellt werden, hält dieser HektarNektar schad- und klaglos.
            </p>
            <h2 className="font-medium">7. Löschung</h2>
            <p>
              HektarNektar ist berechtigt, den Interessenten und/oder die von diesem eingegebenen
              Daten bei Verstoß gegen diese AGB und/oder geltendes Recht zu löschen und/oder ihn zu
              sperren.
            </p>
            <h2 className="font-medium">8. Änderung</h2>
            <p>
              HektarNektar ist berechtigt, die Websites von HektarNektar sowie diese AGB zu ändern.
              Eine Weiternutzung der Websites von HektarNektar nach Änderung der AGB ist möglich,
              wenn der Interessent mit den veränderten AGB einverstanden ist.
            </p>
            <h2 className="font-medium">9. Zusendung von E-Mails</h2>
            <p>
              Mit der Registrierung erteilt der Interessent seine ausdrückliche Zustimmung, Mails
              von HektarNektar zu erhalten.
            </p>
            <h2 className="font-medium">
              10. Gewährleistungs- und Haftungsausschluss von HektarNektar
            </h2>
            <p>
              HektarNektar haftet nicht für die Verfügbarkeit der Websites von HektarNektar und -
              mit Ausnahme von Personenschäden - nicht für eigene Handlungen und die von
              Erfüllungsgehilfen, die nicht grob fahrlässig verschuldet wurden. HektarNektar leistet
              keine Gewähr dafür, dass die auf den Webseites von HektarNektar abrufbaren Inhalte
              frei von Viren, Trojanern oder sonstigen schädlichen Codes oder Scripts sind.
            </p>
            <h2 className="font-medium">11. Wertsicherung</h2>
            <p>
              Die Preise von Hektar Nektar GmbH sind wertgesichert und werden jährlich entsprechend
              dem gültigen VPI indexiert.
            </p>
            <h2 className="font-medium">12. Anwendbares Recht und Gerichtsstand</h2>
            <p>
              Es gilt österreichisches Recht unter Ausschluss der Kollisionsnormen und des
              UN-Kaufrechts. Gerichtsstand für Ansprüche im Zusammenhang mit den Websites von
              HektarNektar ist das für den ersten Wiener Gemeindebezirk sachlich zuständige Gericht.
            </p>
            <h2 className="font-medium">13. Schlussbestimmungen</h2>
            <p>
              Sollten Bestimmungen bzw. wesentliche Bestandteile der ABG ganz oder teilweise
              unwirksam sein oder werden, wird hiedurch die Wirksamkeit der übrigen Bestimmungen
              nicht berührt. An die Stelle der unwirksamen Bestimmungen tritt ersatzweise eine
              Regelung, wie sie die Parteien zur Erreichung des (wirtschaftlich) gleichen
              Ergebnisses in Kenntnis der Unwirksamkeit vereinbart hätten.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditionsScreen;
