import SocialShare from '@components/UIElements/SocialShare';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as AvatarMask } from '@images/avatar-mask-72.svg';
import LazyLoad from 'react-lazyload';
import Slider from '@components/UIElements/Slider';
import SectionHead from '@components/UIElements/SectionHead';
import Plyr from 'plyr-react';
import getVideoId from 'get-video-id';
import VideoModal from '@components/UIElements/Modal';
import GLightbox from 'glightbox';
import { useTranslation } from 'react-i18next';
import { useParams, generatePath, Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { formatDateWithMonthNameAndYear, parseBool } from '@tools';
import {
  PUBLIC_TIMELINE_NOTE_PATH,
  TIMELINE_NOTE_PATH,
  BEEKEEPER_PROFILE_PATH,
  COMPANY_PROFILE_PATH
} from '@constants';
import { ReactComponent as PlayIcon } from '@images/icons/play.svg';
import 'glightbox/dist/css/glightbox.min.css';
import 'plyr-react/dist/plyr.css';
import { useTimelineNoteStores } from '@screens/TimelineNoteScreen/store';
import TimelineNote from '@components/UIElements/TimelineNote';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import _ from 'lodash';

function TimelineNoteScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'timelineNoteScreen' });
  const { timelineNoteSlug, beekeeperSlug } = useParams();
  const [timelineNoteLoading, setTimelineNoteLoading] = useState(true);
  const { timelineNoteStore, similarTimelineNotes, fetchTimelineNote, fetchSimilarTimelineNotes } =
    useTimelineNoteStores();
  const timelineNote = timelineNoteStore.record;
  const { companySlug } = timelineNote;
  const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(null);
  const modal = useRef(null);
  useEffect(() => {
    fetchTimelineNote(timelineNoteSlug).then(() => {
      setTimelineNoteLoading(false);
      if (timelineNote.userId) fetchSimilarTimelineNotes(timelineNote.userId);
    });
  }, []);

  if (timelineNoteLoading) return <div />;

  const timelineNotePath = () => {
    if (parseBool(companySlug))
      return generatePath(PUBLIC_TIMELINE_NOTE_PATH, {
        beekeeperSlug,
        timelineNoteSlug,
        companySlug
      });

    return generatePath(TIMELINE_NOTE_PATH, { beekeeperSlug, timelineNoteSlug });
  };

  const shareLink = (pressReleaseSlug) => {
    return (
      window.location.origin +
      generatePath(TIMELINE_NOTE_PATH, { timelineNoteSlug: pressReleaseSlug, beekeeperSlug })
    );
  };

  const allPhotos = () => {
    return [
      ...timelineNote.originalPhotos.map((photo) => ({
        photo: photo.url
      }))
    ];
  };

  const handleOnMouseDown = (e) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const lightBoxImages = () => allPhotos().map((image) => ({ href: image.photo, type: 'image' }));

  const handleOpenLightbox = (e, i) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      const offerGallery = GLightbox({
        startAt: i,
        elements: lightBoxImages(),
        touchNavigation: true,
        dragAutoSnap: true,
        draggable: false,
        loop: true,
        skin: 'clean hn'
      });
      offerGallery.open();
    }
  };

  const sliderSettings = {
    dots: true,
    arrows: true,
    speed: 800,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    draggable: true,
    lazyLoad: true
  };

  const videoSrc = (url) => {
    const videoDetails = getVideoId(url);
    return {
      type: 'video',
      sources: [{ src: `${videoDetails.id}`, provider: 'youtube' }]
    };
  };
  return (
    <>
      <TitleAndDescription
        screenName="timelineNoteScreen"
        attributes={{
          title: timelineNote.title,
          description: _.truncate(timelineNote.body, { length: 100 })
        }}
      />
      <section className="py-6">
        <div className="max-w-screen-md mx-auto px-4 2xl:px-14">
          <div className="flex items-center justify-between">
            <div className="text-primary text-base font-bold">
              {formatDateWithMonthNameAndYear(timelineNote.date)}
            </div>
            <div className="flex items-center space-x-10 md:space-x-12 text-grey-30">
              <SocialShare
                url={shareLink(timelineNote.slug)}
                title={timelineNote.title}
                body={timelineNote.body}
              />
            </div>
          </div>
          <h1 className="text-lg md:text-4xl text-grey-50 font-bold my-6 leading-normal">
            {timelineNote.title}
          </h1>
          <Slider {...sliderSettings}>
            {allPhotos().map((photo, index) => {
              return (
                <div
                  key="all-photos"
                  className="cursor-pointer"
                  onMouseDown={handleOnMouseDown}
                  onClick={(e) => handleOpenLightbox(e, index)}
                  role="presentation"
                >
                  <div className="aspect-w-16 aspect-h-9">
                    <img
                      src={photo.photo}
                      alt={timelineNote}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="flex items-center justify-between">
            <div className="flex items-center pl-4 py-4">
              <Link
                to={generatePath(BEEKEEPER_PROFILE_PATH, {
                  beekeeperSlug
                })}
                className="relative flex-shrink-0 mr-4 text-primary-40"
              >
                <AvatarMaskBack
                  width="72px"
                  height="61px"
                  className="fill-current absolute -top-2 -left-3"
                />
                <img
                  src={timelineNote.author.avatar}
                  alt={timelineNote.author.name}
                  className="rounded-xl object-cover avatar-mask-72"
                  width="72px"
                  height="72px"
                />
              </Link>
              <div className="transform -translate-y-1">
                <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                  {timelineNote.author.name}
                </h3>
                <div className="text-grey-50 text-xs md:text-sm">
                  {t(`author.${timelineNote.author.sex}`)}
                </div>
              </div>
            </div>
            <div className="mb-2">
              {timelineNote.youtubeUrl && (
                <div className="flex-shrink-0">
                  <button
                    onClick={() => modal.current.open()}
                    type="button"
                    className="outline focus:outline-none inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white text-xs border-0 m-0 py-2 px-4 w-full md:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                  >
                    <PlayIcon
                      width="16"
                      height="16"
                      className="fill-current stroke-current flex-shrink-0 mr-2"
                    />
                    {t('playVideoButton')}
                  </button>
                  <VideoModal ref={modal}>
                    <div className="w-full max-w-screen-lg overflow-y-auto">
                      <Plyr source={videoSrc(timelineNote.youtubeUrl)} />
                    </div>
                  </VideoModal>
                </div>
              )}
            </div>
          </div>
          <AvatarMask width="0" height="0" />
          <div className="text-grey-30 text-sm md:text-base text-entry">{timelineNote.body}</div>
          <div className="mt-6">
            <div className="text-sm text-grey-30">{t('supportedBy')}</div>
            <div className="mt-4">
              <LazyLoad height={64}>
                <Link
                  to={generatePath(COMPANY_PROFILE_PATH, { companySlug })}
                  className="flex h-16"
                >
                  <img src={timelineNote.companyLogo} alt="Logo" height="48px" className="h-full" />
                </Link>
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
      {similarTimelineNotes.length > 2 && (
        <section className="bg-primary-light py-10 md:py-14">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <SectionHead header={t('similarHeader')} subheader={t('similarSubheader')} />
            <div className="mt-10">
              <div className="flex flex-wrap -mx-3">
                {similarTimelineNotes.slice(0, 3).map((similarTimelineNote) => (
                  <TimelineNote
                    key={similarTimelineNote.id}
                    path={timelineNotePath()}
                    timelineNote={similarTimelineNote}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default observer(TimelineNoteScreen);
