import PropTypes from 'prop-types';

function SectionHead({ header, subheader, lead }) {
  return (
    <div className="md:text-center">
      <span className="block text-primary text-sm md:text-base font-bold uppercase">
        {subheader}
      </span>
      <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">{header}</h2>
      {lead && (
        <div className="text-sm md:text-base text-grey-30 mt-4 max-w-xl mr-8 md:mr-auto md:mx-auto">
          {lead}
        </div>
      )}
    </div>
  );
}

SectionHead.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  lead: PropTypes.string
};

SectionHead.defaultProps = {
  header: '',
  subheader: '',
  lead: ''
};

export default SectionHead;
