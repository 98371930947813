import PropTypes from 'prop-types';

function BeeProtectionBox({ head, lead, bg }) {
  return (
    <div className={`p-4 md:p-6 rounded-2xl ${bg}`}>
      <h3 className="text-white text-lg md:text-2xl font-bold">{head}</h3>
      <div className="text-sm md:text-base text-white mt-2">{lead}</div>
    </div>
  );
}

export default BeeProtectionBox;

BeeProtectionBox.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  bg: PropTypes.string
};

BeeProtectionBox.defaultProps = {
  head: '',
  lead: '',
  bg: ''
};
