import CompanyStore from '@/stores/GlobalStores/CompanyStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { createContext, useContext } from 'react';
import { partnersPageStatEndpoint } from '@config/endpoints';

export class PartnersScreenStore {
  constructor() {
    this.companyStore = new CompanyStore();
    this.stats = {
      adoptedBees: 0
    };
    this.bindMethods();

    makeAutoObservable(this);
  }

  bindMethods() {
    this.fetchStats = this.fetchStats.bind(this);
  }

  fetchStats() {
    return partnersPageStatEndpoint.stats().then((response) =>
      runInAction(() => {
        this.stats = response.data;
      })
    );
  }
}

export const StoresContext = createContext(new PartnersScreenStore());
export const usePartnersScreenStore = () => useContext(StoresContext);
