import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function TitleAndDescription({ screenName, attributes }) {
  const { t } = useTranslation('screens', { keyPrefix: screenName });

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>{t('metaTags.title', attributes)}</title>
        <meta name="description" content={t('metaTags.description', attributes)} />
      </Helmet>
    </HelmetProvider>
  );
}

TitleAndDescription.propTypes = {
  screenName: PropTypes.string.isRequired,
  attributes: PropTypes.objectOf(PropTypes.any)
};

TitleAndDescription.defaultProps = {
  attributes: {}
};

export default TitleAndDescription;
