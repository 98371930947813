import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from 'react-router-dom';
import routes from '@config/routes';
import { observer } from 'mobx-react';

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ['/']
  });

  if (breadcrumbs.length <= 1) return null;
  return (
    <section className="breadcrumbs my-4 mb-4">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <ul className="text-xs md:text-sm text-grey-30 flex flex-wra mb-6">
          {breadcrumbs.map(({ match, breadcrumb, customLink }, index) => {
            return (
              <li className="truncate" key={match.pathname}>
                {index !== breadcrumbs.length - 1 ? (
                  <Link
                    to={customLink || match.pathname}
                    className={`text-primary ${index === 2 ? 'capitalize' : ''}`}
                  >
                    {breadcrumb}
                  </Link>
                ) : (
                  <span>{breadcrumb}</span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
//

export default observer(Breadcrumbs);
