import { useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import sal from 'sal.js';
import { ReactComponent as Hex01 } from '@images/hexes/hex-01.svg';
import { ReactComponent as Hex02 } from '@images/hexes/hex-03.svg';

function HexDecoration() {
  useEffect(() => {
    sal({
      threshold: 0.2
    });
  }, []);
  return (
    <>
      <div
        data-sal="fade"
        data-sal-delay="1000"
        data-sal-duration="1500"
        data-sal-easing="ease-out-back"
        className="hidden lg:block absolute top-5 -left-20 transform -translate-y-3/4 -mr-6 text-green pointer-events-none"
      >
        <LazyLoad>
          <Hex01 className="fill-current opacity-30 max-w-[320px]" />
        </LazyLoad>
      </div>
      <div
        data-sal="fade"
        data-sal-delay="1000"
        data-sal-duration="1500"
        data-sal-easing="ease-out-back"
        className="hidden lg:block absolute bottom-0 right-full transform -mr-14 -mb-0 text-primary pointer-events-none"
      >
        <LazyLoad>
          <Hex01 className="fill-current opacity-30" />
        </LazyLoad>
      </div>
      <div
        data-sal="fade"
        data-sal-delay="1000"
        data-sal-duration="1500"
        data-sal-easing="ease-out-back"
        className="hidden xl:block absolute top-1/2 left-full h-[44%] transform -translate-y-1/2 -ml-12 -mt-32 text-orange pointer-events-none"
      >
        <LazyLoad>
          <Hex02 className="fill-current opacity-30 h-full" />
        </LazyLoad>
      </div>
    </>
  );
}

export default HexDecoration;
