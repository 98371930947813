import { makeAutoObservable, runInAction } from 'mobx';
import { attachmentEndpoint } from '@config/endpoints';

class AttachmentStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignAttachments = this.assignAttachments.bind(this);
  }

  async fetchAll(params) {
    return attachmentEndpoint.all(params).then(this.assignAttachments);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }

  async assignAttachments(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default AttachmentStore;
