import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from '@constants';
import LocationMarker from '@images/icons/pin.svg';
import HexDecoration from './components/HexDecoration';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const styleArray = [
  {
    stylers: [
      {
        gamma: 2.42
      },
      {
        saturation: -58
      },
      {
        hue: '#7461ED'
      },
      {
        lightness: -5
      }
    ]
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        saturation: -62
      },
      {
        lightness: -2
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'water',
    stylers: [
      {
        visibility: 'on'
      },
      {
        saturation: -67
      },
      {
        lightness: -2
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  styles: styleArray
};
const libraries = ['places'];

function HeroWithMap({ backgroundColor, lng, lat, markerLng, markerLat, children }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: GOOGLE_API_KEY
  });

  const mapCenter = {
    lng,
    lat
  };

  const markerCenter = {
    lng: markerLng,
    lat: markerLat
  };

  return (
    <section className={`${backgroundColor || 'bg-white'} overflow-hidden pt-8 md:pt-0`}>
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="flex flex-wrap items-center -mx-3">
            <div className="w-full md:w-1/2 px-3 relative z-10">{children}</div>
            <div className="w-full md:w-1/2 px-3">
              <div className="relative z-10 h-full w-full mt-8 md:mt-0">
                <div className="h-full -mx-6 lg:mx-0 transform lg:translate-x-16">
                  <div className="aspect-w-1 aspect-h-1">
                    <LazyLoad>
                      {isLoaded && (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={mapCenter}
                          zoom={15}
                          options={options}
                        >
                          <Marker
                            position={markerCenter}
                            icon={{
                              url: LocationMarker,
                              scaledSize: { width: 36, height: 50 },
                              size: { width: 36, height: 50 },
                              anchor: { x: 18, y: 50 }
                            }}
                          />
                        </GoogleMap>
                      )}
                    </LazyLoad>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HexDecoration />
        </div>
      </div>
    </section>
  );
}

export default HeroWithMap;

HeroWithMap.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object),
  lng: PropTypes.number,
  lat: PropTypes.number,
  markerLng: PropTypes.number,
  markerLat: PropTypes.number
};

HeroWithMap.defaultProps = {
  backgroundColor: '',
  children: [],
  lng: '',
  lat: '',
  markerLng: '',
  markerLat: ''
};
