import PropTypes from 'prop-types';
import { ReactComponent as IconBg } from '@images/icons/icon-hex-bg.svg';

function IconBox({ head, lead, classes, children }) {
  return (
    <div className={`md:w-1/3 px-4 md:px-6 md:mt-20 ${classes}`}>
      <div>
        <div className="relative inline-flex">
          <IconBg className="fill-current" width="115" height="100" />
          <div className="absolute inset-0 flex justify-center items-center">{children}</div>
        </div>
        <h3 className="text-sm md:text-base text-grey-50 font-bold mt-4 md:mt-8 mb-2">{head}</h3>
        <p className="text-sm md:text-base text-grey-30 max-w-xs">{lead}</p>
      </div>
    </div>
  );
}

export default IconBox;

IconBox.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.element
};

IconBox.defaultProps = {
  head: '',
  lead: '',
  classes: '',
  children: ''
};
