import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import CookieBanner from '@components/UIElements/CookieBanner';
import Breadcrumbs from '@components/UIElements/Breadcrumbs';
import Header from './Header';
import Footer from './Footer';

function Layout({ klass }) {
  const {
    authStore: { tokenChecked }
  } = useStores();

  if (tokenChecked)
    return (
      <div className={klass}>
        <Header />
        <Breadcrumbs />
        <Outlet />
        <Footer />
        <CookieBanner />
      </div>
    );

  return <div />;
}

export default observer(Layout);

Layout.propTypes = {
  klass: PropTypes.string
};

Layout.defaultProps = {
  klass: ''
};
