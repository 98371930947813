import { useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as LinkIcon } from '@images/icons/link.svg';
import { generatePath } from 'react-router-dom';
import { PRESS_RELEASE_PATH } from '@constants';
import { parseBool } from '@tools';

function PressReleasesFeedBox({ date, photo, title, abstract, logo, url, slug, datafor }) {
  const [copiedLink, setCopiedLink] = useState(null);

  const handleTooltipChange = () => {
    setCopiedLink('Link kopiert');
  };

  const sourceLink = () => {
    if (parseBool(url)) return url;

    return window.location.origin + generatePath(PRESS_RELEASE_PATH, { slug });
  };

  return (
    <div className="w-full md:w-1/3 px-3 mb-6">
      <div className="p-4 bg-white rounded-2xl shadow-ds16 h-full flex flex-col justify-between group">
        <a href={sourceLink()} target="_blank" rel="noreferrer">
          <div className="overflow-hidden rounded-2xl">
            <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-2xl">
              <LazyLoad className="rounded-2xl">
                <img
                  src={photo}
                  alt={title}
                  className="transition-all duration-300 object-cover absolute inset-0 w-full h-full rounded-2xl group-hover:scale-110"
                />
              </LazyLoad>
            </div>
          </div>
          <div className="text-xs text-primary mb-2 mt-6">{date}</div>
          <h3 className="text-base text-grey-50 font-bold mb-2">{title}</h3>
          <div className="text-sm text-grey-30">{abstract}</div>
        </a>
        <div className="flex items-center justify-between mt-10">
          <CopyToClipboard text={sourceLink()}>
            <button
              type="button"
              data-tip="Link kopieren"
              data-for={datafor}
              onClick={handleTooltipChange}
              className="transition-colors duration-300 outline-none focus:outline-none text-grey-25 hover:text-primary"
            >
              <LinkIcon width="24" height="24" className="fill-current" />
            </button>
          </CopyToClipboard>
          <ReactTooltip
            effect="solid"
            backgroundColor="#272F3E"
            arrowColor="#272F3E"
            id={datafor}
            afterHide={() => setCopiedLink(null)}
            getContent={[
              () => {
                return copiedLink || 'Link kopieren';
              }
            ]}
          />
          <div className="h-8 w-1/2 relative flex justify-end">
            <LazyLoad>
              <img src={logo} alt="Logo" className="object-contain h-full ml-auto" />
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PressReleasesFeedBox;

PressReleasesFeedBox.propTypes = {
  date: PropTypes.string,
  photo: PropTypes.string,
  title: PropTypes.string,
  abstract: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
  datafor: PropTypes.string
};

PressReleasesFeedBox.defaultProps = {
  date: '',
  photo: '',
  title: '',
  abstract: '',
  logo: '',
  url: '',
  slug: '',
  datafor: ''
};
