import { useEffect } from 'react';
import sal from 'sal.js';
import LazyLoad from 'react-lazyload';
import { ReactComponent as HeroHex01 } from '@images/hexes/hex-01.svg';
import { ReactComponent as HeroHex02 } from '@images/hexes/hex-03.svg';

function HexDecoration() {
  useEffect(() => {
    sal({
      threshold: 0.1
    });
  }, []);
  return (
    <>
      <div className="hidden lg:block absolute top-5 left-16 transform -translate-y-3/4 -mr-6 text-green pointer-events-none">
        <div
          data-sal="fade"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="easeOutCubic"
        >
          <LazyLoad>
            <HeroHex01 className="fill-current opacity-30" />
          </LazyLoad>
        </div>
      </div>
      <div className="hidden lg:block absolute top-1/2 right-full transform -translate-y-1/2 -mr-6 -mt-6 text-primary pointer-events-none">
        <div
          data-sal="fade"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="easeOutCubic"
        >
          <LazyLoad>
            <HeroHex01 className="fill-current opacity-30" />
          </LazyLoad>
        </div>
      </div>
      <div className="hidden xl:block absolute top-1/2 left-1/2 h-[80%] max-h-[500px] transform -translate-y-1/2 -ml-20 text-orange pointer-events-none">
        <div
          data-sal="fade"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="easeOutCubic"
          className="h-full"
        >
          <LazyLoad className="h-full">
            <HeroHex02 className="fill-current opacity-30 h-full" />
          </LazyLoad>
        </div>
      </div>
    </>
  );
}

export default HexDecoration;
