import PropTypes from 'prop-types';

function ExternalLinkItem({ href, label }) {
  return (
    <li>
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="text-white leading-loose text-xs lg:text-sm transition-opacity duration-300 hover:opacity-80 py-1 sm:py-0 block sm:inline-block truncate"
      >
        {label}
      </a>
    </li>
  );
}

export default ExternalLinkItem;

ExternalLinkItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string
};

ExternalLinkItem.defaultProps = {
  href: '',
  label: ''
};
