import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { useTimelineNoteStores } from '@screens/TimelineNoteScreen/store';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const DynamicTimelineBreadcrumb = observer(() => {
  const { timelineNoteStore } = useTimelineNoteStores();
  if (!timelineNoteStore.record.title) return <div />;

  return <span>{timelineNoteStore.record.title}</span>;
});

export const DynamicBeekeeperBreadcrumb = observer(() => {
  const {
    authStore: { beekeeperStore }
  } = useStores();
  const { timelineNoteStore } = useTimelineNoteStores();

  return <span>{timelineNoteStore.record.author.name || beekeeperStore.record.name}</span>;
});

export const DefaultBreadcrumb = observer(({ someProp }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'breadcrumbs'
  });
  return <span>{t(someProp)}</span>;
});

export const PressReleasesBreadcrumb = observer(({ someProp }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'breadcrumbs'
  });
  return <span>{t(someProp)}</span>;
});

DefaultBreadcrumb.propTypes = {
  someProp: PropTypes.string
};
DefaultBreadcrumb.defaultProps = {
  someProp: ''
};
