import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@components/UIElements/Breadcrumbs';
import Header from '../BasicLayout/Header';

function Layout() {
  const {
    authStore: { tokenChecked }
  } = useStores();
  const { t } = useTranslation('components', { keyPrefix: 'layouts.basicLayout.footer' });

  if (tokenChecked)
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <Breadcrumbs />
        <Outlet />
        <footer className="bg-primary-120 text-white text-sm md:text-base text-center py-6 md:py-8 mt-auto">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            {t('copyrights', { year: new Date().getFullYear() })}
          </div>
        </footer>
      </div>
    );

  return <div />;
}

export default observer(Layout);
