import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ApplicationFormPopUpPhoto } from '@images/application-form/popup.svg';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';

const modalElement = document.getElementById('modal-root');

export function Modal({ fade = true, defaultOpened = false }, ref) {
  const { t } = useTranslation('components', { keyPrefix: 'forms.companyApplicationForm' });
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div
        className={`modal fixed z-[300] inset-0 w-screen min-h-screen flex justify-center items-center bg-grey-50 bg-opacity-80 p-4 ${
          fade ? 'modal-fade' : ''
        }`}
      >
        <div className="w-full h-full absolute inset-0" role="presentation" onClick={close} />
        <div className="bg-white rounded-2xl p-6 md:p-10 w-full max-w-[466px]">
          <div className="flex flex-col justify-center items-center">
            <ApplicationFormPopUpPhoto width="286" />
            <h3 className="text-lg md:text-2xl text-grey-50 font-bold mt-6 mb-2">
              {t('popupHeader')}
            </h3>
            <p className="text-sm md:text-base text-grey-30 text-center">{t('popupBody')}</p>
            <div className="mt-8 w-full">
              <button
                type="submit"
                onClick={close}
                className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300 focus:outline-none"
              >
                {t('popupButton')}
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          aria-label="close"
          className="transition-colors duration-300 w-10 h-10 rounded-full bg-white bg-opacity-10 hover:bg-opacity-20 absolute top-4 md:top-8 right-4 md:right-8 flex items-center justify-center outline-none focus:outline-none"
          onClick={close}
        >
          <CloseIcon width="24" height="24" className="text-white stroke-current" />
        </button>
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Modal);
