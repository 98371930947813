import { useParams, generatePath, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import {
  BEEKEEPER_PROFILE_PATH,
  COMPANY_PROFILE_PATH,
  PUBLIC_TIMELINE_NOTE_PATH
} from '@constants';
import { formatDateWithYear, parseBool } from '@tools';
import { socialLinks } from '@config/directories';
import SocialLink from '@components/UIElements/SocialLink';
import { useBeekeeperScreenStore } from '@screens/BeekeeperProfileScreen/store';
import HeroWithSlider from '@components/UIElements/HeroWithSlider';
import UserHexInfo from '@components/UIElements/UserHexInfo';
import UserHexBox from '@components/UIElements/UserHexBox';
import { ReactComponent as AvatarMask } from '@images/avatar-mask-72.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as QuoteIcon } from '@images/icons/quote.svg';
import { ReactComponent as LocationIcon } from '@images/icons/location-marker.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import TimelineNote from '@components/UIElements/TimelineNote';
import SectionHead from '@components/UIElements/SectionHead';
import { observer } from 'mobx-react';
import _ from 'lodash';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import DefaultProfile from '@images/placeholders/default-profile.png';

function BeekeeperProfileScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'beekeeperProfileScreen' });
  const { beekeeperSlug } = useParams();
  const {
    fetchBeekeeper,
    record,
    similarBeekeepers,
    timelineNoteStore,
    companyStore,
    profileAnswerStore,
    profileQuestionStore
  } = useBeekeeperScreenStore();
  const [recordFetching, setRecordFetching] = useState(true);
  const beekeeper = record;
  const companies = companyStore.collection;

  useEffect(() => {
    fetchBeekeeper(beekeeperSlug).then(() => setRecordFetching(false));
  }, []);

  function loadMoreTimelineNotes() {
    timelineNoteStore.fetchAll(
      { q: { authorIdEq: beekeeper.user.id }, page: timelineNoteStore.meta.nextPage, per: 3 },
      true
    );
  }

  const allPhotos = () => {
    return [
      beekeeper.profilePhotos?.map((photo) => ({
        url: photo.bigUrl,
        caption: photo.bigUrl
      }))
    ];
  };

  const setHexInfoColor = (index) => {
    if (index % 3 === 1) return 'text-orange-40';
    if (index % 3 === 2) return 'text-green-40';
    return 'text-primary-40';
  };

  const answerQuestionWrapper = (question) => {
    const answer = profileAnswerStore.collection.find(
      ({ profileQuestionId }) => profileQuestionId === question.id
    );

    if (!answer || !answer.text) return null;

    return (
      <div key={question.id} className="mb-4">
        <h3 className="terxt-lg md:text-2xl text-grey-50 font-bold mb-2">{question.text}</h3>
        <div className="text-grey-30 text-sm md:text-base">{answer?.text}</div>
      </div>
    );
  };

  const loadMoreButton = () => {
    if (!parseBool(timelineNoteStore.meta.nextPage)) return null;

    return (
      <button
        className="outline-none focus:outline-none inline-flex items-center justify-center relative bg-white hover:bg-primary-120 text-primary hover:text-white border border-primary hover:border-primary-120 m-0 py-2 px-10 w-full md:w-auto rounded box-border cursor-pointer transition-colors ease-in-out duration-300"
        onClick={() => loadMoreTimelineNotes()}
        type="button"
      >
        {t('loadMoreTimelineNotes')}
      </button>
    );
  };

  if (recordFetching)
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );

  return (
    <>
      <TitleAndDescription
        screenName="beekeeperProfileScreen"
        attributes={{ beekeeperName: beekeeper.user.name }}
      />
      <HeroWithSlider
        backgroundColor="bg-orange-20"
        alt="PROJEKT 2028-Partner"
        photos={allPhotos()}
      >
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 lg:w-full px-3">
            <div className="mt-4">
              <UserHexBox
                avatar={
                  beekeeper.user.avatar.startsWith('/assets/beekeepers/default-profile')
                    ? DefaultProfile
                    : beekeeper.user.avatar
                }
                user={beekeeper.user.name}
                backgroundColor="text-primary-40"
              />
            </div>
            <h1 className="text-xl xl:text-3xl text-grey-50 font-bold leading-tight">
              {t('greetings')}
              {beekeeper.user.name}
            </h1>
          </div>
          <div className="w-full md:w-1/2 lg:w-full px-3">
            <div className="text-grey-30 text-base md:text-xl mt-4">
              {t('supportedBeehiveHeader', {
                hivesNumbers: beekeeper.user.beekeeperNumberOfBeehives,
                date: formatDateWithYear(beekeeper.user.beekeeperSince)
              })}
            </div>
            <div className="mt-8 text-base md:text-xl text-grey-30 flex items-center">
              <LocationIcon width="24" height="24" className="fill-current flex-shrink-0 mr-3" />
              <div>{`${beekeeper.user.livingAddress.city}, ${beekeeper.user.livingAddress.country.code}`}</div>
            </div>
            <ul className="flex flex-wrap transform -translate-x-2 mt-6">
              {socialLinks.map((element) => (
                <SocialLink
                  key={element.name}
                  beekeeper={beekeeper.user}
                  iconElement={element}
                  width="24"
                  spaceX="px-3"
                  spaceY="py-3"
                  classes="mr-3"
                />
              ))}
            </ul>
          </div>
        </div>
      </HeroWithSlider>
      <section className="py-10 md:py-14">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-1/3 px-3">
              <div className="md:sticky md:top-8">
                <div className="mb-4">
                  <div className="flex items-center pl-4 pt-4">
                    <div className="relative flex-shrink-0 mr-4 text-primary-40">
                      <AvatarMaskBack
                        width="118px"
                        height="100px"
                        className="fill-current absolute -top-2 -left-3"
                      />
                      <img
                        src={
                          beekeeper.user.avatar.startsWith('/assets/beekeepers/default-profile')
                            ? DefaultProfile
                            : beekeeper.user.avatar
                        }
                        alt={beekeeper.user.name}
                        className="rounded-xl object-cover avatar-mask-medium"
                        width="120px"
                        height="120px"
                      />
                    </div>
                    <div className="transform -translate-y-1">
                      <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                        {beekeeper.user.name}
                      </h3>
                      <div className="text-grey-50 text-xs md:text-sm">
                        {t(`beekeeper.${beekeeper.user.sex}`)}
                      </div>
                    </div>
                  </div>
                  <AvatarMask width="0" height="0" />
                </div>
                <div className="flex xl:pr-20">
                  <div className="mr-6 flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-primary-20 flex justify-center items-center">
                      <QuoteIcon width="18" height="18" className="text-primary fill-current" />
                    </div>
                  </div>
                  <div className="text-grey-30 text-sm md:text-base">
                    {beekeeper.user.beekeeperMotto}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 px-3">
              <div className="xl:pl-16 mt-6 lg:mt-0">
                {_.sortBy(
                  profileQuestionStore.companyProfileQuestions,
                  ({ questionType }) => questionType
                )
                  .reverse()
                  .map(answerQuestionWrapper)}
              </div>
              <div className="xl:pl-16 mt-8 lg:mt-12">
                <span className="text-xs text-grey-30">{t('beekeeperSupportedBy')}</span>
                <div className="flex flex-wrap items-center space-x-6">
                  <div className="mt-4 flex flex-wrap space-x-6">
                    {companies.map((item) => {
                      return (
                        <Link
                          className="h-16 relative flex mb-6"
                          to={generatePath(COMPANY_PROFILE_PATH, {
                            companySlug: item.slug
                          })}
                        >
                          <LazyLoad height={64}>
                            <img
                              src={item.logo}
                              alt={item.name}
                              className="object-contain h-full"
                            />
                          </LazyLoad>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {timelineNoteStore.collection.length > 0 && (
        <section className="py-14 bg-primary-light">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <SectionHead
              header={t('timelineNotesHeader')}
              subheader={t('timelineNotesSubheader')}
            />
            <div className="mt-10">
              <div className="flex flex-wrap -mx-3">
                {timelineNoteStore.collection.map((timelineNote) => (
                  <TimelineNote
                    key={timelineNote.id}
                    path={generatePath(PUBLIC_TIMELINE_NOTE_PATH, {
                      beekeeperSlug: timelineNote.author.slug || 'slug',
                      timelineNoteSlug: timelineNote.slug,
                      companySlug: timelineNote.companySlug
                    })}
                    timelineNote={timelineNote}
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-center mt-10">{loadMoreButton()}</div>
          </div>
        </section>
      )}
      {similarBeekeepers.length > 0 && (
        <section className="py-10 md:py-14">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <SectionHead header={t('beekeepersHeader')} subheader={t('beekeepersSubheader')} />
            <div className="mt-10">
              <div className="flex flex-wrap -mx-3">
                {similarBeekeepers.map((beekeeperUser, index) => (
                  <div key={beekeeper.id} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-3">
                    <UserHexInfo
                      url={generatePath(BEEKEEPER_PROFILE_PATH, {
                        beekeeperSlug: beekeeperUser.slug
                      })}
                      avatar={beekeeperUser.avatar}
                      user={beekeeperUser.name}
                      address={`${beekeeperUser.livingAddress.city}, ${beekeeperUser.livingAddress.country.code}`}
                      backgroundColor={setHexInfoColor(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default observer(BeekeeperProfileScreen);
