import { makeAutoObservable, runInAction } from 'mobx';
import { profilePhotoEndpoint } from '@config/endpoints';

class ProfilePhotoStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignProfilePhotos = this.assignProfilePhotos.bind(this);
  }

  fetchAll(params) {
    return profilePhotoEndpoint.all(params).then(this.assignProfilePhotos);
  }

  // eslint-disable-next-line class-methods-use-this
  create(params) {
    return profilePhotoEndpoint.create(params);
  }

  // eslint-disable-next-line class-methods-use-this
  delete(id) {
    return profilePhotoEndpoint.delete(id);
  }

  assignProfilePhotos(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default ProfilePhotoStore;
