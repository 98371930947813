import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import { ReactComponent as WebsiteIcon } from '@images/icons/social/website.svg';
import { ReactComponent as TwitterIcon } from '@images/icons/social/twitter.svg';
import { ReactComponent as FacebookIcon } from '@images/icons/social/facebook.svg';
import { ReactComponent as TagIcon } from '@images/icons/social/tag.svg';
import { ReactComponent as InstagramIcon } from '@images/icons/social/instagram.svg';
import { ReactComponent as BriefcaseIcon } from '@images/icons/social/briefcase.svg';
import { ReactComponent as YouTubeIcon } from '@images/icons/social/youtube.svg';
import { ReactComponent as LinkedInIcon } from '@images/icons/social/linkedIn.svg';
import { ReactComponent as XingIcon } from '@images/icons/social/xing.svg';
import { ReactComponent as KununuIcon } from '@images/icons/social/kununu.svg';

function SocialLinks() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm.components.socialLinks'
  });

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
      <div className="pb-4 mb-4 border-b border-grey-10">
        <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">{t('title')}</h2>
        <p className="text-sm md:text-base text-grey-30 max-w-2xl">{t('subtitle')}</p>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="websiteUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('websiteUrl', 'user', errors.websiteUrl)}
                label={translateAttribute('websiteUrl', 'user')}
                ref={ref}
                icon={<WebsiteIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="facebookUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('facebookUrl', 'user', errors.facebookUrl)}
                label={translateAttribute('facebookUrl', 'user')}
                ref={ref}
                icon={<FacebookIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="productsUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('productsUrl', 'user', errors.facebookUrl)}
                label={translateAttribute('productsUrl', 'user')}
                ref={ref}
                icon={<TagIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="instagramUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('instagramUrl', 'user', errors.instagramUrl)}
                label={translateAttribute('instagramUrl', 'user')}
                ref={ref}
                icon={<InstagramIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="careerUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('careerUrl', 'user', errors.careerUrl)}
                label={translateAttribute('careerUrl', 'user')}
                ref={ref}
                icon={<BriefcaseIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="youtubeUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('youtubeUrl', 'user', errors.youtubeUrl)}
                label={translateAttribute('youtubeUrl', 'user')}
                ref={ref}
                icon={<YouTubeIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="xingUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('xingUrl', 'user', errors.xingUrl)}
                label={translateAttribute('xingUrl', 'user')}
                ref={ref}
                icon={<XingIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="linkedinUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('linkedinUrl', 'user', errors.linkedinUrl)}
                label={translateAttribute('linkedinUrl', 'user')}
                ref={ref}
                icon={<LinkedInIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="kununuUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('kununuUrl', 'user', errors.kununuUrl)}
                label={translateAttribute('kununuUrl', 'user')}
                ref={ref}
                icon={<KununuIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>

        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
          <Controller
            name="twitterUrl"
            control={control}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('twitterUrl', 'user', errors.twitterUrl)}
                label={translateAttribute('twitterUrl', 'user')}
                ref={ref}
                icon={<TwitterIcon width="16" height="16" className="text-grey-25" />}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(SocialLinks);
