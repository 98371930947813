import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import CompanyApplicationForm from '@components/Forms/CompanyApplicationForm';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import HeroPhoto from '@images/honey-project/hero.jpg';
import HomeHeroGraph from '@images/more-bees-graph.svg';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import Project2028Folder from '@files/Projekt2028-Unser-Geschenksortiment.pdf';
import StatsBelt from './components/StatsBelt';
import HowItWorks from './components/HowItWorks';
import Partners from './components/Partners';
import Project2028 from './components/Project2028';
import HoneyProject from './components/HoneyProject';
import Packing from './components/Packing';
import Packing02 from './components/Packing02';
import OurPartners from './components/OurPartners';

function HoneyProjectScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'honeyProjectScreen' });
  return (
    <>
      <TitleAndDescription screenName="honeyProjectScreen" />
      <HeroWithSquarePhoto photo={HeroPhoto} alt="PROJEKT 2028-Unternehmen">
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44">{t('lead')}</div>
        <div className="mt-8 md:mt-12">
          <a
            href={Project2028Folder}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-10 w-full md:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
          >
            {t('button')}
          </a>
        </div>
        <div className="my-8 md:my-12">
          <LazyLoad>
            <img
              src={HomeHeroGraph}
              alt={t('heroGraph')}
              className="w-[228px] md:w-[367px] h-[57px] md:h-[92px]"
            />
          </LazyLoad>
        </div>
      </HeroWithSquarePhoto>
      <StatsBelt />
      <HowItWorks />
      <Partners />
      <Project2028 />
      <HoneyProject />
      <Packing />
      <Packing02 />
      <OurPartners />
      <CompanyApplicationForm supportKind="p2028_honey" />
    </>
  );
}

export default HoneyProjectScreen;
