import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './config/i18n';
import { BrowserRouter } from 'react-router-dom';
import SystemAlert from '@components/UIElements/SystemAlert';
import { SystemAlertContext } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';
import TagManager from 'react-gtm-module';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-MZ4WDH5'
  };
  TagManager.initialize(tagManagerArgs);
}

if (['production', 'staging'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1
  });
}

ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <SystemAlertContext>
          <Helmet>
            <title>Projekt2028</title>
          </Helmet>
          <App />
          <SystemAlert />
        </SystemAlertContext>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
