import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';

function CompanyProfileLogosFeed() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.companyProfileLogosFeed'
  });
  const { companyProfileLogoStore } = useStores();

  function fetchCompanyProfileLogos() {
    companyProfileLogoStore.fetchAll({
      q: { visibilityEq: 'visible' }
    });
  }

  useEffect(() => {
    fetchCompanyProfileLogos();
  }, []);

  const companyProfileLogoWrapper = (companyLogo) => {
    return (
      <div key={companyLogo.id}>
        <div>{companyLogo.name}</div>
        <img src={companyLogo.logoUrl} alt="logo" />
      </div>
    );
  };

  return (
    <div>
      <div className="text-3xl">{t('title')}</div>
      <div>{companyProfileLogoStore.collection.map(companyProfileLogoWrapper)}</div>
    </div>
  );
}

export default observer(CompanyProfileLogosFeed);
