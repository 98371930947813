import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { ROOT_PATH } from '@constants';
import Logo2028 from '@images/logo2028-orange.svg';
import HNLogo from '@images/auth-screen/hn-logo.svg';
import ConfirmPasswordForm from '@screens/PasswordReset/ConfirmPasswordScreen/components/ConfirmPasswordForm';

function RemindPasswordScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'remindPasswordScreen' });
  const { authStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.isAuthenticated) navigate(ROOT_PATH);
  }, [authStore.isAuthenticated]);

  return (
    <section className="bg-primary-light min-h-screen relative w-full flex justify-center items-center overflow-hidden">
      <div className="w-screen min-h-screen flex justify-center items-center p-4">
        <div className="bg-white rounded-xl relative z-10 w-full max-w-3xl">
          <div className="flex flex-wrap lg:px-28">
            <div className="w-full">
              <div className="py-8 lg:py-14 px-4 lg:px-10 bg-white h-full">
                <div className="flex justify-center mb-4">
                  <Link to={ROOT_PATH}>
                    <img src={Logo2028} alt={t('logoProjekt2028Alt')} width="122px" height="80" />
                  </Link>
                </div>
                <h1 className="text-grey-50 text-lg lg:text-2xl text-center font-bold leading-normal mt-4">
                  <Trans i18nKey="screens:remindPasswordScreen.title">
                    Schon als Partner*in dabei? <br /> Dann hier entlang ...
                  </Trans>
                </h1>
                <div className="mt-8">
                  <ConfirmPasswordForm />
                </div>
                <div className="mt-8 text-center text-grey-30 text-xs">
                  <div>{t('supportedBy')}</div>
                  <div className="flex justify-center mt-2">
                    <img src={HNLogo} alt={t('logoAlt')} width="97" height="39" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default observer(RemindPasswordScreen);
