import { errorLabel, translateAttribute } from '@helpers/translations';
import { Controller, useForm } from 'react-hook-form';
import { Input, Textarea, Checkbox } from 'hn-ui';
import { EMAIL_REGEXP, COMAPNY_APPLICATION_FORM_DONE_PATH } from '@constants';
import { addServerErrors } from '@helpers/errors';
import { useStores } from '@/stores';
import React, { useState, useRef } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { saveAs } from 'file-saver';
import FormPDF from '@images/form-pdf.png';
import FormPDFMobile from '@images/form-pdf-mobile.png';
import Project2028Pdf from '@files/Hektar_Nektar_Projekt2028_Firmenbienen.pdf';
import { ReactComponent as MailIcon } from '@images/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '@images/icons/phone.svg';
import PropTypes from 'prop-types';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import ApplicationFormModal from './components/ApplicationFormPopUp';

const recaptchaStyles = {
  transform: 'scale(0.77)',
  WebkitTransform: 'scale(0.77)',
  transformOrigin: 'bottom left',
  WebkitTransformOrigin: 'bottom left'
};

function CompanyApplicationForm({ supportKind }) {
  const pathname = window.location.pathname.replace(/\/+$/, '') || 'startsite';
  const modal = useRef(null);
  const navigate = useNavigate();
  const { companyApplicationStore } = useStores();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation('components', { keyPrefix: 'forms.companyApplicationForm' });
  const keyPrefix = 'components:forms.companyApplicationForm';
  const defaultValues = {
    companyName: '',
    contactName: '',
    location: '',
    email: '',
    phoneNumber: '',
    companyPosition: '',
    message: '',
    interestedAsCompany: '',
    interestedInHoneyGiveAways: ''
  };
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange', defaultValues });

  function submitForm(data) {
    setIsSubmitting(true);
    companyApplicationStore
      .create({ companyApplication: { ...data, supportKind } })
      .then(() => {
        // modal.current.open();
        navigate(
          generatePath(COMAPNY_APPLICATION_FORM_DONE_PATH, {
            path: pathname
          })
        );
        setTimeout(() => {
          saveAs(Project2028Pdf, 'Hektar_Nektar_Projekt2028_Firmenbienen.pdf');
        }, 3000);
        setIsSubmitting(false);
        reset();
      })
      .catch((error) => {
        addServerErrors({ errors: error.response.data }, setError, 'companyApplication');
        setIsSubmitting(false);
      });
  }

  const handleRecaptcha = () => {
    setSubmitDisabled(false);
  };

  const handleCaptchaExpire = () => {
    setSubmitDisabled(true);
  };

  return (
    <section id="getFolder" className="bg-primary-20 pt-10 md:pt-16">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <div className="relative">
              <div className="mt-6">
                <span className="block text-primary text-base font-bold uppercase">
                  {t('subheader')}
                </span>
                <h2 className="text-2xl lg:text-4xl font-bold mt-4 text-grey-50 leading-normal">
                  <Trans i18nKey={`${keyPrefix}.header`}>
                    Info-Folder <br /> mit Preisübersicht <br /> als Download
                  </Trans>
                </h2>
              </div>
              <div className="lg:absolute lg:top-1/4 lg:right-0 lg:transform w-3/4 lg:w-1/3 xl:w-1/2 lg:mt-6">
                <img src={FormPDF} alt={t('subheader')} className="hidden md:block mx:auto" />
              </div>
              <div className="p-8 md:hidden">
                <img
                  src={FormPDFMobile}
                  alt={t('subheader')}
                  className="mx-auto md:hidden"
                  width="230px"
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div data-cy="sign-in-form" className="bg-white p-4 md:p-8 shadow-ds12">
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <div className="input-label mb-2">Interesse an:</div>
                  </div>
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="interestedAsCompany"
                        control={control}
                        rules={{ required: false }}
                        defaultValue={false}
                        render={({ field: { onChange, value }, ref }) => (
                          <Checkbox
                            label={translateAttribute('interestedAsCompany', 'companyApplication')}
                            ref={ref}
                            error={errorLabel(
                              'interestedAsCompany',
                              'companyApplication',
                              errors.interestedAsCompany
                            )}
                            value={value}
                            onClick={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="interestedInHoneyGiveAways"
                        control={control}
                        rules={{ required: false }}
                        defaultValue={false}
                        render={({ field: { onChange, value }, ref }) => (
                          <Checkbox
                            label={translateAttribute(
                              'interestedInHoneyGiveAways',
                              'companyApplication'
                            )}
                            ref={ref}
                            error={errorLabel(
                              'interestedInHoneyGiveAways',
                              'companyApplication',
                              errors.interestedInHoneyGiveAways
                            )}
                            value={value}
                            onClick={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="companyName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder=""
                            error={errorLabel(
                              'companyName',
                              'companyApplication',
                              errors.companyName
                            )}
                            label={translateAttribute('companyName', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="location"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder=""
                            error={errorLabel('location', 'companyApplication', errors.location)}
                            label={translateAttribute('location', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="contactName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder=""
                            error={errorLabel(
                              'contactName',
                              'companyApplication',
                              errors.contactName
                            )}
                            label={translateAttribute('contactName', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t('phonePlaceholder')}
                            error={errorLabel(
                              'phoneNumber',
                              'companyApplication',
                              errors.phoneNumber
                            )}
                            label={translateAttribute('phoneNumber', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                            icon={<PhoneIcon width="16" height="16" className="text-grey-25" />}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: true,
                          pattern: {
                            value: EMAIL_REGEXP,
                            message: 'email'
                          }
                        }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t('emailPlaceholder')}
                            error={errorLabel('email', 'companyApplication', errors.email)}
                            label={translateAttribute('email', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                            icon={<MailIcon width="16" height="16" className="text-grey-25" />}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-4">
                      <Controller
                        name="companyPosition"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder=""
                            error={errorLabel(
                              'companyPosition',
                              'companyApplication',
                              errors.companyPosition
                            )}
                            label={translateAttribute('companyPosition', 'companyApplication')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <div className="mb-4">
                      <Controller
                        name="message"
                        control={control}
                        render={({ field, ref }) => (
                          <Textarea
                            {...field}
                            type="text"
                            placeholder=""
                            rows="4"
                            error={errorLabel('message', 'companyApplication', errors.message)}
                            label={translateAttribute('message', 'companyApplication')}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <div className="lg:-mt-4 overflow-hidden">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onChange={handleRecaptcha}
                        onExpired={handleCaptchaExpire}
                        hl="de"
                        style={recaptchaStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-xs text-grey-50 sm:-mt-6">*Pflichtfelder</div>

                <div className="mt-8 pb-6">
                  {isSubmitting ? (
                    <div className="h-10 flex items-center justify-center">
                      <LoaderIcon
                        width="32"
                        height="32"
                        className="animate-spin text-primary-60 fill-current"
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                      disabled={submitDisabled || !isValid}
                    >
                      {t('submit')}
                    </button>
                  )}
                  <ApplicationFormModal ref={modal} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

CompanyApplicationForm.propTypes = {
  supportKind: PropTypes.string
};

CompanyApplicationForm.defaultProps = {
  supportKind: 'project_2028_rent_beehive'
};

export default CompanyApplicationForm;
