import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorLabel } from '@helpers/translations';
import { Controller, useForm } from 'react-hook-form';
import { Input, Checkbox } from 'hn-ui';
import { EMAIL_REGEXP } from '@constants';
import NewsletterImage from '@images/newsletter.png';
import NewsletterImageComplete from '@images/newsletter-complete.png';
import NewsletterFormDecor from '@images/newsletter-form-decor.svg';
import { ReactComponent as MailIcon } from '@images/icons/mail.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import { newsletterEndpoint } from '@config/endpoints';

function Newsletter() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.newsletter' });

  const defaultValues = {
    email: '',
    newsletterAgreement: false
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isSubmitting, isSubmitted }
  } = useForm({ mode: 'onChange', defaultValues });

  function submitForm(data) {
    return newsletterEndpoint.addUser({ email: data.email });
  }
  return (
    <section id="newsletter" className="bg-primary-20 py-10 md:py-16">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap justify-between items-center -mx-4">
          <div className="w-full md:w-6/12 lg:w-5/12 px-4">
            <div className="bg-white shadow-ds8 rounded-2xl p-4 md:p-8 relative z-10">
              {isSubmitted && (
                <div className="text-center">
                  <img
                    src={NewsletterImageComplete}
                    alt={t('newsletterCompleteHead')}
                    className="mx-auto max-w-[230px] md:max-w-full"
                  />
                  <div className="mt-12">
                    <h3 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('newsletterCompleteHead')}
                    </h3>
                    <div className="text-sm md:text-base text-grey-30 mt-4 leading-normal">
                      {t('newsletterCompleteLead')}
                    </div>
                  </div>
                </div>
              )}
              {!isSubmitted && (
                <div className="">
                  <h3 className="text-xl md:text-2xl font-bold mt-2 md:mt-4 text-grey-50 break-words">
                    {t('header')}
                  </h3>
                  <div className="text-sm md:text-base text-grey-30 mt-4 leading-normal">
                    {t('lead')}
                  </div>
                  <div className="mt-8">
                    <form onSubmit={handleSubmit(submitForm)}>
                      <div className="mb-4">
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: true,
                            pattern: {
                              value: EMAIL_REGEXP,
                              message: 'email'
                            }
                          }}
                          render={({ field, ref }) => (
                            <Input
                              {...field}
                              type="text"
                              placeholder={t('emailPlaceholder')}
                              error={errorLabel('email', 'companyApplication', errors.email)}
                              label={t('emailLabel')}
                              ref={ref}
                              markAsRequired
                              icon={<MailIcon width="16" height="16" className="text-grey-25" />}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-8 mb-4">
                        <Controller
                          name="newsletterAgreement"
                          control={control}
                          rules={{ required: true }}
                          defaultValue
                          render={({ field: { onChange, value }, ref }) => (
                            <Checkbox
                              label={
                                <span
                                  className="text-grey-30"
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: t('agreement', {
                                      link: '<a href="/agbs" class="underline hover:no-underline">AGB</a>',
                                      link2:
                                        '<a href="/datenschutz" class="underline hover:no-underline">Datenschutzbestimmungen</a>'
                                    })
                                  }}
                                />
                              }
                              ref={ref}
                              error={errorLabel(
                                'newsletterAgreement',
                                'companyApplication',
                                errors.newsletterAgreement
                              )}
                              value={value}
                              onClick={onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-8 pb-6">
                        {isSubmitting ? (
                          <div className="h-10 flex items-center justify-center">
                            <LoaderIcon
                              width="32"
                              height="32"
                              className="animate-spin text-primary-60 fill-current"
                            />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                            disabled={!isValid}
                          >
                            {t('submit')}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-2/12 px-4 hidden lg:block">
            <img src={NewsletterFormDecor} alt={t('header')} className="-ml-14 mt-10" />
          </div>
          <div className="w-full md:w-6/12 lg:w-5/12 px-4">
            <img
              src={NewsletterImage}
              alt={t('header')}
              className="mx-auto max-w-[230px] md:max-w-full mt-10 md:mt-0"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
