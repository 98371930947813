import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export function SystemAlertContext({ children }) {
  const [alert, setAlert] = useState({ open: false, message: 'saved' });

  useEffect(() => {
    if (alert.open)
      setTimeout(() => {
        setAlert({ open: false, message: '' });
      }, 6000);
  }, [alert.open]);

  const value = useMemo(() => ({ alert, setAlert }), [alert.open]);
  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}

export const AlertContext = createContext(SystemAlertContext);
export const useAlert = () => useContext(AlertContext);

SystemAlertContext.propTypes = {
  children: PropTypes.node.isRequired
};
