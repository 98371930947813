import { makeAutoObservable, runInAction } from 'mobx';
import { pressReleaseEndpoint } from '@config/endpoints';

class PressReleaseStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.meta = {};
    });
  }

  bindMethods() {
    this.assignPressReleases = this.assignPressReleases.bind(this);
    this.assignPressRelease = this.assignPressRelease.bind(this);
  }

  fetchAll(params, extendCollection) {
    return pressReleaseEndpoint
      .all(params)
      .then((response) => this.assignPressReleases(response, extendCollection));
  }

  fetchOne(id) {
    return pressReleaseEndpoint.one(id).then(this.assignPressRelease);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.record = {};
    });
  }

  assignPressReleases(response, extendCollection) {
    const newCollection = response.data.data.map(({ attributes }) => attributes);

    runInAction(() => {
      if (extendCollection) this.collection = this.collection.concat(newCollection);
      else this.collection = newCollection;
      this.meta = response.data.meta;
    });
  }

  assignPressRelease(response) {
    runInAction(() => {
      this.record = response.data.data.attributes;
    });
  }
}

export default PressReleaseStore;
