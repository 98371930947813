import { makeAutoObservable, runInAction } from 'mobx';
import { profileQuestionEndpoint } from '@config/endpoints';
import _ from 'lodash';

class ProfileQuestionStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.companyProfileQuestions = [];
    });
  }

  bindMethods() {
    this.assignProfileQuestions = this.assignProfileQuestions.bind(this);
  }

  fetchCompanyProfileQuestions(params = { q: { kindEq: 'company' } }) {
    return profileQuestionEndpoint.all(params).then(this.assignProfileQuestions);
  }

  assignProfileQuestions(response) {
    runInAction(() => {
      let data = response.data.data.map(({ attributes }) => attributes);
      data = _.sortBy(data, ({ id }) => id);
      this.companyProfileQuestions = data;
    });
  }
}

export default ProfileQuestionStore;
