import { useEffect } from 'react';
import PropTypes from 'prop-types';
import sal from 'sal.js';

function IconSliderBox({ icon, head, delay }) {
  useEffect(() => {
    sal();
  }, []);
  return (
    <div
      data-sal="fade"
      data-sal-delay={delay}
      data-sal-duration="800"
      data-sal-easing="easeOutCubic"
      className="flex-grow min-w-[50vw] md:min-w-0 flex flex-col lg:flex-row items-center justify-center lg:justify-start text-primary"
    >
      <div className="flex-shrink-0 lg:mr-8">{icon}</div>
      <div className="text-center lg:text-left text-sm sm:text-base mt-6 lg:mt-0">{head}</div>
    </div>
  );
}

export default IconSliderBox;

IconSliderBox.propTypes = {
  head: PropTypes.element,
  icon: PropTypes.element,
  delay: PropTypes.string
};

IconSliderBox.defaultProps = {
  head: '',
  icon: '',
  delay: ''
};
