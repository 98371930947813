import { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { parseBool } from '@tools';
import { ReactComponent as TrashIcon } from '@images/icons/trash.svg';
import { ReactComponent as FolderIcon } from '@images/icons/folder-download.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function ProfilePhotosGallery() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm.components.profilePhotosGallery'
  });
  const [fetched, setFetched] = useState(true);
  const [photoUploading, setPhotoUploading] = useState(false);
  const {
    profilePhotoStore,
    authStore: { user }
  } = useStores();

  function reloadCollection() {
    setFetched(false);
    profilePhotoStore
      .fetchAll({ q: { userIdEq: user.id }, sortBy: 'created_at asc' })
      .then(() => setFetched(true));
  }

  function uploadPhoto(data) {
    if (!parseBool(data)) return;

    const formData = new FormData();

    formData.append('profile_photo[photo]', data);
    formData.append('profile_photo[userId]', user.id);

    profilePhotoStore
      .create(formData)
      .then(reloadCollection)
      .then(() => setPhotoUploading(false));
  }

  function removePhoto(id) {
    profilePhotoStore.delete(id).then(reloadCollection);
  }

  const onDrop = useCallback((acceptedFiles) => {
    setPhotoUploading(true);
    uploadPhoto(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles: 3
  });

  const uploadField = () => {
    if (photoUploading)
      return (
        <div className="h-full border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-white">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <LoaderIcon
              width="34"
              height="34"
              className="animate-spin text-primary-60 fill-current"
            />
          </div>
        </div>
      );

    if (isDragActive)
      return (
        <div className="h-full border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-primary-light">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <FolderIcon width="40" height="40" />
          </div>
        </div>
      );

    return (
      <div className="h-full border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 hover:bg-grey-5">
        <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
          <FolderIcon width="40" height="40" />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-2xl px-6 pt-6 pb-2 mb-6 md:mb-10">
      <div className="pb-4 mb-4 border-b border-grey-10">
        <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">{t('title')}</h2>
        <p className="text-sm md:text-base text-grey-30 max-w-2xl">{t('subtitle')}</p>
      </div>
      <div className="flex flex-wrap -mx-3">
        {fetched &&
          profilePhotoStore.collection.map((photo, index) => {
            return (
              <div className="w-full md:w-1/2 px-3" key={photo.id}>
                <div className="text-sm text-grey-50 font-medium mb-1">
                  {t('photoLabel')} {index + 1}
                </div>
                <div className="relative group mb-4">
                  <div className="aspect-w-16 aspect-h-10 rounded-lg">
                    <img src={photo.originalUrl} alt="alt" className="object-cover rounded-lg" />
                  </div>
                  <div className="transition-opacity duration-300 absolute inset-0 bg-grey-50 bg-opacity-70 rounded-lg opacity-0 group-hover:opacity-100" />
                  <button
                    type="button"
                    onClick={() => removePhoto(photo.id)}
                    className="transition-opacity duration-500 absolute top-4 right-4 text-white text-sm inline-flex items-center opacity-0 group-hover:opacity-100"
                  >
                    <TrashIcon
                      width="18px"
                      height="18px"
                      className="fill-current flex-shrink-0 mr-2"
                    />
                    {t('removePhoto')}
                  </button>
                </div>
              </div>
            );
          })}
        <div className="w-full md:w-1/2 px-3">
          <div className="mb-3">
            <div className="text-sm text-grey-50 font-medium mb-1">{t('addPhotoLabel')}</div>
            <div className="aspect-w-16 aspect-h-10 rounded-lg">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {uploadField()}
              </div>
            </div>
          </div>
        </div>
        <input type="file" placeholder={t('addPhoto')} onChange={uploadPhoto} className="hidden" />
      </div>
    </div>
  );
}

export default observer(ProfilePhotosGallery);
