import CompanyApplicationForm from '@components/Forms/CompanyApplicationForm';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import BenefitsBelt from './components/BenefitsBelt';
import HomeHero from './components/HomeHero';
import Partners from './components/Partners';
import BeeProtection from './components/BeeProtection';
import Newsletter from './components/Newsletter';
import CaseStudies from './components/CaseStudies';
import About from './components/About';
import Project2028 from './components/Project2028';
import B2B from './components/B2B';
import Impact from './components/Impact';

function HomeScreen() {
  return (
    <>
      <TitleAndDescription screenName="homeScreen" />
      <HomeHero />
      <BenefitsBelt />
      <B2B />
      <Project2028 />
      <Newsletter />
      <BeeProtection />
      <Impact />
      <Partners />
      <CaseStudies />
      <About />
      <CompanyApplicationForm />
    </>
  );
}

export default HomeScreen;
