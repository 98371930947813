import { parseBool } from '@tools';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

function SocialLink({ beekeeper, iconElement, width, spaceX, spaceY, classes, svgClasses }) {
  const { name, element } = iconElement;
  if (!parseBool(beekeeper?.[name])) return null;

  return (
    <li className={`inline-flex items-center ${classes || ''}`}>
      <a
        href={beekeeper[name]}
        rel="nofollow noopener noreferrer"
        target="_blank"
        className={`transition-colors duration-300 text-grey-30 hover:text-primary inline-block ${
          spaceY || 'py-1'
        } ${spaceX || 'px-2'}`}
      >
        <SVG
          src={element}
          width={width || 16}
          height={width || 16}
          className={`fill-current ${svgClasses}`}
        />
      </a>
    </li>
  );
}

SocialLink.propTypes = {
  beekeeper: PropTypes.objectOf(PropTypes.any).isRequired,
  iconElement: PropTypes.objectOf(PropTypes.any).isRequired,
  width: PropTypes.string,
  spaceX: PropTypes.string,
  spaceY: PropTypes.string,
  classes: PropTypes.string,
  svgClasses: PropTypes.string
};

SocialLink.defaultProps = {
  width: '',
  spaceX: '',
  spaceY: '',
  classes: '',
  svgClasses: ''
};

export default observer(SocialLink);
