import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { formatDateWithMonthNameAndYear, parseBool } from '@tools';
import Plyr from 'plyr-react';
import getVideoId from 'get-video-id';
import { ReactComponent as AvatarMask } from '@images/avatar-mask-72.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as PlayIcon } from '@images/icons/play.svg';
import VideoModal from '@components/UIElements/Modal';
import 'slick-carousel/slick/slick.css';
import 'plyr-react/dist/plyr.css';
import Slider from '@components/UIElements/Slider';
import _ from 'lodash';
import DefaultProfile from '@images/placeholders/default-profile.png';

function TimelineNote({ timelineNote, path }) {
  const modal = useRef(null);
  const { slug, author, youtubeUrl, title, body, date, mediumPhotos, companyLogo } = timelineNote;
  const videoSrc = (url) => {
    const videoDetails = getVideoId(url);
    return {
      type: 'video',
      sources: [{ src: `${videoDetails.id}`, provider: 'youtube' }]
    };
  };

  const youtubeUrlWrapper = () => {
    if (!parseBool(youtubeUrl)) return <div />;

    return (
      <div className="flex-shrink-0">
        <button
          onClick={() => modal.current.open()}
          type="button"
          className="text-primary transition-colors ease-in-out duration-300 outline-none focus:outline-none"
        >
          <PlayIcon width="32" height="32" className="fill-current stroke-current" />
        </button>
        <VideoModal ref={modal}>
          <div className="w-full max-w-screen-lg overflow-y-auto">
            <Plyr source={videoSrc(youtubeUrl)} />
          </div>
        </VideoModal>
      </div>
    );
  };

  return (
    <div key={slug} className="w-full md:w-1/2 xl:w-1/3 px-3 my-3">
      <div className="p-4 bg-white rounded-2xl shadow-ds16 h-full flex flex-col justify-between">
        <div className="h-full flex flex-col justify-between">
          <div>
            <Link to={path}>
              <Slider>
                {mediumPhotos.map((photo) => {
                  return (
                    <div key="photo">
                      <div className="aspect-w-4 aspect-h-3">
                        <LazyLoad>
                          <img
                            src={photo}
                            alt={title}
                            className="transition-opacity duration-300 object-cover absolute inset-0 w-full h-full rounded-2xl group-hover:opacity-90"
                          />
                        </LazyLoad>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </Link>

            <div className="flex items-center justify-between">
              <div className="flex items-center pl-4 pt-4">
                <div className="relative flex-shrink-0 mr-4 text-primary-40">
                  <AvatarMaskBack
                    width="72px"
                    height="61px"
                    className="fill-current absolute -top-2 -left-3"
                  />
                  <img
                    src={
                      author.avatar.startsWith('/assets/beekeepers/default-profile')
                        ? DefaultProfile
                        : author.avatar
                    }
                    alt={author.name}
                    className="rounded-xl object-cover avatar-mask-72"
                    width="71px"
                    height="72px"
                  />
                </div>
                <div className="transform -translate-y-1">
                  <h3 className="text-grey-50 text-xs md:text-sm font-bold">{author.name}</h3>
                  <div className="text-grey-50 text-xs md:text-sm">{`${author.livingAddress?.city} ${author.livingAddress?.country?.code}`}</div>
                </div>
              </div>
              {youtubeUrlWrapper()}
            </div>
            <Link to={path}>
              <h3 className="text-base text-grey-50 font-bold mt-4">{title}</h3>
              <div className="my-2">
                <div className="text-grey-30 text-sm">{_.truncate(body, { length: 150 })}</div>
              </div>
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-primary text-xs my-4">{formatDateWithMonthNameAndYear(date)}</div>
            <div className="h-8 relative flex">
              <img src={companyLogo} alt="" className="h-full" height="32px" />
            </div>
          </div>
        </div>
        <AvatarMask width="0" height="0" />
      </div>
    </div>
  );
}

TimelineNote.propTypes = {
  timelineNote: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired
};

export default TimelineNote;
