import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import HexDecoration from './components/HexDecoration';

function HeroWithSquarePhoto({ photo, alt, backgroundColor, children }) {
  return (
    <section className={`${backgroundColor || 'bg-white'} overflow-hidden pt-8 lg:pt-0`}>
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="flex flex-wrap items-center -mx-3">
            <div className="w-full lg:w-1/2 px-3 relative z-10">{children}</div>
            <div className="w-full lg:w-1/2 px-3">
              <div className="relative z-10 h-full w-full">
                <div className="h-full -mx-6 lg:mx-0 transform lg:translate-x-16">
                  <div className="aspect-w-16 aspect-h-14">
                    <LazyLoad>
                      <img
                        src={photo}
                        alt={alt}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HexDecoration />
        </div>
      </div>
    </section>
  );
}

export default HeroWithSquarePhoto;

HeroWithSquarePhoto.propTypes = {
  photo: PropTypes.string,
  alt: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object)
};

HeroWithSquarePhoto.defaultProps = {
  photo: '',
  alt: '',
  backgroundColor: '',
  children: []
};
