import PropTypes from 'prop-types';

function IconSlider({ backgroundColor, children }) {
  return (
    <section className={`${backgroundColor} py-10 sm:py-12`}>
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex space-x-4 md:space-x-2 overflow-x-auto no-scrollbar">{children}</div>
      </div>
    </section>
  );
}

export default IconSlider;

IconSlider.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object)
};

IconSlider.defaultProps = {
  backgroundColor: '',
  children: []
};
