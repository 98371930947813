import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY_PATH } from '@constants';
import CookieConsent from 'react-cookie-consent';

function CookieBanner() {
  const { t } = useTranslation('components', { keyPrefix: 'uiElements.cookieBanner' });

  return (
    <div className="fixed z-[210] bottom-0 left-0 w-full bg-grey-50">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <CookieConsent
          location="bottom"
          buttonText={t('buttonText')}
          declineButtonText={t('learnMoreButton')}
          cookieName="cookieConsent"
          contentClasses="test"
          buttonWrapperClasses="flex justify-center w-full md:w-auto"
          style={{
            backgroundColor: 'transparent',
            position: 'static',
            alignItems: 'center',
            padding: '24px 0'
          }}
          contentStyle={{
            margin: 0,
            flex: '1 0 300px'
          }}
          buttonStyle={{
            color: '#ffffff',
            fontSize: '13px',
            fontWeight: 400,
            borderRadius: '4px',
            padding: '8px 24px',
            margin: '16px 8px',
            backgroundColor: 'rgba(116, 97, 237, 1)'
          }}
          expires={365}
        >
          <div className="max-w-screen-container mx-auto">
            <div className="flex flex-wrap items-center justify-between">
              <div className="md:mr-8 text-xs md:text-sm max-w-3xl">
                <Trans i18nKey="components:uiElements.cookieBanner.body">
                  Wir benützen 🍪 Cookies um die Benutzerfreundlichkeit der Webseite zu erhöhen.
                  Wenn Sie auf „Ich akzeptiere“ klicken, stimmen Sie diesen zu. Sie können die
                  Cookie-Einstellungen später jederzeit wieder ändern.
                  <Link to={PRIVACY_POLICY_PATH} className="underline hover:no-underline">
                    Erfahren Sie mehr.
                  </Link>
                </Trans>
              </div>
            </div>
          </div>
        </CookieConsent>
      </div>
    </div>
  );
}

export default CookieBanner;
