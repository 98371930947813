import { ROOT_PATH } from '@constants';
import { addServerErrors } from '@helpers/errors';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function ConfirmPasswordForm() {
  const { authStore } = useStores();
  const { t } = useTranslation('screens', {
    keyPrefix: 'confirmPasswordScreen.components.confirmPasswordForm'
  });
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  function submitForm(data) {
    setIsSubmitting(true);
    const headers = {
      'access-token': searchParams.get('access-token'),
      uid: searchParams.get('uid'),
      client: searchParams.get('client')
    };

    authStore
      .confirmPassword(headers, data)
      .then(() => {
        authStore.validateToken().then(() => navigate(ROOT_PATH));
        setIsSubmitting(false);
      })
      .catch((error) => {
        addServerErrors({ errors: { user: error.response.data.errors } }, setError);
        setIsSubmitting(false);
      });
  }

  const handleRecaptcha = () => {
    setSubmitDisabled(false);
  };

  const handleCaptchaExpire = () => {
    setSubmitDisabled(true);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="mb-4">
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field, ref }) => (
            <Input
              {...field}
              type="password"
              placeholder=""
              error={errorLabel('password', 'user', errors.password)}
              label={translateAttribute('password', 'user')}
              ref={ref}
              markAsRequired
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="passwordConfirmation"
          control={control}
          rules={{ required: true }}
          render={({ field, ref }) => (
            <Input
              {...field}
              type="password"
              placeholder=""
              error={errorLabel('passwordConfirmation', 'user', errors.passwordConfirmation)}
              label={translateAttribute('passwordConfirmation', 'user')}
              ref={ref}
              markAsRequired
            />
          )}
        />
      </div>
      <div className="mt-4">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={handleRecaptcha}
          onExpired={handleCaptchaExpire}
          hl="de"
        />
      </div>

      <div className="mt-8">
        {isSubmitting ? (
          <div className="h-10 flex items-center justify-center">
            <LoaderIcon
              width="32"
              height="32"
              className="animate-spin text-primary-60 fill-current"
            />
          </div>
        ) : (
          <button
            type="submit"
            className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-2 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
            disabled={submitDisabled}
          >
            {t('submit')}
          </button>
        )}
      </div>
    </form>
  );
}

export default ConfirmPasswordForm;
