import { useTranslation } from 'react-i18next';
import SectionHead from '@components/UIElements/SectionHead';
import MartinPhoto from '@images/about/team/martin.png';
import MarkPhoto from '@images/about/team/mark.png';
import MiriamPhoto from '@images/about/team/miriam.png';
import RudiPhoto from '@images/about/team/rudi.png';
import JolanthePhoto from '@images/about/team/jolanthe.png';
import FlorianPhoto from '@images/about/team/florian.png';
import KokoPhoto from '@images/about/team/koko.png';
import JudithPhoto from '@images/about/team/judith.png';
import TeamMemberBox from './components/TeamMemberBox';

function Team() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'aboutScreen.components.team'
  });
  return (
    <section className="bg-primary-light py-10 md:py-12">
      <div className="max-w-screen-xl mx-auto px-4 2xl:px-14">
        <SectionHead header={t('header')} subheader={t('subheader')} lead={t('lead')} />
        <div className="mt-4 md:mt-12">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={MartinPhoto}
                head={t('members.members01.name')}
                position={t('members.members01.position')}
                description={t('members.members01.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={MarkPhoto}
                head={t('members.members02.name')}
                position={t('members.members02.position')}
                description={t('members.members02.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={MiriamPhoto}
                head={t('members.members03.name')}
                position={t('members.members03.position')}
                description={t('members.members03.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={RudiPhoto}
                head={t('members.members04.name')}
                position={t('members.members04.position')}
                description={t('members.members04.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={JolanthePhoto}
                head={t('members.members05.name')}
                position={t('members.members05.position')}
                description={t('members.members05.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={JudithPhoto}
                head={t('members.members09.name')}
                position={t('members.members09.position')}
                description={t('members.members09.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={FlorianPhoto}
                head={t('members.members07.name')}
                position={t('members.members07.position')}
                description={t('members.members07.description')}
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3">
              <TeamMemberBox
                photo={KokoPhoto}
                head={t('members.members08.name')}
                position={t('members.members08.position')}
                description={t('members.members08.description')}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
