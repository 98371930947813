import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeIcon } from '@images/icons/eye.svg';
import { formatDateWithLocale } from '@tools';
import { Link } from 'react-router-dom';

function ActivityFeedBox({ thumb, name, month, personThumb, personName, date, url, urlState }) {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.companyActivityFeed'
  });
  return (
    <div className="flex items-center my-4">
      <div className="w-3/4 md:w-1/2">
        <div className="flex items-center">
          <div className="w-12 h-12 md:w-16 md:h-16 rounded-md mr-4 flex-shrink-0">
            <div className="aspect-w-1 aspect-h-1 rounded-md">{thumb}</div>
          </div>
          <div className="truncate pr-3">
            <h3 className="text-sm md:text-base text-grey-50 font-bold truncate">{name}</h3>
            <h4 className="text-xs md:text-sm text-grey-30 truncate">{month}</h4>
          </div>
        </div>
      </div>
      <div className="w-1/4 md:w-1/2">
        <div className="flex items-center justify-end">
          <div className="hidden md:flex items-center basis-1/3 flex-grow-0 flex-shrink">
            <div className="w-6 h-6 rounded-full mr-2 flex-shrink-0">
              <div className="aspect-w-1 aspect-h-1 rounded-md">
                <img src={personThumb} alt={personName} className="rounded-full object-cover" />
              </div>
            </div>
            <div className="text-base text-grey-30">{personName}</div>
          </div>
          <div className="hidden md:flex justify-center text-base text-grey-30 text-center basis-1/3 flex-grow-0 flex-shrink">
            {formatDateWithLocale(date)}
          </div>
          <div className="flex items-center justify-end md:basis-1/3 flex-grow-0 flex-shrink">
            <Link
              to={url}
              state={urlState}
              className="inline-flex items-centertransition-colors duration-300 text-base text-grey-30 hover:text-primary"
            >
              <EyeIcon width="24" height="24" className="stroke-current flex-shrink-0" />
              <span className="hidden md:inline-block ml-2">{t('display')}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityFeedBox;

ActivityFeedBox.propTypes = {
  thumb: PropTypes.node,
  name: PropTypes.string,
  month: PropTypes.string,
  personThumb: PropTypes.string,
  personName: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  urlState: PropTypes.objectOf(PropTypes.object).isRequired
};

ActivityFeedBox.defaultProps = {
  thumb: null,
  name: '',
  month: '',
  personThumb: '',
  personName: '',
  date: '',
  url: ''
};
