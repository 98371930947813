import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import { useStores } from '@/stores';
import { EMAIL_REGEXP, ROOT_PATH, REMIND_PASSWORD_PATH } from '@constants';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addServerErrors } from '@helpers/errors';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as EyeIcon } from '@images/icons/eye.svg';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function SignInForm() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setAlert } = useAlert();
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation('screens', { keyPrefix: 'authScreen.components.signInForm' });
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    watch,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange' });

  const watchAllFields = watch();

  useEffect(() => {
    if (errors.user && errors.user.message.length > 0 && isValid) clearErrors('user');
  }, [watchAllFields]);

  function submitForm(data) {
    setIsSubmitting(true);
    authStore
      .signIn(data)
      .then(() => {
        navigate(ROOT_PATH);
        setAlert({ message: t('formSaved'), open: true });
        setIsSubmitting(false);
      })
      .catch((error) => {
        addServerErrors({ errors: { user: error.response.data.errors } }, setError);
        setIsSubmitting(false);
      });
  }

  const handleRecaptcha = () => {
    setSubmitDisabled(false);
  };

  const handleCaptchaExpire = () => {
    setSubmitDisabled(true);
  };

  return (
    <div data-cy="sign-in-form" className="w-full">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="mb-4">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: EMAIL_REGEXP,
                message: 'email'
              }
            }}
            render={({ field, ref }) => (
              <Input
                {...field}
                type="text"
                placeholder=""
                error={errorLabel('email', 'user', errors.email)}
                label={translateAttribute('email', 'user')}
                ref={ref}
                markAsRequired
              />
            )}
          />
        </div>
        <div className="mb-2">
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field, ref }) => (
              <div className="relative">
                <div className="absolute top-1/2 right-4 transform -translate-y-1/2 mt-2">
                  <EyeIcon
                    onClick={() => setPasswordShown(!passwordShown)}
                    width="16px"
                    height="16px"
                    className="text-grey-30 stroke-current"
                  />
                </div>
                <Input
                  {...field}
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete="on"
                  placeholder=""
                  error={errorLabel('password', 'user', errors.password)}
                  label={translateAttribute('password', 'user')}
                  ref={ref}
                  markAsRequired
                />
                <div className="absolute top-0 right-0 leading-none">
                  <Link
                    to={REMIND_PASSWORD_PATH}
                    className="transition-colors duration-300 text-xs text-primary font-medium hover:text-grey-50"
                  >
                    {t('forgotPassword')}
                  </Link>
                </div>
              </div>
            )}
          />
        </div>
        <div className="text-red text-sm">{errorLabel('user', 'user', errors.user)}</div>

        <div className="mt-4">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={handleRecaptcha}
            onExpired={handleCaptchaExpire}
            hl="de"
          />
        </div>

        <div className="mt-8">
          {isSubmitting ? (
            <div className="h-10 flex items-center justify-center">
              <LoaderIcon
                width="32"
                height="32"
                className="animate-spin text-primary-60 fill-current"
              />
            </div>
          ) : (
            <button
              type="submit"
              className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
              disabled={submitDisabled}
            >
              {t('signIn')}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default observer(SignInForm);
