import PropTypes from 'prop-types';

function FooterHead({ title }) {
  return (
    <h5 className="text-white text-sm md:text-base font-bold mt-6 md:mt-0 mb-4 md:mb-8">{title}</h5>
  );
}

export default FooterHead;

FooterHead.propTypes = {
  title: PropTypes.string
};

FooterHead.defaultProps = {
  title: ''
};
