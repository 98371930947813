import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SkeletonPRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact/Skeleton';
import _ from 'lodash';

function BeekeepersSkeletonLoadingScreen() {
  return (
    <>
      <section className="my-2 mb-2">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <div className="rounded-2xl h-full flex justify-between items-end">
                <Skeleton
                  containerClassName="w-full h-full"
                  width="100%"
                  className="h-24 md:h-32"
                  borderRadius={20}
                />
              </div>
            </div>
            <div className="hidden lg:block w-full lg:w-4/12 xl:w-1/4 px-3">
              <SkeletonPRContact />
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
            <div className="pb-6">
              <div className="flex flex-wrap -mx-3">
                {_.times(6, (i) => (
                  <div key={i} className="w-full md:w-1/2 xl:w-1/3 px-3 mt-4">
                    <div className="rounded-2xl h-full transform translate-y-0 ">
                      <div className="rounded-t-2xl">
                        <div className="flex items-end justify-between p-4">
                          <h3 className=" w-40 h-10 text-lg md:text-2xl text-primary-120 font-bold mb-1 md:mb-3">
                            <Skeleton className="w-10 h-10" />
                          </h3>
                          <div className="w-16 h-16 border-white rounded-full relative flex-shrink-0">
                            <Skeleton
                              width="100%"
                              height="100%"
                              borderRadius={50}
                              className="w-full h-full object-cover absolute inset-0 rounded-full"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="flex items-center justify-between">
                          <div className="text-xs text-primary">
                            <Skeleton width={100} />
                          </div>
                          <ul className="flex items-center">
                            <Skeleton />
                          </ul>
                        </div>
                        <div className="text-grey-30 text-sm mt-4">
                          {_.times(3, (j) => (
                            <div key={j} className="flex items-center my-2">
                              <Skeleton
                                className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                                containerClassName="w-full"
                              />
                              <div className="truncate">
                                <Skeleton />
                              </div>
                            </div>
                          ))}
                          <div className="flex justify-end p-4 mt-6">
                            <div className="text-primary ">
                              <Skeleton width={24} height={24} className="stroke-current" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:hidden mb-6">
              <SkeletonPRContact />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default BeekeepersSkeletonLoadingScreen;
