import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function LinkItem({ href, label }) {
  return (
    <li>
      <Link
        to={href}
        className="text-white leading-loose text-xs lg:text-sm transition-opacity duration-300 hover:opacity-80 py-1 sm:py-0 block sm:inline-block truncate"
      >
        {label}
      </Link>
    </li>
  );
}

export default LinkItem;

LinkItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string
};

LinkItem.defaultProps = {
  href: '',
  label: ''
};
