import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import CompanyApplicationForm from '@components/Forms/CompanyApplicationForm';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import HeroPhoto from '@images/about/hero.jpg';
import HomeHeroGraph from '@images/more-bees-graph.svg';
import OurStory01 from './components/OurStory01';
import OurStory02 from './components/OurStory02';
import Team from './components/Team';

function AboutScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'aboutScreen' });
  return (
    <>
      <HeroWithSquarePhoto
        backgroundColor="bg-primary-light"
        photo={HeroPhoto}
        alt="PROJEKT 2028-Partner"
      >
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44">{t('lead')}</div>
        <div className="my-8 md:my-16">
          <LazyLoad>
            <img
              src={HomeHeroGraph}
              alt={t('heroGraph')}
              className="w-[228px] md:w-[367px] h-[57px] md:h-[92px]"
            />
          </LazyLoad>
        </div>
      </HeroWithSquarePhoto>
      <OurStory01 />
      <OurStory02 />
      <Team />
      <CompanyApplicationForm supportKind="p2028_about_us" />
    </>
  );
}

export default AboutScreen;
