import { makeAutoObservable, runInAction } from 'mobx';
import { TOKEN_STORAGE_KEY } from '@constants';
import { authEndpoint } from '@config/endpoints';
import { parseBool } from '@tools';
import AddressStore from '@/stores/ProfileStores/AddressStore';
import BeekeeperStore from '@/stores/GlobalStores/BeekeeperStore';

class AuthStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);

    this.livingAddressStore = new AddressStore();
    this.beekeeperStore = new BeekeeperStore();
    this.user = {};
  }

  bindMethods() {
    this.assignUserAndFetchAddress = this.assignUserAndFetchAddress.bind(this);
    this.removeToken = this.removeToken.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  initFields() {
    runInAction(() => {
      this.token = undefined;
      this.user = {};
      this.tokenChecked = false;
    });
  }

  get isAuthenticated() {
    return parseBool(this.token);
  }

  get userLivingAddress() {
    return this.livingAddressStore.address;
  }

  signIn(signInParams) {
    return authEndpoint
      .signIn(signInParams)
      .then((response) => {
        this.assignToken(response.headers);
        this.assignUserAndFetchAddress(response);
      })
      .catch((error) => {
        throw error;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  resetPassword(params) {
    return authEndpoint.resetPassword(params);
  }

  // eslint-disable-next-line class-methods-use-this
  confirmPassword(headers, params) {
    return authEndpoint.confirmPassword(headers, params);
  }

  update(params) {
    return authEndpoint.update({ registration: params }).then((response) => {
      runInAction(() => {
        this.user = response.data.data.attributes;
      });
    });
  }

  updateFiles(files) {
    return authEndpoint.update(files).then((response) => {
      runInAction(() => {
        this.user = response.data.data.attributes;
      });
    });
  }

  checkIfSigned() {
    const token = JSON.parse(window.localStorage.getItem(TOKEN_STORAGE_KEY));
    this.tokenChecked = false;

    if (parseBool(token)) {
      this.token = token;
      this.validateToken()
        .then(() => {
          runInAction(() => {
            this.tokenChecked = true;
          });
        })
        .catch(() => {
          this.removeToken();
          this.tokenChecked = true;
        });
    } else {
      this.removeToken();
      runInAction(() => {
        this.tokenChecked = true;
      });
    }
  }

  assignToken(headers) {
    const newToken = {
      accessToken: headers['access-token'],
      client: headers.client,
      uid: headers.uid
    };
    this.token = newToken;
    window.localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(newToken));
  }

  validateToken() {
    return authEndpoint
      .validateToken()
      .then((response) => this.assignUserAndFetchAddress(response, true));
  }

  removeToken() {
    this.token = undefined;
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  logOut() {
    this.removeToken();
  }

  assignUserAndFetchAddress(response, withRelationships = false) {
    const {
      attributes,
      relationships: { livingAddress, assignedBeekeepers }
    } = response.data.data;

    this.user = attributes;
    this.user.beekeeperIds = assignedBeekeepers.data.map(({ id }) => id);

    if (livingAddress) {
      this.user.livingAddressId = livingAddress.data.id;

      if (withRelationships) this.livingAddressStore.fetch(livingAddress.data.id);
    }
  }
}

export default AuthStore;
