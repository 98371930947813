import { makeAutoObservable, runInAction } from 'mobx';
import { companyFileEndpoint } from '@config/endpoints';

class NestedCompanyFileStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignCompanyFiles = this.assignCompanyFiles.bind(this);
  }

  fetchAll(params) {
    return companyFileEndpoint.all(params).then(this.assignCompanyFiles);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }

  async assignCompanyFiles(response) {
    const newCollection = response.data.data.map(({ attributes }) => ({
      ...attributes
    }));

    runInAction(() => {
      this.collection = newCollection;
    });
  }
}

export default NestedCompanyFileStore;
