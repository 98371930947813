import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CommunicationSliderContentBox from './components/CommunicationSliderContentBox';

function CommunicationSlider({ title, data }) {
  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <h2 className="text-primary text-base font-bold uppercase mb-4">{title}</h2>
        <Tabs>
          <TabList className="flex mb-8 space-x-2 lg:space-x-4 overflow-x-auto no-scrollbar">
            {data.map((tab) => {
              return (
                <Tab
                  key={tab.name}
                  className="shrink-0 text-base lg:text-lg font-medium px-4 py-2 cursor-pointer outline-none focus:outline-none tabs__tab"
                >
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>

          {data.map((tabPanel) => {
            return (
              <TabPanel key={tabPanel.name}>
                <CommunicationSliderContentBox
                  sliderToShowNumber={tabPanel.itemsToShowNumber}
                  slides={tabPanel.items}
                  itemsNumber={tabPanel.items.length}
                />
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
}

export default CommunicationSlider;

CommunicationSlider.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

CommunicationSlider.defaultProps = {
  title: ''
};
