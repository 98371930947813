import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import CooperationModal from '@components/UIElements/Modal';
import { ReactComponent as EmailIllustration } from '@images/email-popup.svg';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { formatDate, parseBool } from '@tools';

function CompanyStatus() {
  const {
    authStore: { user }
  } = useStores();
  const modal = useRef(null);
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.companyStatus'
  });

  const endingContractDateBox = () => {
    if (parseBool(user.projekt2028ContractDateEnd))
      return (
        <>
          <div className="text-sm text-grey-50 font-bold mb-2">
            <span>{t('expirationDate')} </span>
            <span className="text-primary">{formatDate(user.projekt2028ContractDateEnd)}</span>
          </div>
          <div className="text-sm">
            <button
              type="button"
              onClick={() => modal.current.open()}
              className="text-primary hover:underline"
            >
              {t('contactButtonModal')}
            </button>
          </div>
        </>
      );

    return <div />;
  };

  return (
    <>
      <div className="rounded-2xl bg-white shadow-ds16">
        <div className="bg-primary-120 rounded-t-2xl px-4 py-4">
          <h3 className="text-white text-lg md:text-2xl font-bold">{t('title')}</h3>
        </div>
        <div className="p-4">
          <div className="text-sm text-grey-50 font-bold mb-2">
            <span>{t('beesNumber')}</span>
            <span className="text-primary">{user.projekt2028AvailableBeekeepers * 50000}</span>
          </div>
          {endingContractDateBox()}
        </div>
      </div>
      <CooperationModal ref={modal}>
        <div className="bg-white rounded-2xl p-6 md:p-10 w-full max-w-[466px] max-h-[90vh] overflow-y-auto">
          <div className="flex flex-col justify-center items-center">
            <div>
              <EmailIllustration width="276" />
            </div>
            <h3 className="text-center text-lg md:text-2xl text-grey-50 font-bold mt-6 mb-2">
              {t('contactTitle')}
            </h3>
            <div className="mt-2 w-full text-center text-grey-30 text-base">{t('contactLead')}</div>
            <div className="mt-2 w-full text-center">
              <a href={`mailto:${t('contactEmail')}`} className="text-primary hover:underline">
                {t('contactEmail')}
              </a>
            </div>
          </div>
        </div>
      </CooperationModal>
    </>
  );
}

export default observer(CompanyStatus);
