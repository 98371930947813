import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import PackingPhoto01 from '@images/honey-project/honey-project/01.png';
import PackingPhoto02 from '@images/honey-project/honey-project/02.png';
import PackingPhoto03 from '@images/honey-project/honey-project/03.png';
import { ReactComponent as NextArrowIcon } from '@images/icons/next.svg';
import { ReactComponent as PrevArrowIcon } from '@images/icons/prev.svg';
import 'slick-carousel/slick/slick.css';

function SampleNextArrow({ onClick }) {
  return (
    <button
      type="button"
      className="hidden outline-none focus:outline-none text-grey-50 w-8 h-8 rounded-full md:flex items-center justify-center absolute z-10 left-24 mt-4 top-full carousel-arrow"
      onClick={onClick}
    >
      <NextArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

SampleNextArrow.propTypes = {
  onClick: PropTypes.func
};

SampleNextArrow.defaultProps = {
  onClick: () => {}
};

function SamplePrevArrow({ onClick }) {
  return (
    <button
      type="button"
      className="hidden outline-none focus:outline-none w-8 h-8 rounded-full md:flex items-center justify-center absolute z-10 -left-2 mt-4 top-full carousel-arrow"
      onClick={onClick}
    >
      <PrevArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

SamplePrevArrow.propTypes = {
  onClick: PropTypes.func
};

SamplePrevArrow.defaultProps = {
  onClick: () => {}
};

function HoneyProject() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'honeyProjectScreen.components.honeyProject'
  });
  const [carouselBoxesNav, setCarousleBoxesNav] = useState(null);
  const [carouselImagesNav, setCarouselImagesNav] = useState(null);
  const carouselBoxes = useRef(null);
  const carouselLogos = useRef(null);

  useEffect(() => {
    setCarousleBoxesNav(carouselBoxes.current);
    setCarouselImagesNav(carouselLogos.current);
  }, []);

  const carouselImagesSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    fade: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true
        }
      }
    ]
  };

  const carouselBoxesSettings = {
    dots: true,
    arrows: true,
    speed: 2000,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      }
    ]
  };

  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex md:flex-row-reverse flex-wrap items-center -mx-3">
          <div className="w-full md:w-1/2 px-3">
            <div className="lg:pl-20 md:py-4">
              <span className="block text-primary text-sm md:text-base font-bold uppercase">
                {t('subheader')}
              </span>
              <div className="slider-combine-dots-and-arrows">
                <Slider asNavFor={carouselImagesNav} ref={carouselBoxes} {...carouselBoxesSettings}>
                  <div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('box01.header')}
                    </h2>
                    <div className="text-sm md:text-base text-grey-30 mt-4">{t('box01.lead')}</div>
                  </div>
                  <div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('box02.header')}
                    </h2>
                    <div className="text-sm md:text-base text-grey-30 mt-4">{t('box02.lead')}</div>
                  </div>
                  <div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('box03.header')}
                    </h2>
                    <div className="text-sm md:text-base text-grey-30 mt-4">{t('box03.lead')}</div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <div className="slider-dots-center slider-dots-no-dots-from-tablet">
              <Slider asNavFor={carouselBoxesNav} ref={carouselLogos} {...carouselImagesSettings}>
                <div>
                  <img src={PackingPhoto01} alt={t('box01.header')} />
                </div>
                <div>
                  <img src={PackingPhoto02} alt={t('box02.header')} />
                </div>
                <div>
                  <img src={PackingPhoto03} alt={t('box03.header')} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HoneyProject;
