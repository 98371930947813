import { makeAutoObservable, runInAction } from 'mobx';
import { adminUserEndpoint } from '@config/endpoints';

class UserStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignAdminUsers = this.assignAdminUsers.bind(this);
  }

  fetchAll(params) {
    return adminUserEndpoint.all(params).then(this.assignAdminUsers);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }

  assignAdminUsers(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default UserStore;
