import { useTranslation } from 'react-i18next';

function PrivacyPolicyScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'privacyPolicyScreen' });
  return (
    <section>
      <div className="bg-primary-light py-14">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <h1 className="text-2xl md:text-4xl text-grey-50 font-bold">{t('title')}</h1>
        </div>
      </div>
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="py-14 text-grey-50 text-sm md:text-base max-w-5xl text-entry">
          <p>Datenschutzerklärung nach der DSGVO</p>
          <h2 className="font-bold">I. Name und Anschrift des Verantwortlichen</h2>
          <p>
            Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
            Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher
            Bestimmungen ist die:
          </p>
          <p>
            Hektar Nektar GmbH
            <br />
            Agnesstraße 12/4/6
            <br />
            3400 Klosterneuburg
            <br />
            FN 473290s
            <br />
            Österreich
            <br />
            Tel.: 0043 676 546 03 40
            <br />
            E-Mail: office@hektarnektar.com
            <br />
            Website: <a href="https://hektarnektar.com/de">www.hektarnektar.com</a>
          </p>
          <h2 className="font-bold">II. Allgemeines zur Datenverarbeitung</h2>
          <h3 className="font-medium">1. Umfang der Verarbeitung personenbezogener Daten</h3>
          <p>
            Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur,
            soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und
            Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer
            Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in
            solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen
            Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften
            gestattet ist.
          </p>
          <h3 className="font-medium">
            2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
          </h3>
          <p>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
            betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
            (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages,
            dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1
            lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
            Durchführung vorvertraglicher Maßnahmen erforderlich sind.
          </p>
          <p>
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
            Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1
            lit. c DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
            natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient
            Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder
            eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und
            Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1
            lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
          </p>
          <h3 className="font-medium">3. Datenlöschung und Speicherdauer</h3>
          <p>
            Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald
            der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen,
            wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche
            unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann,
            wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn,
            dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen
            Vertragsabschluss oder eine Vertragserfüllung besteht.
          </p>
          <h2 className="font-bold">III. Bereitstellung der Website und Erstellung von Logfiles</h2>
          <h3 className="font-medium">1. Beschreibung und Umfang der Datenverarbeitung</h3>
          <p>
            Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
            Informationen vom Computersystem des aufrufenden Rechners.
          </p>
          <p>Folgende Daten werden hierbei erhoben:</p>
          <ul className="ml-6">
            <li>a) Informationen über den Browsertyp und die verwendete Version</li>
            <li>b) Das Betriebssystem des Nutzers</li>
            <li>c) Den Internet-Service-Provider des Nutzers</li>
            <li>d) Die IP-Adresse des Nutzers</li>
            <li>e) Datum und Uhrzeit des Zugriffs</li>
            <li>f) Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
            <li>g) Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
          </ul>
          <p>
            Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung
            dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht
            statt.
          </p>
          <h3 className="font-medium">2. Rechtsgrundlage für die Datenverarbeitung</h3>
          <p>
            Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6
            Abs. 1 lit. f DSGVO.
          </p>
          <h3 className="font-medium">3. Zweck der Datenverarbeitung</h3>
          <p>
            Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine
            Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die
            IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben.
          </p>
          <p>
            Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website
            sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur
            Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung
            der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
          </p>
          <p>
            In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach
            Art. 6 Abs. 1 lit. f DSGVO.
          </p>
          <h3 className="font-medium">4. Dauer der Speicherung</h3>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der
            Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist.
          </p>
          <p>
            Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der
            Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die
            IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden
            Clients nicht mehr möglich ist.
          </p>
          <h3 className="font-medium">5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
          <p>
            Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in
            Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht
            folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
          </p>
          <h2 className="font-bold">IV. Verwendung von Cookies</h2>
          <h3 className="font-medium">1. Beschreibung und Umfang der Datenverarbeitung</h3>
          <p>
            Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im
            Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert
            werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des
            Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge,
            die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website
            ermöglicht.
          </p>
          <p>
            Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige
            Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem
            Seitenwechsel identifiziert werden kann.
          </p>
          <p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>
          <ul className="ml-6">
            <li>a) Spracheinstellungen</li>
            <li>b) Artikel in einem Warenkorb</li>
            <li>c) Log-In-Informationen</li>
          </ul>
          <p>
            Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des
            Surfverhaltens der Nutzer ermöglichen.
          </p>
          <p>Auf diese Weise können folgende Daten übermittelt werden:</p>
          <ul className="ml-6">
            <li>a) Eingegebene Suchbegriffe</li>
            <li>b) Häufigkeit von Seitenaufrufen</li>
            <li>c) Inanspruchnahme von Website-Funktionen</li>
          </ul>
          <p>
            Die auf diese Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen
            pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht mehr
            möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten der
            Nutzer gespeichert.
          </p>
          <p>
            Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner über die Verwendung
            von Cookies zu Analysezwecken informiert und auf diese Datenschutzerklärung verwiesen.
            Es erfolgt in diesem Zusammenhang auch ein Hinweis darauf, wie die Speicherung von
            Cookies in den Browsereinstellungen unterbunden werden kann.
          </p>
          <h3 className="font-medium">2. Rechtsgrundlage für die Datenverarbeitung</h3>
          <p>
            Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von
            Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
          </p>
          <h3 className="font-medium">3. Zweck der Datenverarbeitung</h3>
          <p>
            Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für
            die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den
            Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der
            Browser auch nach einem Seitenwechsel wiedererkannt wird.
          </p>
          <p>Für folgende Anwendungen benötigen wir Cookies:</p>
          <ul className="ml-6">
            <li>a) Warenkorb</li>
            <li>b) Übernahme von Spracheinstellungen</li>
            <li>c) Merken von Suchbegriffen</li>
          </ul>
          <p>
            Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung
            von Nutzerprofilen verwendet.
          </p>
          <p>
            Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Website
            und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie die Website
            genutzt wird und können so unser Angebot stetig optimieren.
          </p>
          <p>
            In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der
            personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.
          </p>
          <h3 className="font-medium">
            4. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
          </h3>
          <p>
            Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite
            übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von
            Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
            Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies
            können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies
            für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der
            Website vollumfänglich genutzt werden.
          </p>
          <h2 className="font-bold">V. Newsletter</h2>
          <h3 className="font-medium">1. Beschreibung und Umfang der Datenverarbeitung</h3>
          <p>
            Auf unserer Internetseite besteht die Möglichkeit einen kostenfreien Newsletter zu
            abonnieren. Dabei werden bei der Anmeldung zum Newsletter die Daten aus der Eingabemaske
            an uns übermittelt.
          </p>
          <p>Bei der Anmeldung werden folgende Daten abgefragt:</p>
          <ul className="ml-6">
            <li>a) Name</li>
            <li>b) Adresse</li>
            <li>c) E-Mailadresse</li>
            <li>d) Telefonnummer</li>
            <li>e) Grund der Anmeldung</li>
          </ul>
          <p>Zudem werden folgende Daten bei der Anmeldung erhoben:</p>
          <ul className="ml-6">
            <li>a) IP-Adresse des aufrufenden Rechners</li>
            <li>b) Datum und Uhrzeit der Registrierung</li>
          </ul>
          <p>
            Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgang Ihre Einwilligung
            eingeholt und auf diese Datenschutzerklärung verwiesen.
          </p>
          <p>
            Wenn Sie auf unserer Internetseite Waren oder Dienstleistungen erwerben und hierbei Ihre
            E-Mail-Adresse hinterlegen, kann diese in der Folge durch uns für den Versand eines
            Newsletters verwendet werden. In einem solchen Fall wird über den Newsletter
            ausschließlich Direktwerbung für eigene ähnliche Waren oder Dienstleistungen versendet.
          </p>
          <p>
            Der Versand der Newsletter erfolgt mittels „ActiveCampaign“,
            einerNewsletterversandplattform des US-Anbieters ActiveCampaign, LLC., 1 N Dearborn St
            5th floor, Chicago, IL 60602, Vereinigte Staaten. Die E-Mail-Adressen unserer
            Newsletterempfänger, als auch deren weitere, im Rahmen dieser Hinweise beschriebenen
            Daten, werden auf den Servern von ActiveCampaign in den USA gespeichert. ActiveCampaign
            verwendet diese Informationen zum Versand und zur Auswertung der Newsletter in unserem
            Auftrag. Des Weiteren kann ActiveCampaign nach eigenen Informationen diese Daten zur
            Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen
            Optimierung des Versandes und der Darstellung der Newsletter oder für wirtschaftliche
            Zwecke, um zu bestimmen aus welchen Ländern die Empfänger kommen. ActiveCampaign nutzt
            die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder
            an Dritte weiterzugeben.
          </p>
          <h3 className="font-medium">2. Rechtsgrundlage für die Datenverarbeitung</h3>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletters durch den
            Nutzer ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
          </p>
          <p>
            Weiters gilt: Rechtsgrundlage für den Versand des Newsletters infolge des Verkaufs von
            Waren oder Dienstleistungen ist § 7 Abs. 3 UWG.
          </p>
          <h3 className="font-medium">3. Zweck der Datenverarbeitung</h3>
          <p>Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter zuzustellen.</p>
          <p>
            Die Erhebung sonstiger personenbezogener Daten im Rahmen des Anmeldevorgangs dient dazu,
            einen Missbrauch der Dienste oder der verwendeten E-Mail-Adresse zu verhindern.
          </p>
          <h3 className="font-medium">4. Dauer der Speicherung</h3>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind. Die E-Mail-Adresse des Nutzers wird demnach solange
            gespeichert, wie das Abonnement des Newsletters aktiv ist.
          </p>
          <h3 className="font-medium">5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
          <p>
            Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit gekündigt
            werden. Zu diesem Zweck findet sich im Anmeldeprofil ein entsprechender Link.
          </p>
          <h2 className="font-bold">VI. Registrierung</h2>
          <h3 className="font-medium">1. Beschreibung und Umfang der Datenverarbeitung</h3>
          <p>
            Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter Angabe
            personenbezogener Daten zu registrieren. Die Daten werden dabei in eine Eingabemaske
            eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte
            findet nicht statt. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:
          </p>
          <ul className="ml-6">
            <li>a) Name</li>
            <li>b) Adresse</li>
            <li>c) E-Mailadresse</li>
            <li>d) Telefonnummer</li>
            <li>e) Grund der Anmeldung</li>
          </ul>
          <p>Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:</p>
          <ul className="ml-6">
            <li>a) Die IP-Adresse des Nutzers</li>
            <li>a) Datum und Uhrzeit der Registrierung</li>
          </ul>
          <p>
            Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers zur
            Verarbeitung dieser Daten eingeholt.
          </p>
          <h3 className="font-medium">2. Rechtsgrundlage für die Datenverarbeitung</h3>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
            Nutzers Art. 6 Abs. 1 lit. a DSGVO.
          </p>
          <h3 className="font-medium">3. Zweck der Datenverarbeitung</h3>
          <p>
            Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte und
            Leistungen auf unserer Website erforderlich.
          </p>
          <p>
            Für die Vermittlung unserer B2B und B2C Produkte benötigen wir die Kontaktdaten der
            User. Um regionale Dienstleitungen anbieten zu können, erfordert die Registrierung
            Angaben zum Wohnort. Eine identifikaton der User ist notwendig um professionelle und
            authentische Geschäftsverbindungen zu ermöglichen.
          </p>
          <h3 className="font-medium">4. Dauer der Speicherung</h3>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind.
          </p>
          <p>
            Dies ist für die während des Registrierungsvorgangs erhobenen Daten der Fall, wenn die
            Registrierung auf unserer Internetseite aufgehoben oder abgeändert wird.
          </p>
          <h3 className="font-medium">5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
          <p>
            Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über
            Sie gespeicherten Daten können Sie jederzeit abändern lassen. Bitte kontaktieren Sie uns
            dazu unter office@hektarnektar.com
          </p>
          <h2 className="font-bold">VII. Kontaktformular und E-Mail-Kontakt</h2>
          <h3 className="font-medium">1. Beschreibung und Umfang der Datenverarbeitung</h3>
          <p>
            Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die
            elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit
            war, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und
            gespeichert. Diese Daten sind: Name, Betreff, Kontaktdaten, Nachricht
          </p>
          <p>Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:</p>
          <ul className="ml-6">
            <li>a) Die IP-Adresse des Nutzers</li>
            <li>b) Datum und Uhrzeit der Registrierung</li>
          </ul>
          <p>
            Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung
            eingeholt und auf diese Datenschutzerklärung verwiesen.
          </p>
          <p>
            Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In
            diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers
            gespeichert.
          </p>
          <p>
            Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden
            ausschließlich für die Verarbeitung der Konversation verwendet.
          </p>
          <h3 className="font-medium">2. Rechtsgrundlage für die Datenverarbeitung</h3>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
            Nutzers Art. 6 Abs. 1 lit. a DSGVO.
          </p>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer
            E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf
            den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
            Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
          </p>
          <h3 className="font-medium">3. Zweck der Datenverarbeitung</h3>
          <p>
            Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur
            Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran
            auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.
          </p>
          <p>
            Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen
            dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer
            informationstechnischen Systeme sicherzustellen.
          </p>
          <h3 className="font-medium">4. Dauer der Speicherung</h3>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des
            Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der
            Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die
            Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
            Sachverhalt abschließend geklärt ist.
          </p>
          <p>
            Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden
            spätestens nach einer Frist von sieben Tagen gelöscht.
          </p>
          <h3 className="font-medium">5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
          <p>
            Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der
            personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf,
            so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In
            einem solchen Fall kann die Konversation nicht fortgeführt werden.
          </p>
          <p>
            Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden
            in diesem Fall gelöscht.
          </p>
          <h2 className="font-bold">VIII. Weitergabe personenbezogener Daten an Dritte</h2>
          <h3 className="font-medium">1. Weitergabe der Daten an Dienstleister</h3>
          <h4>a) Beschreibung und Umfang der Datenverarbeitung</h4>
          <p>
            Personenbezogene Daten, welche erhoben werden, werden automatisierungsunterstützt
            gespeichert und verarbeitet und an die nachfolgend genannten Unternehmen zur weiteren
            Verarbeitung und zu nachgenannten Zwecken übermittelt. Bei den personenbezogenen Daten
            handelt es sich um:
          </p>
          <p>
            Firmendaten,Ust,Vorname,Nachname,Anschrift,Telefonnummer,E-Mail-Adresse,Geburtsdatum,Imkervereine,Imker
            seit,Profilfoto,Bankdaten,Ausweiskopie, IP Adresse, Browserdaten
          </p>
          <p>
            Um bestimmte Dienstleitungen erfüllen zu können, werden die dafür bestimmten Daten
            weitergegeben:
          </p>
          <p>
            PeakMedia Sp. z o.o.,Pl. Na Groblach 21,31-101 Kraków, Poland
            <br />
            Zweck: Programmierung hektarnektar.com
          </p>
          <p>
            Amazon Web Services, Inc.,410 Terry Avenue North,Seattle WA 98109,United States
            <br />
            Zweck: Bereitstellung der Webseite hektarnektar.com durch Serverarchitektur
          </p>
          <p>
            Mailgun Technologies, Inc.,535 Mission St.,San Francisco, CA 94105
            <br />
            Zweck: Versenden von E-Mails über das System
          </p>
          <p>
            facebook inc.,1 Hacker Way,94025 Menlo Park, USA
            <br />
            Zweck: Anmelden und Sharing mit facebook
          </p>
          <p>
            Smartlook,Milady Horakove 13,602 00 Brno,Czech Republic (European Union)
            <br />
            Zweck: User Tracking und Analyse
          </p>
          <p>
            Smartsupp.com, s.r.o., USt-ID CZ03668681
            <br />
            Zweck: Customer Support Chat
            <br />
            Weitere Informationen zu smartsupp und der Datensicherheit finden Sie hier:
            https://www.smartsupp.com/help/privacy/
          </p>
          <p>
            ActiveCampaign, LLC., 1 N Dearborn St 5th floor, Chicago, IL 60602, Vereinigte Staaten
            <br />
            Zweck: Email Campaigning Tool für Newsletter
          </p>
          <p>
            Google LLC,1600 Amphitheatre Parkway,Mountain View, CA 94043,USA
            <br />
            Zweck: User Tracking und Analyse
          </p>
          <p>
            Lead Forensics Ltd. 3000 Lakeside, North Harbour, Western Road, Portsmouth. Hampshire,
            PO6 3EN
            <br />
            Zweck: B2B Website Tracking
          </p>
          <p>
            PayPal (Europe) S.à r.l. et Cie, S.C.A.. 22-24 Boulevard Royal. L-2449 Luxembourg
            <br />
            Zweck: Zahlungsabwicklung
          </p>
          <p>
            Stripe, Inc.,185 Berry Street, Suite 550,San Francisco, CA 94107, USA
            <br />
            Zweck: Zahlungsabwicklung auf hektarnektar.com
          </p>
          <p>
            Rogy und Rogy Wirtschaftsprüfungs- und,Steuerberatungs GmbH,Geusaugasse 39/8, A-1030
            Wien
            <br />
            Zweck: Buchhaltung
          </p>
          <h4>b) Rechtsgrundlage für die Datenverarbeitung</h4>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
            Nutzers Art. 6 Abs. 1 lit. a DSGVO. Weitere Rechtsgrundlage bildet Art. 6 Abs. 1 lit. b
            DSGVO, da die Verarbeitung für die Erfüllung des Vertrages, dessen Vertragspartei die
            betroffene Person ist, und zur Durchführung vorvertragliche Maßnahmen erforderlich ist,
            die auf Antrag der betroffenen Person erfolgt.
          </p>
          <h4>c) Zweck der Datenverarbeitung</h4>
          <p>
            Die Weitergabe der Daten erfolgt an die in Punkt 1. aufgeführten Unternehmen zu dort
            angeführten Zwecken
          </p>
          <h4>d) Dauer der Speicherung</h4>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
            nicht mehr erforderlich sind. Dies ist bei einer Weitergabe von Daten zur Erfüllung
            eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen dann der Fall, wenn die
            Daten für die Durchführung des Vertrages nicht mehr erforderlich sind. Auch nach
            Abschluss des Vertrags kann eine Erforderlichkeit, personenbezogene Daten des
            Vertragspartners zu speichern, bestehen, um vertraglichen oder gesetzlichen
            Verpflichtungen nachzukommen.
          </p>
          <h4>e) Widerspruchs- und Beseitigungsmöglichkeit</h4>
          <p>
            Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung und
            Weitergabe der personenbezogenen Daten zu widerrufen. Sind die Daten zur Erfüllung eines
            Vertrages oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, ist eine
            vorzeitige Löschung der Daten nur möglich, soweit nicht vertragliche oder gesetzliche
            Verpflichtungen einer Löschung entgegenstehen.
          </p>
          <h3 className="font-medium">2. Weitergabe an Bezahldienste und Paymentverfahren</h3>
          <p>
            Bei der Zahlung mit Kreditkarte,Paypal, SofortPayment oder mittels Giropay erfolgt die
            Zahlungsabwicklung über Stripe vom Zahlungsdienstleister Stripe Payments Europe Ltd,
            Block 4, Harcourt Centre, Harcourt Road, Dublin 2, Irland. Nähere Informationen zum
            Datenschutz von Stripe finden Sie unter https://stripe.com/de/privacy#translation. Im
            Falle einer PayPal Zahlung erfolgt die Zahlungsabwicklung über die Firma PayPal (Europe)
            S.à r.l. et Cie, S.C.A. 22-24 Boulevard Royal. L-2449 Luxembourg
          </p>
          <h3 className="font-medium">3. Einsatz von Social-Media-Plugins</h3>
          <h4>Umfang der Verarbeitung personenbezogener Daten</h4>
          <p>
            Unser Internetauftritt verwendet Social Plugins. Die Social Plugins auf unserer Website
            sind standardmäßig deaktiviert. Eine Weitergabe von Daten an das jeweilige soziale
            Netzwerk erfolgt erst nach Aktivierung der Social Plugins durch einen Klick darauf. Die
            Aktivierung der Social Plugins endet mit der Deaktivierung oder der Löschung Ihrer
            Cookies.
          </p>
          <p>
            Nach der Aktivierung wird eine direkte Verbindung mit dem Server des jeweiligen sozialen
            Netzwerks aufgebaut. Der Inhalt der Schaltfläche wird dann von den sozialen Netzwerken
            direkt an Ihren Browser übertragen und von diesem in die Website eingebunden.
          </p>
          <p>
            Nach Aktivierung einer Schaltfläche kann das jeweilige soziale Netzwerk bereits Daten
            erheben, unabhängig davon, ob Sie mit der Schaltfläche interagieren. Sind Sie bei einem
            sozialen Netzwerk eingeloggt, kann dieses Ihren Besuch dieser Website Ihrem
            Benutzerkonto zuordnen. Wenn Sie Mitglied bei einem sozialen Netzwerk sind und nicht
            möchten, dass dieses die bei Ihrem Besuch unserer Website gesammelten Daten mit Ihren
            gespeicherten Mitgliedsdaten verknüpft, müssen Sie sich vor der Aktivierung der
            Schaltflächen aus dem jeweiligen sozialen Netzwerk ausloggen.
          </p>
          <p>
            Auf den Umfang der Daten, die von den sozialen Netzwerken mit Ihren Schaltflächen
            erhoben werden, haben wir keinen Einfluss. Zweck und Umfang der Datenerhebung und die
            weitere Verarbeitung und Nutzung der Daten durch die jeweiligen sozialen Netzwerke sowie
            Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
            entnehmen Sie bitte den Datenschutzhinweisen der jeweiligen sozialen Netzwerke.
          </p>
          <h4>a) Facebook Share</h4>
          <p>
            Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601 South California
            Avenue, Palo Alto, CA 94304, USA, integriert. Die Facebook-Plugins erkennen Sie an dem
            Facebook-Logo oder dem &quot;Like-Button&quot; (&quot;Gefällt mir&quot;) auf unserer
            Seite. Eine Übersicht über die Facebook-Plugins finden Sie unter:
            http://developers.facebook.com/docs/plugins/.
          </p>
          <p>
            Wenn Sie das Plugin aktivieren, wird eine direkte Verbindung zwischen Ihrem Browser und
            dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“
            anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte
            unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
            unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als
            Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
            Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der
            Datenschutzerklärung von Facebook unter: http://de-de.facebook.com/policy.php.
          </p>
          <p>
            Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem
            Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
            Facebook-Benutzerkonto aus.
          </p>
          <h4>b) Facebook Tracking Pixel</h4>
          <p>
            Mit Ihrer Einwilligung setzen wir innerhalb unseres Internetauftritts den
            „Besucheraktions-Pixel“ der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304,
            USA (“Facebook”) ein. Mit dessen Hilfe können wir die Aktionen von Nutzern
            nachverfolgen, nach dem diese eine Facebook-Werbeanzeige gesehen oder geklickt haben. So
            können wir die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
            marktforschungszwecke erfassen. Die so erfassten Daten sind für uns anonym, das heißt
            wir sehen nicht die personenbezogenen Daten einzelner Nutzer. Diese Daten werden jedoch
            von Facebook gespeichert und verarbeitet, worüber wir Sie entsprechend unserem
            Kenntnisstand unterrichten. Facebook kann diese Daten mit ihrem Facebookkonto verbinden
            und auch für eigene Werbezwecke, entsprechend Facebooks Datensverwendungsrichtlinie
            verwenden https://www.facebook.com/about/privacy/. Sie können Facebook sowie dessen
            Partnern das Schalten von Werbeanzeigen auf und außerhalb von Facebook ermöglichen. Es
            kann ferner zu diesen Zwecken ein Cookie auf Ihrem Rechner gespeichert werden.
          </p>
          <h4>c) Facebook Connect</h4>
          <p>
            Wir bieten Ihnen die Möglichkeit sich für unseren Dienst mit Facebook-Connect
            anzumelden. Eine zusätzliche Registrierung ist bei dieser Option nicht nötig. Zur
            Anmeldung werden Sie auf die Seite von Facebook weitergeleitet, wo Sie sich mit ihren
            Nutzungsdaten anmelden können. Hierdurch werden Ihr Facebook-Profil und unser Dienst
            verknüpft. Durch die Verknüpfung werden automatisch folgende Informationen von der
            Facebook Inc. an uns übermittelt und von uns verwendet: Daten aus dem öffentlichen
            Facebook-Profil und E-Mail-Adresse.
          </p>
          <p>
            Diese Informationen sind für den Vertragsschluss zwingend erforderlich, um sie
            identifizieren zu können.
          </p>
          <p>
            Weitere Informationen zu Facebook-Connect und den Privatsphäre-Einstellungen entnehmen
            Sie bitte den Datenschutzhinweisen und den Nutzungsbedingungen der Facebook Inc.
          </p>
          <h4>d) Twitter</h4>
          <p>
            Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen
            werden angeboten durch die Twitter Inc., Twitter, Inc. 1355 Market St, Suite 900, San
            Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der Funktion “Re-Tweet”
            werden die von Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und anderen
            Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter übertragen.
          </p>
          <p>
            Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen
            hierzu finden Sie in der Datenschutzerklärung von Twitter unter
            http://twitter.com/privacy.
          </p>
          <p>
            Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen
            unterhttp://twitter.com/account/settings ändern.
          </p>
          <h3 className="font-medium">4. Websiteanalysedienste</h3>
          <h4>Umfang der Verarbeitung personenbezogener Daten</h4>
          <h4>a) Google Analytics</h4>
          <p>
            Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc.
            (www.google.de). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem
            Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA übertragen und dort
            gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird
            Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
            Google in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser
            Website aktiv. Im Auftrag des Betreibers dieser Website wird Google diese Informationen
            benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
            Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
            Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
            mit anderen Daten von Google zusammengeführt.
          </p>
          <h4>b) Google Tag Manager</h4>
          <p>
            Diese Website benutzt den Google Tag Manager. Google Tag Manager ist eine Lösung, mit
            der Vermarkter Website-Tags über eine Oberfläche verwalten können. Das Tool Tag Manager
            selbst (das die Tags implementiert) ist eine cookielose Domain und erfasst keine
            personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer Tags, die ihrerseits
            unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn
            auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
            alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.
          </p>
          <h4>c) Google Webfonts</h4>
          <p>
            Auf dieser Website werden externe Schriften, Google Fonts verwendet. Google Fonts ist
            ein Dienst der Google Inc. (&quot;Google\&quot;). Die Einbindung dieser Web Fonts
            erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA.
            Hierdurch wird an den Server übermittelt, welche unserer Internetseiten Sie besucht
            haben. Auch wird die IP-Adresse des Browsers des Endgerätes des Besuchers dieser
            Internetseiten von Google gespeichert. Nähere Informationen finden Sie in den
            Datenschutzhinweisen von Google, die Sie hier abrufen können:
          </p>
          <p>
            www.google.com/fonts#AboutPlace:about <br />
            www.google.com/policies/privacy/
          </p>
          <h4>d) Google Adwords</h4>
          <p>
            Unsere Webseite nutzt das Google Conversion-Tracking. Sind Sie über eine von Google
            geschaltete Anzeige auf unsere Webseite gelangt, wird von Google Adwords ein Cookie auf
            Ihrem Rechner gesetzt. Das Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer
            auf eine von Google geschaltete Anzeige klickt. Diese Cookies verlieren nach 30 Tagen
            ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer
            bestimmte Seiten unserer Website und das Cookie ist noch nicht abgelaufen, können wir
            und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite
            weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies
            können somit nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die
            mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
            Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking
            entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige
            geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
            weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
            persönlich identifizieren lassen.
          </p>
          <p>
            Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür erforderliche Setzen
            eines Cookies ablehnen - etwa per Browser-Einstellung, die das automatische Setzen von
            Cookies generell deaktiviert oder Ihren Browser so einstellen, dass Cookies von der
            Domain „googleleadservices.com“ blockiert werden.
          </p>
          <p>
            Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen, solange Sie keine
            Aufzeichnung von Messdaten wünschen. Haben Sie alle Ihre Cookies im Browser gelöscht,
            müssen Sie das jeweilige Opt-out Cookie erneut setzen.
          </p>
          <h4>e) Smartsupp</h4>
          <p>
            Unsere Website nutzt das Aufzeichnungs und Chat-Tool der Firma Smartsupp.com, s.r.o.,
            USt-ID CZ03668681, mit dem personenbezogene Daten analysiert werden können.
          </p>
          <h2 className="font-bold">IX. Rechte der betroffenen Person</h2>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO
            und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
          </p>
          <h3 className="font-medium">1. Auskunftsrecht</h3>
          <p>
            Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
            personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
          </p>
          <p>
            Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
            Informationen Auskunft verlangen:
          </p>
          <ul className="ml-6">
            <li>a) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
            <li>b) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
            <li>
              c) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
              betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
            </li>
            <li>
              d) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten
              oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung
              der Speicherdauer;
            </li>
            <li>
              e) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
              personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den
              Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
            </li>
            <li>f) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
            <li>
              g) alle verfügbaren Informationen über die Herkunft der Daten, wenn die
              personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
            </li>
            <li>
              h) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
              gemäß Art. 22 Abs. 1 und 4 DSGVO und - zumindest in diesen Fällen - aussagekräftige
              Informationen über die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
            </li>
          </ul>
          <p>
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
            personenbezogenen Daten in ein Drittland oder an eine internationale Organisation
            übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten
            Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
            werden.
          </p>
          <h3 className="font-medium">2. Recht auf Berichtigung</h3>
          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
            Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen,
            unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich
            vorzunehmen.
          </p>
          <h3 className="font-medium">3. Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der
            Sie betreffenden personenbezogenen Daten verlangen:
          </p>
          <ul className="ml-6">
            <li>
              a) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer
              bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
              personenbezogenen Daten zu überprüfen;
            </li>
            <li>
              b) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten
              ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
              verlangen;
            </li>
            <li>
              c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung
              nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen benötigen, oder
            </li>
            <li>
              d) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt
              haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen
              gegenüber Ihren Gründen überwiegen.
            </li>
          </ul>
          <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt,
            dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet
            werden.
          </p>
          <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt,
            werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben
            wird.
          </p>
          <h3 className="font-medium">4. Recht auf Löschung</h3>
          <h4>a) Löschungspflicht</h4>
          <p>
            Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
            personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist
            verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe
            zutrifft:
          </p>
          <ul className="ml-6">
            <li>
              - Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie
              erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
            </li>
            <li>
              - Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
              lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen
              Rechtsgrundlage für die Verarbeitung.
            </li>
            <li>
              - Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es
              liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen
              gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
            </li>
            <li>- Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
            <li>
              - Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
              rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
              erforderlich, dem der Verantwortliche unterliegt.
            </li>
            <li>
              - Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste
              der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
            </li>
          </ul>
          <h4>b) Information an Dritte</h4>
          <p>
            Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht
            und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter
            Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene
            Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die
            personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene
            Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von
            Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
          </p>
          <h4>c) Ausnahmen</h4>
          <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
          <ul className="ml-6">
            <li>- zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
            <li>
              - zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
              der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert,
              oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in
              Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
            </li>
            <li>
              - aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
              Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
            </li>
            <li>
              - für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
              historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO,
              soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
              Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
            </li>
            <li>- zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
          </ul>
          <h3 className="font-medium">5. Recht auf Unterrichtung</h3>
          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung
            gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
            Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese
            Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
            sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
            verbunden.
          </p>
          <p>
            Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger
            unterrichtet zu werden.
          </p>
          <h3 className="font-medium">6. Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem
            Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem
            anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
            personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          </p>
          <ul className="ml-6">
            <li>
              - die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9
              Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
            </li>
            <li>- die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
          </ul>
          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie
            betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen
            Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und
            Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
            Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
            Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
            übertragen wurde.
          </p>
          <h3 className="font-medium">7. Widerspruchsrecht</h3>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die
            aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
            gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>
            Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr,
            es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <p>
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt
            auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          </p>
          <p>
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
            betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
          </p>
          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
            Informationsgesellschaft - ungeachtet der Richtlinie 2002/58/EG - Ihr Widerspruchsrecht
            mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen
            verwendet werden.
          </p>
          <h3 className="font-medium">
            8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
          </h3>
          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu
            widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          </p>
          <h3 className="font-medium">
            9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
          </h3>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung -
            einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen
            gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
          </p>
          <ul className="ml-6">
            <li>
              - für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem
              Verantwortlichen erforderlich ist,
            </li>
            <li>
              - aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
              Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
              Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen
              enthalten oder
            </li>
            <li>- mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
          </ul>
          <p>
            Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener
            Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt
            und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten
            Interessen getroffen wurden.
          </p>
          <p>
            Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene
            Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren,
            wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
            Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der
            Entscheidung gehört.
          </p>
          <h3 className="font-medium">10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
            steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die DSGVO verstößt.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
            Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der
            Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
          </p>
          <h3 className="font-medium">11. Aktualisierung und Änderung</h3>
          <p>
            Teile der Datenschutzerklärung können von uns geändert oder aktualisiert werden, ohne
            dass die Nutzer von uns benachrichtigt werden. Wir ersuchen die Nutzer daher die
            Datenschutzerklärung zu überprüfen bevor sie unser Angebot nutzen, um bei möglichen
            Änderungen oder Aktualisierungen auf dem neuen Stand zu sein. Stand der
            Datenschutzerklärung: Mai 2018.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyScreen;
