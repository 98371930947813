import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';

const modalElement = document.getElementById('modal-root');

function Modal({ fade = true, defaultOpened = false, closeInside = false, children }, ref) {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div
        className={`modal fixed z-[300] inset-0 w-screen min-h-screen flex justify-center items-center bg-grey-50 bg-opacity-80 p-4 ${
          fade ? 'modal-fade' : ''
        }`}
      >
        <div className="relative z-10 flex justify-center items-center">
          {closeInside ? (
            <button
              type="button"
              aria-label="close"
              className="transition-colors duration-300 w-10 h-10 rounded-full bg-grey-50 bg-opacity-20 hover:bg-opacity-20 absolute z-10 top-2 right-2 flex items-center justify-center outline-none focus:outline-none"
              onClick={close}
            >
              <CloseIcon width="24" height="24" className="text-grey-50 stroke-current" />
            </button>
          ) : null}
          {children}
        </div>
        {!closeInside ? (
          <button
            type="button"
            aria-label="close"
            className="transition-colors duration-300 w-10 h-10 rounded-full bg-white bg-opacity-10 hover:bg-opacity-20 absolute z-10 top-4 md:top-8 right-4 md:right-8 flex items-center justify-center outline-none focus:outline-none"
            onClick={close}
          >
            <CloseIcon width="24" height="24" className="text-white stroke-current" />
          </button>
        ) : null}
        <div className="w-full h-full absolute inset-0" role="presentation" onClick={close} />
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Modal);
