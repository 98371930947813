import { useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import sal from 'sal.js';
import { ReactComponent as DonateBeeHeartIcon } from '@images/icons/donate-bee-heart.svg';
import { ReactComponent as BeeFlyIcon } from '@images/icons/bee-fly.svg';

function B2BBox({ photo, head, lead, bgColor, iconColor, delay }) {
  useEffect(() => {
    sal();
  }, []);

  return (
    <div
      data-sal="fade"
      data-sal-delay={delay}
      data-sal-duration="2000"
      className="w-full max-w-[326px] mx-4 md:mx-10 xl:mx-0 pb-16 last:pb-0 xl:pb-0 relative b2b-box"
    >
      <div className="h-full transform -translate-x-4 md:translate-x-0">
        <div className="relative h-full">
          <div className="h-full bg-white rounded-4xl transform -translate-y-4 translate-x-6 relative z-10 shadow-ds12">
            <LazyLoad>
              <div className="aspect-w-16 aspect-h-12">
                <img
                  src={photo}
                  alt={head}
                  className="object-cover rounded-tl-4xl rounded-tr-4xl"
                />
              </div>
            </LazyLoad>
            <div className="px-5 py-10 rounded-bl-4xl rounded-br-4xl relative">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <DonateBeeHeartIcon
                  width="53px"
                  height="48"
                  className={`fill-current ${iconColor}`}
                />
              </div>
              <h3 className="text-base text-grey-50 font-bold mb-2">{head}</h3>
              <p className="text-grey-30 text-sm md:text-base">{lead}</p>
            </div>
          </div>
          <div className={`absolute inset-0 rounded-4xl bg-current ${bgColor}`} />
        </div>
      </div>
      <div className="absolute top-1/2 left-full transform -translate-y-1/2 w-[174px] hidden xl:block bee-fly">
        <div data-sal="fade" data-sal-delay={delay * 2} data-sal-duration="2000">
          <BeeFlyIcon className="text-orange fill-current" />
        </div>
      </div>
    </div>
  );
}

export default B2BBox;

B2BBox.propTypes = {
  photo: PropTypes.string,
  head: PropTypes.string,
  lead: PropTypes.string,
  bgColor: PropTypes.string,
  iconColor: PropTypes.string,
  delay: PropTypes.string
};

B2BBox.defaultProps = {
  photo: '',
  head: '',
  lead: '',
  bgColor: '',
  iconColor: '',
  delay: ''
};
