import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '@constants';
import Photo422 from '@images/422.svg';

function Error422Screen() {
  const { t } = useTranslation('screens', { keyPrefix: 'error422Screen' });
  return (
    <section className="bg-primary-light">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="min-h-screen flex flex-col items-center justify-center">
          <img src={Photo422} alt="404" />
          <div className="mt-10 text-center xl:transform xl:-translate-y-12">
            <h1 className="text-2xl md:text-4xl text-grey-50 text-center font-bold leading-normal">
              <Trans i18nKey="screens:error422Screen.title">
                Mayday...
                <br />
                Hier gibt es ein Problem in der Flugbahn
              </Trans>
            </h1>
            <p className="text-grey-30 text-base mt-4">
              <Trans i18nKey="screens:error422Screen.body">
                Versuche es nochmal oder
                <a href={`mailto:${t('email')}`} className="text-primary hover:underline">
                  kontaktiere uns
                </a>
                oder
                <Link to={ROOT_PATH} className="text-primary hover:underline">
                  gehe zurück zur Startseite
                </Link>
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error422Screen;
