import { useEffect } from 'react';
import PropTypes from 'prop-types';
import sal from 'sal.js';

function StatBox({ icon, head, number, delay }) {
  useEffect(() => {
    sal();
  }, []);
  return (
    <div
      data-sal="fade"
      data-sal-delay={delay}
      data-sal-duration="800"
      data-sal-easing="easeOutCubic"
      className="flex-grow min-w-[50vw] md:min-w-0 flex flex-col lg:flex-row items-center justify-center text-primary"
    >
      <div className="flex-shrink-0 lg:mr-8">{icon}</div>
      <div className="mt-2 lg:mt-0">
        <div className="text-grey-30 text-center lg:text-left text-base sm:text-lg font-bold">
          {head}
        </div>
        <div className="text-2xl md:text-4xl text-orange text-center lg:text-left font-bold">
          {number}
        </div>
      </div>
    </div>
  );
}

export default StatBox;

StatBox.propTypes = {
  head: PropTypes.string,
  icon: PropTypes.element,
  delay: PropTypes.string,
  number: PropTypes.string
};

StatBox.defaultProps = {
  head: '',
  icon: '',
  delay: '',
  number: ''
};
