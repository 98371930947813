import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { ReactComponent as AvatarMask } from '@images/user-hex-info-max.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right.svg';
import 'slick-carousel/slick/slick.css';

function Partners({ items, title, subtitle, lead, keyPrefix }) {
  const { t } = useTranslation('screens', { keyPrefix });
  const [carouselBoxesNav, setCarousleBoxesNav] = useState(null);
  const [carouselLogosNav, setCarouselLogosNav] = useState(null);
  const carouselBoxes = useRef(null);
  const carouselLogos = useRef(null);

  useEffect(() => {
    setCarousleBoxesNav(carouselBoxes.current);
    setCarouselLogosNav(carouselLogos.current);
  }, []);

  const carouselLogosSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 9000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  };

  const carouselBoxesSettings = {
    dots: false,
    arrows: false,
    speed: 1500,
    fade: true
  };

  return (
    <section className="bg-orange-20 pt-10 sm:pt-12 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/3 px-4">
            <span className="block text-primary text-sm md:text-base font-bold uppercase">
              {subtitle}
            </span>
            <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">{title}</h2>
            <div className="text-sm md:text-base text-grey-30 mt-4 xl:pr-12">{lead}</div>
          </div>
          <div className="w-full lg:w-2/3 px-4 mt-8 lg:mt-0">
            <div className="bg-white shadow-ds8 rounded-2xl p-4 md:p-8 slider-dots-no-dots">
              <Slider asNavFor={carouselLogosNav} ref={carouselBoxes} {...carouselBoxesSettings}>
                {items.map((partner, index) => {
                  return (
                    <div key={partner.id}>
                      <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-5/12 px-4">
                          <div className="aspect-w-16 aspect-h-13 rounded-xl overflow-hidden">
                            <img
                              src={partner.photo.type}
                              alt={t(`boxes.box${index}.company`) || ''}
                              className="rounded-xl object-cover"
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-7/12 px-4">
                          <div className="flex flex-col justify-between h-full py-4">
                            <div className="h-full">
                              <div className="flex items-center pl-[10%] md:pl-[30%] pt-4 md:pt-0 transform">
                                <div className={`relative flex-shrink-0 mr-4 ${partner.color}`}>
                                  <AvatarMaskBack
                                    width="118px"
                                    height="100px"
                                    className="fill-current absolute -top-1 -left-3"
                                  />
                                  <img
                                    src={partner.person.type}
                                    alt={t(`boxes.box${index}.name`)}
                                    className="rounded-xl object-cover avatar-mask-medium"
                                    width="120px"
                                    height="120px"
                                  />
                                </div>
                                <div className="transform -translate-y-1">
                                  <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                                    {t(`boxes.box${index}.name`)}
                                  </h3>
                                  <div className="text-grey-50 text-xs md:text-sm">
                                    {t(`boxes.box${index}.position`)}
                                  </div>
                                </div>
                              </div>
                              <div className="text-center text-grey-30 text-xs md:text-sm mt-4 md:mt-4 xl:px-6">
                                {t(`boxes.box${index}.quote`)}
                                <span className="inline-block ml-2">
                                  <Link
                                    to={t(`boxes.box${index}.url`)}
                                    className="text-primary inline-flex items-center group font-medium"
                                  >
                                    {t('partnerLink')}
                                    <ArrowRight
                                      width="16px"
                                      height="15px"
                                      className="fill-current stroke-current flex-shrink-0 ml-2 transition-transform duration-300 transform group-hover:translate-x-2"
                                    />
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <AvatarMask width="0" height="0" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-16 lg:mt-8">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="relative py-6 lg:py-10">
            <div className="bg-primary-0 absolute top-0 md:left-5 h-full transform scale-125 origin-bottom-right logo-backdrop" />
            <Slider asNavFor={carouselBoxesNav} ref={carouselLogos} {...carouselLogosSettings}>
              {items.map((partner, index) => {
                return (
                  <div key={partner.id} className="flex items-center justify-center py-4 px-2">
                    <img
                      src={partner.logo.type}
                      alt={t(`boxes.box${index}.company`) || ''}
                      width="160px"
                      height="56px"
                      className="mx-auto md:transform md:translate-x-5 grayscale logo"
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;

Partners.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lead: PropTypes.string,
  keyPrefix: PropTypes.string
};

Partners.defaultProps = {
  items: [],
  title: '',
  subtitle: '',
  lead: '',
  keyPrefix: ''
};
