/* eslint-disable jsx-a11y/label-has-associated-control */
import { generatePath, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { parseBool } from '@tools';
import LazyLoad from 'react-lazyload';
import Switch from 'react-switch';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import fileExtension from 'file-extension';
import PRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact';
import { ReactComponent as DocumentDownloadIcon } from '@images/icons/document-download.svg';
import SocialMediaIcon from '@images/icons/company-pages/social-media.svg';
import PRIcon from '@images/icons/company-pages/pr.svg';
import Project2028Icon from '@images/icons/company-pages/projekt-2028.svg';
import CSRIcon from '@images/icons/company-pages/csr.svg';
import EmployerBrandingIcon from '@images/icons/company-pages/employer-branding.svg';
import LimitedAccessModal from '@components/Modals/LimitedAccessModal';
import { COMPANY_PORTAL_ARTICLE_PATH } from '@constants';
import { filesExtension } from '@config/directories';
import CompanyPortalFileScreenSkeleton from '@screens/CompanyPortal/CompanyPortalFilesScreen/components/Skeleton';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import Header from './components/Header';

const companyFileTypes = {
  social_media: {
    key: 'socialMedia',
    listItems: 3,
    headerBackground: 'bg-primary-40',
    icon: <SocialMediaIcon />
  },
  pr: {
    key: 'pr',
    listItems: 3,
    headerBackground: 'bg-green-40',
    icon: <PRIcon />
  },
  projekt_2028: {
    key: 'project2028',
    listItems: 2,
    headerBackground: 'bg-orange-40',
    icon: <Project2028Icon />
  },
  employer_branding: {
    key: 'employerBranding',
    headerBackground: 'bg-complimentary-20',
    icon: <EmployerBrandingIcon />
  },
  csr: {
    key: 'csr',
    listItems: 3,
    headerBackground: 'bg-red-40',
    icon: <CSRIcon />
  }
};

const allowedFormatForBackground = ['image/jpg', 'image/jpeg', 'image/png'];

function CompanyPortalFilesScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'companyPortal.companyPortalFilesScreen' });
  const modal = useRef(null);
  const { type } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    companyFileStore,
    companyFileCategoryStore,
    authStore: { user }
  } = useStores();
  const [filesFetched, setFilesFetched] = useState(false);
  const [scopeType, setScopeType] = useState('visible');
  const [scopeSwitch, setScopeSwitch] = useState(false);
  const [filesCategory, setFilesCategory] = useState({ id: null, name: t('filesHeader') });

  function fetchCompanyFiles(page = 1, extendCollection = false) {
    let qParams = { groupEq: type };

    if (filesCategory.name !== t('filesHeader')) {
      qParams = { groupEq: type, companyFileCategoryIdEq: filesCategory.id };
    }

    return companyFileStore
      .fetchAll(
        {
          q: qParams,
          authorizedScopeType: scopeType,
          sortBy: 'created_at desc',
          page
        },
        extendCollection,
        true
      )
      .then(() => setFilesFetched(true));
  }

  useEffect(() => {
    fetchCompanyFiles();
  }, [filesCategory.id]);

  function filterFiles(companyFileCategory) {
    setFilesCategory({ id: companyFileCategory.id, name: companyFileCategory.name });
  }

  function filterWithStateCategory() {
    if (parseBool(state?.categoryName)) {
      const companyFileCategory = companyFileCategoryStore.collection.find(
        ({ name }) => state.categoryName === name
      );
      filterFiles(companyFileCategory);
      navigate(window.location.pathname, { replace: true });
    }
  }

  function fileCanBeDownloaded({ allowShowOnLimitedAccess, visibleForAll }) {
    return user.access === 'full_access' || allowShowOnLimitedAccess || visibleForAll;
  }

  useEffect(() => {
    companyFileStore.cleanCollection();
    companyFileCategoryStore.cleanCollection();
  }, []);

  useEffect(() => {
    companyFileCategoryStore
      .fetchAll({ q: { forFileGroup: type }, sortBy: 'position' })
      .then(() => {
        fetchCompanyFiles().then(filterWithStateCategory);
      });
  }, [scopeType]);

  function changeScopeType() {
    setScopeSwitch(!scopeSwitch);
    if (scopeType === 'downloadable') setScopeType('visible');
    else setScopeType('downloadable');
  }

  function downloadFile(companyFile) {
    const { id, attachmentFileName } = companyFile;

    if (fileCanBeDownloaded(companyFile)) companyFileStore.downloadFile(id, attachmentFileName);
    else modal.current.open();
  }

  function downloadFiles(companyFile) {
    const { id, title } = companyFile;

    companyFileStore.downloadFiles(id, `${title}_files.zip`);
  }

  function clearFilters() {
    setFilesCategory({ id: null, name: t('filesHeader') });
    fetchCompanyFiles();
  }

  function loadMore() {
    fetchCompanyFiles(companyFileStore.meta.nextPage, true);
  }

  const downloadIcon = (companyFile) => {
    return (
      <button
        key={companyFile.attachmentFileName}
        type="button"
        className="flex-1 min-w-0 flex items-center my-0.5 outline-none focus:outline-none"
        onClick={() => downloadFile(companyFile)}
      >
        {filesExtension[companyFile.attachmentContentType]?.small}
        <div className="text-grey-50 text-sm md:text-base font-bold lowercase truncate">
          {companyFile.attachmentFileName}
        </div>
      </button>
    );
  };

  const fileName = (companyFile) => {
    return (
      <div className="flex-1 min-w-0 flex items-center my-0.5">
        {fileExtension(companyFile.attachmentFileName) === 'ai'
          ? filesExtension['image/ai'].small
          : filesExtension[companyFile.attachmentContentType]?.small}
        <div className="text-grey-50 text-sm md:text-base font-bold lowercase truncate">
          {companyFile.attachmentFileName}
        </div>
      </div>
    );
  };

  const filesDownloadWrapper = (companyFile) => {
    if (companyFile.companyFiles.length > 0) return companyFile.companyFiles.map(downloadIcon);
    if (parseBool(companyFile.attachmentFileName)) return fileName(companyFile);

    return <div />;
  };

  function downloadOption(companyFile) {
    if (companyFile.companyFiles.length > 0) return downloadFiles(companyFile);
    return downloadFile(companyFile);
  }

  const backgroundImageType = (companyFile) => {
    if (
      allowedFormatForBackground.includes(companyFile.attachmentContentType) ||
      companyFile.backgroundImageUrl.includes('.png') ||
      companyFile.type === 'CompanyArticle'
    ) {
      return (
        <LazyLoad>
          <img
            src={
              parseBool(companyFile.backgroundFileName)
                ? companyFile.backgroundImageUrl
                : companyFile.attachmentUrl
            }
            alt={companyFile.title}
            className={`object-cover absolute inset-0 w-full h-full rounded-2xl ${
              !fileCanBeDownloaded(companyFile) ? 'grayscale opacity-80' : 'opacity-100'
            }`}
          />
        </LazyLoad>
      );
    }

    return (
      <div
        className={`bg-complimentary-20 rounded-2xl flex items-center justify-center ${
          !fileCanBeDownloaded(companyFile) ? 'grayscale opacity-80' : 'opacity-100'
        }`}
      >
        {fileExtension(companyFile.attachmentFileName) === 'ai'
          ? filesExtension['image/ai'].color
          : filesExtension[companyFile.attachmentContentType]?.color}
      </div>
    );
  };

  const fileContent = (companyFile) => {
    return (
      <>
        <div className="mb-4">
          <div className="aspect-w-16 aspect-h-9">{backgroundImageType(companyFile)}</div>
        </div>
        {parseBool(companyFile.title) && (
          <h3 className="text-base text-grey-50 font-bold my-4">{companyFile.title}</h3>
        )}
        <div className="md:mt-2 mb-2">
          <div className="text-grey-30 text-sm break-words">
            {(companyFile.abstract || companyFile.description).replace(/<[^>]+>/g, '')}
          </div>
        </div>
      </>
    );
  };

  const linkTypeWrapper = (companyFile) => {
    if (companyFile.type === 'CompanyFile' || !parseBool(companyFile.slug))
      return fileContent(companyFile);

    return (
      <Link to={generatePath(COMPANY_PORTAL_ARTICLE_PATH, { articleSlug: companyFile.slug, type })}>
        {fileContent(companyFile)}
      </Link>
    );
  };

  const companyFileWrapper = (companyFile) => {
    return (
      <div key={companyFile.id} className="px-3 my-3">
        <div className="p-4 bg-white rounded-2xl shadow-ds16 flex flex-col justify-between">
          {linkTypeWrapper(companyFile)}
          <div className="text-sm md:text-base text-grey-50 font-bold">
            <div className="flex flex-col">{filesDownloadWrapper(companyFile)}</div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <button
              className="outline-none focus:outline-none"
              type="button"
              onClick={() => downloadOption(companyFile)}
            >
              <DocumentDownloadIcon
                width="24"
                height="24"
                className={`transition-colors duration-300 text-grey-25 fill-current ${
                  !fileCanBeDownloaded(companyFile) ? 'hover:text-grey-25' : 'hover:text-primary'
                }`}
              />
            </button>
            <div className="flex items-center">
              <div className="relative h-6 w-6 rounded-full flex-shrink-0 mr-2">
                <LazyLoad>
                  <img
                    src={companyFile.author.avatarUrl}
                    alt="Avatar"
                    className={`w-full h-full object-cover rounded-full absolute inset-0 ${
                      !fileCanBeDownloaded(companyFile) ? 'grayscale opacity-80' : 'opacity-100'
                    }`}
                  />
                </LazyLoad>
              </div>
              <div className="text-grey-30 text-sm">{companyFile.author.firstname}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const companyFileCategoryWrapper = (companyFileCategory) => {
    return (
      <li key={companyFileCategory.id} className="text-sm md:text-base flex-shrink-0">
        <button
          className={`block w-full lg:text-left outline-none focus:outline-none px-6 lg:px-4 py-1 border-b-2 lg:border-b-0 lg:border-l-4 ${
            filesCategory.name === companyFileCategory.name
              ? 'text-primary font-bold border-primary'
              : 'text-grey-30 border-transparent lg:border-grey-5'
          }`}
          type="button"
          onClick={() => filterFiles(companyFileCategory)}
        >
          {companyFileCategory.name}
        </button>
      </li>
    );
  };

  const loadMoreButton = () => {
    if (!parseBool(companyFileStore.meta.nextPage)) return <div />;

    return (
      <button
        className="inline-flex items-center justify-center relative bg-transparent hover:bg-primary-120 text-primary hover:text-white border border-primary m-0 py-2 px-12 rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
        type="button"
        onClick={() => loadMore()}
      >
        {t('loadMoreFiles')}
      </button>
    );
  };

  if (!filesFetched) return <CompanyPortalFileScreenSkeleton />;
  return (
    <>
      <TitleAndDescription screenName="companyPortal.companyPortalFilesScreen" />
      <section className="my-4">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <Header
                backgroundColor={companyFileTypes[type].headerBackground}
                title={t(`headers.${companyFileTypes[type].key}`)}
                icon={companyFileTypes[type].icon.type}
              />
            </div>
            <div className="hidden lg:block w-full lg:w-4/12 xl:w-1/4 px-3">
              <PRContact />
            </div>
          </div>
          <div className="mt-6 mb-8">
            <label className="flex items-center">
              <Switch
                onChange={() => changeScopeType()}
                checked={!scopeSwitch}
                onColor="#37C591"
                onHandleColor="#fff"
                handleDiameter={22}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="none"
                activeBoxShadow="none"
                height={24}
                width={32}
                className="react-switch"
              />
              <span className="text-sm text-grey-30 ml-4 cursor-pointer">
                {scopeSwitch ? t('switchScope') : t('switchScopeAll')}
              </span>
            </label>
          </div>
        </div>
      </section>
      <section className="mt-6 mb-4">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex md:flex-row-reverse flex-wrap -mx-3">
            <div className="w-full lg:w-4/12 xl:w-1/4 px-3">
              <div className="lg:sticky lg:top-4">
                <h3 className="text-2xl text-grey-50 font-bold mb-8 hidden lg:block">
                  {t('categoryHeader')}
                </h3>
                <ul className="flex lg:block items-center space-x-4 md:space-x-0 overflow-x-auto no-scrollbar mb-4 -mx-4 lg:mx-0 pl-4">
                  <li className="text-sm md:text-base flex-shrink-0">
                    <button
                      className={`block w-full lg:text-left outline-none focus:outline-none px-6 lg:px-4 py-1 border-b-2 lg:border-b-0 lg:border-l-4 ${
                        filesCategory.name === t('filesHeader')
                          ? 'text-primary font-bold border-primary'
                          : 'text-grey-30 border-transparent lg:border-grey-5'
                      }`}
                      type="button"
                      onClick={() => clearFilters()}
                    >
                      {t('clearFilters')}
                    </button>
                  </li>
                  {companyFileCategoryStore.collection.map(companyFileCategoryWrapper)}
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-4 border-b border-grey-15 hidden md:block">
                {filesCategory.name}
              </h2>
              <div className="w-full">
                <ResponsiveMasonry
                  gutter="16"
                  columnsCountBreakPoints={{ 350: 1, 767: 2, 1280: 3 }}
                >
                  <Masonry>
                    {filesFetched &&
                      companyFileStore.collection.map((companyFile) =>
                        companyFileWrapper(companyFile)
                      )}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
              <div className="my-6 flex justify-center">{loadMoreButton()}</div>
              <div className="mb-4 lg:hidden">
                <PRContact />
              </div>
            </div>
          </div>
        </div>
      </section>
      <LimitedAccessModal ref={modal} />
    </>
  );
}

export default observer(CompanyPortalFilesScreen);
