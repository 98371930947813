import { useTranslation, Trans } from 'react-i18next';
import CaseStudyPhoto01 from '@images/case-studies/01.jpg';
import CaseStudyPhoto02 from '@images/case-studies/02.jpg';
import CaseStudyPhoto03 from '@images/case-studies/03.jpg';
import CaseStudyPhoto04 from '@images/case-studies/04.jpg';
import CaseStudyPhoto05 from '@images/case-studies/05.jpg';
import CaseStudyPhoto06 from '@images/case-studies/06.jpg';
import CaseStudyPhoto07 from '@images/case-studies/07.jpg';
import CaseStudyPhoto08 from '@images/case-studies/08.jpg';
import CaseStudyPhoto09 from '@images/case-studies/09.jpg';
import CaseStudtItem from './components/CaseStudyItem';

const CASE_STUDIES = [
  {
    id: 1,
    name: 'Takko Fashion',
    url: '/case-studies/takko-fashion',
    photo: <CaseStudyPhoto01 />
  },
  {
    id: 2,
    name: 'Raiffeisen',
    url: '/case-studies/raiffeisen',
    photo: <CaseStudyPhoto02 />
  },
  {
    id: 3,
    name: 'Metro',
    url: '/case-studies/metro',
    photo: <CaseStudyPhoto03 />
  },
  {
    id: 4,
    name: 'Chewow',
    url: '/case-studies/chewow',
    photo: <CaseStudyPhoto04 />
  },
  {
    id: 5,
    name: 'Think',
    url: '/case-studies/think',
    photo: <CaseStudyPhoto05 />
  },
  {
    id: 6,
    name: 'BUWOG',
    url: '/case-studies/BUWOG',
    photo: <CaseStudyPhoto06 />
  },
  {
    id: 7,
    name: 'Hertz',
    url: '/case-studies/Hertz',
    photo: <CaseStudyPhoto07 />
  },
  {
    id: 8,
    name: 'Weleda',
    url: '/case-studies/weleda',
    photo: <CaseStudyPhoto08 />
  },
  {
    id: 9,
    name: 'PEZ',
    url: '/case-studies/pez',
    photo: <CaseStudyPhoto09 />
  }
];

function CaseStudies() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.caseStudies' });
  return (
    <section className="bg-orange-20 py-10 md:py-14 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="md:text-center">
          <span className="block text-primary text-sm md:text-base font-bold uppercase">
            {t('subheader')}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
            <Trans i18nKey="screens:homeScreen.caseStudies.header">
              Unsere <span className="block md:inline"> Partnerunternehmen tun</span>
              <span className="block md:inline"> Gutes und reden darüber</span>
            </Trans>
          </h2>
          <div className="text-sm md:text-base text-grey-30 mt-4 max-w-xl mr-8 md:mr-auto md:mx-auto">
            {t('lead')}
          </div>
        </div>
      </div>
      <div className="mt-8 md:mt-14">
        <div className="relative mb-4 h-[256px] md:h-[355px]">
          <div className="max-w-[1680px] mx-auto">
            <div className="flex overflow-hidden w-full py-4 case-studies-slides-box">
              <div className="flex justify-between items-center shrink-0 case-studies-slides">
                {CASE_STUDIES.map((item) => {
                  return (
                    <CaseStudtItem
                      key={item.id}
                      img={item.photo.type}
                      name={item.name}
                      url={item.url}
                    />
                  );
                })}
              </div>
              <div className="flex justify-between items-center shrink-0 case-studies-slides">
                {CASE_STUDIES.map((item) => {
                  return (
                    <CaseStudtItem
                      key={item.id}
                      img={item.photo.type}
                      name={item.name}
                      url={item.url}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudies;
