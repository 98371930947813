import { makeAutoObservable, runInAction } from 'mobx';
import { companyFileEndpoint } from '@config/endpoints';
import fileDownload from 'js-file-download';
import CompanyFileCategoryStore from '@/stores/CompanyPortalStores/CompanyFileCategoryStore';
import _ from 'lodash';
import AdminUserStore from '@/stores/GlobalStores/AdminUserStore';
import NestedCompanyFileStore from '@/stores/CompanyPortalStores/NestedCompanyFileStore';

class CompanyFileStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.meta = {};
      this.companyFileCategoryStore = new CompanyFileCategoryStore();
      this.authorStore = new AdminUserStore();
      this.nestedCompanyFileStore = new NestedCompanyFileStore();
    });
  }

  bindMethods() {
    this.assignCompanyFiles = this.assignCompanyFiles.bind(this);
    this.fetchAndAssignRelationships = this.fetchAndAssignRelationships.bind(this);
  }

  fetchAll(params, extendCollection = false, applyRelationships = false) {
    return companyFileEndpoint
      .all(params)
      .then((response) => this.assignCompanyFiles(response, extendCollection, applyRelationships));
  }

  // eslint-disable-next-line class-methods-use-this
  downloadFile(id, name) {
    return companyFileEndpoint.download(id).then((response) => fileDownload(response.data, name));
  }

  // eslint-disable-next-line class-methods-use-this
  downloadFiles(id, name) {
    return companyFileEndpoint
      .downloadFiles(id)
      .then((response) => fileDownload(response.data, name));
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.meta = {};
    });
  }

  async assignCompanyFiles(response, extendCollection, applyRelationships) {
    let newCollection = response.data.data.map(
      ({ attributes, relationships: { author, companyFiles } }) => ({
        ...attributes,
        authorId: author.data.id,
        companyFileIds: companyFiles.data.map(({ id }) => id)
      })
    );
    if (applyRelationships) newCollection = await this.fetchAndAssignRelationships(newCollection);

    runInAction(() => {
      if (extendCollection) this.collection = this.collection.concat(newCollection);
      else this.collection = newCollection;
      this.meta = response.data.meta;
    });
  }

  async fetchAndAssignRelationships(collection) {
    const companyFileCategoryIds = collection.map(
      ({ companyFileCategoryId }) => companyFileCategoryId
    );
    const authorIds = collection.map(({ authorId }) => authorId);
    const companyFileIds = collection.map((record) => record.companyFileIds);

    await this.companyFileCategoryStore.fetchAll({ q: { idIn: _.uniq(companyFileCategoryIds) } });

    if (_.uniq(authorIds).length > 0)
      await this.authorStore.fetchAll({ q: { idIn: _.uniq(authorIds) } });

    if (_.uniq(companyFileIds.flat()).length > 0)
      await this.nestedCompanyFileStore.fetchAll({
        q: { idIn: _.uniq(companyFileIds.flat()) },
        authorizedScopeType: 'visible'
      });

    return collection.map((record) => {
      const foundCompanyFileCategory = this.companyFileCategoryStore.collection.find(
        ({ id }) => record.companyFileCategoryId === id
      );
      const foundAuthor = this.authorStore.collection.find(
        ({ id }) => record.authorId === id.toString()
      );
      const foundCompanyFiles = this.nestedCompanyFileStore.collection.filter(({ id }) =>
        record.companyFileIds.includes(id.toString())
      );

      return {
        companyFileCategory: foundCompanyFileCategory,
        author: foundAuthor,
        companyFiles: foundCompanyFiles,
        ...record
      };
    });
  }
}

export default CompanyFileStore;
