import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AvatarMask } from '@images/user-hex-info-max.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';

function QuoteSection({ cover, avatar, name, position, quote }) {
  const { t } = useTranslation('screens', { keyPrefix: 'caseStudyScreen.quoteSection' });
  return (
    <section className="bg-orange-20 py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/4 px-4">
            <span className="block text-primary text-sm md:text-base font-bold uppercase lg:mt-8">
              {t('subheader')}
            </span>
            <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
              {t('header')}
            </h2>
            <div className="text-sm md:text-base text-grey-30 mt-4 xl:pr-6">{t('lead')}</div>
          </div>
          <div className="w-full lg:w-3/4 px-4 mt-8 lg:mt-0">
            <div className="bg-white shadow-ds8 rounded-2xl p-4 md:p-8">
              <div>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-5/12 px-4">
                    <div className="aspect-w-16 aspect-h-13 rounded-xl overflow-hidden">
                      <img src={cover} alt={name} className="rounded-xl object-cover" />
                    </div>
                  </div>
                  <div className="w-full md:w-7/12 px-4">
                    <div className="flex flex-col justify-between h-full py-4">
                      <div className="h-full">
                        <div className="flex items-center pl-[12%] md:pl-[33%] pt-4 md:pt-0 transform">
                          <div className="relative flex-shrink-0 mr-3 text-orange-60 w-[118px] h-[100px]">
                            <AvatarMaskBack
                              width="118px"
                              height="100px"
                              className="fill-current absolute -top-1 -left-3"
                            />
                            <img
                              src={avatar}
                              alt={name}
                              className="rounded-xl h-full w-full object-cover avatar-mask-medium"
                              width="120px"
                              height="120px"
                            />
                          </div>
                          <div className="transform -translate-y-1">
                            <h3 className="text-grey-50 text-xs md:text-sm font-bold">{name}</h3>
                            <div className="text-grey-50 text-xs md:text-sm">{position}</div>
                          </div>
                        </div>
                        <div className="text-center text-grey-30 text-xs md:text-sm mt-4 md:mt-4 xl:px-6">
                          {quote}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AvatarMask width="0" height="0" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuoteSection;

QuoteSection.propTypes = {
  cover: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  quote: PropTypes.string
};

QuoteSection.defaultProps = {
  cover: '',
  avatar: '',
  name: '',
  position: '',
  quote: ''
};
