import { ReactComponent as BeeHeartIcon } from '@images/icons/bee-heart.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { COMPANY_PORTAL_PATH } from '@constants';

function CompanyPortalButton() {
  const { t } = useTranslation('components', {
    keyPrefix: 'layouts.basicLayout.header.components.companyPortalButton'
  });

  return (
    <Link
      to={COMPANY_PORTAL_PATH}
      className="inline-flex items-center justify-center w-full lg:w-auto relative bg-primary-0 hover:bg-primary-20 text-primary hover:text-primary-120 border-0 m-0 py-2 px-6 rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
    >
      <BeeHeartIcon className="mr-3 fill-current" width="24px" height="24px" />
      {t('name')}
    </Link>
  );
}

export default CompanyPortalButton;
