import LazyLoad from 'react-lazyload';
import { ReactComponent as HeroHex01 } from '@images/hexes/hex-01.svg';
import { ReactComponent as HeroHex02 } from '@images/hexes/hex-02.svg';

function HexDecoration() {
  return (
    <>
      <div className="hidden lg:block 3xl:hidden absolute top-5 left-16 transform -translate-y-3/4 -mr-6 text-green pointer-events-none">
        <div data-sal="fade" data-sal-duration="800">
          <LazyLoad>
            <HeroHex01 className="fill-current opacity-30" />
          </LazyLoad>
        </div>
      </div>
      <div className="hidden lg:block 3xl:hidden absolute top-1/2 right-full transform -translate-y-1/2 -mr-6 -mt-6 text-primary pointer-events-none">
        <div data-sal="fade" data-sal-duration="800">
          <LazyLoad>
            <HeroHex01 className="fill-current opacity-30" />
          </LazyLoad>
        </div>
      </div>
      <div className="hidden lg:block 3xl:hidden absolute top-1/2 left-full h-[44%] transform -translate-y-1/2 -ml-12 -mt-32 text-orange pointer-events-none">
        <div className="h-full" data-sal="fade" data-sal-duration="800">
          <LazyLoad className="h-full">
            <HeroHex02 className="fill-current opacity-30 h-full" />
          </LazyLoad>
        </div>
      </div>
    </>
  );
}

export default HexDecoration;
