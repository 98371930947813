import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { CASE_STUDY_PATH } from '@constants';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import CommunicationSliderBody from '../CommunicationSliderBody';

function CommunicationSliderContentBox({ sliderToShowNumber, slides, itemsNumber }) {
  const [carouselBoxesNav, setCarousleBoxesNav] = useState(null);
  const [carouselLogosNav, setCarouselLogosNav] = useState(null);
  const carouselBoxes = useRef(null);
  const carouselLogos = useRef(null);

  useEffect(() => {
    setCarousleBoxesNav(carouselBoxes.current);
    setCarouselLogosNav(carouselLogos.current);
  }, []);

  const carouselLogosSettings = {
    dots: false,
    speed: 1500,
    autoplaySpeed: 8000,
    slidesToShow: sliderToShowNumber,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  };

  const carouselBoxesSettings = {
    dots: false,
    arrows: false,
    fade: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  };
  return (
    <>
      <div className="relative z-20 slider-dots-no-dots">
        <Slider asNavFor={carouselLogosNav} ref={carouselBoxes} {...carouselBoxesSettings}>
          {slides.map((slide) => {
            return (
              <CommunicationSliderBody
                key={slide.head}
                head={slide.head}
                lead={slide.lead}
                logo={slide.logo.type}
                photo={slide.photo.type}
                url={generatePath(CASE_STUDY_PATH, { caseStudySlug: slide.url })}
                backgroundColor={slide.backgroundColor}
              />
            );
          })}
        </Slider>
      </div>
      <div className="hidden lg:block relative z-10 transform -translate-y-4">
        <div className="flex -mx-3 justify-end">
          <div className="w-7/12 px-3">
            <div
              className={`slider-dots-no-dots slider-logos-progress ${
                itemsNumber <= 4 ? 'slider-hide-cloned' : ''
              } `}
            >
              <Slider asNavFor={carouselBoxesNav} ref={carouselLogos} {...carouselLogosSettings}>
                {slides.map((slide) => {
                  return (
                    <div
                      key={slide.head}
                      className={`relative overflow-hidden ${slide.backgroundColor}`}
                    >
                      <div className="px-4 pt-8 pb-6 flex justify-center relative overflow-hidden slick-progress-bg">
                        <div className="h-8 relative flex z-10">
                          <LazyLoad height={32}>
                            <img
                              src={slide.logo.type}
                              alt={slide.head}
                              className="object-contain h-full grayscale"
                            />
                          </LazyLoad>
                        </div>
                        <div className="h-full bg-current absolute top-0 left-0 slick-progress" />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunicationSliderContentBox;

CommunicationSliderContentBox.propTypes = {
  sliderToShowNumber: PropTypes.number,
  itemsNumber: PropTypes.number,
  slides: PropTypes.arrayOf(PropTypes.object)
};

CommunicationSliderContentBox.defaultProps = {
  sliderToShowNumber: '',
  itemsNumber: '',
  slides: []
};
