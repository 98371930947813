import { makeAutoObservable, runInAction } from 'mobx';
import { companyFileCategoryEndpoint } from '@config/endpoints';

class CompanyFileCategoryStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignCompanyFileCategories = this.assignCompanyFileCategories.bind(this);
  }

  fetchAll(params) {
    return companyFileCategoryEndpoint.all(params).then(this.assignCompanyFileCategories);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }

  assignCompanyFileCategories(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default CompanyFileCategoryStore;
