import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import PartnerPhoto01 from '@images/honey-project/partners/01.jpg';
import PartnerPhoto02 from '@images/honey-project/partners/02.jpg';
import PartnerPhoto03 from '@images/honey-project/partners/03.jpg';
import PartnerPhoto04 from '@images/honey-project/partners/04.jpg';
import PartnerPhoto05 from '@images/honey-project/partners/05.jpg';
import PartnerPhoto06 from '@images/honey-project/partners/06.jpg';
import PartnerPhoto07 from '@images/honey-project/partners/07.jpg';
import PartnerPhoto08 from '@images/honey-project/partners/08.jpg';
import PartnerPhoto09 from '@images/honey-project/partners/09.jpg';
import PartnerPhoto10 from '@images/honey-project/partners/10.jpg';
import PartnerPhoto11 from '@images/honey-project/partners/11.jpg';
import PartnerPhoto12 from '@images/honey-project/partners/12.jpg';
import PartnerPhoto13 from '@images/honey-project/partners/13.jpg';
import Badge from '@images/honey-project/badge.png';
import Bees from '@images/honey-project/bees.png';
import { ReactComponent as NextArrowIcon } from '@images/icons/next.svg';
import { ReactComponent as PrevArrowIcon } from '@images/icons/prev.svg';

function NextArrow({ onClick }) {
  return (
    <button
      type="button"
      className="outline-none focus:outline-none bg-white text-grey-50 w-8 h-8 rounded-full flex items-center justify-center absolute z-10 right-6 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <NextArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

NextArrow.propTypes = {
  onClick: PropTypes.func
};

NextArrow.defaultProps = {
  onClick: () => {}
};

function PrevArrow({ onClick }) {
  return (
    <button
      type="button"
      className="outline-none focus:outline-none bg-white w-8 h-8 rounded-full flex items-center justify-center absolute z-10 left-6 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <PrevArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

PrevArrow.propTypes = {
  onClick: PropTypes.func
};

PrevArrow.defaultProps = {
  onClick: () => {}
};

function OurPartners() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'honeyProjectScreen.components.ourPartners'
  });

  const sliderSettings = {
    dots: true,
    arrows: true,
    speed: 800,
    swipeToSlide: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    draggable: true,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: true,
          centerPadding: '7%'
        }
      }
    ]
  };

  return (
    <section className="bg-primary-light py-10 md:py-12 xl:pt-20">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="md:text-center relative">
          <span className="block text-primary text-sm md:text-base font-bold uppercase">
            {t('subheader')}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
            {t('header')}
          </h2>
          <LazyLoad height={139}>
            <img
              src={Bees}
              alt={t('subheader')}
              className="hidden xl:block absolute top-0 left-24 transform -translate-y-10"
              height="139px"
              width="148px"
            />
          </LazyLoad>
          <LazyLoad height={144}>
            <img
              src={Badge}
              alt={t('subheader')}
              className="hidden xl:block absolute top-0 right-28 transform -translate-y-10"
              height="144px"
              width="170px"
            />
          </LazyLoad>
        </div>
        <div className="mt-16 -mx-4 slider-dots-center">
          <Slider {...sliderSettings}>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto01} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto02} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto03} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto04} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto05} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto06} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto07} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto08} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto09} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto10} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto11} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto12} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
            <div className="px-4">
              <div className="aspect-h-1 aspect-w-1">
                <img src={PartnerPhoto13} alt={t('subheader')} className="rounded-2xl" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default OurPartners;
