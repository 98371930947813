import { makeAutoObservable, runInAction } from 'mobx';
import { postsEndpoint } from '@config/endpoints';

class PostStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.meta = {};
    });
  }

  bindMethods() {
    this.assignPosts = this.assignPosts.bind(this);
    this.assignPost = this.assignPost.bind(this);
  }

  fetchAll(params, extendCollection) {
    return postsEndpoint
      .all(params)
      .then((response) => this.assignPosts(response, extendCollection));
  }

  fetchOne(id) {
    return postsEndpoint.one(id).then(this.assignPost);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.record = {};
    });
  }

  assignPosts(response, extendCollection) {
    const newCollection = response.data.data.map(({ attributes }) => attributes);

    runInAction(() => {
      if (extendCollection) this.collection = this.collection.concat(newCollection);
      else this.collection = newCollection;
      this.meta = response.data.meta;
    });
  }

  assignPost(response) {
    runInAction(() => {
      this.record = response.data.data.attributes;
    });
  }
}

export default PostStore;
