export const TOKEN_STORAGE_KEY = 'project-2028-frontend-key';

/// ROUTES

export const ROOT_PATH = '/';
export const ABSOLUTE_ROOT_PATH = process.env.REACT_APP_HOST;
export const PROFILE_PATH = '/profil';
export const COMPANY_PORTAL_BEEKEEPERS_PATH = '/content-portal/imkerinnen/';
export const COMPANY_PORTAL_BEEKEEPER_PATH = '/content-portal/imkerinnen/:beekeeperSlug';
export const COMPANY_PORTAL_PATH = '/content-portal';
export const COMPANY_PORTAL_FILES_PATH = '/content-portal/:type';
export const COMPANY_PORTAL_ARTICLE_PATH = '/content-portal/:type/:articleSlug';
export const AUTH_PATH = '/anmelden';
export const CASE_STUDIES_PATH = '/case-studies';
export const CASE_STUDY_PATH = '/case-studies/:caseStudySlug';
export const POSTS_PATH = '/blog';
export const POST_PATH = '/blog/:postSlug';
export const PRESS_RELEASE_PATH = '/presse/:slug';
export const PRESS_RELEASES_PATH = '/presse';
export const TIMELINE_NOTE_PATH = '/content-portal/imkerinnen/:beekeeperSlug/:timelineNoteSlug';
export const PUBLIC_TIMELINE_NOTE_PATH =
  '/unternehmen/:companySlug/:beekeeperSlug/:timelineNoteSlug';
export const PARTNERS_PATH = '/unternehmen';
export const COMPANY_PROFILE_PATH = '/unternehmen/:companySlug';
export const OLD_BEEKEEPER_PROFILE_PATH = '/unternehmen/:companySlug/:beekeeperSlug';
export const BEEKEEPER_PROFILE_PATH = '/imkerinnen/:beekeeperSlug';
export const HONEY_PROJECT_PATH = '/firmengeschenke';
export const ABOUT_PATH = '/ueber';
export const CONTACT_PATH = '/kontakt';
export const TERMS_OF_USE_PROJECT_2028_PORTAL_PATH = '/nutzungsbedingungen';
export const PRIVACY_POLICY_PATH = '/datenschutz';
export const TERMS_AND_CONDITIONS_PATH = '/agbs';
export const REMIND_PASSWORD_PATH = '/remind-password';
export const CONFIRM_PASSWORD_PATH = '/confirm-password';
export const COMAPNY_APPLICATION_FORM_DONE_PATH = ':path/danke';
export const CONTACT_FORM_DONE_PATH = '/kontakt/danke';
export const ERROR_500_PATH = '/500';
export const ERROR_422_PATH = '/422';

export const HN_PATH = 'https://hektarnektar.com/de';
export const HN_BEEKEEPERS_PATH = 'https://hektarnektar.com/de/projektimker';
export const HN_COMMUNITY_PATH = 'https://hektarnektar.com/de/projekt2028/imker/profile';
export const HN_MARKETPLACE_PATH = 'https://hektarnektar.com/de/marktplatz';
export const HN_ADOPT_BEE_PATH = 'https://hektarnektar.com/de/bienenpatenschaft';

/// Website links
export const FACEBOOK_PATH = 'https://facebook.com/hektarnektar1/';
export const INSTAGRAM_PATH = 'https://www.instagram.com/hektarnektar';

// Google API Key
export const GOOGLE_API_KEY = 'AIzaSyC_apNt1RraUsDSxCPvxOhQ3vyCxlBN284';

/// Regexps

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const ADOPTED_BEES_NUMBER = '196.450.000';

export default {};
