import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SkeletonPRContact() {
  return (
    <div className="p-4 rounded-2xl">
      <h3 className="text-white text-lg md:text-2xl font-bold">
        <Skeleton width="70%" />
      </h3>
      <div className="flex items-center mt-2">
        <div className="relative overflow-hidden w-16 h-16 flex-shrink-0 mr-4">
          <div className="object-cover w-full h-full absolute inset-0">
            <Skeleton height="90%" width="90%" borderRadius={50} />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="inline-block my-1 text-white text-sm hover:underline">
            <Skeleton width="70%" />
          </div>
          <div className="inline-block my-1 text-white text-sm hover:underline">
            <Skeleton width="70%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonPRContact;
