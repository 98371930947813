import { useTranslation, Trans } from 'react-i18next';
import HeroWithMap from '@components/UIElements/HeroWithMap';
import { ReactComponent as EmailIcon } from '@images/icons/mail.svg';
import ContactForm from '@screens/ContactScreen/components/ContactForm';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

function ContactScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'contactScreen' });
  return (
    <>
      <TitleAndDescription screenName="contactScreen" />
      <HeroWithMap lng={16.26813} lat={48.18775} markerLng={16.26813} markerLat={48.18775}>
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base mt-6 xl:pr-44 text-entry">
          <Trans i18nKey="screens:contactScreen.lead">
            <p>Hietzinger Hauptstraße 145-147 / 1 / 1, 1130 Wien, Österreich</p>
            <p>
              Firmenbuchnummer: FN 473290s UID Nummer: ATU72433789 Geschäftsführer: Martin Poreda,
              Mark Poreda, Miriam Walch
            </p>
            <p>Mitglied der Wirtschaftskammer Niederösterreich</p>
          </Trans>
        </div>
        <div className="mt-4">
          <a
            href={`mailto:${t('email')}`}
            className="flex items-center text-sm md:text-base text-primary hover:text-primary-120"
          >
            <EmailIcon width="16" height="16" className="fill-current flex-shrink-0 mr-3" />
            {t('email')}
          </a>
        </div>
      </HeroWithMap>
      <ContactForm />
    </>
  );
}

export default ContactScreen;
