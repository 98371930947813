import { action, makeObservable, observable, runInAction } from 'mobx';
import { userEndpoint } from '@config/endpoints';
import AddressStore from '@/stores/ProfileStores/AddressStore';

const defaultRecord = { livingAddress: {} };

class UserStore {
  constructor() {
    this.initFields();
    this.bindMethods();

    makeObservable(this, {
      collection: observable,
      fetchAll: action,
      cleanCollection: action,
      meta: observable
    });

    this.livingAddressStore = new AddressStore();
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.record = defaultRecord;
      this.meta = {};
    });
  }

  bindMethods() {
    this.assignUser = this.assignUser.bind(this);
    this.assignUsers = this.assignUsers.bind(this);
  }

  fetchAll(params, extendCollection = false) {
    return userEndpoint
      .all(params)
      .then((response) => this.assignUsers(response, extendCollection));
  }

  fetchOne(id) {
    return userEndpoint.one(id).then((response) => this.assignUser(response));
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.record = {
        livingAddress: {}
      };
    });
  }

  async assignUser(response) {
    const {
      attributes,
      relationships: { livingAddress, assignedBeekeepers }
    } = response.data.data;
    const newRecord = attributes;

    newRecord.beekeeperIds = assignedBeekeepers.data.map(({ id }) => id);
    newRecord.livingAddressId = livingAddress.data.id;

    if (livingAddress) {
      await this.livingAddressStore.fetch(livingAddress.data.id);
      newRecord.livingAddress = this.livingAddressStore.address;
    }

    runInAction(() => {
      this.record = newRecord;
    });
  }

  async assignUsers(response, extendCollection = false) {
    let newCollection = response.data.data.map(
      ({ attributes, relationships: { livingAddress } }) => ({
        livingAddressId: livingAddress?.data?.id,
        ...attributes
      })
    );

    if (extendCollection) newCollection = this.collection.concat(newCollection);

    const livingAddressIds = newCollection.map(({ livingAddressId }) => livingAddressId);
    await this.livingAddressStore.fetchAll({ q: { idIn: livingAddressIds } });
    newCollection = newCollection.map((record) => {
      const livingAddress =
        this.livingAddressStore.collection.find(({ id }) => id === record.livingAddressId) || {};

      return { livingAddress, ...record };
    });

    runInAction(() => {
      this.collection = newCollection;
      this.meta = response.data.meta;
    });
  }
}

export default UserStore;
