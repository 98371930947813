import { useEffect, useState } from 'react';
import { useParams, Link, generatePath } from 'react-router-dom';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { POSTS_PATH, POST_PATH } from '@constants';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { ReactComponent as FacebookIcon } from '@images/icons/social/facebook.svg';
import { ReactComponent as LinkedInIcon } from '@images/icons/social/linkedIn.svg';
import { ReactComponent as TwitterIcon } from '@images/icons/social/twitter.svg';
import { ReactComponent as WebsiteIcon } from '@images/icons/social/website.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import Share from './components/Share';
import Entry from './components/Entry';

function PostScreen() {
  const { postSlug } = useParams();
  const [recordFetching, setRecordFetching] = useState(true);
  const [similarPosts, setSimilarPosts] = useState([]);
  const { postStore } = useStores();
  const post = postStore.record;
  const { t } = useTranslation('screens', { keyPrefix: 'postScreen' });

  useEffect(async () => {
    await postStore.fetchAll({
      sortBy: 'published_at desc'
    });
    await postStore.fetchOne(postSlug);
    const similarPostsExcludeId = postStore.collection.filter(
      (item) => item.id !== postStore.record.id
    );
    const similarPostsExcludeCategory = similarPostsExcludeId.filter(
      (item) => item.postCategory.title === postStore.record.postCategory.title
    );
    setSimilarPosts(similarPostsExcludeCategory.slice(0, 3));
    setRecordFetching(false);
  }, []);

  const shareLink = (slug) => {
    return window.location.origin + generatePath(POST_PATH, { postSlug: slug });
  };

  if (recordFetching)
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );

  return (
    <>
      <TitleAndDescription
        screenName="postScreen"
        attributes={{
          postTitle: post.title,
          description: _.truncate(post.abstract, { length: 100 })
        }}
      />

      <section className="py-8">
        <div className="max-w-screen-content mx-auto px-4 relative">
          <div className="flex items-center justify-between lg:mt-10">
            <Link
              to={POSTS_PATH}
              className="group transition-colors duration-300 ease-out font-medium text-sm text-primary hover:text-primary-120 inline-flex items-center py-2"
            >
              <ArrowRightIcon
                width="16"
                height="16"
                className="transition-all duration-300 ease-out transform rotate-180 stroke-current flex-shrink-0 mr-1 group-hover:-translate-x-1"
              />
              {t('backToBlog')}
            </Link>
            <div>
              <span
                className={`${
                  post.postCategory.title === 'News' ? 'bg-primary' : 'bg-green'
                } text-white text-xs font-medium uppercase px-6 py-2 inline-flex`}
              >
                {post.postCategory.title}
              </span>
            </div>
          </div>
          <div>
            <div className="text-center mt-6">
              <h1 className="text-xl md:text-4xl font-bold text-grey-50">{post.title}</h1>
              <div className="text-base text-grey-30 mt-2">
                {post.author.firstname && `by ${post.author.firstname} `}
                {post.author.lastname && post.author.lastname}
              </div>
            </div>
            <div className="mt-4">
              <div className="aspect-w-16 aspect-h-9">
                <img src={post.cover} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
            <div className="mt-6 text-grey-25 text-sm">
              {moment(post.publishedAt).locale('de').format('dddd, DD. MMMM YYYY')}
            </div>
            <div className="mt-6">
              <div className="blog-entry prose prose-base max-w-none text-grey-30 prose-headings:text-grey-50">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: post.body }} />
              </div>
            </div>
          </div>
          <div className="mt-16 mb-12 lg:mt-32 lg:mb-20">
            <div className="flex items-center justify-center space-x-6">
              <div className="transition-colors duration-300 text-grey-30 hover:text-primary">
                <FacebookShareButton
                  url={shareLink(post.slug)}
                  quote={post.abstract}
                  className="w-14 h-14 rounded-full bg-white inline-flex items-center justify-center shadow-ds8 outline-none focus:outline-none"
                >
                  <FacebookIcon width="24" height="24" className="fill-current" />
                </FacebookShareButton>
              </div>
              <div className="transition-colors duration-300 text-grey-30 hover:text-primary">
                <LinkedinShareButton
                  url={shareLink(post.slug)}
                  title={post.title}
                  summary={post.abstract}
                  source={shareLink(post.slug)}
                  className="w-14 h-14 rounded-full bg-white inline-flex items-center justify-center shadow-ds8 outline-none focus:outline-none"
                >
                  <LinkedInIcon width="24" height="24" className="fill-current" />
                </LinkedinShareButton>
              </div>
              <div className="transition-colors duration-300 text-grey-30 hover:text-primary">
                <TwitterShareButton
                  url={shareLink(post.slug)}
                  title={post.title}
                  className="w-14 h-14 rounded-full bg-white inline-flex items-center justify-center shadow-ds8 outline-none focus:outline-none"
                >
                  <TwitterIcon width="24" height="24" className="fill-current" />
                </TwitterShareButton>
              </div>
              <div className="transition-colors duration-300 text-grey-30 hover:text-primary">
                <EmailShareButton
                  url={shareLink(post.slug)}
                  subject={post.title}
                  body={post.abstract}
                  className="w-14 h-14 rounded-full bg-white inline-flex items-center justify-center shadow-ds8 outline-none focus:outline-none"
                >
                  <WebsiteIcon width="24" height="24" className="fill-current" />
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </section>
      {similarPosts.length > 0 && (
        <section className="pt-16 bg-primary-light">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <h2 className="text-2xl md:text-4xl font-bold text-grey-50 mb-10">
              {t('similarPostsHead')}
            </h2>
            <div className="flex flex-wrap -mx-4 lg:-mx-6">
              {similarPosts.map((entry) => {
                return (
                  <Entry
                    key={entry.id}
                    url={generatePath(POST_PATH, { postSlug: entry.slug })}
                    cover={entry.cover}
                    postCategory={entry.postCategory.title}
                    date={entry.publishedAt}
                    title={entry.title}
                    abstract={entry.abstract}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}
      <div className="bg-primary-light">
        <Share />
      </div>
    </>
  );
}

export default PostScreen;
