import { makeAutoObservable, runInAction } from 'mobx';
import { companyActivityFeedEndpoint } from '@config/endpoints';
import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import CompanyFileStore from '@/stores/CompanyPortalStores/CompanyFileStore';
import { parseBool } from '@tools';

class CompanyActivityFeedStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.timelineNoteStore = new TimelineNoteStore();
      this.companyFileStore = new CompanyFileStore();
    });
  }

  bindMethods() {
    this.assignCompanyActivityFeeds = this.assignCompanyActivityFeeds.bind(this);
  }

  fetchAll(params) {
    return companyActivityFeedEndpoint.all(params).then(this.assignCompanyActivityFeeds);
  }

  async assignCompanyActivityFeeds(response) {
    let newCollection = response.data.data.map(({ attributes }) => attributes);
    const timelineNoteIds = newCollection.flatMap(({ activitableId, activitableType }) =>
      activitableType === 'TimelineNote' ? activitableId : []
    );

    const companyFileOrArticleIds = newCollection.flatMap(({ activitableId, activitableType }) =>
      activitableType === 'CompanyFile' || activitableType === 'CompanyArticle' ? activitableId : []
    );

    await this.timelineNoteStore.fetchAll({ q: { idIn: timelineNoteIds.concat(-1) } });
    await this.companyFileStore.fetchAll(
      {
        q: { idIn: companyFileOrArticleIds.concat(-1) },
        authorizedScopeType: 'visible'
      },
      false,
      true
    );

    newCollection = newCollection.flatMap((record) => {
      const { activitableId, activitableType } = record;
      let foundActivitable;

      if (activitableType === 'TimelineNote')
        foundActivitable = this.timelineNoteStore.collection.find(({ id }) => id === activitableId);
      else
        foundActivitable = this.companyFileStore.collection.find(({ id }) => id === activitableId);

      return parseBool(foundActivitable) ? { activitable: foundActivitable, ...record } : [];
    });

    runInAction(() => {
      this.collection = newCollection;
    });
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }
}

export default CompanyActivityFeedStore;
