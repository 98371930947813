import { makeAutoObservable, runInAction } from 'mobx';
import { userEndpoint, beekeeperEndpoint } from '@config/endpoints';
import CompanyBeekeeperStore from '@/stores/GlobalStores/CompanyBeekeeperStore';
import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import ProfilePhotoStore from '@/stores/GlobalStores/ProfilePhotoStore';
import AttachmentStore from '@/stores/GlobalStores/AttachmentStore';
import fileDownload from 'js-file-download';
import AddressStore from '@/stores/ProfileStores/AddressStore';
import _ from 'lodash';

const defaultRecord = {
  companyBeekeeper: {},
  livingAddress: {},
  timeLineNotes: [],
  profilePhotos: [],
  attachments: []
};

class BeekeeperStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);

    this.livingAddressStore = new AddressStore();
    this.companyBeekeeperStore = new CompanyBeekeeperStore();
    this.timelineNoteStore = new TimelineNoteStore();
    this.photoProfileStore = new ProfilePhotoStore();
    this.attachmentStore = new AttachmentStore();
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.record = defaultRecord;
    });
  }

  bindMethods() {
    this.assignUsers = this.assignUsers.bind(this);
    this.assignUser = this.assignUser.bind(this);
  }

  fetchAll(params) {
    return userEndpoint.all(params).then(this.assignUsers);
  }

  fetchOne(slug, companyId = null) {
    return userEndpoint.one(slug).then((response) => this.assignUser(response, companyId));
  }

  // eslint-disable-next-line class-methods-use-this
  downloadAllPhotos(id, name) {
    return beekeeperEndpoint
      .downloadAllBeekeeperPhotos(id)
      .then((response) => fileDownload(response.data, name));
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.record = defaultRecord;
    });
  }

  async assignUsers(response) {
    const newCollection = response.data.data.map(
      ({ attributes, relationships: { livingAddress } }) => ({
        livingAddressId: livingAddress?.data?.id,
        ...attributes
      })
    );

    const beekeeperIds = newCollection.map(({ id }) => id);
    const addressIds = newCollection.map(({ livingAddressId }) => livingAddressId);

    await this.companyBeekeeperStore.fetchAll({
      q: { beekeeperIdIn: _.uniq(beekeeperIds), discardedAtNull: true }
    });

    await this.livingAddressStore.fetchAll({ q: { idIn: addressIds } });

    runInAction(() => {
      this.collection = newCollection.map((record) => {
        const companyBeekeeper = this.companyBeekeeperStore.collection.find(
          ({ beekeeperId }) => beekeeperId === record.id
        );
        const livingAddress = this.livingAddressStore.collection.find(
          ({ id }) => id === record.livingAddressId
        );

        return { companyBeekeeper, livingAddress, ...record };
      });
    });
  }

  async assignUser(response, companyId = null) {
    const {
      attributes,
      relationships: { livingAddress }
    } = response.data.data;
    const userRecord = attributes;
    const extraParams = companyId ? { companyIdEq: companyId } : {};

    await this.companyBeekeeperStore.fetchAll({
      q: { beekeeperIdIn: [userRecord.id], discardedAtNull: true, ...extraParams }
    });

    const companyBeekeeperIds = this.companyBeekeeperStore.collection.map(({ id }) => id);

    await this.timelineNoteStore.fetchAll({
      q: { companyBeekeeperIdIn: companyBeekeeperIds },
      limit: 6
    });

    await this.photoProfileStore.fetchAll({ q: { userIdEq: userRecord.id } });

    await this.attachmentStore.fetchAll({
      q: {
        attachable_of_TimelineNote_type_author_id_in: userRecord.id,
        attachable_of_TimelineNote_type_company_beekeeper_id_in: companyBeekeeperIds
      }
    });

    const companyBeekeeper = this.companyBeekeeperStore.collection.find(
      ({ beekeeperId }) => beekeeperId === userRecord.id
    );

    if (livingAddress) {
      await this.livingAddressStore.fetch(livingAddress.data.id);
      userRecord.livingAddress = this.livingAddressStore.address;
    }

    runInAction(() => {
      this.record = {
        companyBeekeeper,
        timeLineNotes: this.timelineNoteStore.collection,
        profilePhotos: this.photoProfileStore.collection,
        attachments: this.attachmentStore.collection,
        ...userRecord
      };
    });
  }
}

export default BeekeeperStore;
