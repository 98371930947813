import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import { createContext, useContext } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import CompanyBeekeeperStore from '@/stores/GlobalStores/CompanyBeekeeperStore';
import ProfilePhotoStore from '@/stores/GlobalStores/ProfilePhotoStore';
import UserStore from '@/stores/GlobalStores/UserStore';
import AddressStore from '@/stores/ProfileStores/AddressStore';
import ProfileQuestionStore from '@/stores/ProfileStores/ProfileQuestionStore';
import ProfileAnswerStore from '@/stores/ProfileStores/ProfileAnswerStore';

export class BeekeeperScreenStore {
  constructor() {
    this.userStore = new UserStore();
    this.livingAddressStore = new AddressStore();
    this.companyBeekeeperStore = new CompanyBeekeeperStore();
    this.timelineNoteStore = new TimelineNoteStore();
    this.photoProfileStore = new ProfilePhotoStore();
    this.similarCompanyBeekeeperStore = new CompanyBeekeeperStore();
    this.similarBeekeepersStore = new UserStore();
    this.companyStore = new UserStore();
    this.profileQuestionStore = new ProfileQuestionStore();
    this.profileAnswerStore = new ProfileAnswerStore();

    this.bindMethods();
    this.record = {};
    this.similarBeekeepers = [];

    makeAutoObservable(this);
  }

  bindMethods() {
    this.assignBeekeeper = this.assignBeekeeper.bind(this);
    this.fetchBeekeeper = this.fetchBeekeeper.bind(this);
  }

  fetchBeekeeper(slug) {
    return this.userStore.fetchOne(slug).then(() => this.assignBeekeeper(this.userStore.record));
  }

  async assignBeekeeper(userRecord) {
    await this.companyBeekeeperStore.fetchAll({
      q: { beekeeperIdIn: [userRecord.id], discardedAtNull: true }
    });

    await this.timelineNoteStore.fetchAll({
      q: { authorIdEq: userRecord.id },
      page: 1,
      per: 3
    });

    await this.photoProfileStore.fetchAll({ q: { userIdEq: userRecord.id } });

    const companyBeekeeper = this.companyBeekeeperStore.collection.find(
      ({ beekeeperId }) => beekeeperId === userRecord.id
    );

    await this.similarCompanyBeekeeperStore.fetchAll({
      q: { companyIdEq: companyBeekeeper.companyId }
    });

    await this.companyStore.fetchAll({
      q: { idIn: this.companyBeekeeperStore.collection.map((x) => x.companyId) }
    });

    await this.profileQuestionStore.fetchCompanyProfileQuestions({
      q: {
        categoryIn: ['Vorstellung', 'Imkerei', 'Meinung', 'ImkerInnen', 'Hektar Nektar'],
        kind: 'beekeeper'
      }
    });

    const questionIds = this.profileQuestionStore.companyProfileQuestions.map(({ id }) => id);

    await this.profileAnswerStore.fetchAll({
      q: { userIdEq: userRecord.id, questionIdIn: questionIds.concat(-1) }
    });

    const beekeeperIds = this.similarCompanyBeekeeperStore.collection
      .map(({ beekeeperId }) => beekeeperId)
      .filter((id) => id !== userRecord.id);

    if (beekeeperIds.length > 1) {
      await this.similarBeekeepersStore.fetchAll({ q: { idIn: beekeeperIds } });
    }

    runInAction(() => {
      this.similarBeekeepers = this.similarBeekeepersStore.collection;
      this.record = {
        user: userRecord,
        companyBeekeeper,
        profilePhotos: this.photoProfileStore.collection
      };
    });
  }
}

export const StoresContext = createContext(new BeekeeperScreenStore());
export const useBeekeeperScreenStore = () => useContext(StoresContext);
