import { useTranslation } from 'react-i18next';
import { useStores } from '@/stores';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { CASE_STUDY_PATH } from '@constants';
import CaseStudyBox from '@components/UIElements/CaseStudyBox';
import SectionHead from '@components/UIElements/SectionHead';

function FavoriteCaseStudies() {
  const { t } = useTranslation('screens', { keyPrefix: 'caseStudiesScreen.favoriteCaseStudies' });
  const { caseStudyStore } = useStores();
  const [caseStudiesFetching, setCaseStudiesFetching] = useState(true);

  useEffect(() => {
    caseStudyStore.fetchAll().then(() => setCaseStudiesFetching(false));
  }, []);

  const setHexInfoColor = (index) => {
    if (index % 4 === 1) return 'text-green-40';
    if (index % 4 === 2) return 'text-primary-40';
    if (index % 4 === 3) return 'text-red-40';
    return 'text-orange-40';
  };

  if (caseStudiesFetching) return <div />;

  return (
    <section className="bg-white py-10 md:py-14 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <SectionHead header={t('header')} subheader={t('subheader')} lead={t('lead')} />
        <div className="mt-8 pr-6">
          <div className="flex flex-wrap -mx-6">
            {caseStudyStore.collection.map((item, index) => {
              return (
                <div key={item.id} className="w-full md:w-1/2 lg:w-1/3 px-6">
                  <CaseStudyBox
                    key={item.id}
                    title={item.companyName}
                    url={generatePath(CASE_STUDY_PATH, { caseStudySlug: item.slug })}
                    logo={item.logo}
                    cover={item.overview}
                    lead={item.description}
                    backgroundColor={setHexInfoColor(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FavoriteCaseStudies;
