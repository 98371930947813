import Placeholder from '@images/timelinenote-placeholder.jpg';
import PropTypes from 'prop-types';
import { ReactComponent as NextArrowIcon } from '@images/icons/next.svg';
import { ReactComponent as PrevArrowIcon } from '@images/icons/prev.svg';
import ReactSlickSlider from 'react-slick';

function SampleNextArrow({ onClick }) {
  return (
    <button
      type="button"
      className="outline-none focus:outline-none bg-white text-grey-50 w-8 h-8 rounded-full flex items-center justify-center absolute z-10 right-4 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <NextArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

SampleNextArrow.propTypes = {
  onClick: PropTypes.func
};

SampleNextArrow.defaultProps = {
  onClick: () => {}
};

function SamplePrevArrow({ onClick }) {
  return (
    <button
      type="button"
      className="outline-none focus:outline-none bg-white w-8 h-8 rounded-full flex items-center justify-center absolute z-10 left-4 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <PrevArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

SamplePrevArrow.propTypes = {
  onClick: PropTypes.func
};

SamplePrevArrow.defaultProps = {
  onClick: () => {}
};

const defaultChildren = (
  <div>
    <div>
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={Placeholder}
          alt="Placeholder"
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
    </div>
    <div>
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={Placeholder}
          alt="Placeholder"
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
    </div>
  </div>
);

const sliderSettings = {
  dots: true,
  arrows: true,
  speed: 800,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  false: true,
  draggable: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

function Slider({ children }) {
  return (
    <div className="slider-dots-center">
      <ReactSlickSlider {...sliderSettings}>{children}</ReactSlickSlider>
    </div>
  );
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object)
};

Slider.defaultProps = {
  children: defaultChildren
};

export default Slider;
