import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

function SkeletonCompanyActivityFeed() {
  return (
    <section className="py-10">
      <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-1 border-b border-grey-15">
        <Skeleton width="30%" />
      </h2>
      <div>
        {_.times(7, () => (
          <div className="flex items-center my-4">
            <div className="w-3/4 md:w-1/2">
              <div className="flex items-center">
                <div className="w-12 h-12 md:w-16 md:h-16 rounded-md mr-4 flex-shrink-0">
                  <div className="aspect-w-1 aspect-h-1 rounded-md">
                    <div className="rounded-md object-cover">
                      <Skeleton width="100%" height="100%" />
                    </div>
                  </div>
                </div>
                <div className="truncate pr-3 w-full">
                  <h3 className="text-sm md:text-base text-grey-50 font-bold truncate h-10">
                    <Skeleton width="40%" height="70%" />
                  </h3>
                  <h4 className="text-xs md:text-sm text-grey-30 truncate">
                    <Skeleton width="30%" height="100%" />
                  </h4>
                </div>
              </div>
            </div>
            <div className="w-1/4 md:w-1/2">
              <div className="flex items-center justify-end">
                <div className="hidden md:flex items-center basis-1/3 flex-grow-0 flex-shrink">
                  <div className="w-6 h-6 rounded-full mr-2 flex-shrink-0">
                    <div className="aspect-w-1 aspect-h-1 rounded-md">
                      <div className="rounded-full object-cover">
                        <Skeleton width="100%" height="100%" />
                      </div>
                    </div>
                  </div>
                  <div className="text-base text-grey-30 w-full mt-2">
                    <Skeleton />
                  </div>
                </div>
                <div className="hidden md:flex justify-center text-base text-grey-30 text-center basis-1/3 flex-grow-0 flex-shrink mt-2">
                  <Skeleton containerClassName="w-1/2" />
                </div>
                <div className="flex items-center justify-end md:basis-1/3 flex-grow-0 flex-shrink">
                  <div className="inline-flex items-centertransition-colors duration-300 text-base text-grey-30 hover:text-primary">
                    <div className="fill-current stroke-current flex-shrink-0 w-6 h-6">
                      <Skeleton width="100%" height="100%" />
                    </div>
                  </div>
                  <div className="hidden md:inline-block ml-2 w-1/2 mt-2">
                    <Skeleton width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default SkeletonCompanyActivityFeed;
