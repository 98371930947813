import { makeAutoObservable, runInAction } from 'mobx';
import { addressEndpoint } from '@config/endpoints';
import CountryStore from '@/stores/GlobalStores/CountryStore';
import _ from 'lodash';

class AddressStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);

    this.countryStore = new CountryStore();
  }

  initFields() {
    runInAction(() => {
      this.address = { country: {} };
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignAddress = this.assignAddress.bind(this);
    this.assignAddresses = this.assignAddresses.bind(this);
  }

  fetchAll(params) {
    return addressEndpoint.all(params).then(this.assignAddresses);
  }

  // eslint-disable-next-line class-methods-use-this
  fetch(id) {
    return addressEndpoint.get(id).then(this.assignAddress);
  }

  // eslint-disable-next-line class-methods-use-this
  create(params) {
    return addressEndpoint.create(params);
  }

  // eslint-disable-next-line class-methods-use-this
  update(id, params) {
    return addressEndpoint.update(id, { address: params });
  }

  async assignAddress(response) {
    const { attributes } = response.data.data;
    const countryIds = [attributes].map(({ countryId }) => countryId);

    await this.countryStore.fetchAll({ q: { idIn: _.uniq(countryIds) } });

    runInAction(() => {
      this.address = { ...attributes, country: this.countryStore.collection[0] };
    });
  }

  async assignAddresses(response) {
    const newCollection = response.data.data.map(({ attributes }) => attributes);

    const countryIds = newCollection.map(({ countryId }) => countryId);

    await this.countryStore.fetchAll({ q: { idIn: _.uniq(countryIds) } });

    runInAction(() => {
      this.collection = newCollection.map((attributes) => {
        const foundCountry =
          this.countryStore.collection.find(({ id }) => id === attributes.countryId) || {};

        return { country: foundCountry, ...attributes };
      });
    });
  }
}

export default AddressStore;
