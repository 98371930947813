import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ABOUT_PATH } from '@constants';
import LazyLoad from 'react-lazyload';
import sal from 'sal.js';
import AboutPhoto from '@images/about.png';
import AboutBackPhoto from '@images/about-back.png';
import { ReactComponent as Bee01 } from '@images/bees/bee-01.svg';
import HexDecoration from './components/HexDecoration';

function About() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.about' });
  useEffect(() => {
    sal();
  }, []);
  return (
    <section className="bg-white overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="relative z-10 py-10 md:py-32">
            <div className="flex flex-wrap flex-row-reverse items-center -mx-4">
              <div className="w-full md:w-5/12 px-4">
                <div className="relative">
                  <span className="block text-primary text-base font-bold uppercase">
                    {t('subheader')}
                  </span>
                  <h2 className="text-2xl md:text-4xl font-bold mt-4 text-grey-50">
                    {t('header')}
                  </h2>
                  <div className="text-base text-grey-30 mt-4 xl:pr-28">
                    {t('lead')}
                    <div className="text-right mt-3">
                      <div>{t('caption')}</div>
                      <div className="font-bold">{t('names')}</div>
                    </div>
                  </div>
                  <div className="mt-8">
                    <Link
                      to={ABOUT_PATH}
                      className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-10 w-full md:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                    >
                      Zu unserer Mission
                    </Link>
                  </div>
                  <div className="absolute top-0 right-0 transform -translate-x-full -translate-y-full -mt-8 hidden md:block">
                    <Bee01 className="beeshake" />
                  </div>
                  <div className="absolute top-full right-0 transform -translate-x-full translate-y-full hidden md:block">
                    <Bee01 className="beeshake" />
                  </div>
                  <div className="absolute top-1/2 left-0 transform -translate-x-full translate-y-1/2 rotate-90 -ml-20 hidden lg:block">
                    <Bee01 className="beeshake" />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-7/12 px-4">
                <div className="px-2 mt-8 md:mt-0 flex justify-center">
                  <div className="relative inline-block mx-auto">
                    <div className="relative z-10">
                      <LazyLoad offset={600}>
                        <img
                          src={AboutPhoto}
                          alt="HN"
                          className="w-full max-w-[520px]"
                          width="520"
                          height="520"
                        />
                      </LazyLoad>
                    </div>
                    <div className="absolute top-0 left-0">
                      <img
                        src={AboutBackPhoto}
                        alt="HN Bee"
                        className="w-full max-w-[520px] rotate"
                        data-sal="rotate"
                        data-sal-delay="1500"
                        data-sal-duration="2000"
                        data-sal-easing="ease-out-back"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HexDecoration />
        </div>
      </div>
    </section>
  );
}

export default About;
