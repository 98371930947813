import UserStore from '@/stores/GlobalStores/UserStore';
import BeekeeperStore from '@/stores/GlobalStores/BeekeeperStore';
import { userEndpoint } from '@config/endpoints';
import { runInAction } from 'mobx';
import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import ProfilePhotoStore from '@/stores/GlobalStores/ProfilePhotoStore';
import ProfileAnswerStore from '@/stores/ProfileStores/ProfileAnswerStore';
import ProfileQuestionStore from '@/stores/ProfileStores/ProfileQuestionStore';
import _ from 'lodash';

class CompanyStore extends UserStore {
  constructor() {
    super();
    this.beekeeperStore = new BeekeeperStore();
    this.timelineNoteStore = new TimelineNoteStore();
    this.profilePhotoStore = new ProfilePhotoStore();
    this.profileAnswerStore = new ProfileAnswerStore();
    this.profileQuestionStore = new ProfileQuestionStore();
  }

  bindMethods() {
    this.assignUser = this.assignUser.bind(this);
    this.assignUsers = this.assignUsers.bind(this);
  }

  fetchOne(id) {
    return userEndpoint.one(id).then(this.assignUser);
  }

  cleanRecord() {
    runInAction(() => {
      this.beekeeperStore = new BeekeeperStore();
      this.timelineNoteStore = new TimelineNoteStore();
      this.profilePhotoStore = new ProfilePhotoStore();
      this.profileAnswerStore = new ProfileAnswerStore();
      this.profileQuestionStore = new ProfileQuestionStore();
      this.record = {};
    });
  }

  async assignUser(response) {
    const {
      attributes,
      relationships: { assignedBeekeepers, timelineNotes }
    } = response.data.data;

    const beekeeperIds = assignedBeekeepers.data.map(({ id }) => id);
    const timelineNoteIds = timelineNotes.data.map(({ id }) => id);

    if (_.uniq(beekeeperIds).length > 0)
      await this.beekeeperStore.fetchAll({ q: { idIn: beekeeperIds } });

    if (_.uniq(timelineNoteIds).length > 0)
      await this.timelineNoteStore.fetchAll({
        q: { idIn: timelineNoteIds, authorSlugNull: false },
        sortBy: 'published_at desc',
        page: 1,
        per: 3
      });

    await this.profilePhotoStore.fetchAll({
      q: { userIdEq: attributes.id },
      sortBy: 'created_at asc'
    });

    await this.profileQuestionStore.fetchCompanyProfileQuestions();
    await this.profileAnswerStore.fetchAll({
      q: {
        userIdEq: attributes.id,
        questionIdIn: this.profileQuestionStore.companyProfileQuestions.map(({ id }) => id)
      }
    });

    runInAction(() => {
      this.record = {
        beekeepers: this.beekeeperStore.collection,
        timelineNotes: this.timelineNoteStore.collection,
        profilePhotos: this.profilePhotoStore.collection,
        profileAnswers: this.profileAnswerStore.collection,
        profileQuestions: this.profileQuestionStore.companyProfileQuestions,
        timelineNoteIds,
        ...attributes
      };
    });
  }
}

export default CompanyStore;
