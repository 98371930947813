import { makeAutoObservable } from 'mobx';
import { supportRequestEndpoint } from '@config/endpoints';

class SupportRequestStore {
  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  create(params) {
    return supportRequestEndpoint.create(params);
  }
}

export default SupportRequestStore;
