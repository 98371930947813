import { makeAutoObservable, runInAction } from 'mobx';
import { countryEndpoint } from '@config/endpoints';

class CountryStore {
  collection = [];

  fetched = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAll(params) {
    this.fetched = false;
    return countryEndpoint.all(params).then((response) => {
      runInAction(() => {
        this.collection = response.data.data.map((record) => record.attributes);
        this.fetched = true;
      });
    });
  }
}

export default CountryStore;
