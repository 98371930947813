import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
// import ReactImageMagnify from 'react-image-magnify';
import InnerImageZoom from 'react-inner-image-zoom';
import GetYourBadge01 from '@images/case-studies-screen/get-yourbadge/01.jpg';
import GetYourBadgeZoom01 from '@images/case-studies-screen/get-yourbadge/01-zoom.jpg';
import GetYourBadge02 from '@images/case-studies-screen/get-yourbadge/02.jpg';
import GetYourBadgeZoom02 from '@images/case-studies-screen/get-yourbadge/02-zoom.jpg';
import GetYourBadge03 from '@images/case-studies-screen/get-yourbadge/03.jpg';
import GetYourBadgeZoom03 from '@images/case-studies-screen/get-yourbadge/03-zoom.jpg';
import GetYourBadge04 from '@images/case-studies-screen/get-yourbadge/04.jpg';
import GetYourBadgeZoom04 from '@images/case-studies-screen/get-yourbadge/04-zoom.jpg';
import GetYourBadge05 from '@images/case-studies-screen/get-yourbadge/05.jpg';
import GetYourBadgeZoom05 from '@images/case-studies-screen/get-yourbadge/05-zoom.jpg';
import Badge from '@images/honey-project/badge.png';
import Bees from '@images/honey-project/bees.png';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const BADGES = [
  {
    id: 0,
    image: <GetYourBadge02 />,
    imageZoom: <GetYourBadgeZoom02 />,
    coordinates: {
      x: 'left-[53%]',
      y: 'top-[34%]'
    }
  },
  {
    id: 1,
    image: <GetYourBadge03 />,
    imageZoom: <GetYourBadgeZoom03 />,
    coordinates: {
      x: 'left-[20%]',
      y: 'top-[13%]'
    }
  },
  {
    id: 2,
    image: <GetYourBadge04 />,
    imageZoom: <GetYourBadgeZoom04 />,
    coordinates: {
      x: 'left-[23%]',
      y: 'top-[54%]'
    }
  },
  {
    id: 3,
    image: <GetYourBadge05 />,
    imageZoom: <GetYourBadgeZoom05 />,
    coordinates: {
      x: 'left-[23%]',
      y: 'top-[30%]'
    }
  },
  {
    id: 4,
    image: <GetYourBadge01 />,
    imageZoom: <GetYourBadgeZoom01 />,
    coordinates: {
      x: 'left-[8%]',
      y: 'top-[50%]'
    }
  }
];

function GetYourBadge() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'caseStudiesScreen.getYourBadge'
  });

  const sliderSettings = {
    dots: false,
    arrows: false,
    speed: 800,
    swipeToSlide: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    draggable: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '7%'
        }
      }
    ]
  };

  return (
    <section className="bg-primary-light py-10 md:py-12 xl:pt-20">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="md:text-center relative">
          <span className="block text-primary text-sm md:text-base font-bold uppercase">
            {t('subheader')}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
            {t('header')}
          </h2>
          <LazyLoad height={139}>
            <img
              src={Bees}
              alt={t('subheader')}
              className="hidden xl:block absolute top-0 left-24 transform -translate-y-10"
              height="139px"
              width="148px"
            />
          </LazyLoad>
          <LazyLoad height={144}>
            <img
              src={Badge}
              alt={t('subheader')}
              className="hidden xl:block absolute top-0 right-28 transform -translate-y-10"
              height="144px"
              width="170px"
            />
          </LazyLoad>
        </div>
        <div className="mt-16 -mx-4 slider-dots-center">
          <Slider {...sliderSettings}>
            {BADGES.map((item) => {
              return (
                <div key={item.id} className="px-4">
                  <div className="aspect-h-1 aspect-w-1">
                    <div>
                      <InnerImageZoom
                        src={item.image.type}
                        zoomSrc={item.imageZoom.type}
                        hideHint
                        fullscreenOnMobile
                        className="get-your-badge-item"
                      />
                      <div
                        className={`flex h-4 w-4 absolute pointer-events-none ${item.coordinates.x} ${item.coordinates.y}`}
                      >
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
                        <span className="relative inline-flex rounded-full h-4 w-4 bg-primary" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default GetYourBadge;
