import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Headroom from 'react-headroom';
import Logo from '@images/logo2028.svg';
import { ReactComponent as MenuIcon } from '@images/icons/menu.svg';
import { ReactComponent as CloseMenuIcon } from '@images/icons/close-menu.svg';
import { useStores } from '@/stores';
import LogOutButton from '@components/Layouts/BasicLayout/Header/components/LogOutButton';
import CompanyPortalButton from '@components/Layouts/BasicLayout/Header/components/CompanyPortalButton';
import {
  ROOT_PATH,
  CASE_STUDIES_PATH,
  PROFILE_PATH,
  COMPANY_PORTAL_PATH,
  PARTNERS_PATH,
  HONEY_PROJECT_PATH,
  POSTS_PATH,
  ABOUT_PATH,
  CONTACT_PATH
} from '@constants';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReactComponent as UserIcon } from '@images/icons/user.svg';
import { ReactComponent as BeeIcon } from '@images/icons/bee.svg';
import { ReactComponent as LogOutIcon } from '@images/icons/logout.svg';
import UserAvatarPlaceholder from '@images/avatar-placeholder.png';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';
import Avatar from './components/Avatar';
import LoginButton from './components/LoginButton';
import MenuItem from './components/MenuItem';

function Header() {
  const { t } = useTranslation('components', { keyPrefix: 'layouts.basicLayout.header' });
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {
    authStore: { isAuthenticated, user, logOut }
  } = useStores();
  const { setAlert } = useAlert();

  const handleMenuClick = () => {
    setOpen(!open);
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('bodyMobileNavVisible');
  };

  const signInOrAvatar = () => {
    if (isAuthenticated)
      return (
        <div
          role="presentation"
          className="relative outline-none focus:outline-none cursor-pointer"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <Avatar>
            {user.avatarFileName ? (
              <img src={user.avatar} alt={user.name} className="rounded-full" />
            ) : (
              <img src={UserAvatarPlaceholder} alt={user.name} className="rounded-full" />
            )}
          </Avatar>
          <div
            className={`absolute z-40 top-full mt-2 right-0 bg-white shadow-ds16 rounded min-w-[280px] ${
              dropdownOpen ? 'block' : 'hidden'
            }`}
          >
            <div className="p-2">
              <Link
                to={PROFILE_PATH}
                className="py-2 px-2 rounded flex items-center text-grey-30 hover:bg-grey-5"
              >
                <UserIcon width="16px" height="16" className="flex-shrink-0 fill-current mr-4" />
                <span className="text-base text-grey-30">Profile</span>
              </Link>
              <Link
                to={COMPANY_PORTAL_PATH}
                className="py-2 px-2 rounded flex items-center text-grey-30 hover:bg-grey-5"
              >
                <BeeIcon width="16px" height="16" className="flex-shrink-0 fill-current mr-4" />
                <span className="text-base text-grey-30">Content-Portal</span>
              </Link>
              <hr className="my-2 h-1px w-full bg-grey-20" />
              <button
                type="button"
                onClick={() => {
                  logOut();
                  setAlert({ open: true, message: t('loggedOut') });
                }}
                className="py-2 px-2 rounded flex items-center text-grey-30 hover:bg-grey-5 w-full"
              >
                <LogOutIcon width="16px" height="16" className="flex-shrink-0 fill-current mr-4" />
                <span className="text-base text-grey-30">Abmelden</span>
              </button>
            </div>
          </div>
        </div>
      );

    return <LoginButton />;
  };

  return (
    <>
      <div
        role="presentation"
        className={`fixed z-40 inset-0 bg-grey-50 bg-opacity-60 transition-opacity duration-300 lg:invisible lg:opacity-0
        ${open ? 'visible opacity-100' : 'invisible opacity-0'}`}
        onClick={handleMenuClick}
      />
      <header>
        <Headroom disableInlineStyles>
          <div className="bg-primary">
            <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
              <div className="flex justify-between items-center py-3">
                <Link to={ROOT_PATH} className="flex items-center">
                  <img src={Logo} alt={t('logo')} />
                  <span className="text-white text-xs inline-block ml-4">
                    <Trans i18nKey="layout:header.title">
                      eine Initiative von <span className="block lg:inline">Hektar Nektar</span>
                    </Trans>
                  </span>
                </Link>
                <div className="hidden lg:flex items-center">{signInOrAvatar()}</div>
                <button type="button" className="lg:hidden" onClick={handleMenuClick}>
                  <MenuIcon width="40px" height="40px" />
                </button>
                <button
                  type="button"
                  className={`fixed z-50 top-4 right-5 transition-opacity duration-300 lg:invisible lg:opacity-0
                  ${open ? 'visible opacity-100' : 'invisible opacity-0'} `}
                  onClick={handleMenuClick}
                >
                  <CloseMenuIcon width="32px" height="32px" />
                </button>
              </div>
            </div>
          </div>
        </Headroom>
        <div
          className={`bg-white shadow-ds8 fixed z-[200] lg:z-auto lg:relative top-0 lg:top-auto left-0 lg:left-auto min-h-screen lg:min-h-0 w-[260px] lg:w-auto transition-transform duration-500 transform lg:transform-none
            ${open ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <div className="flex flex-col lg:flex-row justify-between items-center py-2 min-h-screen lg:min-h-0 lg:h-14">
              <div className="w-full lg:w-auto">
                <div className="flex items-center justify-center pt-4 pb-8 lg:hidden">
                  <Link to={ROOT_PATH}>
                    <img src={Logo} alt={t('logo')} height="54px" width="79px" />
                  </Link>
                  <span className="text-grey-30 text-xs inline-block ml-4">
                    <Trans i18nKey="layout:header.title">
                      eine Initiative von <span className="block lg:inline">Hektar Nektar</span>
                    </Trans>
                  </span>
                </div>
                <ul className="flex flex-wrap flex-col lg:flex-row lg:items-center lg:space-x-6 xl:space-x-12">
                  <MenuItem
                    path={ROOT_PATH}
                    label={t('nav.company')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={HONEY_PROJECT_PATH}
                    label={t('nav.honeyProject')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={CASE_STUDIES_PATH}
                    label={t('nav.caseStudies')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={PARTNERS_PATH}
                    label={t('nav.partners')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={POSTS_PATH}
                    label={t('nav.blog')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={ABOUT_PATH}
                    label={t('nav.about')}
                    onClick={() => setOpen(false)}
                  />
                  <MenuItem
                    path={CONTACT_PATH}
                    label={t('nav.contact')}
                    onClick={() => setOpen(false)}
                  />
                </ul>
              </div>
              <div className="pb-20 lg:pb-0 w-full lg:w-auto">
                {isAuthenticated && (
                  <div className="my-8 flex items-center lg:hidden">
                    <Link
                      to={PROFILE_PATH}
                      className="flex-shrink-0"
                      onClick={() => setOpen(false)}
                    >
                      <Avatar>
                        <img src={user.avatar} alt={user.name} className="rounded-full" />
                      </Avatar>
                    </Link>
                    <Link
                      to={PROFILE_PATH}
                      className="leading-snug text-sm flex flex-col pl-3"
                      onClick={() => setOpen(false)}
                    >
                      <span className="text-grey-30">{user.name}</span>
                      <span className="text-grey-20">{t('profile')}</span>
                    </Link>
                  </div>
                )}
                {isAuthenticated && <CompanyPortalButton />}
                <div className="mt-4 lg:hidden">
                  {isAuthenticated ? <LogOutButton /> : <LoginButton />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default observer(Header);
