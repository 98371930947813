import { Link } from 'react-router-dom';
import { AUTH_PATH } from '@constants';
import { useTranslation } from 'react-i18next';

function LoginButton() {
  const { t } = useTranslation('components', {
    keyPrefix: 'layouts.basicLayout.header.components.loginButton'
  });

  return (
    <Link
      to={AUTH_PATH}
      className="inline-flex items-center justify-center relative bg-green hover:bg-green-80 text-white border-0 m-0 py-2 px-16 w-full lg:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
    >
      {t('login')}
    </Link>
  );
}

export default LoginButton;
