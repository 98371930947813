import api from '@api';

export const authEndpoint = {
  signIn: (signInParams) => api.post('auth/sign_in', signInParams),
  validateToken: () => api.get('auth/validate_token'),
  resetPassword: (params) => api.post('auth/password', params),
  confirmPassword: (headers, params) => api.put('auth/password', params, { headers }),
  update: (params) => api.patch('auth', params)
};

export const attachmentEndpoint = {
  all: (params) => api.get(`attachments`, { params })
};

export const beekeeperEndpoint = {
  downloadAllBeekeeperPhotos: (id) =>
    api.get(`beekeepers/${id}/download_all_photos`, { responseType: 'blob' })
};

export const userEndpoint = {
  all: (params) => api.get(`users`, { params }),
  one: (slug) => api.get(`users/${slug}`)
};

export const adminUserEndpoint = {
  all: (params) => api.get(`admin_users`, { params })
};

export const companyBeekeeperEndpoint = {
  all: (params) => api.get(`company_beekeepers`, { params }),
  one: (id) => api.get(`company_beekeepers/${id}`)
};

export const companyApplicationEndpoint = {
  create: (params) => api.post('company_applications', params)
};

export const companyFileEndpoint = {
  all: (params) => api.get(`company_files`, { params }),
  download: (id) => api.get(`company_files/${id}/download_file`, { responseType: 'blob' }),
  downloadFiles: (id) => api.get(`company_files/${id}/download_files`, { responseType: 'blob' })
};

export const companyArticleEndpoint = {
  one: (id) => api.get(`company_articles/${id}`)
};

export const companyFileCategoryEndpoint = {
  all: (params) => api.get(`company_file_categories`, { params })
};

export const eventEndpoint = {
  all: (params) => api.get(`events`, { params })
};

export const companyProfileLogoEndpoint = {
  all: (params) => api.get(`company_profile_logos`, { params })
};

export const pressReleaseEndpoint = {
  all: (params) => api.get(`press_releases`, { params }),
  one: (id) => api.get(`press_releases/${id}`)
};

export const postsEndpoint = {
  all: (params) => api.get(`posts`, { params }),
  one: (id) => api.get(`posts/${id}`)
};

export const companyActivityFeedEndpoint = {
  all: (params) => api.get(`company_activity_feeds`, { params })
};

export const timelineNoteEndpoint = {
  all: (params) => api.get(`timeline_notes`, { params }),
  one: (id) => api.get(`timeline_notes/${id}`)
};

export const addressEndpoint = {
  all: (params) => api.get('addresses', { params }),
  get: (id) => api.get(`addresses/${id}`),
  create: (params) => api.post('addresses', params),
  update: (id, params) => api.patch(`addresses/${id}`, params)
};

export const profileQuestionEndpoint = {
  all: (params) => api.get(`profile_questions`, { params })
};

export const profileAnswerEndpoint = {
  all: (params) => api.get(`profile_answers`, { params }),
  create: (params) => api.post('profile_answers', params),
  update: (id, params) => api.patch(`profile_answers/${id}`, params)
};

export const profilePhotoEndpoint = {
  all: (params) => api.get(`profile_photos`, { params }),
  create: (params) => api.post('profile_photos', params),
  delete: (id) => api.delete(`profile_photos/${id}`)
};

export const countryEndpoint = {
  all: (params) => api.get('/countries', { params })
};

export const regionEndpoint = {
  all: () => api.get('/regions')
};

export const caseStudyEndpoint = {
  all: () => api.get('/case_studies'),
  one: (id) => api.get(`case_studies/${id}`)
};

export const caseStudyCategoryEndpoint = {
  all: (params) => api.get('/case_study_categories', { params })
};

export const caseStudySectionEndpoint = {
  all: (params) => api.get('/case_study_sections', { params })
};

export const partnersPageStatEndpoint = {
  stats: () => api.get('/partners_page_stats/summary_adopted_bees')
};

export const supportRequestEndpoint = {
  create: (params) => api.post('support_requests', params)
};

export const newsletterEndpoint = {
  addUser: (params) => api.post('newsletter/add_user', params)
};

export default {};
