import { useState } from 'react';
import PropTypes from 'prop-types';
import GLightbox from 'glightbox';
import Slider from '@components/UIElements/Slider';
import 'glightbox/dist/css/glightbox.min.css';

function HeroWithSlider({ backgroundColor, photos, children }) {
  const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(null);

  const handleOnMouseDown = (e) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const lightboxPhotos = () => {
    return [
      ...photos[0].map((photo) => ({
        photo
      }))
    ];
  };

  const lightBoxImages = () =>
    lightboxPhotos().map((image) => ({ href: image.photo.url, type: 'image' }));

  const handleOpenLightbox = (e, i) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      const offerGallery = GLightbox({
        startAt: i,
        elements: lightBoxImages(),
        touchNavigation: true,
        dragAutoSnap: true,
        draggable: false,
        loop: true,
        skin: 'clean hn'
      });
      offerGallery.open();
    }
  };

  return (
    <section className={`${backgroundColor} overflow-hidden pt-8 lg:pt-0 lg:mb-8 relative`}>
      <div className="bg-white w-full h-10 absolute bottom-0 left-0 z-10" />
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="flex flex-wrap items-center -mx-3">
            <div className="w-full lg:w-1/3 px-3">{children}</div>
            <div className="w-full lg:w-2/3 px-3">
              <div className="relative z-10 pb-10 -mx-4 lg:mx-0 mt-6 lg:mt-0 no-arrows-on-mobile">
                <div className="aspect-w-1 aspect-h-1 md:aspect-w-16 md:aspect-h-10">
                  <div className="h-full transform 2xl:translate-x-16">
                    <Slider>
                      {photos[0].map((photo, index) => {
                        return (
                          <div
                            key={photo}
                            onMouseDown={handleOnMouseDown}
                            onClick={(e) => handleOpenLightbox(e, index)}
                            role="presentation"
                            className="cursor-pointer"
                          >
                            <div className="aspect-w-1 aspect-h-1 md:aspect-w-16 md:aspect-h-10">
                              <img
                                src={photo.url}
                                alt={`Foto ${index}`}
                                className="absolute inset-0 w-full h-full object-cover"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroWithSlider;

HeroWithSlider.propTypes = {
  backgroundColor: PropTypes.string,
  photos: PropTypes.arrayOf(PropTypes.array),
  children: PropTypes.node
};

HeroWithSlider.defaultProps = {
  backgroundColor: '',
  photos: [],
  children: null
};
