import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ReactTooltip from 'react-tooltip';
import PackingPhoto from '@images/honey-project/packing/packing-02.jpg';
import { ReactComponent as ListIcon01 } from '@images/icons/honey-project/packing/05.svg';
import { ReactComponent as ListIcon02 } from '@images/icons/honey-project/packing/06.svg';
import { ReactComponent as ListIcon03 } from '@images/icons/honey-project/packing/07.svg';
import 'slick-carousel/slick/slick.css';

function Packing02() {
  const { t } = useTranslation('screens', { keyPrefix: 'honeyProjectScreen.components.packing02' });

  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex md:flex-row-reverse flex-wrap items-center -mx-3">
          <div className="w-full md:w-1/2 px-3">
            <div className="lg:pl-20 md:py-4">
              <span className="block text-primary text-sm md:text-base font-bold uppercase">
                {t('subheader')}
              </span>
              <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                {t('header')}
              </h2>
              <div className="text-sm md:text-base text-grey-30 mt-4">
                <ul className="lg:pr-24 mb-8 md:mb-0">
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon01 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">{t('list.item01')}</div>
                  </li>
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon02 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">{t('list.item02')}</div>
                  </li>
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon03 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">{t('list.item03')}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <div className="relative">
              <LazyLoad>
                <img src={PackingPhoto} alt={t('header')} />
              </LazyLoad>
              <ReactTooltip
                effect="solid"
                backgroundColor="#ffffff"
                textColor="#6D727F"
                arrowColor="transparent"
                place="right"
                html
                padding="8px 8px"
                className="shadow-ds12 text-base leading-normal"
              />
              <div className="flex h-4 w-4 absolute top-[33%] left-[68.5%]" data-tip={t('tooltip')}>
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
                <span className="relative inline-flex rounded-full h-4 w-4 bg-primary" />
              </div>
              <div className="flex h-4 w-4 absolute top-[53%] left-[29%]" data-tip={t('tooltip')}>
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
                <span className="relative inline-flex rounded-full h-4 w-4 bg-primary" />
              </div>
              <div className="flex h-4 w-4 absolute top-[66%] left-[68.5%]" data-tip={t('tooltip')}>
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
                <span className="relative inline-flex rounded-full h-4 w-4 bg-primary" />
              </div>
            </div>
            <div className="flex -mx-3">
              <div className="w-1/2 px-3">
                <div className="lg:pl-16 mt-6">
                  <div className="text-sm md:text-base text-grey-30 font-bold">
                    {t('photoLegend.heads.01')}
                  </div>
                  <div className="text-sm md:text-base text-grey-30">
                    {t('photoLegend.subheads.01')}
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-3">
                <div className="lg:pl-16 mt-6">
                  <div className="text-sm md:text-base text-grey-30 font-bold">
                    {t('photoLegend.heads.02')}
                  </div>
                  <div className="text-sm md:text-base text-grey-30">
                    {t('photoLegend.subheads.02')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Packing02;
