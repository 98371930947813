import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import HeroWithSlider from '@components/UIElements/HeroWithSlider';
import UserHexInfo from '@components/UIElements/UserHexInfo';
import TimelineNote from '@components/UIElements/TimelineNote';
import { socialLinks } from '@config/directories';
import SocialLink from '@components/UIElements/SocialLink';
import SectionHead from '@components/UIElements/SectionHead';
import HeroPhoto from '@images/partners-screen/hero.jpg';
import { ReactComponent as AvatarMask } from '@images/avatar-mask-72.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as QuoteIcon } from '@images/icons/quote.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { generatePath, useParams } from 'react-router-dom';
import { useStores } from '@/stores';
import { parseBool } from '@tools';
import { observer } from 'mobx-react';
import { BEEKEEPER_PROFILE_PATH, PUBLIC_TIMELINE_NOTE_PATH } from '@constants';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import DefaultProfile from '@images/placeholders/default-profile.png';

function CompanyProfileScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'companyProfileScreen' });
  const [companyFetching, setCompanyFetching] = useState(true);

  const { companySlug } = useParams();
  const {
    companyStore,
    companyStore: { timelineNoteStore }
  } = useStores();
  const user = companyStore.record;

  useEffect(() => {
    companyStore.cleanRecord();
    companyStore.fetchOne(companySlug).then(async () => setCompanyFetching(false));
  }, []);

  function loadMoreTimelineNotes() {
    timelineNoteStore.fetchAll(
      {
        q: { idIn: companyStore.record.timelineNoteIds, authorSlugNull: false },
        sortBy: 'published_at desc',
        per: 3,
        page: timelineNoteStore.meta.nextPage
      },
      true
    );
  }

  const loadMoreButton = () => {
    if (!parseBool(timelineNoteStore.meta.nextPage)) return null;

    return (
      <button
        className="outline-none focus:outline-none inline-flex items-center justify-center relative bg-white hover:bg-primary-120 text-primary hover:text-white border border-primary hover:border-primary-120 m-0 py-2 px-10 w-full md:w-auto rounded box-border cursor-pointer transition-colors ease-in-out duration-300"
        onClick={() => loadMoreTimelineNotes()}
        type="button"
      >
        {t('loadMoreTimelineNotes')}
      </button>
    );
  };

  const answerQuestionWrapper = (question) => {
    const answer = companyStore.profileAnswerStore.collection.find(
      ({ profileQuestionId }) => profileQuestionId === question.id
    );

    return (
      <div key={question.id} className="mb-4">
        <h3 className="terxt-lg md:text-2xl text-grey-50 font-bold mb-2">{question?.text}</h3>
        <div className="text-grey-30 text-sm md:text-base text-entry">{parse(answer?.text)}</div>
      </div>
    );
  };

  const allPhotos = () => {
    return [
      user.profilePhotos?.map((photo) => ({
        url: photo.originalUrl,
        caption: photo.originalUrl
      }))
    ];
  };

  const setHexInfoColor = (index) => {
    if (index % 3 === 1) return 'text-orange-40';
    if (index % 3 === 2) return 'text-green-40';
    return 'text-primary-40';
  };

  if (companyFetching)
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );

  return (
    <>
      <TitleAndDescription
        screenName="companyProfileScreen"
        attributes={{ companyName: user.companyName }}
      />
      <HeroWithSlider
        backgroundColor="bg-primary-light"
        photo={HeroPhoto}
        alt="PROJEKT 2028-Unternehmen"
        photos={allPhotos()}
      >
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 lg:w-full px-3">
            <div className="h-24 w-full relative flex mb-6 mt-4">
              <LazyLoad height={96}>
                <img src={user.logo} alt={user.companyName} className="object-contain h-full" />
              </LazyLoad>
            </div>
            <h1 className="text-xl xl:text-3xl text-grey-50 font-bold leading-tight">
              {t('greetings')}
              {user.companyName}
            </h1>
            <div className="text-grey-30 text-base md:text-xl mt-4">
              {t('supportedBeekeepersHeader')}
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-full px-3">
            <a href="#beekeepersList" className="flex items-center pl-4 mt-4">
              {user.beekeepers.slice(0, 3).map((beekeeper) => {
                return (
                  <div
                    key={beekeeper.name}
                    className="w-[40px] h-[40px] rounded-full border border-grey-30 lg:border-white relative -ml-4"
                  >
                    <img
                      src={
                        beekeeper.avatar.startsWith('/assets/beekeepers/default-profile')
                          ? DefaultProfile
                          : beekeeper.avatar
                      }
                      alt={beekeeper.name}
                      className="rounded-full object-cover w-[40px] h-[40px]"
                    />
                  </div>
                );
              })}
              {user.beekeepers.length > 3 && (
                <div className="text-grey-25 text-base font-bold flex-shrink-0 ml-1">
                  {`+${user.beekeepers.length - 3} mehr`}
                </div>
              )}
            </a>
            <ul className="flex flex-wrap transform -translate-x-2 mt-10">
              {socialLinks.map((element) => (
                <SocialLink
                  key={element.name}
                  beekeeper={user}
                  iconElement={element}
                  width="24"
                  spaceX="px-3"
                  spaceY="py-3"
                  classes="mr-3"
                />
              ))}
            </ul>
          </div>
        </div>
      </HeroWithSlider>
      <section className="py-10 md:py-14">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-1/3 px-3">
              <div className="md:sticky md:top-8">
                <div className="mb-4">
                  <div className="flex items-center pl-4 pt-4">
                    <div className="relative flex-shrink-0 mr-4 text-primary-40">
                      <AvatarMaskBack
                        width="118px"
                        height="100px"
                        className="fill-current absolute -top-1 -left-3"
                      />
                      <LazyLoad height={120}>
                        <img
                          src={user.avatar}
                          alt={user.name}
                          className="rounded-xl object-cover avatar-mask-medium"
                          width="120px"
                          height="120px"
                        />
                      </LazyLoad>
                    </div>
                    <div className="transform -translate-y-2">
                      <h3 className="text-grey-50 text-xs md:text-sm font-bold">{user.name}</h3>
                      <div className="text-grey-50 text-xs md:text-sm">{user.roleInCompany}</div>
                    </div>
                  </div>
                  <AvatarMask width="0" height="0" />
                </div>
                <div className="flex xl:pr-20">
                  <div className="mr-6 flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-primary-20 flex justify-center items-center">
                      <QuoteIcon width="18" height="18" className="text-primary fill-current" />
                    </div>
                  </div>
                  <div className="text-grey-30 text-sm md:text-base text-entry">
                    {parse(user.companyMotto)}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/3 px-3">
              <div className="xl:pl-16 mt-12 md:mt-0">
                {companyStore.profileQuestionStore.companyProfileQuestions.map(
                  answerQuestionWrapper
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {companyStore.record.beekeepers.length > 0 && (
        <section id="beekeepersList" className="py-10 md:py-14">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <SectionHead header={t('beekeepersHeader')} subheader={t('beekeepersSubheader')} />
            <div className="mt-10">
              <div className="flex flex-wrap -mx-3">
                {companyStore.record.beekeepers.map((beekeeper, index) => (
                  <div key={beekeeper.id} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-3">
                    <UserHexInfo
                      url={generatePath(BEEKEEPER_PROFILE_PATH, {
                        beekeeperSlug: beekeeper.slug
                      })}
                      avatar={
                        beekeeper.avatar.startsWith('/assets/beekeepers/default-profile')
                          ? DefaultProfile
                          : beekeeper.avatar
                      }
                      user={beekeeper.name}
                      address={`${beekeeper.livingAddress.city}, ${beekeeper.livingAddress.country.code}`}
                      backgroundColor={setHexInfoColor(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
      {timelineNoteStore.collection.length > 0 && (
        <section className="py-14 bg-primary-light">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <SectionHead
              header={t('timelineNotesHeader')}
              subheader={t('timelineNotesSubheader')}
            />
            <div className="mt-10">
              <div className="flex flex-wrap -mx-3">
                {timelineNoteStore.collection
                  .filter(({ author }) => parseBool(author.slug))
                  .map((timelineNote) => (
                    <TimelineNote
                      key={timelineNote.id}
                      path={generatePath(PUBLIC_TIMELINE_NOTE_PATH, {
                        beekeeperSlug: timelineNote.author.slug || 'slug',
                        timelineNoteSlug: timelineNote.slug,
                        companySlug: user.slug
                      })}
                      timelineNote={timelineNote}
                    />
                  ))}
              </div>
            </div>
            <div className="flex justify-center mt-10">{loadMoreButton()}</div>
          </div>
        </section>
      )}
    </>
  );
}

export default observer(CompanyProfileScreen);
