import FacebookIcon from '@images/icons/social/facebook.svg';
import InstagramIcon from '@images/icons/social/instagram.svg';
import LinkedInIcon from '@images/icons/social/linkedIn.svg';
import TwitterIcon from '@images/icons/social/twitter.svg';
import YouTubeIcon from '@images/icons/social/youtube.svg';
import XingIcon from '@images/icons/social/xing.svg';
import BriefcaseIcon from '@images/icons/social/briefcase.svg';
import KununuIcon from '@images/icons/social/kununu.svg';
import TagIcon from '@images/icons/social/tag.svg';
import WebsiteIcon from '@images/icons/social/website.svg';
import { ReactComponent as JPGIcon } from '@images/icons/files-type/jpg.svg';
import { ReactComponent as JPGColorIcon } from '@images/icons/files-type-color/jpg.svg';
import { ReactComponent as PNGIcon } from '@images/icons/files-type/png.svg';
import { ReactComponent as PNGColorIcon } from '@images/icons/files-type-color/png.svg';
import { ReactComponent as TIFFIcon } from '@images/icons/files-type/tiff.svg';
import { ReactComponent as TIFFColorIcon } from '@images/icons/files-type-color/tiff.svg';
import { ReactComponent as EPSIcon } from '@images/icons/files-type/eps.svg';
import { ReactComponent as EPSColorIcon } from '@images/icons/files-type-color/eps.svg';
import { ReactComponent as PDFIcon } from '@images/icons/files-type/pdf.svg';
import { ReactComponent as PDFColorIcon } from '@images/icons/files-type-color/pdf.svg';
import { ReactComponent as XLSXIcon } from '@images/icons/files-type/xlsx.svg';
import { ReactComponent as XLSXColorIcon } from '@images/icons/files-type-color/xlsx.svg';
import { ReactComponent as PPTIcon } from '@images/icons/files-type/ppt.svg';
import { ReactComponent as PPTColorIcon } from '@images/icons/files-type-color/ppt.svg';
import { ReactComponent as AIIcon } from '@images/icons/files-type/ai.svg';
import { ReactComponent as AIColorIcon } from '@images/icons/files-type-color/ai.svg';
import { ReactComponent as DOCXIcon } from '@images/icons/files-type/docx.svg';
import { ReactComponent as DOCXColorIcon } from '@images/icons/files-type-color/docx.svg';
import { ReactComponent as MOVIcon } from '@images/icons/files-type/mov.svg';
import { ReactComponent as MOVColorIcon } from '@images/icons/files-type-color/mov.svg';
import { ReactComponent as MP4Icon } from '@images/icons/files-type/mp4.svg';
import { ReactComponent as MP4ColorIcon } from '@images/icons/files-type-color/mp4.svg';
import { ReactComponent as EmptyIcon } from '@images/icons/files-type/empty.svg';
import { ReactComponent as EmptyColorIcon } from '@images/icons/files-type-color/empty.svg';

export const socialLinks = [
  { name: 'facebookUrl', element: FacebookIcon },
  { name: 'instagramUrl', element: InstagramIcon },
  { name: 'linkedinUrl', element: LinkedInIcon },
  { name: 'twitterUrl', element: TwitterIcon },
  { name: 'youtubeUrl', element: YouTubeIcon },
  { name: 'xingUrl', element: XingIcon },
  { name: 'websiteUrl', element: WebsiteIcon },
  { name: 'careerUrl', element: BriefcaseIcon },
  { name: 'kununuUrl', element: KununuIcon },
  { name: 'productsUrl', element: TagIcon }
];

export const filesExtension = {
  'image/jpeg': {
    small: (
      <JPGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <JPGColorIcon width="114" height="114" />
  },
  'image/jpg': {
    small: (
      <JPGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <JPGColorIcon width="114" height="114" />
  },
  'image/png': {
    small: (
      <PNGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <PNGColorIcon width="114" height="114" />
  },
  'image/tiff': {
    small: (
      <TIFFIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <TIFFColorIcon width="114" height="114" />
  },
  'image/x-eps': {
    small: (
      <EPSIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <EPSColorIcon width="114" height="114" />
  },
  'application/postscript': {
    small: (
      <EPSIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <EPSColorIcon width="114" height="114" />
  },
  'application/pdf': {
    small: (
      <PDFIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <PDFColorIcon width="114" height="114" />
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    small: (
      <XLSXIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <XLSXColorIcon width="114" height="114" />
  },
  'application/vnd.ms-powerpoint': {
    small: (
      <PPTIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <PPTColorIcon width="114" height="114" />
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    small: (
      <PPTIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <PPTColorIcon width="114" height="114" />
  },
  'image/ai': {
    small: (
      <AIIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <AIColorIcon width="114" height="114" />
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    small: (
      <DOCXIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <DOCXColorIcon width="114" height="114" />
  },
  'application/zip': {
    small: (
      <DOCXIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <DOCXColorIcon width="114" height="114" />
  },
  'video/quicktime': {
    small: (
      <MOVIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <MOVColorIcon width="114" height="114" />
  },
  'video/mp4': {
    small: (
      <MP4Icon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <MP4ColorIcon width="114" height="114" />
  },
  null: {
    small: (
      <EmptyIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
    ),
    color: <EmptyColorIcon width="114" height="114" />
  }
};

export default {};
