import { EMAIL_REGEXP, CONFIRM_PASSWORD_PATH } from '@constants';
import { addServerErrors } from '@helpers/errors';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function RemindForm() {
  const { authStore } = useStores();
  const { t } = useTranslation('screens', {
    keyPrefix: 'remindPasswordScreen.components.remindForm'
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { errors, isValid }
  } = useForm({ mode: 'onChange' });

  const watchAllFields = watch();
  const { setAlert } = useAlert();

  useEffect(() => {
    if (errors.user && errors.user.message.length > 0 && isValid) clearErrors('user');
  }, [watchAllFields]);

  function submitForm(data) {
    setIsSubmitting(true);
    authStore
      .resetPassword({
        ...data,
        redirectUrl: window.location.origin + generatePath(CONFIRM_PASSWORD_PATH)
      })
      .then(() => {
        setAlert({ open: true, message: t('submitAlert') });
        setIsSubmitting(false);
      })
      .catch((error) => {
        addServerErrors({ errors: { user: error.response.data.errors } }, setError);
        setIsSubmitting(false);
      });
  }

  const handleRecaptcha = () => {
    setSubmitDisabled(false);
  };

  const handleCaptchaExpire = () => {
    setSubmitDisabled(true);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="mb-4">
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: EMAIL_REGEXP,
              message: 'email'
            }
          }}
          render={({ field, ref }) => (
            <Input
              {...field}
              type="text"
              placeholder=""
              error={errorLabel('email', 'user', errors.email)}
              label={translateAttribute('email', 'user')}
              ref={ref}
              markAsRequired
            />
          )}
        />
      </div>
      <div className="text-red text-sm">{errorLabel('user', 'user', errors.user)}</div>
      <div className="mt-4">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={handleRecaptcha}
          onExpired={handleCaptchaExpire}
          hl="de"
        />
      </div>

      <div className="mt-8">
        {isSubmitting ? (
          <div className="h-10 flex items-center justify-center">
            <LoaderIcon
              width="32"
              height="32"
              className="animate-spin text-primary-60 fill-current"
            />
          </div>
        ) : (
          <button
            type="submit"
            className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-2 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
            disabled={submitDisabled}
          >
            {t('submit')}
          </button>
        )}
      </div>
    </form>
  );
}

export default RemindForm;
