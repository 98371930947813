import { useTranslation } from 'react-i18next';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

function TermsOfUseProject2028PortalScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'termsOfUseProject2028PortalScreen' });
  return (
    <>
      <TitleAndDescription screenName="termsOfUseProject2028PortalScreen" />
      <section>
        <div className="bg-primary-light py-14">
          <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
            <h1 className="text-2xl md:text-4xl text-grey-50 font-bold">{t('title')}</h1>
          </div>
        </div>
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="py-14 text-grey-50 text-sm md:text-base max-w-5xl text-entry">
            <p>{t('content.paragraph0')}</p>
            <p>{t('content.paragraph1')}</p>
            <p>{t('content.paragraph2')}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsOfUseProject2028PortalScreen;
