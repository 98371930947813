import React from 'react';
import { Navigate, useParams, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _ from 'lodash';

function RedirectRoute({ path, attributesToPass }) {
  const params = useParams();
  const pickedParams = _.pickBy(params, (_value, key) => attributesToPass.includes(key));

  return <Navigate to={generatePath(path, pickedParams)} replace />;
}

RedirectRoute.propTypes = {
  path: PropTypes.string.isRequired,
  attributesToPass: PropTypes.arrayOf(PropTypes.string)
};

RedirectRoute.defaultProps = {
  attributesToPass: []
};

export default observer(RedirectRoute);
