import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import MediaPartnerLogo01 from '@images/media-partners/01.png';
import MediaPartnerLogo02 from '@images/media-partners/02.png';
import MediaPartnerLogo03 from '@images/media-partners/03.png';
import MediaPartnerLogo04 from '@images/media-partners/04.png';
import MediaPartnerLogo05 from '@images/media-partners/05.png';

const mediaPartners = [
  { image: MediaPartnerLogo01, name: 'Food & Farm' },
  { image: MediaPartnerLogo02, name: 'Lust auf Garten' },
  { image: MediaPartnerLogo03, name: 'Kraut & Ruben' },
  { image: MediaPartnerLogo04, name: 'Grun' },
  { image: MediaPartnerLogo05, name: 'Garten Flora' }
];

function MediaPartners() {
  const { t } = useTranslation('components', { keyPrefix: 'uiElements.mediaPartners' });
  return (
    <section>
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <h3 className="text-primary text-base font-bold uppercase">{t('header')}</h3>
        <div className="my-12">
          <div className="flex space-x-4 md:space-x-24 overflow-x-auto no-scrollbar">
            {mediaPartners.map((item) => {
              return (
                <div
                  key={item.name}
                  className="flex-grow min-w-[50vw] md:min-w-0 flex flex-col lg:flex-row items-center justify-center lg:justify-start text-primary"
                >
                  <div>
                    <LazyLoad height={50}>
                      <img src={item.image} alt={item.name} className="grayscale" />
                    </LazyLoad>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MediaPartners;
