import { makeAutoObservable, runInAction } from 'mobx';
import { regionEndpoint } from '@config/endpoints';

class RegionStore {
  collection = [];

  fetched = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAll() {
    this.fetched = false;
    return regionEndpoint.all().then((response) => {
      runInAction(() => {
        this.collection = response.data.data.map((record) => record.attributes);
        this.fetched = true;
      });
    });
  }

  findById(id) {
    return this.collection.find((country) => country.id === id) || {};
  }
}

export default RegionStore;
