import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

function ImpactBox({ head, lead, icon }) {
  return (
    <div className="px-2 sm:px-5 py-5 h-full min-h-[355px]">
      <div className="h-full bg-white rounded-4xl p-4 sm:p-6 shadow-ds12 min-h-[355px]">
        <LazyLoad height={80}>
          <img src={icon} alt={head} width="80" height="80" />
        </LazyLoad>
        <h3 className="text-base text-grey-50 font-bold mt-6 mb-2">{head}</h3>
        <p className="text-sm md:text-base text-grey-30">{lead}</p>
      </div>
    </div>
  );
}

export default ImpactBox;

ImpactBox.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  icon: PropTypes.string
};

ImpactBox.defaultProps = {
  head: '',
  lead: '',
  icon: ''
};
