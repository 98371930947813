import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import CompanyStore from '@/stores/GlobalStores/CompanyStore';
import { createContext, useContext } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

export class TimelineNoteScreenStore {
  constructor() {
    this.timelineNoteStore = new TimelineNoteStore();
    this.companyStore = new CompanyStore();
    this.similarTimelineNotes = [];
    this.bindMethods();

    makeAutoObservable(this);
  }

  bindMethods() {
    this.fetchTimelineNote = this.fetchTimelineNote.bind(this);
    this.fetchSimilarTimelineNotes = this.fetchSimilarTimelineNotes.bind(this);
  }

  fetchTimelineNote(slug) {
    return this.timelineNoteStore.fetchOne(slug);
  }

  fetchSimilarTimelineNotes(companyId) {
    return this.timelineNoteStore.fetchAll({ q: { userIdEq: companyId } }).then(() => {
      runInAction(() => {
        this.similarTimelineNotes = this.timelineNoteStore.collection;
      });
    });
  }
}

export const StoresContext = createContext(new TimelineNoteScreenStore());
export const useTimelineNoteStores = () => useContext(StoresContext);
