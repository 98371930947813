import { errorLabel, translateAttribute } from '@helpers/translations';
import { Controller, useForm } from 'react-hook-form';
import { Input, Textarea } from 'hn-ui';
import { EMAIL_REGEXP, CONTACT_FORM_DONE_PATH } from '@constants';
import { addServerErrors } from '@helpers/errors';
import { useStores } from '@/stores';
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ContactEmail from '@images/contact/email.png';
import { ReactComponent as MailIcon } from '@images/icons/mail.svg';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function ContactForm() {
  const { supportRequestStore } = useStores();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('screens', { keyPrefix: 'contactScreen.components.contactForm' });
  const keyPrefix = 'screens:contactScreen.components.contactForm';
  const { setAlert } = useAlert();
  const defaultValues = {
    subject: '',
    email: '',
    message: ''
  };
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange', defaultValues });

  function submitForm(data) {
    setIsSubmitting(true);
    supportRequestStore
      .create({ supportRequest: { ...data, kind: 'project2028' } })
      .then(() => {
        reset();
        setAlert({ open: true, message: t('formSaved') });
        navigate(CONTACT_FORM_DONE_PATH);
        setIsSubmitting(false);
      })
      .catch((error) => {
        addServerErrors({ errors: error.response.data }, setError, 'supportRequest');
        setIsSubmitting(false);
      });
  }

  const handleRecaptcha = () => {
    setSubmitDisabled(false);
  };

  const handleCaptchaExpire = () => {
    setSubmitDisabled(true);
  };

  return (
    <section className="bg-primary-20 pt-10 md:pt-16">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <div className="md:mt-12 flex flex-wrap -mx-3">
              <div className="w-full lg:w-1/2 px-3">
                <div className="mt-6">
                  <span className="block text-primary text-base font-bold uppercase">
                    {t('subheader')}
                  </span>
                  <h2 className="text-2xl lg:text-4xl font-bold mt-4 text-grey-50 leading-normal">
                    <Trans i18nKey={`${keyPrefix}.header`}>
                      Wir freuen uns auf <span className="md:block">Ihre</span>
                      <span className="md:block">Kontaktaufnahme</span>
                    </Trans>
                  </h2>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-3">
                <div className="flex justify-center py-8 lg:py-0">
                  <img
                    src={ContactEmail}
                    alt={t('subheader')}
                    className="mx:auto max-w-[230px] xl:max-w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div data-cy="sign-in-form" className="bg-white p-4 md:p-8 shadow-ds12">
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-6">
                      <Controller
                        name="subject"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder=""
                            error={errorLabel('subject', 'supportRequest', errors.subject)}
                            label={translateAttribute('subject', 'supportRequest')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-1/2 px-3">
                    <div className="mb-6">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: true,
                          pattern: {
                            value: EMAIL_REGEXP,
                            message: 'email'
                          }
                        }}
                        render={({ field, ref }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t('emailPlaceholder')}
                            error={errorLabel('email', 'supportRequest', errors.email)}
                            label={translateAttribute('email', 'supportRequest')}
                            ref={ref}
                            markAsRequired
                            icon={<MailIcon width="16" height="16" className="text-grey-25" />}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3 mb-6">
                    <div className="mb-6">
                      <Controller
                        name="message"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, ref }) => (
                          <Textarea
                            {...field}
                            type="text"
                            rows="5"
                            placeholder=""
                            error={errorLabel('message', 'supportRequest', errors.message)}
                            label={translateAttribute('message', 'supportRequest')}
                            ref={ref}
                            markAsRequired
                          />
                        )}
                      />
                    </div>
                    <div className="text-xs text-grey-50 sm:-mt-6 mb-6">*Pflichtfelder</div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full lg:w-1/2 px-3">
                    <div className="lg:-mt-4">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onChange={handleRecaptcha}
                        onExpired={handleCaptchaExpire}
                        hl="de"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-8 pb-6">
                  {isSubmitting ? (
                    <div className="h-10 flex items-center justify-center">
                      <LoaderIcon
                        width="32"
                        height="32"
                        className="animate-spin text-primary-60 fill-current"
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                      disabled={submitDisabled || !isValid}
                    >
                      {t('submit')}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
