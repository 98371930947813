import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyApplicationForm from '@components/Forms/CompanyApplicationForm';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import PartnersSection from '@components/UIElements/Partners';
import CompanyUserBox from '@components/UIElements/ComapnyUserBox';
import SectionHead from '@components/UIElements/SectionHead';
import { parseBool } from '@tools';
import { observer } from 'mobx-react';
import { useInView } from 'react-cool-inview';
import HeroPhoto from '@images/partners-screen/hero.jpg';
import { ReactComponent as BeeIcon } from '@images/icons/bee-shape.svg';
import MediaPartners from '@components/UIElements/MediaPartners';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import { generatePath } from 'react-router-dom';
import { COMPANY_PROFILE_PATH, ADOPTED_BEES_NUMBER } from '@constants';
import PARTNERS from '@screens/PartnersScreen/partners';
import { usePartnersScreenStore } from '@screens/PartnersScreen/store';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

function PartnersScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'partnersScreen' });
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const { companyStore, fetchStats, stats } = usePartnersScreenStore();

  function fetchCompanies(page = 1) {
    return companyStore.fetchAll(
      {
        q: { kindEq: 'company', usernameNotNull: true, profileVisibleEq: true },
        sortBy: 'created_at desc',
        per: 30,
        page
      },
      true
    );
  }

  useEffect(() => {
    companyStore.cleanCollection();
    fetchStats();
    fetchCompanies().then(() => setCompaniesLoading(false));
  }, []);

  function loadMore() {
    fetchCompanies(companyStore.meta.nextPage);
  }

  const { observe } = useInView({
    rootMargin: '80px 0px',
    onEnter: ({ unobserve }) => {
      if (companyStore.meta.nextPage) loadMore();
      else unobserve();
    }
  });

  const companiesWrapper = () => {
    return (
      <section className="bg-white py-10 md:py-14 overflow-hidden">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <SectionHead
            header={t('companiesHeader')}
            subheader={t('companiesSubheader')}
            lead={t('companiesLead')}
          />
          <div className="mt-8 md:mt-14">
            <div className="flex flex-wrap -mx-3">
              {companyStore.collection.map(({ username, logo, slug, livingAddress }, index) => (
                <div
                  ref={observe}
                  key={slug}
                  className="w-1/2 sm:w-1/2 md:w-1/3 xl:w-1/6 px-3 py-3"
                >
                  <CompanyUserBox
                    url={generatePath(COMPANY_PROFILE_PATH, { companySlug: slug })}
                    name={username}
                    logo={logo}
                    address={`${livingAddress.city}, ${livingAddress.country.name}`}
                    isNew={index < 5}
                  />
                </div>
              ))}
            </div>
            <div className="mt-8 flex justify-center">
              {parseBool(companyStore.meta.nextPage) && (
                <button
                  className="outline-none focus:outline-none inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-10 w-full md:w-auto rounded box-border cursor-pointer transition-colors ease-in-out duration-300"
                  type="button"
                  onClick={() => loadMore()}
                >
                  {t('loadMoreCompanies')}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const companiesLoader = () => {
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );
  };

  return (
    <>
      <TitleAndDescription screenName="partnersScreen" />
      <HeroWithSquarePhoto photo={HeroPhoto} alt="PROJEKT 2028-Unternehmen">
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44">{t('lead')}</div>
        <div className="mt-6 lg:mt-12">
          <div className="text-lg md:text-xl xl:text-2xl text-orange font-bold mb-6">
            {t('beeNumberHeader')}
          </div>
          <div className="flex text-orange mb-6 lg:mb-0">
            <BeeIcon width="60" height="60" className="fill-current flex-shrink-0 mr-2" />
            <div>
              <div className="text-3xl md:text:4xl xl:text-5xl font-bold">
                {ADOPTED_BEES_NUMBER || stats.adoptedBees.toLocaleString('de-DE')}
              </div>
              <div className="text-sm lg:text-base font-bold uppercase">{t('moreBees')}</div>
            </div>
          </div>
        </div>
      </HeroWithSquarePhoto>
      <PartnersSection
        items={PARTNERS}
        title={t('partners.header')}
        subtitle={t('partners.subheader')}
        lead={t('partners.lead')}
        keyPrefix="partnersScreen.partners"
      />
      {companiesLoading ? companiesLoader() : companiesWrapper()}
      <MediaPartners />
      <CompanyApplicationForm supportKind="p2028_partners" />
    </>
  );
}

export default observer(PartnersScreen);
