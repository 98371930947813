import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function CaseStudyBox({ title, lead, cover, logo, url, backgroundColor }) {
  const { t } = useTranslation('screens', { keyPrefix: 'caseStudiesScreen.favoriteCaseStudies' });
  return (
    <div className="my-8 md:my-10">
      <Link to={url} reloadDocument>
        <div className="aspect-w-16 aspect-h-13 group">
          <div className={`bg-current rounded-3xl ${backgroundColor}`} />
          <LazyLoad>
            <img
              src={cover}
              alt={title}
              className="transition-transform duration-300 absolute inset-0 w-full h-full object-cover rounded-3xl transform translate-x-4 -translate-y-4 group-hover:translate-x-2 group-hover:-translate-y-2"
            />
          </LazyLoad>
        </div>
      </Link>
      <div className="mt-6">
        <Link to={url}>
          <div className="h-8 w-full relative flex mb-6">
            <LazyLoad height={32}>
              <img src={logo} alt={title} className="object-contain h-full" />
            </LazyLoad>
          </div>
        </Link>
      </div>
      <div className="text-grey-25 text-xs md:text-sm my-4">{lead}</div>
      <Link
        to={url}
        reloadDocument
        className="text-primary text-xs md:text-sm font-medium flex items-center group"
      >
        {t('readMore')}
        <ArrowRightIcon
          width="16"
          height="16"
          className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
        />
      </Link>
    </div>
  );
}

export default CaseStudyBox;

CaseStudyBox.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  backgroundColor: PropTypes.string
};

CaseStudyBox.defaultProps = {
  cover: '',
  title: '',
  lead: '',
  logo: '',
  url: '',
  backgroundColor: ''
};
