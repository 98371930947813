import { makeAutoObservable, runInAction } from 'mobx';
import { companyArticleEndpoint } from '@config/endpoints';
import _ from 'lodash';
import CompanyFileCategoryStore from '@/stores/CompanyPortalStores/CompanyFileCategoryStore';
import AdminUserStore from '@/stores/GlobalStores/AdminUserStore';
import NestedCompanyFileStore from '@/stores/CompanyPortalStores/NestedCompanyFileStore';
import { parseBool } from '@tools';

class CompanyArticleStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.record = {};
      this.companyFileCategoryStore = new CompanyFileCategoryStore();
      this.authorStore = new AdminUserStore();
      this.nestedCompanyFileStore = new NestedCompanyFileStore();
    });
  }

  bindMethods() {
    this.assignCompanyArticle = this.assignCompanyArticle.bind(this);
    this.fetchAndAssignRelationships = this.fetchAndAssignRelationships.bind(this);
  }

  fetchOne(id) {
    return companyArticleEndpoint.one(id).then(this.assignCompanyArticle);
  }

  async assignCompanyArticle(response) {
    const {
      attributes,
      relationships: { author, companyFiles }
    } = response.data.data;
    const newRecord = {
      ...attributes,
      authorId: author.data.id,
      companyFileIds: companyFiles.data.map(({ id }) => id)
    };

    const record = await this.fetchAndAssignRelationships(newRecord);

    runInAction(() => {
      this.record = record;
    });
  }

  async fetchAndAssignRelationships(record) {
    const { companyFileCategoryId } = record;
    const { authorId } = record;
    const { companyFileIds } = record;

    await this.companyFileCategoryStore.fetchAll({ q: { idEq: companyFileCategoryId } });

    if (parseBool(authorId)) await this.authorStore.fetchAll({ q: { idEq: authorId } });

    if (_.uniq(companyFileIds.flat()).length > 0)
      await this.nestedCompanyFileStore.fetchAll({
        q: { idIn: _.uniq(companyFileIds.flat()) },
        authorizedScopeType: 'visible'
      });

    const foundCompanyFileCategory = this.companyFileCategoryStore.collection.find(
      ({ id }) => record.companyFileCategoryId === id
    );
    const foundAuthor = this.authorStore.collection.find(
      ({ id }) => record.authorId === id.toString()
    );
    const foundCompanyFiles = this.nestedCompanyFileStore.collection.filter(({ id }) =>
      record.companyFileIds.includes(id.toString())
    );

    return {
      companyFileCategory: foundCompanyFileCategory,
      author: foundAuthor,
      companyFiles: foundCompanyFiles,
      ...record
    };
  }
}

export default CompanyArticleStore;
