import moment from 'moment';
import 'moment/locale/de';

export function parseBool(b) {
  return !/^(false|0)$/i.test(b) && !!b;
}

export function formatDate(date) {
  return moment(date).locale('de').format('DD.MM.YYYY');
}

export function formatDateWithLocale(date) {
  return moment(date, 'LL').locale('de').format('DD.MM.YYYY');
}

export function formatDateWithMonthName(date) {
  return moment(date).locale('de').format('DD, MMMM YYYY');
}

export function formatDateWithMonthNameAndYear(date) {
  return moment(date).locale('de').format('MMMM YYYY');
}

export function formatDateWithYear(date) {
  return moment(date).locale('de').format('YYYY');
}

export default {};
