import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import DayPicker from 'react-day-picker';
import ReactTooltip from 'react-tooltip';
import 'react-day-picker/lib/style.css';
import EventModal from '@components/UIElements/Modal';
import { formatDate } from '@tools';
import moment from 'moment';
import SkeletonEventsFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/EventsFeed/Skeleton';
import _ from 'lodash';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';

function EventsFeed() {
  const modal = useRef(null);
  const [eventName, setEventName] = useState('');
  const [eventBody, setEventBody] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventsFetched, setEventsFetched] = useState(false);
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.eventsFeed'
  });
  const { eventStore } = useStores();

  function fetchEvents() {
    return eventStore.fetchAll();
  }

  function findEventByDate(calendarDate) {
    return eventStore.collection.find(({ date }) => formatDate(date) === formatDate(calendarDate));
  }

  useEffect(() => {
    fetchEvents().then(() => setEventsFetched(true));
  }, []);

  const renderDay = (calendarDate) => {
    const foundEvent = findEventByDate(calendarDate);

    if (foundEvent) return <div data-tip={foundEvent.name}>{calendarDate.getDate()}</div>;

    return <div>{calendarDate.getDate()}</div>;
  };

  const sortedEvents = () => {
    const collection = eventStore.collection.filter((record) =>
      moment(record.date).isAfter(new Date())
    );
    return _.sortBy(collection, ({ date }) => date);
  };

  const handleDayClick = (calendarDate, { highlighted }) => {
    const foundEvent = findEventByDate(calendarDate);

    if (highlighted) {
      setEventName(foundEvent.name);
      setEventBody(foundEvent.description);
      setEventDate(foundEvent.date);
      modal.current.open();
    }
  };

  const handleEventClick = (event) => {
    setEventName(event.name);
    setEventBody(event.description);
    setEventDate(event.date);
    modal.current.open();
  };

  const eventWrapper = (event) => {
    return (
      <div className="flex items-center justify-between my-2" key={event.id}>
        <button
          type="button"
          className="group w-full flex items-center justify-between outline-none focus:outline-none"
          onClick={() => handleEventClick(event)}
        >
          <div className="flex-1 min-w-0 flex items-center">
            <span className="w-2 h-2 mr-2 bg-orange rounded-full flex-shrink-0" />
            <div className="transition-colors duration-300 text-grey-50 text-sm truncate group-hover:text-primary">
              {event.name}
            </div>
          </div>
          <div className="text-grey-50 text-sm font-bold flex-shrink-0 ml-1">
            {formatDate(event.date)}
          </div>
        </button>
      </div>
    );
  };

  if (!eventsFetched) return <SkeletonEventsFeed />;

  return (
    <>
      <div className="rounded-2xl bg-white shadow-ds16">
        <div className="bg-primary-120 rounded-t-2xl px-4 py-4">
          <h3 className="text-white text-lg md:text-2xl font-bold">{t('title')}</h3>
        </div>
        <div className="p-4">
          <div className="mt-3">
            <DayPicker
              renderDay={renderDay}
              localeUtils={MomentLocaleUtils}
              locale="de"
              modifiers={{ highlighted: eventStore.collection.map(({ date }) => new Date(date)) }}
              onDayClick={handleDayClick}
              onMonthChange={() => ReactTooltip.rebuild()}
              className="hn-datepicker"
            />
            <ReactTooltip effect="solid" backgroundColor="#272F3E" arrowColor="#272F3E" />
          </div>
          <h4 className="text-primary-120 text-sm uppercase font-bold mb-3">
            {t('eventsLegendTitle')}
          </h4>
          <div>{sortedEvents().map(eventWrapper)}</div>
        </div>
      </div>
      <EventModal ref={modal}>
        <div className="bg-white rounded-2xl p-6 md:p-10 w-full max-w-[560px] max-h-[90vh] overflow-y-auto">
          <div className="">
            <h2 className="text-2xl text-grey-50 font-bold mb-2">{eventName}</h2>
            <div className="text-sm text-primary font-bold">{formatDate(eventDate)}</div>
            <div className="text-base text-grey-30 mt-4 text-entry">
              {eventBody.split('\n').map((str) => (
                <p key={str}>{str}</p>
              ))}
            </div>
          </div>
        </div>
      </EventModal>
    </>
  );
}

export default observer(EventsFeed);
