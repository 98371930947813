import i18n from '@config/i18n';
import _ from 'lodash';

export function addServerErrors({ errors }, setError, model = undefined) {
  const translateMessage = (attribute, messages) => {
    if (model && typeof messages !== 'string') {
      messages
        .map((message) => `${i18n.t(`attributes:${model}:${attribute}`)} ${message}`)
        .join('\n');
      return messages.join('. ');
    }

    return messages;
  };

  Object.keys(errors).forEach((key) => {
    setError(_.camelCase(key), { type: 'backend', message: translateMessage(key, errors[key]) });
  });
}

export default { addServerErrors };
