import Logo02 from '@images/case-studies-screen/favorities/logos/02.png';
import Logo03 from '@images/case-studies-screen/favorities/logos/03.png';
import Logo04 from '@images/case-studies-screen/favorities/logos/04.png';
import Logo06 from '@images/case-studies-screen/favorities/logos/06.png';
import Logo08 from '@images/case-studies-screen/favorities/logos/08.png';
import Logo09 from '@images/case-studies-screen/favorities/logos/09.png';
import Logo10 from '@images/case-studies-screen/favorities/logos/10.png';
import Logo11 from '@images/case-studies-screen/favorities/logos/11.png';

import WeledaProjectHoney from '@images/case-studies-screen/offline-communication/project-honey/weleda.png';
import MetroProjectHoney from '@images/case-studies-screen/offline-communication/project-honey/metro.png';
import RfBankProjectHoney from '@images/case-studies-screen/offline-communication/project-honey/rfbank.png';
import MetroPos from '@images/case-studies-screen/offline-communication/pos/metro.png';
import CheWowPos from '@images/case-studies-screen/offline-communication/pos/chewow.png';
import PezPos from '@images/case-studies-screen/offline-communication/pos/pez.png';
import TakkoPos from '@images/case-studies-screen/offline-communication/pos/takko.png';
import BuwogEmployerBranding from '@images/case-studies-screen/offline-communication/employer-branding/buwog.png';
import EyEvents from '@images/case-studies-screen/offline-communication/events/ey.png';
import RfBankEvents from '@images/case-studies-screen/offline-communication/events/rfbank.png';

const CASE_STUDIES = [
  {
    name: 'Projekt-Honig',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Projekt-Honig als süßer Botschafter',
        lead: 'Als kleine Aufmerksamkeit mit sinnvoller Message fungiert der Projekt-Honig. Mit den für Weleda gebrandeten PROJEKT 2028 Honigbechern macht das Unternehmen nicht nur seinen Kund*innen eine Freude...',
        url: 'weleda',
        logo: <Logo11 />,
        photo: <WeledaProjectHoney />,
        backgroundColor: 'text-orange-40'
      },
      {
        head: 'Offline-Promotion via Flugblatt',
        lead: 'Auch Offline-Promotion war Teil der externen Kommunikation zu PROJEKT 2028. Im Metro-Prospekt wurde die Kooperation sowie der Verkauf des PROJEKT 2028-Honigs angekündigt und entsprechend optisch aufbereitet.',
        url: 'metro',
        logo: <Logo02 />,
        photo: <MetroProjectHoney />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'PROJEKT-Honig Give-Aways',
        lead: 'Aktionstag mit doppeltem Sinn. Im Rahmen des Weltspartags 2019 hat Raiffeisen NÖ-Wien nicht nur rund um das Thema Sparen informiert. Sumsi, das Maskottchen der Bank, machte gleichzeitig auch auf das Engagement in PROJEKT 2028 aufmerksam...',
        url: 'raiffeisen',
        logo: <Logo10 />,
        photo: <RfBankProjectHoney />,
        backgroundColor: 'text-primary-40'
      }
    ]
  },
  {
    name: 'Point of Sale',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'POS-Marketing für den Bienenschutz',
        lead: 'Um auf den Wert regionaler Produkte aufmerksam zu machen und gleichzeitig die Käufer*innen für den Bienenschutz zu begeistern, setzte Metro die Kooperation mit PROJEKT 2028 auch in allen 12 Filialen des Landes bildstark in Szene...',
        url: 'metro',
        logo: <Logo02 />,
        photo: <MetroPos />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'Jede Packung für den Bienenschutz',
        lead: 'Pro verkaufte Packung geht ein gewisser Betrag direkt an PROJEKT 2028 und damit 1:1 in den Bienenschutz. Um dieses Engagement den Chewow-Käufer*innen zu vermitteln und zugleich einen Kaufanreiz zu setzen, ist jedes der Produkte mit dem PROJEKT-2028-Logo...',
        url: 'chewow',
        logo: <Logo09 />,
        photo: <CheWowPos />,
        backgroundColor: 'text-primary-40'
      },
      {
        head: 'Buzz und Honey als Botschafter*innen',
        lead: '1 Million mehr Bienen in Österreich und Deutschland. Dafür sorgen die beiden Bienen-PEZ-Spender Buzz und Honey von PEZ. Mit der Sonderedition wird nicht nur die Unterstützung des PROJEKT 2028 nach außen getragen...',
        url: 'pez',
        logo: <Logo06 />,
        photo: <PezPos />,
        backgroundColor: 'text-red-40'
      },
      {
        head: 'Bienenschutz in Sockenform',
        lead: 'Um auch in den Filialen in Deutschland und Österreich auf das Engagement in PROJEKT 2028 hinzuweisen und die Käufer*innen für den Bienenschutz zu gewinnen, entwarf TAKKO Fashion im Herbst 2020 eine eigene Socken-Kollektion...',
        url: 'takko-fashion',
        logo: <Logo03 />,
        photo: <TakkoPos />,
        backgroundColor: 'text-orange-40'
      }
    ]
  },
  {
    name: 'Employer Branding',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Biene zur Mitarbeiter*innenbindung',
        lead: 'Pandemiebedingt musst das traditionelle EY-Sommerfest im Jahr 2020 abgesagt werden. Um dennoch gemeinsam auf das abgeschlossene Finanzjahr anzustoßen, fand der Summer CocktEYl virtuell statt...',
        url: 'BUWOG',
        logo: <Logo04 />,
        photo: <BuwogEmployerBranding />,
        backgroundColor: 'text-red-40'
      }
    ]
  },
  {
    name: 'Events',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Biene zur Mitarbeiter*innenbindung',
        lead: 'Pandemiebedingt musst das traditionelle EY-Sommerfest im Jahr 2020 abgesagt werden. Um dennoch gemeinsam auf das abgeschlossene Finanzjahr anzustoßen, fand der Summer CocktEYl virtuell statt...',
        url: 'ey',
        logo: <Logo08 />,
        photo: <EyEvents />,
        backgroundColor: 'text-red-40'
      },
      {
        head: 'Flyer als Botschaftsträger',
        lead: 'In den Genuss von leckerem Honig kamen auch die Teilnehmer*innen und Besucher*innen des Kiddy Contest 2019. Auch Sumsi kam auf der beliebten Veranstaltung erneut zum Einsatz. Auf einem eigens erstellten Flyer wies das Maskottchen auf die Unterstützung von PROJEKT 2028...',
        url: 'raiffeisen',
        logo: <Logo10 />,
        photo: <RfBankEvents />,
        backgroundColor: 'text-green-40'
      }
    ]
  }
];

export default CASE_STUDIES;
