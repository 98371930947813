import { useTranslation, Trans } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import PackingPhoto01 from '@images/honey-project/packing/packing-01.jpg';
import { ReactComponent as ListIcon01 } from '@images/icons/honey-project/packing/01.svg';
import { ReactComponent as ListIcon02 } from '@images/icons/honey-project/packing/02.svg';
import { ReactComponent as ListIcon03 } from '@images/icons/honey-project/packing/03.svg';
import { ReactComponent as ListIcon04 } from '@images/icons/honey-project/packing/04.svg';
import 'slick-carousel/slick/slick.css';

function Packing() {
  const { t } = useTranslation('screens', { keyPrefix: 'honeyProjectScreen.components.packing' });

  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap items-center -mx-3">
          <div className="w-full md:w-1/2 px-3">
            <div className="lg:pr-20 md:py-4">
              <span className="block text-primary text-sm md:text-base font-bold uppercase">
                {t('subheader')}
              </span>
              <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                {t('header')}
              </h2>
              <div className="text-sm md:text-base text-grey-30 mt-4">
                <ul className="lg:pr-24 mb-8 md:mb-0">
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon01 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">
                      <Trans i18nKey="screens:honeyProjectScreen.components.packing.list.item01">
                        Fließt in Bienenschutz in Deutschland und Österreich
                      </Trans>
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon02 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">
                      <Trans i18nKey="screens:honeyProjectScreen.components.packing.list.item02">
                        Die Erlöse fließen in die Erfüllung der Mission.
                        <span className="text-primary block">Yippieh - mehr Bienen!</span>
                      </Trans>
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon03 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">
                      <Trans i18nKey="screens:honeyProjectScreen.components.packing.list.item03">
                        Bienen bringen Balkone, Gärten und Wiesen zum blühen.
                        <span className="text-primary block">Mmmhhh - duftet das gut!</span>
                      </Trans>
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <div className="h-8 w-8 mr-5 flex-shrink-0 flex items-center justify-center text-primary-120 bg-primary-20 rounded-full">
                      <ListIcon04 width="32" height="32" className="fill-current" />
                    </div>
                    <div className="text-sm md:text-base text-grey-30">
                      <Trans i18nKey="screens:honeyProjectScreen.components.packing.list.item04">
                        Unterstützt unsere Helden: die Imker*innen
                        <span className="text-primary block">Bussi - ihr seid spitze!</span>
                      </Trans>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <div className="aspect-w-16 aspect-h-9">
              <LazyLoad>
                <img
                  src={PackingPhoto01}
                  alt={t('header')}
                  className="absolute inset-0 w-full h-full object-cover rounded-2xl"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Packing;
