import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function CommunicationSliderBody({ head, lead, logo, url, photo, backgroundColor }) {
  const { t } = useTranslation('screens', {
    keyPrefix: 'caseStudiesScreen.communicationSlider'
  });

  return (
    <div className={`${backgroundColor}`}>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 lg:w-5/12 px-3 mb-4 md:mb-12 lg:mb-0">
          <div className="xl:pr-20">
            <div className="lg:mt-16 lg:mb-6">
              <Link to={url} className="h-10 w-full relative flex my-2 lg:mb-6">
                <LazyLoad height={40}>
                  <img src={logo} alt={head} className="object-contain h-full" />
                </LazyLoad>
              </Link>
            </div>
            <div className="hidden md:block">
              <h3 className="text-grey-50 text-2xl font-bold">{head}</h3>
              <div className="text-grey-30 text-sm lg:text-base mt-4">{lead}</div>
              <div className="mt-4 relative z-10">
                <Link
                  to={url}
                  className="text-primary text-xs md:text-sm font-medium flex items-center group"
                >
                  {t('readMore')}
                  <ArrowRightIcon
                    width="16"
                    height="16"
                    className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-7/12 px-3">
          <Link to={url}>
            <div className="aspect-w-16 aspect-h-9">
              <div className="bg-current rounded-2xl" />
              <img src={photo} alt={head} />
            </div>
          </Link>
          <div className="md:hidden mt-6 mb-12">
            <h3 className="text-grey-50 text-2xl font-bold">{head}</h3>
            <div className="text-grey-30 text-sm lg:text-base mt-4">{lead}</div>
            <div className="mt-4 relative z-10">
              <Link
                to={url}
                className="text-primary text-xs md:text-sm font-medium flex items-center group"
              >
                {t('readMore')}
                <ArrowRightIcon
                  width="16"
                  height="16"
                  className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunicationSliderBody;

CommunicationSliderBody.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  photo: PropTypes.string,
  backgroundColor: PropTypes.string
};

CommunicationSliderBody.defaultProps = {
  head: '',
  lead: '',
  logo: '',
  url: '',
  photo: '',
  backgroundColor: ''
};
