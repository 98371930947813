import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ROOT_PATH,
  AUTH_PATH,
  CASE_STUDIES_PATH,
  PARTNERS_PATH,
  HONEY_PROJECT_PATH,
  ABOUT_PATH,
  POSTS_PATH,
  CONTACT_PATH,
  FACEBOOK_PATH,
  INSTAGRAM_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_AND_CONDITIONS_PATH,
  HN_PATH,
  HN_BEEKEEPERS_PATH,
  HN_COMMUNITY_PATH,
  HN_MARKETPLACE_PATH
} from '@constants';
import Project2028Logo from '@images/logo-2028-footer.svg';
import HNLogo from '@images/hn-logo.svg';
import { ReactComponent as FacebookIcon } from '@images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@images/icons/instagram.svg';
import SocialLink from './components/SocialLink';
import FooterHead from './components/FooterHead';
import LinkItem from './components/LinkItem';
import ExternalLinkItem from './components/ExternalLinkItem';

function Footer() {
  const { t } = useTranslation('components', { keyPrefix: 'layouts.basicLayout.footer' });

  useEffect(() => {
    const scrollToNewsletter = () => {
      const element = document.getElementById('newsletter');
      if (element) {
        const offset = element.offsetTop;
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        });
      }
    };

    const hasTargetId = window.location.href.includes('#newsletter');
    if (hasTargetId) {
      window.addEventListener('load', scrollToNewsletter);
    }
    return () => {
      window.removeEventListener('load', scrollToNewsletter);
    };
  }, []);
  return (
    <footer className="bg-primary pt-12 md:pt-16 pb-10">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-5/12 xl:w-6/12 px-4">
            <div className="flex items-center space-x-12 pb-6 md:pb-0">
              <div className="w-1/2 md:w-auto">
                <Link to={ROOT_PATH}>
                  <img
                    src={Project2028Logo}
                    alt={t('project2028NavHeader')}
                    className="w-[75px] md:w-[82px] h-[51px] md:h-[56px]"
                  />
                </Link>
              </div>
              <div className="w-1/2 md:w-auto pr-16">
                <a href={HN_PATH} target="_blank" rel="noopener noreferrer">
                  <img
                    src={HNLogo}
                    alt={t('logo')}
                    className="w-[114px] md:w-[93px] h-[44px] md:h-[36px]"
                  />
                </a>
              </div>
            </div>
            <div className="text-white mt-6 md:mt-12">
              <h4 className="text-2xl lg:text-4xl font-bold">{t('adoptBees.title')}</h4>
              <div>
                <p className="mt-2 md:mt-6 text-base lg:text-xl">{t('newsletter.subtitle')}</p>
                <div className="mt-6 mb-8">
                  <a
                    href="/#newsletter"
                    className="min-w-[210px] inline-flex items-center justify-center relative bg-white bg-opacity-20 hover:bg-opacity-100 hover:bg-primary-120 text-white hover:text-primary-0 text-xs md:text-base border-0 m-0 py-2 px-4 rounded outline-none box-border cursor-pointer transition-all ease-in-out duration-300"
                  >
                    {t('newsletter.button')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-7/12 xl:w-6/12 px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-1/2 md:w-1/3 px-4">
                <FooterHead title={t('project2028NavHeader')} />
                <ul>
                  <LinkItem href={ROOT_PATH} label={t('nav.company')} />
                  <LinkItem href={HONEY_PROJECT_PATH} label={t('nav.honeyProject')} />
                  <LinkItem href={CASE_STUDIES_PATH} label={t('nav.caseStudies')} />
                  <LinkItem href={PARTNERS_PATH} label={t('nav.partners')} />
                  <LinkItem href={POSTS_PATH} label={t('nav.blog')} />
                  <LinkItem href={ABOUT_PATH} label={t('nav.about')} />
                  <LinkItem href={CONTACT_PATH} label={t('nav.contact')} />
                  <LinkItem href={AUTH_PATH} label={t('nav.login')} />
                </ul>
              </div>
              <div className="w-1/2 md:w-2/3 px-4">
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full md:w-1/2 px-2">
                    <FooterHead title={t('beekeepersNavHeader')} />
                    <ul>
                      <ExternalLinkItem
                        href={HN_BEEKEEPERS_PATH}
                        label={t('navBeekeepers.becomeBeekeeper')}
                      />
                      <ExternalLinkItem
                        href={HN_COMMUNITY_PATH}
                        label={t('navBeekeepers.community')}
                      />
                      <ExternalLinkItem
                        href={HN_MARKETPLACE_PATH}
                        label={t('navBeekeepers.marketplace')}
                      />
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 px-2">
                    <FooterHead title={t('statuteNavHeader')} />
                    <ul>
                      <LinkItem href={TERMS_AND_CONDITIONS_PATH} label={t('navStatute.terms')} />
                      <LinkItem href={PRIVACY_POLICY_PATH} label={t('navStatute.privacyPolicy')} />
                      <LinkItem href={CONTACT_PATH} label={t('navStatute.imprint')} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="block border-t border-white my-10" />
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex space-x-4">
            <SocialLink href={FACEBOOK_PATH}>
              <FacebookIcon width="24px" height="24px" className="fill-current" />
            </SocialLink>
            <SocialLink href={INSTAGRAM_PATH}>
              <InstagramIcon width="24px" height="24px" className="fill-current" />
            </SocialLink>
          </div>
          <div className="text-white text-sm mt-6 sm:mt-0">
            {t('copyrights', { year: new Date().getFullYear() })}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
