import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmailIcon } from '@images/icons/mail.svg';
import LazyLoad from 'react-lazyload';
import sal from 'sal.js';

function TeamMemberBox({ head, position, description, photo, email }) {
  useEffect(() => {
    sal();
  }, []);
  return (
    <div
      data-sal="fade"
      data-sal-delay="200"
      data-sal-duration="1000"
      data-sal-easing="easeOutCubic"
    >
      <div className="md:px-6 py-6 md:py-10 h-full">
        <div className="lg:px-8">
          <div className="flex justify-center">
            <LazyLoad>
              <img src={photo} alt={head} className="max-w-[310px]" />
            </LazyLoad>
          </div>
          <h3 className="text-base md:text-2xl text-grey-50 font-bold mt-4">{head}</h3>
          <h4 className="text-sm md:text-base text-primary font-bold">{position}</h4>
          <div className="text-grey-30 text-sm mt-2">{description}</div>
          {email && (
            <div className="mt-4 flex">
              <a
                href={`mailto:${email}`}
                className="transition-colors duration-300 text-sm text-grey-30 flex items-center group hover:text-primary"
              >
                <EmailIcon
                  width="16"
                  height="16"
                  className="transition-colors duration-300 text-grey-20 fill-current flex-shrink-0 mr-3 group-hover:text-primary"
                />
                {email}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamMemberBox;

TeamMemberBox.propTypes = {
  head: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  photo: PropTypes.string
};

TeamMemberBox.defaultProps = {
  head: '',
  position: '',
  description: '',
  email: '',
  photo: ''
};
