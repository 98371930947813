import { useLocation, useRoutes } from 'react-router-dom';
import interceptorsSetup from '@config/interceptors';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { useEffect, useLayoutEffect } from 'react';
import routes from './config/routes';

function App() {
  const { authStore } = useStores();
  const location = useLocation();

  interceptorsSetup(useStores());

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    authStore.checkIfSigned();
  }, []);

  return useRoutes(routes);
}

export default observer(App);
