import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { parseBool } from '@tools';
import { ADOPTED_BEES_NUMBER, POST_PATH } from '@constants';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import HeroPhoto from '@images/blog-screen/hero.jpg';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import { ReactComponent as BeeIcon } from '@images/icons/bee-shape.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import Share from './components/Share';
import Entry from './components/Entry';
import FeaturedPost from './components/FeaturedPost';

function PostsScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'postsScreen' });
  const { postStore } = useStores();
  const [fetched, setFetched] = useState(false);

  function fetchPosts(page = 1, extendCollection = false) {
    postStore
      .fetchAll(
        {
          sortBy: 'published_at desc',
          page,
          per: 1
        },
        extendCollection
      )
      .then(() => setFetched(true));
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  const postWrapper = (post) => {
    return (
      <Entry
        key={post.id}
        url={generatePath(POST_PATH, { postSlug: post.slug })}
        cover={post.cover}
        postCategory={post.postCategory.title}
        date={post.publishedAt}
        title={post.title}
        abstract={post.abstract}
      />
    );
  };

  function loadMore() {
    fetchPosts(postStore.meta.nextPage, true);
  }

  const loadMoreButton = () => {
    if (!parseBool(postStore.meta.nextPage)) return <div />;

    return (
      <div className="flex justify-center mt-6">
        <button
          className="inline-flex items-center justify-center relative bg-transparent font-bold text-2xl text-grey-25 hover:text-grey-30 m-0 py-2 px-12 outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
          type="button"
          onClick={() => loadMore()}
        >
          {t('loadMoreEntries')}
        </button>
      </div>
    );
  };

  if (!fetched)
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );
  return (
    <>
      <TitleAndDescription screenName="postsScreen" />
      <HeroWithSquarePhoto
        backgroundColor="bg-primary-light"
        photo={HeroPhoto}
        alt="PROJEKT 2028-Unternehmen"
      >
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44">{t('lead')}</div>
        <div className="mt-6 lg:mt-12">
          <div className="text-lg md:text-xl xl:text-2xl text-orange font-bold mb-6">
            {t('beeNumberHeader')}
          </div>
          <div className="flex text-orange mb-6 lg:mb-0">
            <BeeIcon width="60" height="60" className="fill-current flex-shrink-0 mr-2" />
            <div>
              <div className="text-3xl md:text:4xl xl:text-5xl font-bold">
                {ADOPTED_BEES_NUMBER}
              </div>
              <div className="text-sm lg:text-base font-bold uppercase">{t('moreBees')}</div>
            </div>
          </div>
        </div>
      </HeroWithSquarePhoto>
      <section className="py-8 lg:py-16">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <h2 className="text-2xl md:text-4xl font-bold text-grey-50 mb-10">Neuigkeiten</h2>
          <FeaturedPost
            key={postStore.collection[0].id}
            url={generatePath(POST_PATH, { postSlug: postStore.collection[0].slug })}
            cover={postStore.collection[0].cover}
            postCategory={postStore.collection[0].postCategory.title}
            date={postStore.collection[0].publishedAt}
            title={postStore.collection[0].title}
            abstract={postStore.collection[0].abstract}
          />
        </div>
      </section>

      <section className="pt-16">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-4 lg:-mx-6">
            {postStore.collection.map(postWrapper)}
          </div>
          {loadMoreButton()}
        </div>
      </section>
      <Share />
    </>
  );
}

export default observer(PostsScreen);
