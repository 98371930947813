import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function Entry({ url, cover, postCategory, date, title, abstract }) {
  const { t } = useTranslation('screens', { keyPrefix: 'postsScreen' });
  return (
    <div className="w-full md:w-1/2 lg:w-1/3 px-4 lg:px-6 mb-8">
      <div className="bg-white shadow-ds8 rounded-2xl overflow-hidden h-full">
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="relative">
              <Link to={url} reloadDocument>
                <div className="aspect-w-9 aspect-h-6 group">
                  <LazyLoad>
                    <img src={cover} alt="" className="w-full h-full object-cover" />
                  </LazyLoad>
                </div>
              </Link>
              <div
                className={`${
                  postCategory === 'News' ? 'bg-primary' : 'bg-green'
                } text-white text-xs uppercase font-medium px-6 py-2 inline-flex absolute bottom-0 left-4`}
              >
                {postCategory && postCategory}
              </div>
            </div>

            <div className="px-6">
              <div className="text-xs text-grey-30 mt-8">
                {moment(date).locale('de').format('DD.MM.YYYY')}
              </div>
              <h3 className="text-xl md:text-2xl font-bold mt-2 text-grey-50">{title}</h3>
              <div className="text-sm md:text-base text-grey-30 mt-4">{abstract}</div>
            </div>
          </div>
          <div className="px-6">
            <div className="py-8">
              <Link
                to={url}
                reloadDocument
                className="text-primary text-xs md:text-sm font-medium uppercase flex items-center group"
              >
                {t('readMore')}
                <ArrowRightIcon
                  width="16"
                  height="16"
                  className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Entry.propTypes = {
  url: PropTypes.string,
  cover: PropTypes.string,
  postCategory: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  abstract: PropTypes.string
};

Entry.defaultProps = {
  url: '',
  cover: '',
  postCategory: '',
  date: '',
  title: '',
  abstract: ''
};

export default Entry;
