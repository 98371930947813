import { Trans } from 'react-i18next';
import IconSlider from '@components/UIElements/IconsSlider';
import IconSliderBox from '@components/UIElements/IconSliderBox';
import { ReactComponent as BenefitIcon01 } from '@images/icons/benefits/01.svg';
import { ReactComponent as BenefitIcon02 } from '@images/icons/benefits/02.svg';
import { ReactComponent as BenefitIcon03 } from '@images/icons/benefits/03.svg';
import { ReactComponent as BenefitIcon04 } from '@images/icons/benefits/04.svg';

function BenefitsBelt() {
  const keyPrefix = 'screens:homeScreen.benfits';
  return (
    <IconSlider backgroundColor="bg-primary-20">
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head01`}>
            Imagestärkung <br /> durch Nachhaltigkeit
          </Trans>
        }
        icon={<BenefitIcon01 width="76px" height="76px" className="fill-current" />}
        delay="400"
      />
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head02`}>
            Erhöhte Medienpräsenz <br /> durch Bienenschutz
          </Trans>
        }
        icon={<BenefitIcon02 width="76px" height="76px" className="fill-current" />}
        delay="600"
      />
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head03`}>
            Authentischer Content <br /> für Marketing & PR
          </Trans>
        }
        icon={<BenefitIcon03 width="76px" height="76px" className="fill-current" />}
        delay="800"
      />
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head04`}>
            Vermittlung positiver <br /> Unternehmenswerte
          </Trans>
        }
        icon={<BenefitIcon04 width="76px" height="76px" className="fill-current" />}
        delay="1000"
      />
    </IconSlider>
  );
}

export default BenefitsBelt;
