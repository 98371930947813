import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import CompanyApplicationForm from '@components/Forms/CompanyApplicationForm';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import HeroPhoto from '@images/case-studies-screen/hero.jpg';
import HomeHeroGraph from '@images/more-bees-graph.svg';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import BenefitsBelt from './components/BenefitsBelt';
import Partners from './components/Partners';
import FavoriteCaseStudies from './components/FavoriteCaseStudies';
import GetYourBadge from './components/GetYourBadge';
import CommunicationSlider from './components/CommunicationSlider';
import OnlineCommunicationData from './online-communication-data';
import OfflineCommunicationData from './offline-communication-data';

function CaseStudiesScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'caseStudiesScreen' });
  return (
    <>
      <TitleAndDescription screenName="caseStudiesScreen" />
      <HeroWithSquarePhoto photo={HeroPhoto} alt="PROJEKT 2028-Unternehmen">
        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-primary font-bold leading-normal">
          {t('title')}
        </h1>
        <h2 className="text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {t('subtitle')}
        </h2>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44">{t('lead')}</div>
        <div className="my-8 md:my-16">
          <LazyLoad>
            <img
              src={HomeHeroGraph}
              alt={t('heroGraph')}
              className="w-[228px] md:w-[367px] h-[57px] md:h-[92px]"
            />
          </LazyLoad>
        </div>
      </HeroWithSquarePhoto>
      <BenefitsBelt />
      <CommunicationSlider title={t('onlineCommunication.title')} data={OnlineCommunicationData} />
      <Partners />
      <CommunicationSlider
        title={t('offlineCommunication.title')}
        data={OfflineCommunicationData}
      />
      <GetYourBadge />
      <FavoriteCaseStudies />
      <CompanyApplicationForm supportKind="p2028_case_studies" />
    </>
  );
}

export default CaseStudiesScreen;
