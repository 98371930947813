import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function CompanyPageBox({ title, lead, url, icon, headerBackground, children }) {
  return (
    <div className="w-full md:w-1/2 xl:w-1/3 px-3 mb-6">
      <div className="group transition-all duration-300 bg-white rounded-3xl shadow-ds16 h-full transform translate-y-0 hover:-translate-y-1 hover:shadow-ds24">
        <div className="flex flex-col justify-between h-full">
          <Link to={url} className="flex flex-col justify-between h-full">
            <div>
              <div
                className={`flex justify-between items-end p-4 rounded-t-3xl ${headerBackground}`}
              >
                <h2 className="text-lg md:text-2xl text-primary-120 font-bold">{title}</h2>
                <img
                  src={icon}
                  alt={title}
                  className="flex-shrink-0 ml-4 h-16 w-16 md:h-20 md:w-20"
                />
              </div>
              <div className="p-4 text-grey-30 text-sm">
                <div>{lead}</div>
                <ul className="mt-2">{children}</ul>
              </div>
            </div>
            <div className="flex justify-end p-4 mt-6">
              <ArrowRightIcon
                width="24"
                height="24"
                className="transition-all duration-300 text-primary stroke-current tranform -translate-x-2 group-hover:opacity-80 group-hover:translate-x-0"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CompanyPageBox;

CompanyPageBox.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  lead: PropTypes.string,
  headerBackground: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node
};

CompanyPageBox.defaultProps = {
  url: '',
  title: '',
  lead: '',
  headerBackground: '',
  icon: '',
  children: null
};
