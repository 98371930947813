import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@components/UIElements/Modal';
import { ABSOLUTE_ROOT_PATH } from '@constants';
import SharePhoto from '@images/blog-screen/share.png';
import { ReactComponent as HeartIcon } from '@images/blog-screen/heart.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { ReactComponent as FacebookIcon } from '@images/icons/social/facebook.svg';
import { ReactComponent as LinkedInIcon } from '@images/icons/social/linkedIn.svg';
import { ReactComponent as TwitterIcon } from '@images/icons/social/twitter.svg';
import { ReactComponent as WebsiteIcon } from '@images/icons/social/website.svg';

function Share() {
  const modal = useRef(null);
  const { t } = useTranslation('screens', { keyPrefix: 'postsScreen' });
  return (
    <section className="py-16">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="bg-primary-20 p-6 rounded-2xl">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full md:w-1/2 px-4">
              <div className="lg:px-14 lg:py-10">
                <div className="flex items-center text-primary text-base -ml-2">
                  <HeartIcon width="60" height="52" />
                  <span className="inline-block -mt-2">{t('shareSubtitle')}</span>
                </div>
                <h3 className="text-xl md:text-2xl font-bold text-grey-50">{t('shareTitle')}</h3>
                <p className="text-sm md:text-base text-grey-30 mt-4">{t('shareLead')}</p>
                <div className="mt-8">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center relative disabled:bg-grey-20 disabled:pointer-events-none bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-12 rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                    onClick={() => modal.current.open()}
                  >
                    {t('shareButton')}
                  </button>
                  <Modal ref={modal}>
                    <div className="bg-white rounded-2xl p-6 md:p-10 w-full max-w-[466px] max-h-[90vh] overflow-y-auto">
                      <div className="flex flex-col justify-center items-center">
                        <div>
                          <img src={SharePhoto} alt={t('shareSocialTitle')} width="260" />
                        </div>
                        <h3 className="text-center text-lg md:text-2xl text-grey-50 font-bold mt-6 mb-2">
                          {t('shareModalTitle')}
                        </h3>
                        <div className="mt-2 w-full text-center text-grey-30 font-bold text-base">
                          {t('shareModalBody')}
                        </div>
                        <div className="mt-4 w-full text-center text-grey-30 text-base">
                          {t('shareModalFooter')}
                        </div>
                        <div className="mt-8 text-grey-30 w-full text-center">
                          <div className="transition-colors duration-300 hover:text-primary border-t border-grey-10 py-4 w-full">
                            <FacebookShareButton
                              url={ABSOLUTE_ROOT_PATH}
                              quote={t('shareSocialBody')}
                              className="flex items-center justify-center w-full"
                            >
                              <FacebookIcon
                                width="24"
                                height="24"
                                className="fill-current flex-shrink-0"
                              />
                              <div className="ml-3">{t('shareFacebook')}</div>
                            </FacebookShareButton>
                          </div>
                          <div className="transition-colors duration-300 hover:text-primary border-t border-grey-10 py-4 w-full">
                            <TwitterShareButton
                              url={ABSOLUTE_ROOT_PATH}
                              title={t('shareSocialTitle')}
                              className="flex items-center justify-center w-full"
                            >
                              <TwitterIcon
                                width="24"
                                height="24"
                                className="fill-current flex-shrink-0"
                              />
                              <div className="ml-3">{t('shareTwitter')}</div>
                            </TwitterShareButton>
                          </div>
                          <div className="transition-colors duration-300 hover:text-primary border-t border-grey-10 py-4 w-full">
                            <LinkedinShareButton
                              url={ABSOLUTE_ROOT_PATH}
                              title={t('shareSocialTitle')}
                              summary={t('shareSocialBody')}
                              source={ABSOLUTE_ROOT_PATH}
                              className="flex items-center justify-center w-full"
                            >
                              <LinkedInIcon
                                width="24"
                                height="24"
                                className="fill-current flex-shrink-0"
                              />
                              <div className="ml-3">{t('shareLinkedin')}</div>
                            </LinkedinShareButton>
                          </div>
                          <div className="transition-colors duration-300 hover:text-primary border-t border-grey-10 py-4 w-full">
                            <EmailShareButton
                              url={ABSOLUTE_ROOT_PATH}
                              subject={t('shareSocialTitle')}
                              body={t('shareSocialBody')}
                              className="flex items-center justify-center w-full"
                            >
                              <WebsiteIcon
                                width="24"
                                height="24"
                                className="fill-current flex-shrink-0"
                              />
                              <div className="ml-3">{t('shareEmail')}</div>
                            </EmailShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <div className="px-14 pt-10 lg:pr-20">
                <img src={SharePhoto} alt={t('shareSubtitle')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Share;
