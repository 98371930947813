import LazyLoad from 'react-lazyload';
import { ReactComponent as DocumentDownloadIcon } from '@images/icons/document-download.svg';
import { useStores } from '@/stores';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import parse from 'html-react-parser';
import { ReactComponent as JPGIcon } from '@images/icons/files-type/jpg.svg';
import { ReactComponent as PNGIcon } from '@images/icons/files-type/png.svg';
import { ReactComponent as PDFIcon } from '@images/icons/files-type/pdf.svg';
import { ReactComponent as XLSXIcon } from '@images/icons/files-type/xlsx.svg';
import { ReactComponent as TIFFIcon } from '@images/icons/files-type/tiff.svg';
import { ReactComponent as PPTIcon } from '@images/icons/files-type/ppt.svg';
import { ReactComponent as AIIcon } from '@images/icons/files-type/ai.svg';
import { ReactComponent as DOCXIcon } from '@images/icons/files-type/docx.svg';
import { ReactComponent as MOVIcon } from '@images/icons/files-type/mov.svg';
import { ReactComponent as MP4Icon } from '@images/icons/files-type/mp4.svg';
import { ReactComponent as EPSIcon } from '@images/icons/files-type/eps.svg';
import { ReactComponent as EmptyIcon } from '@images/icons/files-type/empty.svg';

const filesExtension = {
  'image/jpeg': (
    <JPGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'image/jpg': (
    <JPGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'image/png': (
    <PNGIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'image/tiff': (
    <TIFFIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'application/pdf': (
    <PDFIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
    <XLSXIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'application/vnd.ms-powerpoint': (
    <PPTIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'image/ai': (
    <AIIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
    <DOCXIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'video/quicktime': (
    <MOVIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'video/mp4': (
    <MP4Icon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  'image/x-eps': (
    <EPSIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  ),
  null: (
    <EmptyIcon width="20" height="20" className="flex-shrink-0 text-grey-30 fill-current mr-2" />
  )
};

function ArticleScreen() {
  const {
    companyArticleStore,
    companyArticleStore: { record },
    companyFileStore,
    authStore: { user }
  } = useStores();
  const { articleSlug } = useParams();
  const [articleLoading, setArticleLoading] = useState(true);
  const modal = useRef(null);

  useEffect(() => {
    companyArticleStore.fetchOne(articleSlug).then(() => setArticleLoading(false));
  }, []);

  function fileCanBeDownloaded({ allowShowOnLimitedAccess, visibleForAll }) {
    return user.access === 'full_access' || allowShowOnLimitedAccess || visibleForAll;
  }

  function downloadFile(companyFile) {
    const { id, name } = companyFile;

    if (fileCanBeDownloaded(companyFile)) companyFileStore.downloadFile(id, name);
    else modal.current.open();
  }

  const companyFileDownloadIcon = (companyFile) => {
    return (
      <li key={companyFile.attachmentFileName} className="flex items-center my-2">
        <button
          className="outline-none focus:outline-none flex items-center hover:text-primary group"
          onClick={() => downloadFile(companyFile)}
          type="button"
        >
          <DocumentDownloadIcon
            width="24"
            height="24"
            className="transition-colors duration-300 text-grey-25 fill-current flex-shrink-0 mr-1 group-hover:text-primary"
          />
          {filesExtension[companyFile.attachmentContentType]}
          <div className="transition-colors duration-300 text-grey-50 text-sm md:text-base font-bold truncate group-hover:text-primary">
            {companyFile.attachmentFileName}
          </div>
        </button>
      </li>
    );
  };

  if (articleLoading) return <div />;

  return (
    <div>
      <section className="py-8 md:py-12 min-h-[90vh]">
        <div className="max-w-screen-md mx-auto px-4 2xl:px-14">
          <div className="flex items-center justify-between">
            <div className="md:text-grey-50 text-xs md:text-sm bg-green-40 py-1 px-2 rounded">
              {record.companyFileCategory.name}
            </div>
          </div>
          <h1 className="text-lg md:text-4xl text-grey-50 font-bold my-6 leading-normal">
            {record.title}
          </h1>
          <div className="aspect-w-16 aspect-h-9">
            <LazyLoad>
              <img
                src={record.backgroundImageUrl}
                alt={record.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </LazyLoad>
          </div>
          <div className="flex items-center py-6">
            <div className="relative h-6 w-6 rounded-full flex-shrink-0 mr-2">
              <LazyLoad>
                <img
                  src={record.author.avatarUrl}
                  alt="Avatar"
                  className="w-full h-full object-cover rounded-full absolute inset-0"
                />
              </LazyLoad>
            </div>
            <div className="text-grey-30 text-sm">{`${record.author.firstname} von Hektar Nektar`}</div>
          </div>
          <div className="text-grey-30 text-sm md:text-base text-entry">
            {parse(record.description)}
            <ul className="my-4">{record.companyFiles.map(companyFileDownloadIcon)}</ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default observer(ArticleScreen);
