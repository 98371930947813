import HomeScreen from '@screens/HomeScreen';
import AuthScreen from '@screens/AuthScreen';
import Layout from '@components/Layouts/BasicLayout';
import {
  AUTH_PATH,
  REMIND_PASSWORD_PATH,
  COMPANY_PORTAL_PATH,
  PROFILE_PATH,
  CASE_STUDIES_PATH,
  ROOT_PATH,
  COMPANY_PORTAL_BEEKEEPERS_PATH,
  COMPANY_PORTAL_BEEKEEPER_PATH,
  TIMELINE_NOTE_PATH,
  PARTNERS_PATH,
  COMPANY_PROFILE_PATH,
  HONEY_PROJECT_PATH,
  ABOUT_PATH,
  CONTACT_PATH,
  BEEKEEPER_PROFILE_PATH,
  CASE_STUDY_PATH,
  TERMS_OF_USE_PROJECT_2028_PORTAL_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_AND_CONDITIONS_PATH,
  ERROR_500_PATH,
  ERROR_422_PATH,
  CONFIRM_PASSWORD_PATH,
  PUBLIC_TIMELINE_NOTE_PATH,
  COMPANY_PORTAL_ARTICLE_PATH,
  PRESS_RELEASES_PATH,
  OLD_BEEKEEPER_PROFILE_PATH,
  COMAPNY_APPLICATION_FORM_DONE_PATH,
  CONTACT_FORM_DONE_PATH,
  POSTS_PATH,
  POST_PATH
} from '@constants';
import ProfileScreen from '@screens/ProfileScreen';
import AuthLayout from '@components/Layouts/AuthLayout';
import SmallFooterLayout from '@components/Layouts/SmallFooterLayout';
import ProtectedRoute from '@components/ScreenWrappers/ProtectedRoute';
import CaseStudiesScreen from '@screens/CaseStudiesScreen';
import CompanyPortalScreen from '@screens/CompanyPortal/CompanyPortalScreen';
import NoMatch from '@components/Layouts/NoMatch';
import CompanyPortalFilesScreen from '@screens/CompanyPortal/CompanyPortalFilesScreen';
import BeekeepersScreen from '@screens/CompanyPortal/Beekeepers/BeekeepersScreen';
import BeekeeperScreen from '@screens/CompanyPortal/Beekeepers/BeekeeperScreen';
import ArticleScreen from '@screens/CompanyPortal/ArticleScreen';
import PressReleaseScreen from '@screens/PressReleaseScreen';
import TimelineNoteScreen from '@screens/TimelineNoteScreen';
import PartnersScreen from '@screens/PartnersScreen';
import CompanyProfileScreen from '@screens/CompanyProfileScreen';
import HoneyProjectScreen from '@screens/HoneyProjectScreen';
import AboutScreen from '@screens/AboutScreen';
import ContactScreen from '@screens/ContactScreen';
import BeekeeperProfileScreen from '@screens/BeekeeperProfileScreen';
import CaseStudyScreen from '@screens/CaseStudyScreen';
import PostsScreen from '@screens/PostsScreen';
import PostScreen from '@screens/PostScreen';
import {
  DefaultBreadcrumb,
  DynamicTimelineBreadcrumb,
  DynamicBeekeeperBreadcrumb,
  PressReleasesBreadcrumb
} from '@components/UIElements/Breadcrumbs/BreadcrumbsFunctions';
import TermsOfUseProject2028PortalScreen from '@screens/TermsOfUseProject2028PortalScreen';
import CompanyApplicationFormDoneScreen from '@screens/CompanyApplicationFormDoneScreen';
import ContactFormDoneScreen from '@screens/ContactFormDoneScreen';
import PrivacyPolicyScreen from '@screens/PrivacyPolicyScreen';
import TermsAndConditionsScreen from '@screens/TermsAndConditionsScreen';
import RemindPasswordScreen from '@screens/PasswordReset/RemindPasswordScreen';
import Error500Screen from '@screens/Error500Screen';
import Error422Screen from '@screens/Error422Screen';
import ConfirmPasswordScreen from '@screens/PasswordReset/ConfirmPasswordScreen';
import { Navigate } from 'react-router-dom';
import RedirectRoute from '../components/ScreenWrappers/RedirectRoute';

const routes = [
  {
    path: ROOT_PATH,
    breadcrumb: null,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomeScreen />
      },
      {
        path: AUTH_PATH,
        element: <AuthScreen />
      },
      {
        path: CASE_STUDIES_PATH,
        element: <CaseStudiesScreen />
      },
      {
        path: PRESS_RELEASES_PATH,
        breadcrumb: PressReleasesBreadcrumb,
        props: { someProp: 'content-portal', customLink: COMPANY_PORTAL_PATH },
        children: [
          {
            path: ':slug',
            element: <ProtectedRoute component={<PressReleaseScreen />} />
          }
        ]
      },
      {
        path: PARTNERS_PATH,
        children: [
          {
            index: true,
            element: <PartnersScreen />
          },
          {
            path: PUBLIC_TIMELINE_NOTE_PATH,
            element: <TimelineNoteScreen />
          }
        ]
      },
      {
        path: HONEY_PROJECT_PATH,
        element: <HoneyProjectScreen />
      },
      {
        path: ABOUT_PATH,
        element: <AboutScreen />
      },
      {
        path: CONTACT_PATH,
        element: <ContactScreen />
      },
      {
        path: TERMS_OF_USE_PROJECT_2028_PORTAL_PATH,
        element: <TermsOfUseProject2028PortalScreen />
      },
      {
        path: COMAPNY_APPLICATION_FORM_DONE_PATH,
        element: <CompanyApplicationFormDoneScreen />
      },
      {
        path: CONTACT_FORM_DONE_PATH,
        element: <ContactFormDoneScreen />
      },
      {
        path: PRIVACY_POLICY_PATH,
        element: <PrivacyPolicyScreen />
      },
      {
        path: TERMS_AND_CONDITIONS_PATH,
        element: <TermsAndConditionsScreen />
      },
      {
        path: ERROR_500_PATH,
        element: <Error500Screen />
      },
      {
        path: ERROR_422_PATH,
        element: <Error422Screen />
      }
    ]
  },
  {
    path: COMPANY_PORTAL_PATH,
    element: <SmallFooterLayout />,
    children: [
      {
        index: true,
        breadcrumb: DefaultBreadcrumb,
        props: { someProp: 'content-portal' },
        element: <ProtectedRoute component={<CompanyPortalScreen />} />
      },
      {
        path: ':type',
        element: <ProtectedRoute component={<CompanyPortalFilesScreen />} />
      },
      {
        path: TIMELINE_NOTE_PATH,
        breadcrumb: DynamicTimelineBreadcrumb,
        element: <TimelineNoteScreen />
      },
      {
        path: COMPANY_PORTAL_BEEKEEPERS_PATH,
        children: [
          {
            index: true,
            breadcrumb: DefaultBreadcrumb,
            props: { someProp: 'beekeepers' },
            element: <ProtectedRoute component={<BeekeepersScreen />} />
          },
          {
            path: COMPANY_PORTAL_BEEKEEPER_PATH,
            breadcrumb: DynamicBeekeeperBreadcrumb,
            element: <ProtectedRoute component={<BeekeeperScreen />} />
          }
        ]
      },
      {
        path: COMPANY_PORTAL_ARTICLE_PATH,
        element: <ProtectedRoute component={<ArticleScreen />} />
      }
    ]
  },
  {
    path: PROFILE_PATH,
    element: <SmallFooterLayout />,
    children: [
      {
        path: PROFILE_PATH,
        element: <ProtectedRoute component={<ProfileScreen />} />
      }
    ]
  },
  {
    path: COMPANY_PROFILE_PATH,
    element: <Layout klass="breadcrumb-override" />,
    children: [
      {
        path: COMPANY_PROFILE_PATH,
        element: <CompanyProfileScreen />
      }
    ]
  },
  {
    path: BEEKEEPER_PROFILE_PATH,
    element: <Layout klass="breadcrumb-override" />,
    children: [
      {
        path: BEEKEEPER_PROFILE_PATH,
        element: <BeekeeperProfileScreen />
      }
    ]
  },
  {
    path: CASE_STUDY_PATH,
    element: <Layout klass="breadcrumb-override" />,
    children: [
      {
        path: CASE_STUDY_PATH,
        element: <CaseStudyScreen />
      }
    ]
  },
  {
    path: POSTS_PATH,
    element: <Layout klass="breadcrumb-override" />,
    children: [
      {
        path: POSTS_PATH,
        element: <PostsScreen />
      }
    ]
  },
  {
    path: POST_PATH,
    element: <Layout klass="breadcrumb-override" />,
    children: [
      {
        path: POST_PATH,
        element: <PostScreen />,
        breadcrumb: null
      }
    ]
  },
  {
    path: AUTH_PATH,
    element: <AuthLayout />,
    children: [
      {
        path: AUTH_PATH,
        element: <AuthScreen />
      }
    ]
  },
  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        path: REMIND_PASSWORD_PATH,
        element: <RemindPasswordScreen />
      },
      {
        path: CONFIRM_PASSWORD_PATH,
        element: <ConfirmPasswordScreen />
      }
    ]
  },
  { path: '*', element: <NoMatch /> },
  { path: '/company-portal/', element: <Navigate to="/content-portal" /> },
  { path: '/company-portal/imkerinnen/', element: <Navigate to="/content-portal/imkerinnen/" /> },
  {
    path: '/company-portal/*',
    element: <Navigate to="/content-portal" />
  },

  /// Redirects
  {
    path: OLD_BEEKEEPER_PROFILE_PATH,
    element: <RedirectRoute path={BEEKEEPER_PROFILE_PATH} attributesToPass={['beekeeperSlug']} />
  }
];

export default routes;
