import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SkeletonEventsFeed() {
  return (
    <div className="rounded-2xl bg-white ">
      <div className="rounded-t-2xl px-4 py-4 h-80">
        <h3 className="text-white text-lg md:text-2xl font-bold h-40">
          <Skeleton height={30} />
        </h3>
      </div>
      <div className="p-4 ">
        <div className="mt-3">
          <Skeleton />
        </div>
        <h4 className="text-primary-120 text-sm uppercase font-bold mb-3">
          <Skeleton />
        </h4>
        <div>
          <Skeleton />
        </div>
      </div>
    </div>
  );
}

export default SkeletonEventsFeed;
