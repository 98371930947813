import api from '@api';
import Qs from 'qs';

export default function setup({ authStore }) {
  function assignToken({ headers }) {
    if (headers['access-token']) {
      authStore.assignToken(headers);
    }
  }

  api.interceptors.request.use((config) => {
    const newConfig = config;
    const { token } = authStore;

    newConfig.paramsSerializer = (params) =>
      Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

    if (!token) return newConfig;

    newConfig.headers['access-token'] = token.accessToken;
    newConfig.headers.client = token.client;
    newConfig.headers.uid = token.uid;

    return newConfig;
  });

  api.interceptors.response.use(
    (response) => {
      assignToken(response);

      return response;
    },
    (error) => {
      assignToken(error.response);

      return Promise.reject(error);
    }
  );
}
