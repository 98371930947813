import IconSlider from '@components/UIElements/IconsSlider';
import IconSliderBox from '@components/UIElements/IconSliderBox';
import { Trans } from 'react-i18next';
import { ReactComponent as BenefitIcon01 } from '@images/icons/benefits/05.svg';
import { ReactComponent as BenefitIcon02 } from '@images/icons/benefits/02.svg';
import { ReactComponent as BenefitIcon03 } from '@images/icons/benefits/06.svg';

function BenefitsBelt() {
  const keyPrefix = 'screens:caseStudiesScreen.benfits';
  return (
    <IconSlider backgroundColor="bg-primary-20">
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head01`}>
            Postings unserer Bienenschutz-Partner in sozialen Medien <br /> = Likes-Garantie und
            begeisterte Follower
          </Trans>
        }
        icon={<BenefitIcon01 width="76px" height="76px" className="fill-current" />}
        delay="400"
      />
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head02`}>
            Die Biene ist ein Social Media Star <br /> = Likes und Follower für Ihre Marke
          </Trans>
        }
        icon={<BenefitIcon02 width="76px" height="76px" className="fill-current" />}
        delay="600"
      />
      <IconSliderBox
        head={
          <Trans i18nKey={`${keyPrefix}.head03`}>
            Die Presse liebt PROJEKT 2028 <br /> = Clippings für unsere Partnerunternehmen
          </Trans>
        }
        icon={<BenefitIcon03 width="76px" height="76px" className="fill-current" />}
        delay="800"
      />
    </IconSlider>
  );
}

export default BenefitsBelt;
