import React from 'react';
import { Trans } from 'react-i18next';
import i18n from '@config/i18n';
// hooks outside main component will provide this error: Rendered more hooks than during the previous render

export const translateAttribute = (attribute, model) => {
  return i18n.t(`common:attributes.${model}.${attribute}`);
};

export const validationMessages = (attribute, model, validation, messageValue = {}) => {
  const customValidation = () => validation === 'validate';

  const translatedMessage = () => {
    if (!validation) return '';
    if (customValidation()) {
      return `common:validationMessages.${model}.${attribute}.${validation}`;
    }

    return `common:validationMessages.${validation}`;
  };

  return (
    <Trans values={{ field: translateAttribute(attribute, model), messageValue }}>
      {translatedMessage()}
    </Trans>
  );
};

export const errorLabel = (attribute, model, error) => {
  if (!error) return '';

  return validationMessages(attribute, model, error.type, error.message);
};
