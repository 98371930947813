import Logo01 from '@images/case-studies-screen/favorities/logos/01.png';
import Logo02 from '@images/case-studies-screen/favorities/logos/02.png';
import Logo03 from '@images/case-studies-screen/favorities/logos/03.png';
import Logo05 from '@images/case-studies-screen/favorities/logos/05.png';
import Logo06 from '@images/case-studies-screen/favorities/logos/06.png';
import Logo07 from '@images/case-studies-screen/favorities/logos/07.png';
import Logo08 from '@images/case-studies-screen/favorities/logos/08.png';
import Logo09 from '@images/case-studies-screen/favorities/logos/09.png';
import Logo10 from '@images/case-studies-screen/favorities/logos/10.png';
import Logo11 from '@images/case-studies-screen/favorities/logos/11.png';
import WeledaFacebook from '@images/case-studies-screen/online-communication/facebook/weleda.png';
import MetroFacebook from '@images/case-studies-screen/online-communication/facebook/metro.png';
import ThinkFacebook from '@images/case-studies-screen/online-communication/facebook/think.png';
import TakkoFacebook from '@images/case-studies-screen/online-communication/facebook/takko.png';
import EYYouTube from '@images/case-studies-screen/online-communication/youtube/ey.png';
import AgasaatYouTube from '@images/case-studies-screen/online-communication/youtube/agasaat.png';
import RfbankYouTube from '@images/case-studies-screen/online-communication/youtube/rfbank.png';
import WeledaWebsite from '@images/case-studies-screen/online-communication/website/weleda.png';
import MetroWebsite from '@images/case-studies-screen/online-communication/website/metro.png';
import ThinkWebsite from '@images/case-studies-screen/online-communication/website/think.png';
import RfBankWebsite from '@images/case-studies-screen/online-communication/website/rfbank.png';
import HertzWebsite from '@images/case-studies-screen/online-communication/website/hertz.png';
import AgasaatWebsite from '@images/case-studies-screen/online-communication/website/agasaat.png';
import CheWowWebsite from '@images/case-studies-screen/online-communication/website/chewow.png';
import PezWebsite from '@images/case-studies-screen/online-communication/website/pez.png';

const OnlineCommunicationData = [
  {
    name: 'Facebook',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Bienen in der Hertz Unternehmensbroschüre',
        lead: 'Bereits seit 2019 ist Weleda ein Teil von PROJEKT 2028. Als weltweit führende Herstellerin von ganzheitlichen Naturkosmetikprodukten und Arzneimitteln für die anthroposophische Therapierichtung ist Weleda Nachhaltigkeit und Artenschutz ein besonderes Anliegen...',
        url: 'weleda',
        logo: <Logo11 />,
        photo: <WeledaFacebook />,
        backgroundColor: 'text-red-40'
      },
      {
        head: 'Social Media Kommunikation als Image-Boost',
        lead: 'Metro Österreich, mit 2.100 Beschäftigten in 12 Großmärkten auf 140.000 Quadratmetern im ganzen Land vertreten, ist seit 2019 Teil von PROJEKT 2028. Zum Auftakt sowie im Verlauf der Kooperation nutzte Metro intensiv seine Social Media Kanäle wie etwa Facebook...',
        url: 'metro',
        logo: <Logo02 />,
        photo: <MetroFacebook />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'PROJEKT-Imkerin als Testimonial',
        lead: 'Think!, der nachhaltige Schuhproduzent aus Oberösterreich, hat seine unterstützte Imkerin Laura als Testimonial in die Frühjahr-/Sommerkampagne 2021 integriert. Die gesamte Kampagne dreht sich um die Biene, die Imkerei und Laura im Mittelpunkt...',
        url: 'think',
        logo: <Logo05 />,
        photo: <ThinkFacebook />,
        backgroundColor: 'text-primary-40'
      },
      {
        head: 'Ein*e Imker*in für jedes Bundesland',
        lead: 'TAKKO Fashion, mit über 1.100 Stores in Deutschland und rund 18.000 Mitarbeitern einer der größten Fashion-Discounter in Europa, engagiert sich seit 2020 in PROJEKT 2028. Mit der Unterstützung von je einem/einer Imker*in für jedes deutsche Bundesland ...',
        url: 'takko-fashion',
        logo: <Logo03 />,
        photo: <TakkoFacebook />,
        backgroundColor: 'text-orange-40'
      }
    ]
  },
  {
    name: 'YouTube',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Eine Biene zu Gast bei EY',
        lead: 'Eine Biene in Videoform zu Besuch im Büro von EY Österreich schafft Aufmerksamkeit für die Partnerschaft und das Thema Bienenschutz. 850.000 Bienen mehr gibt es dank der Unterstützung von EY für das PROJEKT 2028. Erreicht wurde dies durch 8 unterstütze Imker*innen und 4.200 Honig-Giveaways...',
        url: 'ey',
        logo: <Logo08 />,
        photo: <EYYouTube />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'Video als Marketing-Tool',
        lead: 'Das Maishandelsunternehmen Agasaat unterstützt PROJEKT 2028 seit Beginn und nützt dieses Engagement intensiv für die externe Kommunikation. Die Bedeutung der Bienen für die Landwirtschaft kann nicht hoch genug eingeschätzt werden und Agasaat kommuniziert diese Wichtigkeit gezielt nach außen...',
        url: 'agasaat',
        logo: <Logo07 />,
        photo: <AgasaatYouTube />,
        backgroundColor: 'text-orange-40'
      },
      {
        head: 'Info-Video zum Tag der Biene',
        lead: 'Die Raiffeisenlandesbank Niederösterreich-Wien mit 1.146 Mitarbeiter*innen und über einer Million betreuten Kund*innen ist seit 2019 PROJEKT 2028-Partner. Mit Wurzeln im agrarischen Bereich setzt sie sich vielfältig für regionale, nachhaltige Produktion und eine Sicherung ländlicher Räume ein...',
        url: 'raiffeisen',
        logo: <Logo10 />,
        photo: <RfbankYouTube />,
        backgroundColor: 'text-primary-40'
      }
    ]
  },
  {
    name: 'Website',
    itemsToShowNumber: 4,
    items: [
      {
        head: 'Landingpage für den Bienenschutz',
        lead: 'Mit einer eigenen Landingpage rund um die Biene und das Engagement in Sachen Bienenschutz informiert Weleda auch über die Unterstützung von PROJEKT 2028. Die Besucher*innen erfahren alles über die Kooperation und die Beweggründe für die Beteiligung an PROJEKT 2028...',
        url: 'weleda',
        logo: <Logo11 />,
        photo: <WeledaWebsite />,
        backgroundColor: 'text-orange-40'
      },
      {
        head: 'Bienenschutz für die Pressearbeit',
        lead: 'Die Kooperation mit PROJEKT 2028 und damit ein Engagement im Bienenschutz eignet sich hervorragend für die Pressearbeit. Metro konnte mit einer Presseaussendung zum Kooperationsstart umfangreiche Coverage erzielen...',
        url: 'metro',
        logo: <Logo02 />,
        photo: <MetroWebsite />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'Landingpage als PROJEKT-Begleitung',
        lead: 'Think! zeigt sein Engagement für den Bienenschutz auch auf seiner Unternehmensseite. Eine eigene Landingpage unterstützt die Kampagne rund um Laura und klärt den Hintergrund zu PROJEKT 2028 auf. Somit stellt die Unterstützung von PROJEKT 2028...',
        url: 'think',
        logo: <Logo05 />,
        photo: <ThinkWebsite />,
        backgroundColor: 'text-primary-40'
      },
      {
        head: 'Landingpage als Informationsträger',
        lead: 'Um das Engagement für den Bienenschutz auch auf der Website publik zu machen, hat Raiffeisen NÖ-Wien eine eigene Landingpage erstellt. Auf dieser erfahren User*innen alles über die Beteiligung an PROJEKT 2028 und die Notwendigkeit des Einsatzes für Bienen...',
        url: 'raiffeisen',
        logo: <Logo10 />,
        photo: <RfBankWebsite />,
        backgroundColor: 'text-orange-40'
      },
      {
        head: 'Bienen in der Hertz Unternehmensbroschüre',
        lead: '250.000 neue Mitarbeiter*innen in den Hertz-Unternehmensfarben gelb und schwarz summen auf dem Gelände des Hertz Classics Flagship Stores. Das Engagement in PROJEKT 2028 und damit die Ansiedlung von fünf Bienenvölkern in den Weinbergen von Gumpoldskirchen war für Hertz Austria...',
        url: 'Hertz',
        logo: <Logo01 />,
        photo: <HertzWebsite />,
        backgroundColor: 'text-green-40'
      },
      {
        head: 'Rund-um-Information über Website',
        lead: 'Agasaat kommuniziert seine Unterstützung für PROJEKT 2028 auch über seine Social Media Kanäle sowie über gezielte Pressearbeit. Auf der Website des Unternehmens findet man zudem eine ausführliche Kooperationsbeschreibung und bewährte Tipps für den Bienenschutz in der Landwirtschaft...',
        url: 'agasaat',
        logo: <Logo07 />,
        photo: <AgasaatWebsite />,
        backgroundColor: 'text-orange-40'
      },
      {
        head: 'Hohe Markenglaubwürdigkeit durch Artenschutz',
        lead: 'Chewow hat sich ganz auf die Produktion pflanzlicher „Fleischalternativen“ spezialisiert. Ob Nugg*ts aus Ackerbohnen oder Gyr*s aus Kürbiskernmehl – für diesen Genuss muss kein Tier sterben. Um auch gegen das Bienensterben anzugehen, unterstützt Chewow PROJEKT 2028...',
        url: 'chewow',
        logo: <Logo09 />,
        photo: <CheWowWebsite />,
        backgroundColor: 'text-primary-40'
      },
      {
        head: 'Landingpage für Aufmerksamkeit und Information',
        lead: 'Maximale Aufmerksamkeit für das Engagement in PROJEKT 2028 sichert PEZ mit einer eigenen Landingpage. Unter #jedebienezählt erfahren Besucher*innen alles rund um den Einsatz von PEZ in Sachen Nachhaltigkeit und Artenschutz und warum Bienenschutz von so großer Bedeutung ist...',
        url: 'pez',
        logo: <Logo06 />,
        photo: <PezWebsite />,
        backgroundColor: 'text-red-40'
      }
    ]
  }
];

export default OnlineCommunicationData;
