import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { parseBool } from '@tools';
import CompanyQuestions from '@screens/ProfileScreen/components/CompanyProfileForm/components/CompanyQuestions';
import ProfilePhotosGallery from '@screens/ProfileScreen/components/CompanyProfileForm/components/ProfilePhotosGallery';
import SocialLinks from '@screens/ProfileScreen/components/CompanyProfileForm/components/SocialLinks';
import CompanyInformation from '@screens/ProfileScreen/components/CompanyProfileForm/components/CompanyInformation';
import ContactPersonInformation from '@screens/ProfileScreen/components/CompanyProfileForm/components/ContactPersonInformation';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';

function CompanyProfileForm() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm'
  });
  const {
    authStore,
    authStore: { livingAddressStore },
    profileQuestionStore,
    profileAnswerStore
  } = useStores();
  const { setAlert } = useAlert();

  const profileAnswers = () => {
    return (
      profileQuestionStore.companyProfileQuestions.map((questionRecord) => {
        const answer = profileAnswerStore.collection.find(
          (answerRecord) => answerRecord.profileQuestionId === questionRecord.id
        );

        if (parseBool(answer))
          return {
            id: answer.id,
            userId: authStore.user.id,
            profileQuestionId: questionRecord.id,
            text: answer.text
          };

        return { userId: authStore.user.id, profileQuestionId: questionRecord.id, text: '' };
      }) || []
    );
  };

  function defaultValues() {
    const { user, userLivingAddress } = authStore;

    return {
      companyName: user.companyName || '',
      companyMotto: user.companyMotto || '',
      roleInCompany: user.roleInCompany || '',
      name: user.name || '',
      websiteUrl: user.websiteUrl || '',
      facebookUrl: user.facebookUrl || '',
      productsUrl: user.productsUrl || '',
      instagramUrl: user.instagramUrl || '',
      careerUrl: user.careerUrl || '',
      youtubeUrl: user.youtubeUrl || '',
      xingUrl: user.xingUrl || '',
      linkedinUrl: user.linkedinUrl || '',
      kununuUrl: user.kununuUrl || '',
      twitterUrl: user.twitterUrl || '',
      address: {
        address: userLivingAddress.address || '',
        city: userLivingAddress.city || '',
        regionId: userLivingAddress.regionId || '',
        zipcode: userLivingAddress.zipcode || '',
        countryId: userLivingAddress.countryId || ''
      },
      profileAnswersAttributes: profileAnswers()
    };
  }
  const formMethods = useForm({ mode: 'onChange', defaultValues: defaultValues() });
  const {
    reset,
    handleSubmit,
    formState: { isDirty }
  } = formMethods;

  async function submitForm(data) {
    const addressParams = data.address;
    const userParams = _.omit(data, 'address');

    await authStore.update(userParams);
    await livingAddressStore.update(authStore.userLivingAddress.id, addressParams);

    reset({}, { keepValues: true });
    setAlert({ open: true, message: t('formSaved') });
  }

  const saveCancelBar = () => {
    if (!isDirty) return <div />;

    return (
      <div className="fixed z-50 bottom-0 left-0 w-full bg-grey-50">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex justify-between items-center py-3 md:py-0 md:h-[73px]">
            <div className="text-white text-xs md:text-base">{t('settingsUnsaved')}</div>
            <div className="flex">
              <div className="ml-3 md:ml-6">
                <button
                  type="button"
                  className="inline-flex items-center justify-center relative bg-white bg-opacity-10 hover:bg-opacity-20 text-xs md:text-base text-white border-0 m-0 py-2 px-4 md:px-14 w-auto rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                  onClick={() => reset(defaultValues())}
                >
                  {t('cancelChanges')}
                </button>
              </div>
              <div className="ml-3 md:ml-6">
                <button
                  type="submit"
                  className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-xs md:text-base text-white border-0 m-0 py-2 px-4 md:px-14 w-auto rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                >
                  {t('saveChanges')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div data-cy="sign-in-form">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitForm)}>
          {saveCancelBar()}
          <CompanyInformation />

          <CompanyQuestions />

          <ContactPersonInformation />

          <ProfilePhotosGallery />

          <SocialLinks />
        </form>
      </FormProvider>
    </div>
  );
}

export default observer(CompanyProfileForm);
