import { makeAutoObservable, runInAction } from 'mobx';
import { profileAnswerEndpoint } from '@config/endpoints';

class ProfileAnswerStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
    });
  }

  bindMethods() {
    this.assignProfileAnswers = this.assignProfileAnswers.bind(this);
  }

  fetchAll(params) {
    return profileAnswerEndpoint.all(params).then(this.assignProfileAnswers);
  }

  // eslint-disable-next-line class-methods-use-this
  create(params) {
    return profileAnswerEndpoint.create(params);
  }

  // eslint-disable-next-line class-methods-use-this
  update(id, params) {
    return profileAnswerEndpoint.update(id, { profileAnswer: params });
  }

  assignProfileAnswers(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default ProfileAnswerStore;
