import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import _ from 'lodash';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import SkeletonPRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact/Skeleton';

function CompanyPortalFileScreenSkeleton() {
  return (
    <>
      <section className="my-3">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <div className="rounded-2xl h-full flex justify-between items-end">
                <Skeleton
                  containerClassName="w-full h-full"
                  width="100%"
                  className="h-24 md:h-32"
                  borderRadius={20}
                />
              </div>
            </div>
            <div className="hidden lg:block w-full lg:w-4/12 xl:w-1/4 px-3">
              <SkeletonPRContact />
            </div>
          </div>
          <div className="mt-4 mb-10">
            <div className="flex items-center">
              <Skeleton height={24} width={32} borderRadius={20} className="react-switch" />
              <div className="text-sm text-grey-30 mt-1 ml-4 w-full">
                <Skeleton width={200} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-6 mb-4">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex md:flex-row-reverse flex-wrap -mx-3">
            <div className="w-full lg:w-4/12 xl:w-1/4 px-3">
              <div className="lg:sticky lg:top-4">
                <h3 className="text-2xl text-grey-50 font-bold mb-8 hidden lg:block">
                  <Skeleton />
                </h3>
                <ul className="hidden md:flex lg:block items-center space-x-4 md:space-x-0 overflow-x-auto no-scrollbar mb-4 -mx-4 lg:mx-0 pl-4">
                  {_.times(3, () => (
                    <li className="text-sm md:text-base flex-shrink-0">
                      <div className="block lg:text-left outline-none  px-6 lg:px-4 border-b-2 lg:border-b-0 lg:border-l-4">
                        <Skeleton height={30} />
                      </div>
                    </li>
                  ))}
                </ul>
                <Skeleton width="100%" height={30} containerClassName="mb-4 block md:hidden" />
              </div>
            </div>
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-4 border-b border-grey-15 hidden md:block">
                <Skeleton width="20%" />
              </h2>
              <div className="w-full">
                <ResponsiveMasonry
                  gutter="16"
                  columnsCountBreakPoints={{ 350: 1, 767: 2, 1280: 3 }}
                >
                  <Masonry>
                    {_.times(6, () => (
                      <div className="px-3 my-3">
                        <div className="p-4 bg-white rounded-2xl flex flex-col h-full justify-between">
                          <Skeleton className="mb-1" height={180} />
                          <div className="text-sm md:text-base text-grey-50 font-bold">
                            <div className="flex flex-col">
                              <Skeleton width="30%" className="mb-1" />
                              <Skeleton width="40%" className="mb-1" />
                              <Skeleton width="80%" className="mb-1" />
                              <Skeleton height={60} className="mb-1" />
                            </div>
                          </div>
                          <div className="flex items-center justify-between mt-6">
                            <div className="outline-none focus:outline-none">
                              <Skeleton
                                width={24}
                                height={24}
                                className="transition-colors duration-300 text-grey-25 fill-current"
                              />
                            </div>
                            <div className="flex items-center">
                              <div className="relative h-6 w-6 rounded-full flex-shrink-0 mr-2">
                                <div>
                                  <div className="w-full h-full object-cover rounded-full absolute inset-0">
                                    <Skeleton borderRadius={25} height={25} />
                                  </div>
                                </div>
                              </div>
                              <div className="text-grey-30 text-sm mt-2">
                                <Skeleton width={100} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
              <div className="my-6 flex justify-center">
                <Skeleton />
              </div>
              <div className="mb-4 lg:hidden">
                <SkeletonPRContact />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CompanyPortalFileScreenSkeleton;
