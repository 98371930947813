import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { COMPANY_PROFILE_PATH } from '@constants';
import CompanyProfileForm from '@screens/ProfileScreen/components/CompanyProfileForm';
import { useEffect, useState } from 'react';
import ProfileScreenSkeleton from '@screens/ProfileScreen/components/Skeleton';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

function ProfileScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'profileScreen' });
  const [addressFetched, setAddressFetched] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [regionsFetched, setRegionsFetched] = useState(false);
  const [profileAnswersFetched, setProfileAnswersFetched] = useState(false);

  const {
    authStore: { user, livingAddressStore },
    countryStore,
    regionStore,
    profileQuestionStore,
    profileAnswerStore,
    profilePhotoStore
  } = useStores();

  useEffect(() => {
    livingAddressStore.fetch(user.livingAddressId).then(() => setAddressFetched(true));
    profileQuestionStore.fetchCompanyProfileQuestions().then(() => {
      profileAnswerStore
        .fetchAll({
          q: {
            userIdEq: user.id,
            questionIdIn: profileQuestionStore.companyProfileQuestions.map(({ id }) => id)
          }
        })
        .then(() => setProfileAnswersFetched(true));
    });
    countryStore
      .fetchAll({ q: { codeIn: ['AT', 'DE', 'CH'] } })
      .then(() => setCountriesFetched(true));
    regionStore.fetchAll().then(() => setRegionsFetched(true));
    profilePhotoStore.fetchAll({ q: { userIdEq: user.id }, sortBy: 'created_at asc' });
  }, []);

  return (
    (addressFetched && profileAnswersFetched && countriesFetched && regionsFetched && (
      <>
        <TitleAndDescription screenName="profileScreen" />
        <section className="bg-grey-5 py-4 md:py-14 relative">
          <div className="max-w-screen-lg mx-auto px-4 2xl:px-14">
            <div className="border-b border-grey-15 mb-6 md:mb-10">
              <div className="flex flex-wrap justify-between items-center pb-4">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <h1 className="text-grey-50 text-2xl md:text-4xl font-bold">{t('title')}</h1>
                  <span
                    className={`${
                      user.profileVisible ? 'bg-green-40' : 'bg-grey-10'
                    } text-grey-50 text-sm px-4 md:px-6 py-0.5 rounded-xl flex-shrink-0 ml-4`}
                  >
                    {t(`visibleStatus.${user.profileVisible}`)}
                  </span>
                </div>
                <div className="w-full md:w-auto mt-4 md:mt-0 flex justify-end">
                  <Link
                    to={generatePath(COMPANY_PROFILE_PATH, { companySlug: user.slug })}
                    className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-14 w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300 disabled:bg-grey-20 disabled:pointer-events-none"
                    disabled={!user.profileVisible}
                  >
                    {t('profileButton')}
                  </Link>
                </div>
              </div>
            </div>
            <CompanyProfileForm />
          </div>
        </section>
      </>
    )) || <ProfileScreenSkeleton />
  );
}

export default observer(ProfileScreen);
