import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROOT_PATH } from '@constants';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';

function ProtectedRoute({ component }) {
  const {
    authStore: { isAuthenticated }
  } = useStores();
  const location = useLocation();

  if (!isAuthenticated && location.pathname !== ROOT_PATH) {
    return <Navigate to={ROOT_PATH} replace />;
  }

  return component;
}

ProtectedRoute.propTypes = {
  component: PropTypes.element.isRequired
};

export default observer(ProtectedRoute);
