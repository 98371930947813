import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as FacebookIcon } from '@images/icons/social/facebook.svg';
import { ReactComponent as LinkedInIcon } from '@images/icons/social/linkedIn.svg';
import { ReactComponent as TwitterIcon } from '@images/icons/social/twitter.svg';
import { ReactComponent as WebsiteIcon } from '@images/icons/social/website.svg';

function SocialShare({ url, title, body }) {
  const { t } = useTranslation('components', { keyPrefix: 'uiElements.socialShare' });
  return (
    <>
      <ReactTooltip effect="solid" backgroundColor="#272F3E" arrowColor="#272F3E" />
      <div
        data-tip={t('shareOnFacebookTooltip')}
        className="transition-colors duration-300 hover:text-primary"
      >
        <FacebookShareButton url={url} quote={body}>
          <FacebookIcon width="24" height="24" className="fill-current" />
        </FacebookShareButton>
      </div>
      <div
        data-tip={t('shareOnLinkedInTooltip')}
        className="transition-colors duration-300 hover:text-primary"
      >
        <LinkedinShareButton url={url} title={title} summary={body} source={url}>
          <LinkedInIcon width="24" height="24" className="fill-current" />
        </LinkedinShareButton>
      </div>
      <div
        data-tip={t('shareOnTwitterTooltip')}
        className="transition-colors duration-300 hover:text-primary"
      >
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon width="24" height="24" className="fill-current" />
        </TwitterShareButton>
      </div>
      <div
        data-tip={t('shareOnEmailTooltip')}
        className="transition-colors duration-300 hover:text-primary"
      >
        <EmailShareButton url={url} subject={title} body={body}>
          <WebsiteIcon width="24" height="24" className="fill-current" />
        </EmailShareButton>
      </div>
    </>
  );
}

export default SocialShare;

SocialShare.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string
};

SocialShare.defaultProps = {
  url: '',
  title: '',
  body: ''
};
