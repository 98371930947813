import { makeAutoObservable, runInAction } from 'mobx';
import { caseStudyEndpoint } from '@config/endpoints';

class CaseStudyStore {
  constructor() {
    this.collection = [];
    this.record = {};
    makeAutoObservable(this);
  }

  fetchAll(params) {
    this.fetched = false;
    return caseStudyEndpoint.all(params).then((response) => {
      runInAction(() => {
        this.collection = response.data.data.map((record) => record.attributes);
      });
    });
  }

  fetchOne(id) {
    return caseStudyEndpoint.one(id).then((response) => {
      runInAction(() => {
        this.record = response.data.data;
      });
    });
  }
}

export default CaseStudyStore;
