import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import { useEffect, useState } from 'react';

function CompanyQuestions() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm.components.companyQuestions'
  });
  const {
    profileQuestionStore,
    authStore: { user }
  } = useStores();
  const {
    control,
    register,
    getValues,
    watch,
    formState: { errors }
  } = useFormContext();
  const [companyMottoLength, setCompanyMottoLength] = useState(getValues('companyMotto').length);

  useEffect(() => setCompanyMottoLength(getValues('companyMotto').length), [watch('companyMotto')]);

  const companyProfileQuestion = (question, index) => {
    const questionName = `profileAnswersAttributes.${index}.text`;
    const error = errors.profileAnswersAttributes?.[index]
      ? errorLabel('companyMotto', 'user', errors.profileAnswersAttributes[index].text)
      : false;

    useEffect(() => {}, [watch(questionName)]);

    return (
      <div className="mb-4 md:mb-8 flex flex-wrap" key={question.id}>
        <input
          type="hidden"
          {...register(`profileAnswersAttributes.${index}.userId`)}
          value={user.id}
        />
        <input
          type="hidden"
          {...register(`profileAnswersAttributes.${index}.profileQuestionId`)}
          value={question.id}
        />
        <Controller
          name={questionName}
          control={control}
          rules={{ minLength: { value: 70, message: 70 } }}
          render={({ field, ref }) => (
            <Textarea
              {...field}
              label={question.text}
              error={error}
              ref={ref}
              rows="5"
              placeholder=""
            />
          )}
        />
        <div className="text-xs text-grey-50">
          {t('charactersLength')} {getValues(questionName).length}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
      <div className="pb-4 mb-4 border-b border-grey-10">
        <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">{t('title')}</h2>
        <p className="text-sm md:text-base text-grey-30 max-w-2xl">{t('subtitle')}</p>
      </div>
      <div>{profileQuestionStore.companyProfileQuestions.map(companyProfileQuestion)}</div>
      <div className="mb-4 md:mb-8 flex flex-wrap">
        <Controller
          name="companyMotto"
          control={control}
          rules={{ minLength: { value: 70, message: 70 } }}
          render={({ field, ref }) => (
            <Textarea
              {...field}
              placeholder=""
              error={errorLabel('companyMotto', 'user', errors.companyMotto)}
              label={translateAttribute('companyMotto', 'user')}
              ref={ref}
              rows="3"
            />
          )}
        />
        <div className="text-xs text-grey-50">
          {t('charactersLength')} {companyMottoLength}
        </div>
      </div>
    </div>
  );
}

export default observer(CompanyQuestions);
