import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import OurStoryPhoto01 from '@images/about/our-story-01.jpg';
import OurStoryPhoto02 from '@images/about/our-story-02.jpg';
import { ReactComponent as NextArrowIcon } from '@images/icons/next.svg';
import { ReactComponent as PrevArrowIcon } from '@images/icons/prev.svg';
import 'slick-carousel/slick/slick.css';

function NextArrow({ onClick }) {
  return (
    <button
      type="button"
      className="hidden outline-none focus:outline-none text-grey-50 w-8 h-8 rounded-full md:flex items-center justify-center absolute z-10 left-20 mt-4 -ml-2 top-full carousel-arrow"
      onClick={onClick}
    >
      <NextArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

NextArrow.propTypes = {
  onClick: PropTypes.func
};

NextArrow.defaultProps = {
  onClick: () => {}
};

function PrevArrow({ onClick }) {
  return (
    <button
      type="button"
      className="hidden outline-none focus:outline-none w-8 h-8 rounded-full md:flex items-center justify-center absolute z-10 -left-2 mt-4 top-full carousel-arrow"
      onClick={onClick}
    >
      <PrevArrowIcon width="16" height="16" className="stroke-current" />
    </button>
  );
}

PrevArrow.propTypes = {
  onClick: PropTypes.func
};

PrevArrow.defaultProps = {
  onClick: () => {}
};

function OurStory01() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'aboutScreen.components.ourStory01'
  });
  const [carouselBoxesNav, setCarousleBoxesNav] = useState(null);
  const [carouselImagesNav, setCarouselImagesNav] = useState(null);
  const carouselBoxes = useRef(null);
  const carouselLogos = useRef(null);

  useEffect(() => {
    setCarousleBoxesNav(carouselBoxes.current);
    setCarouselImagesNav(carouselLogos.current);
  }, []);

  const carouselImagesSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    arrows: false,
    autoplay: false,
    fade: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true
        }
      }
    ]
  };

  const carouselBoxesSettings = {
    dots: true,
    arrows: true,
    speed: 2000,
    fade: true,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      }
    ]
  };

  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex md:flex-row-reverse flex-wrap items-center -mx-3">
          <div className="w-full md:w-1/2 px-3">
            <div className="lg:pl-20 xl:pr-20 md:py-4">
              <span className="block text-primary text-sm md:text-base font-bold uppercase">
                {t('subheader')}
              </span>
              <div className="slider-combine-dots-and-arrows">
                <Slider asNavFor={carouselImagesNav} ref={carouselBoxes} {...carouselBoxesSettings}>
                  <div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('box01.header')}
                    </h2>
                    <div className="text-sm md:text-base text-grey-30 mt-4">{t('box01.lead')}</div>
                  </div>
                  <div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                      {t('box02.header')}
                    </h2>
                    <div className="text-sm md:text-base text-grey-30 mt-4">{t('box02.lead')}</div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <div className="slider-dots-center slider-dots-no-dots-from-tablet">
              <Slider asNavFor={carouselBoxesNav} ref={carouselLogos} {...carouselImagesSettings}>
                <div>
                  <img src={OurStoryPhoto01} alt={t('box01.header')} />
                </div>
                <div className="lg:pt-24">
                  <img src={OurStoryPhoto02} alt={t('box02.header')} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurStory01;
