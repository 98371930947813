import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { generatePath, Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { COMPANY_PORTAL_BEEKEEPER_PATH } from '@constants';
import PRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact';
import BeekeeperIcon from '@images/icons/company-pages/beekeeper.svg';
import { ReactComponent as EmailIcon } from '@images/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '@images/icons/phone.svg';
import { ReactComponent as HomeIcon } from '@images/icons/home.svg';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';
import { socialLinks } from '@config/directories';
import SocialLink from '@components/UIElements/SocialLink';
import BeekeepersSkeletonLoadingScreen from '@screens/CompanyPortal/Beekeepers/BeekeepersScreen/components/Skeleton';
import Header from './components/Header';

function BeekeepersScreen() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.beekeepers.beekeepersScreen'
  });
  const [fetched, setFetched] = useState(false);
  const {
    authStore: { user, beekeeperStore }
  } = useStores();

  useEffect(() => {
    beekeeperStore
      .fetchAll({
        q: { idIn: user.beekeeperIds.concat(-1), parentCompanyDiscardedAtNull: true }
      })
      .then(() => setFetched(true));
  }, []);

  if (!fetched) return <BeekeepersSkeletonLoadingScreen />;
  return (
    <>
      <section className="my-4 mb-4">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <Header title={t('title')} icon={BeekeeperIcon} />
            </div>
            <div className="w-full lg:w-4/12 xl:w-1/4 px-3 hidden lg:block">
              <PRContact />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <div className="pb-6">
                <div className="flex flex-wrap -mx-3">
                  {beekeeperStore.collection.map((beekeeper) => (
                    <div key={beekeeper.id} className="w-full md:w-1/2 xl:w-1/3 px-3 my-3">
                      <div className="transition-all duration-300 bg-white rounded-2xl shadow-ds16 h-full transform translate-y-0 hover:-translate-y-1 hover:shadow-ds24">
                        <div className="rounded-t-2xl bg-yellow-40">
                          <Link
                            className="flex items-end justify-between p-4"
                            to={generatePath(COMPANY_PORTAL_BEEKEEPER_PATH, {
                              beekeeperSlug: beekeeper.slug
                            })}
                          >
                            <h3 className="text-lg md:text-2xl text-primary-120 font-bold mb-1 md:mb-3">
                              {beekeeper.name}
                            </h3>
                            <div className="w-16 h-16 border-2 border-white rounded-full relative flex-shrink-0">
                              <LazyLoad>
                                <img
                                  src={beekeeper.avatar}
                                  alt={beekeeper.name}
                                  className="w-full h-full object-cover absolute inset-0 rounded-full"
                                />
                              </LazyLoad>
                            </div>
                          </Link>
                        </div>
                        <div className="p-4">
                          <div className="flex items-center justify-between">
                            <div className="text-xs text-primary">
                              {t('beekeeperSince', {
                                date: new Date(beekeeper.beekeeperSince).getFullYear()
                              })}
                            </div>
                            <ul className="flex items-center">
                              {socialLinks.map((element) => (
                                <SocialLink
                                  key={element.name}
                                  beekeeper={beekeeper}
                                  iconElement={element}
                                />
                              ))}
                            </ul>
                          </div>
                          <div className="text-grey-30 text-sm mt-4">
                            {beekeeper.livingAddress?.phone && (
                              <div className="flex items-center my-2">
                                <PhoneIcon
                                  width="16"
                                  height="16"
                                  className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                                />
                                <div className="truncate">{beekeeper.livingAddress.phone}</div>
                              </div>
                            )}
                            {beekeeper?.email && (
                              <div className="flex items-center my-2">
                                <EmailIcon
                                  width="16"
                                  height="16"
                                  className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                                />
                                <div className="truncate">{beekeeper.email}</div>
                              </div>
                            )}
                            {beekeeper.livingAddress?.city && (
                              <div className="flex items-center my-2">
                                <HomeIcon
                                  width="16"
                                  height="16"
                                  className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                                />
                                <div className="truncate">
                                  {beekeeper.livingAddress.addressWithHouseNumber}
                                  {', '}
                                  {beekeeper.livingAddress.zipcode} {beekeeper.livingAddress.city}
                                  {', '}
                                  {beekeeper.livingAddress.country.code}
                                </div>
                              </div>
                            )}
                            <div className="flex justify-end p-4 mt-6">
                              <Link
                                to={generatePath(COMPANY_PORTAL_BEEKEEPER_PATH, {
                                  beekeeperSlug: beekeeper.slug
                                })}
                                className="transition-all duration-300 text-primary hover:opacity-80 hover:translate-x-2"
                              >
                                <ArrowRightIcon width="24" height="24" className="stroke-current" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:hidden mb-6">
                <PRContact />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default observer(BeekeepersScreen);
