import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import _ from 'lodash';

function ProfileScreenSkeleton() {
  return (
    <section className="bg-grey-5 py-4 md:py-14">
      <div className="max-w-screen-lg mx-auto px-4 2xl:px-14">
        <div className="border-b border-grey-15 mb-6 md:mb-10">
          <div className="flex flex-wrap justify-between items-center pb-4 ">
            <div className="flex items-center justify-between w-4/5 md:w-2/5 ">
              <h1 className="text-grey-50 text-2xl md:text-4xl w-full font-bold">
                <Skeleton width="100%" />
              </h1>
              <Skeleton className="text-grey-50 text-sm px-4 md:px-6 py-0.5 rounded-xl w-full flex-shrink-0 ml-4" />
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0 flex justify-end">
              <Skeleton
                width={179}
                className=" sm:right inline-flex items-center justify-center relative  text-white border-0 m-0 py-2 px-14 w-auto rounded outline-none box-border "
              />
            </div>
          </div>
        </div>
        <div data-cy="sign-in-form">
          <div>
            <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
              <div className="pb-4 mb-4 border-b border-grey-10">
                <h2 className="text-lg md:text-2xl sm:w-1/2 md:w-2/6 text-grey-50 font-bold mb-2">
                  <Skeleton width="70%" />
                </h2>
                <p className="text-sm md:text-base w-5/6 md:w-2/6 text-grey-30 max-w-2xl">
                  <Skeleton width="100%" />
                </p>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/2 px-3">
                  <div className="mb-4 md:mb-8">
                    <Skeleton width="30%" className="mb-1" />
                    <Skeleton width="100%" height={35} />
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full md:w-1/2 px-3">
                      <div className="mb-4 md:mb-8">
                        <Skeleton width="30%" className="mb-1" />
                        <Skeleton width="100%" height={37} />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="mb-4 md:mb-2">
                        <Skeleton width="40%" className="mb-1" />
                        <Skeleton width="100%" height={37} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full md:w-1/2 px-3">
                      <div className="mb-4 md:mb-8">
                        <Skeleton width="40%" className="mb-1" />
                        <Skeleton width="100%" height={37} />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="mb-4 md:mb-8">
                        <Skeleton width="40%" className="mb-1" />
                        <Skeleton width="100%" height={37} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <div className="mb-4 md:mb-0">
                        <Skeleton width="20%" className="mb-1" />
                        <Skeleton width="100%" height={37} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <span className="input-label">
                    <Skeleton width="30%" className="mb-1" />
                    <Skeleton width="100%" height={190} />
                  </span>
                  <div />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
              <div className="pb-4 mb-4 border-b border-grey-10">
                <h2 className="text-lg md:text-2xl text-grey-50 font-bold">
                  <Skeleton width="45%" />
                </h2>
                <p className="text-sm md:text-base text-grey-30 max-w-2xl">
                  <Skeleton width="40%" />
                </p>
              </div>
              <div>
                {_.times(3, () => (
                  <div className="mb-4 md:mb-12 flex flex-wrap w-full">
                    <div className="text-xs text-grey-50 w-full">
                      <Skeleton width="50%" height={16} className="mb-1" />
                      <Skeleton width="100%" height={120} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
              <div className="pb-4 mb-4 border-b border-grey-10">
                <h2 className="text-lg md:text-2xl text-grey-50 font-bold">
                  <Skeleton width="40%" />
                </h2>
                <p className="text-sm md:text-base text-grey-30 max-w-2xl">
                  <Skeleton width="60%" />
                </p>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 px-4">
                  <div className="mb-4 md:mb-8">
                    <Skeleton width="40%" className="mb-1" />
                    <Skeleton width="100%" height={39} />
                  </div>
                  <div className="mb-4 md:mb-8">
                    <Skeleton width="40%" className="mb-1" />
                    <Skeleton width="100%" height={39} />
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4">
                  <div className="flex flex-col md:ml-36 md:items-center">
                    <div className="input-label inline-block mb-1 w-full">
                      <Skeleton width="65%" />
                    </div>
                    <div />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-2xl px-6 pt-6 pb-2 mb-6 md:mb-10">
              <div className="pb-4 mb-4 border-b border-grey-10">
                <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">
                  <Skeleton width="20%" />
                </h2>
                <p className="text-sm md:text-base text-grey-30 max-w-2xl">
                  <Skeleton height={50} />
                </p>
              </div>
              <div className="flex flex-wrap -mx-3">
                {_.times(3, () => (
                  <div className="w-full md:w-1/2 px-3">
                    <div className="text-sm text-grey-50 font-medium mb-1">
                      <Skeleton width="20%" />
                    </div>
                    <div className="relative group mb-4">
                      <div className="aspect-w-16 aspect-h-10 rounded-lg">
                        <div className="object-cover rounded-lg">
                          <Skeleton height={194} />
                        </div>
                      </div>
                      <div className="absolute inset-0 rounded-lg opacity-0 " />
                    </div>
                  </div>
                ))}
                <div className="w-full md:w-1/2 px-3">
                  <div className="mb-3">
                    <div className="text-sm text-grey-50 font-medium mb-1">
                      <Skeleton width="40%" />
                    </div>
                    <div className="aspect-w-16 aspect-h-10 rounded-lg">
                      <div>
                        <div>
                          <Skeleton height={194} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-6">
              <div className="pb-4 mb-4 border-b border-grey-10">
                <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">
                  <Skeleton width="50%" />
                </h2>
                <p className="text-sm md:text-base text-grey-30 max-w-2xl">
                  <Skeleton width="60%" />
                </p>
              </div>
              <div className="flex flex-wrap -mx-4">
                {_.times(10, () => (
                  <div className="w-full md:w-1/2 px-4 mb-4 md:mb-8">
                    <Skeleton width="40%" className="mb-1" />
                    <Skeleton height={39} />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end mt-8">
              <div className="inline-flex items-center justify-center relative  border-0 m-0 py-2 w-auto rounded box-border ">
                <Skeleton width={190} height={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ProfileScreenSkeleton;
