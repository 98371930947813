import { ReactComponent as Hex01 } from '@images/hexes/hex-01.svg';
import { ReactComponent as Hex02 } from '@images/hexes/hex-03.svg';

function HexDecoration() {
  return (
    <>
      <div className="hidden lg:block absolute ml-16 top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-green pointer-events-none">
        <Hex01 className="fill-current opacity-30" width="494px" height="494px" />
      </div>
      <div className="hidden lg:block absolute top-1/2 left-16 transform -translate-x-1/2 -translate-y-1/4 -mr-6 -mt-6 text-primary pointer-events-none">
        <Hex01 className="fill-current opacity-30" width="460px" height="460px" />
      </div>
      <div className="hidden xl:block absolute top-8 left-full transform -ml-28 text-orange pointer-events-none">
        <Hex02 className="fill-current opacity-30 h-full" width="573px" height="573px" />
      </div>
    </>
  );
}

export default HexDecoration;
