import { generatePath, Link } from 'react-router-dom';
import {
  COMPANY_PORTAL_FILES_PATH,
  COMPANY_PORTAL_BEEKEEPERS_PATH,
  TERMS_OF_USE_PROJECT_2028_PORTAL_PATH
} from '@constants';
import { useTranslation } from 'react-i18next';
import CompanyActivityFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/CompanyActivityFeed';
import PressReleasesFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/PressReleasesFeed';
import CompanyProfileLogosFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/CompanyProfileLogosFeed';
import EventsFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/EventsFeed';
import CompanyPageBox from '@screens/CompanyPortal/CompanyPortalScreen/components/CompanyPageBox';
import PRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact';
import CompanyStatus from '@screens/CompanyPortal/CompanyPortalScreen/components/CompanyStatus';
import { ReactComponent as CheckIcon } from '@images/icons/check.svg';
import SocialMediaIcon from '@images/icons/company-pages/social-media.svg';
import PRIcon from '@images/icons/company-pages/pr.svg';
import Project2028Icon from '@images/icons/company-pages/projekt-2028.svg';
import CSRIcon from '@images/icons/company-pages/csr.svg';
import EmployerBrandingIcon from '@images/icons/company-pages/employer-branding.svg';
import BeekeeperIcon from '@images/icons/company-pages/beekeeper.svg';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';

const companyFileTypes = {
  socialMedia: {
    key: 'social_media',
    listItems: 3,
    headerBackground: 'bg-primary-40',
    icon: <SocialMediaIcon />
  },
  pr: {
    key: 'pr',
    listItems: 3,
    headerBackground: 'bg-green-40',
    icon: <PRIcon />
  },
  project2028: {
    key: 'projekt_2028',
    listItems: 2,
    headerBackground: 'bg-orange-40',
    icon: <Project2028Icon />
  },
  employerBranding: {
    key: 'employer_branding',
    listItems: 3,
    headerBackground: 'bg-complimentary-20',
    icon: <EmployerBrandingIcon />
  },
  csr: {
    key: 'csr',
    listItems: 3,
    headerBackground: 'bg-red-40',
    icon: <CSRIcon />
  }
};

function CompanyPortalScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'companyPortal.companyPortalScreen' });

  const companyFileBoxes = () => {
    return Object.keys(companyFileTypes).map((key) => (
      <CompanyPageBox
        key={key}
        title={t(`companyPages.${key}.header`)}
        lead={t(`companyPages.${key}.lead`)}
        headerBackground={companyFileTypes[key].headerBackground}
        icon={companyFileTypes[key].icon.type}
        url={generatePath(COMPANY_PORTAL_FILES_PATH, {
          type: companyFileTypes[key].key
        })}
      >
        {[...Array(companyFileTypes[key].listItems)].map((elem, index) => {
          const idx = index;
          return (
            <li key={idx + 1} className="flex py-0.5">
              <CheckIcon width="16" height="16" className="flex-shrink-0 mr-2 mt-0.5" />
              {t(`companyPages.${key}.list.0${index + 1}`)}
            </li>
          );
        })}
      </CompanyPageBox>
    ));
  };
  return (
    <>
      <TitleAndDescription screenName="companyPortal.companyPortalScreen" />
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <section className="py-10">
          <h1 className="text-primary text-2xl md:text-4xl font-bold mb-2">{t('title')}</h1>
          <p className="text-grey-30 text-sm mb-2">{t('subtitle')}</p>
          <Link to={TERMS_OF_USE_PROJECT_2028_PORTAL_PATH} className="text-sm text-primary">
            {t('tosLink')}
          </Link>
        </section>
        <div className="w-full">
          <div className="lg:hidden mb-10">
            <CompanyStatus />
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
              <section className="py-2">
                <div className="flex flex-wrap -mx-3">
                  {companyFileBoxes()}
                  <CompanyPageBox
                    title={t('companyPages.beekeepers.header')}
                    lead={t('companyPages.beekeepers.lead')}
                    headerBackground="bg-yellow-40"
                    icon={BeekeeperIcon}
                    url={COMPANY_PORTAL_BEEKEEPERS_PATH}
                  >
                    <li className="flex py-0.5">
                      <CheckIcon width="16" height="16" className="flex-shrink-0 mr-2 mt-0.5" />
                      {t('companyPages.beekeepers.list.01')}
                    </li>
                    <li className="flex py-0.5">
                      <CheckIcon width="16" height="16" className="flex-shrink-0 mr-2 mt-0.5" />
                      {t('companyPages.beekeepers.list.02')}
                    </li>
                    <li className="flex py-0.5">
                      <CheckIcon width="16" height="16" className="flex-shrink-0 mr-2 mt-0.5" />
                      {t('companyPages.beekeepers.list.03')}
                    </li>
                  </CompanyPageBox>
                </div>
              </section>
              <CompanyActivityFeed />
              <PressReleasesFeed />
              <div className="hidden">
                <CompanyProfileLogosFeed />
              </div>
            </div>
            <div className="w-full lg:w-4/12 xl:w-1/4 px-3 py-2">
              <div className="hidden lg:block">
                <CompanyStatus />
              </div>
              <div className="mt-6">
                <EventsFeed />
              </div>
              <div className="my-6">
                <PRContact />
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
    </>
  );
}

export default CompanyPortalScreen;
