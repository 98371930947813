import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import CookieBanner from '@components/UIElements/CookieBanner';

function Layout() {
  const {
    authStore: { tokenChecked }
  } = useStores();

  if (tokenChecked)
    return (
      <div>
        <Outlet />
        <CookieBanner />
      </div>
    );

  return <div />;
}

export default observer(Layout);
