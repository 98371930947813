import PropTypes from 'prop-types';

function Header({ title, icon }) {
  return (
    <div className="bg-yellow-40 p-4 rounded-2xl h-full flex justify-between items-end">
      <h1 className="text-lg md:text-4xl text-primary-120 font-bold">{title}</h1>
      <img src={icon} alt={title} className="flex-shrink-0 ml-4 h-16 w-16 md:h-24 md:w-24" />
    </div>
  );
}

export default Header;

Header.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
};

Header.defaultProps = {
  title: '',
  icon: ''
};
