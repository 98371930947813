import { useTranslation, Trans } from 'react-i18next';
import Slider from 'react-slick';
import ImpactBoxIcon01 from '@images/impact/zero-hunger.png';
import ImpactBoxIcon02 from '@images/impact/gender-equality.png';
import ImpactBoxIcon03 from '@images/impact/decent-work.png';
import ImpactBoxIcon04 from '@images/impact/responsible-consumption.png';
import ImpactBoxIcon05 from '@images/impact/climate-action.png';
import ImpactBoxIcon06 from '@images/impact/life-on-land.png';
import ImpactBox from './components/ImpactBox';
import 'slick-carousel/slick/slick.css';

const IMPACT_SLIDES = [
  {
    id: 1,
    icon: <ImpactBoxIcon01 />
  },
  {
    id: 2,
    icon: <ImpactBoxIcon02 />
  },
  {
    id: 3,
    icon: <ImpactBoxIcon03 />
  },
  {
    id: 4,
    icon: <ImpactBoxIcon04 />
  },
  {
    id: 5,
    icon: <ImpactBoxIcon05 />
  },
  {
    id: 6,
    icon: <ImpactBoxIcon06 />
  }
];

function Impact() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.impact' });
  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 800,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: true,
          centerPadding: '7%'
        }
      }
    ]
  };
  return (
    <section className="bg-green-20 py-10 md:py-14 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="md:text-center">
          <span className="block text-primary text-sm md:text-base font-bold uppercase">
            {t('subheader')}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 break-words">
            <Trans i18nKey="screens:homeScreen.impact.header">
              PROJEKT 2028 in Ihrer
              <span className="block md:inline hyphens-auto"> Nachhaltigkeitskommunikation</span>
            </Trans>
          </h2>
          <div className="text-sm md:text-base text-grey-30 mt-4 max-w-xl mr-8 md:mr-auto md:mx-auto">
            {t('lead')}
          </div>
        </div>
      </div>
      <div className="max-w-[1680px] mx-auto">
        <div className="mt-6 sm:mt-10 sm:mb-10 relative sm:h-[355px] impact-slides-box">
          <Slider {...sliderSettings} className="sm:hidden">
            {IMPACT_SLIDES.map((item) => {
              return (
                <div key={item.id} className="impact-box">
                  <ImpactBox
                    head={t(`boxes.box0${item.id}.head`)}
                    lead={t(`boxes.box0${item.id}.lead`)}
                    icon={item.icon.type}
                  />
                </div>
              );
            })}
          </Slider>
          <div className="hidden sm:flex sm:justify-between sm:items-center sm:shrink-0 impact-slides">
            {IMPACT_SLIDES.map((item) => {
              return (
                <div key={item.id} className="impact-box">
                  <ImpactBox
                    head={t(`boxes.box0${item.id}.head`)}
                    lead={t(`boxes.box0${item.id}.lead`)}
                    icon={item.icon.type}
                  />
                </div>
              );
            })}
            {IMPACT_SLIDES.map((item) => {
              return (
                <div key={item.id} className="impact-box">
                  <ImpactBox
                    head={t(`boxes.box0${item.id}.head`)}
                    lead={t(`boxes.box0${item.id}.lead`)}
                    icon={item.icon.type}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Impact;
