import { makeAutoObservable, runInAction } from 'mobx';
import { caseStudyCategoryEndpoint } from '@config/endpoints';

class CaseStudyStore {
  constructor() {
    this.collection = [];
    makeAutoObservable(this);
  }

  fetchAll(params) {
    this.fetched = false;
    return caseStudyCategoryEndpoint.all(params).then((response) => {
      runInAction(() => {
        this.collection = response.data.data.map((record) => record.attributes);
      });
    });
  }
}

export default CaseStudyStore;
