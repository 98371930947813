import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import PartnerLogo01 from '@images/partners/01.png';
import PartnerLogo02 from '@images/partners/02.png';
import PartnerLogo03 from '@images/partners/03.png';
import PartnerLogo04 from '@images/partners/04.png';
import PartnerLogo05 from '@images/partners/05.png';
import PartnerLogo06 from '@images/partners/06.png';
import PartnerLogo07 from '@images/partners/07.png';
import PartnerLogo08 from '@images/partners/08.png';
import PartnerLogo09 from '@images/partners/09.png';
import PartnerLogo10 from '@images/partners/10.png';
import PartnerLogo11 from '@images/partners/11.png';
import PartnerLogo12 from '@images/partners/12.png';
import Photo1 from '@images/partners/photo-1.jpg';
import Photo2 from '@images/partners/photo-2.jpg';
import Photo3 from '@images/partners/photo-3.jpg';
import Photo4 from '@images/partners/photo-4.jpg';
import Photo5 from '@images/partners/photo-5.jpg';
import Photo6 from '@images/partners/photo-6.jpg';
import Photo7 from '@images/partners/photo-7.jpg';
import Photo8 from '@images/partners/photo-8.jpg';
import Photo9 from '@images/partners/photo-9.jpg';
import Photo10 from '@images/partners/photo-10.jpg';
import Photo11 from '@images/partners/photo-11.jpg';
import Photo12 from '@images/partners/photo-12.jpg';
import Person1 from '@images/partners/person-1.jpg';
import Person2 from '@images/partners/person-2.jpg';
import Person3 from '@images/partners/person-3.jpg';
import Person4 from '@images/partners/person-4.jpg';
import Person5 from '@images/partners/person-5.jpg';
import Person6 from '@images/partners/person-6.jpg';
import Person7 from '@images/partners/person-7.jpg';
import Person8 from '@images/partners/person-8.jpg';
import Person9 from '@images/partners/person-9.jpg';
import Person10 from '@images/partners/person-10.jpg';
import Person11 from '@images/partners/person-11.jpg';
import Person12 from '@images/partners/person-12.jpg';
import { ReactComponent as AvatarMask } from '@images/user-hex-info-max.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right.svg';
import 'slick-carousel/slick/slick.css';

const PARTNERS = [
  {
    id: 1,
    logo: <PartnerLogo01 />,
    photo: <Photo1 />,
    person: <Person1 />,
    color: 'text-primary-60'
  },
  {
    id: 2,
    logo: <PartnerLogo02 />,
    photo: <Photo2 />,
    person: <Person2 />,
    color: 'text-green-60'
  },
  {
    id: 3,
    logo: <PartnerLogo03 />,
    photo: <Photo3 />,
    person: <Person3 />,
    color: 'text-orange-60'
  },
  {
    id: 4,
    logo: <PartnerLogo04 />,
    photo: <Photo4 />,
    person: <Person4 />,
    color: 'text-primary-60'
  },
  {
    id: 5,
    logo: <PartnerLogo05 />,
    photo: <Photo5 />,
    person: <Person5 />,
    color: 'text-green-60'
  },
  {
    id: 6,
    logo: <PartnerLogo06 />,
    photo: <Photo6 />,
    person: <Person6 />,
    color: 'text-orange-60'
  },
  {
    id: 7,
    logo: <PartnerLogo07 />,
    photo: <Photo7 />,
    person: <Person7 />,
    color: 'text-primary-60'
  },
  {
    id: 8,
    logo: <PartnerLogo08 />,
    photo: <Photo8 />,
    person: <Person8 />,
    color: 'text-green-60'
  },
  {
    id: 9,
    logo: <PartnerLogo09 />,
    photo: <Photo9 />,
    person: <Person9 />,
    color: 'text-orange-60'
  },
  {
    id: 10,
    logo: <PartnerLogo10 />,
    photo: <Photo10 />,
    person: <Person10 />,
    color: 'text-primary-60'
  },
  {
    id: 11,
    logo: <PartnerLogo11 />,
    photo: <Photo11 />,
    person: <Person11 />,
    color: 'text-green-60'
  },
  {
    id: 12,
    logo: <PartnerLogo12 />,
    photo: <Photo12 />,
    person: <Person12 />,
    color: 'text-orange-60'
  }
];

function Partners() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'honeyProjectScreen.components.partners'
  });
  const [carouselBoxesNav, setCarousleBoxesNav] = useState(null);
  const [carouselLogosNav, setCarouselLogosNav] = useState(null);
  const carouselBoxes = useRef(null);
  const carouselLogos = useRef(null);

  useEffect(() => {
    setCarousleBoxesNav(carouselBoxes.current);
    setCarouselLogosNav(carouselLogos.current);
  }, []);

  const carouselLogosSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  };

  const carouselBoxesSettings = {
    dots: false,
    arrows: false,
    speed: 1500,
    fade: true
  };

  return (
    <section className="bg-orange-20 pt-10 md:pt-12 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/3 px-4">
            <span className="block text-primary text-sm md:text-base font-bold uppercase">
              {t('subheader')}
            </span>
            <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
              {t('header')}
            </h2>
            <div className="text-sm md:text-base text-grey-30 mt-4 xl:pr-12">{t('lead')}</div>
          </div>
          <div className="w-full lg:w-2/3 px-4 mt-8 lg:mt-0">
            <div className="bg-white shadow-ds8 rounded-2xl p-4 md:p-8 slider-dots-no-dots">
              <Slider asNavFor={carouselLogosNav} ref={carouselBoxes} {...carouselBoxesSettings}>
                {PARTNERS.map((partner, index) => {
                  return (
                    <div key={partner.id}>
                      <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-5/12 px-4">
                          <div className="aspect-w-16 aspect-h-13 rounded-xl overflow-hidden">
                            <img
                              src={partner.photo.type}
                              alt={t(`boxes.box${index}.company`) || ''}
                              className="rounded-xl object-cover"
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-7/12 px-4">
                          <div className="flex flex-col justify-between h-full py-4">
                            <div className="h-full">
                              <div className="flex items-center pl-[12%] md:pl-[33%] pt-4 md:pt-0 transform">
                                <div className={`relative flex-shrink-0 mr-3 ${partner.color}`}>
                                  <AvatarMaskBack
                                    width="118px"
                                    height="100px"
                                    className="fill-current absolute -top-1 -left-3"
                                  />
                                  <img
                                    src={partner.person.type}
                                    alt={t(`boxes.box${index}.name`)}
                                    className="rounded-xl object-cover avatar-mask-medium"
                                    width="120px"
                                    height="120px"
                                  />
                                </div>
                                <div className="transform -translate-y-1">
                                  <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                                    {t(`boxes.box${index}.name`)}
                                  </h3>
                                  <div className="text-grey-50 text-xs md:text-sm">
                                    {t(`boxes.box${index}.position`)}
                                  </div>
                                </div>
                              </div>
                              <div className="text-center text-grey-30 text-xs md:text-sm mt-4 md:mt-4 xl:px-6">
                                {t(`boxes.box${index}.quote`)}
                                <span className="inline-block ml-2">
                                  <a
                                    href={t(`boxes.box${index}.url`)}
                                    className="text-primary inline-flex items-center group font-medium"
                                  >
                                    {t('partnerLink')}
                                    <ArrowRight
                                      width="16px"
                                      height="15px"
                                      className="fill-current stroke-current flex-shrink-0 ml-2 transition-transform duration-300 transform group-hover:translate-x-2"
                                    />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <AvatarMask width="0" height="0" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-16 lg:mt-8">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          <div className="relative py-6 lg:py-10">
            <div className="bg-primary-0 absolute top-0 md:left-5 h-full transform scale-125 origin-bottom-right logo-backdrop" />
            <Slider asNavFor={carouselBoxesNav} ref={carouselLogos} {...carouselLogosSettings}>
              {PARTNERS.map((partner, index) => {
                return (
                  <div key={partner.id} className="flex items-center justify-center py-4 px-2">
                    <img
                      src={partner.logo.type}
                      alt={t(`boxes.box${index}.company`) || ''}
                      width="160px"
                      height="56px"
                      className="mx-auto grayscale logo"
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;
