import PropTypes from 'prop-types';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

function MenuItem({ path, label, onClick }) {
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <li>
      <NavLink
        onClick={onClick}
        to={path}
        className={`block lg:inline-block px-2 lg:px-0 py-2 lg:py-0 rounded lg:rounded-none transition-colors ease-in-out duration-300 hover:text-primary${
          match ? ' text-primary' : ' text-grey-30'
        }`}
      >
        {label}
      </NavLink>
    </li>
  );
}

export default MenuItem;

MenuItem.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

MenuItem.defaultProps = {
  path: '',
  label: '',
  onClick: () => {}
};
