import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ContactPersonPhoto from '@images/company-portal/pr-contact-person.jpg';

function PRContact() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.PRContact'
  });
  return (
    <div className="p-4 bg-primary-120 rounded-2xl">
      <h3 className="text-white text-lg md:text-2xl font-bold">{t('title')}</h3>
      <div className="flex items-center mt-2">
        <div className="relative overflow-hidden w-16 h-16 rounded-full border-2 border-primary flex-shrink-0 mr-4">
          <LazyLoad>
            <img
              src={ContactPersonPhoto}
              alt={t('title')}
              className="object-cover w-full h-full absolute inset-0"
            />
          </LazyLoad>
        </div>
        <div className="flex flex-col">
          <a className="inline-block my-1 text-white text-sm hover:underline" href={t('email')}>
            {t('email')}
          </a>
          <a
            className="inline-block my-1 text-white text-sm hover:underline"
            href={t('phoneClickable')}
          >
            {t('phone')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default PRContact;
