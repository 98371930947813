import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { generatePath, useParams } from 'react-router-dom';
import { useStores } from '@/stores';
import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import GLightbox from 'glightbox';
import PRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact';
import TimelineNote from '@components/UIElements/TimelineNote';
import { ReactComponent as EmailIcon } from '@images/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '@images/icons/phone.svg';
import { ReactComponent as HomeIcon } from '@images/icons/home.svg';
import { ReactComponent as DocumentDownloadIcon } from '@images/icons/document-download.svg';
import { ReactComponent as EyeIcon } from '@images/icons/eye.svg';
import BeekeeperAvatarPlaceholder from '@images/beekeeper-avatar-placeholder.png';
import 'slick-carousel/slick/slick.css';
import { socialLinks } from '@config/directories';
import SocialLink from '@components/UIElements/SocialLink';
import 'glightbox/dist/css/glightbox.min.css';
import BeekeeperScreenSkeleton from '@screens/CompanyPortal/Beekeepers/BeekeeperScreen/components/Skeleton';
import { TIMELINE_NOTE_PATH } from '@constants';

function BeekeeperScreen() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.beekeepers.beekeeperScreen'
  });
  const { beekeeperSlug } = useParams();
  const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(null);
  const [beekeeperLoading, setBeekeeperLoading] = useState(true);

  const {
    authStore: { user, beekeeperStore }
  } = useStores();

  useEffect(() => {
    beekeeperStore.cleanCollection();
    beekeeperStore.fetchOne(beekeeperSlug, user.id).then(() => setBeekeeperLoading(false));
  }, []);

  const allPhotos = () => {
    return [
      {
        photo: beekeeperStore.record.avatarOriginal,
        caption: user.name,
        thumbnail: beekeeperStore.record.avatar
      },
      ...beekeeperStore.record.profilePhotos.map((photo) => ({
        photo: photo.standardUrl,
        caption: beekeeperStore.record.name,
        thumbnail: photo.thumbHorizontalUrl
      })),
      ...beekeeperStore.record.attachments.map((attachment) => ({
        photo: attachment.url,
        caption: beekeeperStore.record.name,
        thumbnail: attachment.thumbnail
      }))
    ];
  };

  function downloadAllFiles() {
    beekeeperStore.downloadAllPhotos(
      beekeeperStore.record.id,
      `${beekeeperStore.record.name}_photos.zip`
    );
  }

  const handleOnMouseDown = (e) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const lightBoxImages = () => allPhotos().map((image) => ({ href: image.photo, type: 'image' }));

  const handleOpenLightbox = (e, i) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      const offerGallery = GLightbox({
        startAt: i,
        elements: lightBoxImages(),
        touchNavigation: true,
        dragAutoSnap: true,
        draggable: false,
        loop: true,
        skin: 'clean hn'
      });
      offerGallery.open();
    }
  };

  const handleOpenLightboxFromStart = () => {
    const offerGallery = GLightbox({
      startAt: 0,
      elements: lightBoxImages(),
      touchNavigation: true,
      dragAutoSnap: true,
      draggable: false,
      loop: true,
      skin: 'clean hn'
    });
    offerGallery.open();
  };

  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 800,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    false: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  if (beekeeperLoading) return <BeekeeperScreenSkeleton />;

  return (
    <div className="pb-10">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
            <section className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/2 lg:w-full xl:w-1/3 px-3 mb-6">
                <div className="bg-white rounded-2xl shadow-ds16 h-full">
                  <div className="rounded-t-2xl p-4 bg-yellow-40">
                    <div className="flex items-end justify-between">
                      <h3 className="text-lg md:text-2xl text-primary-120 font-bold mb-1 md:mb-3">
                        {beekeeperStore.record.name}
                      </h3>
                      <div className="w-16 h-16 border-2 border-white rounded-full relative flex-shrink-0">
                        <LazyLoad>
                          {beekeeperStore.record.avatar ? (
                            <img
                              src={beekeeperStore.record.avatar}
                              alt={beekeeperStore.record.name}
                              className="w-full h-full object-cover absolute inset-0 rounded-full"
                            />
                          ) : (
                            <img
                              src={BeekeeperAvatarPlaceholder}
                              alt={beekeeperStore.record.name}
                              className="w-full h-full object-cover absolute inset-0 rounded-full"
                            />
                          )}
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="text-xs text-primary">
                        {t('beekeeperSince', {
                          date: new Date(beekeeperStore.record.beekeeperSince).getFullYear()
                        })}
                      </div>
                      <ul className="flex items-center">
                        {socialLinks.map((element) => (
                          <SocialLink
                            width="20"
                            svgClasses="w-5 h-5 lg:w-4 lg:h-4"
                            key={element.name}
                            beekeeper={beekeeperStore.record}
                            iconElement={element}
                          />
                        ))}
                      </ul>
                    </div>
                    <div className="text-grey-30 text-sm">
                      {beekeeperStore.record.livingAddress?.phone && (
                        <div className="flex items-center my-2">
                          <PhoneIcon
                            width="16"
                            height="16"
                            className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                          />
                          <div className="truncate">
                            {beekeeperStore.record.livingAddress.phone}
                          </div>
                        </div>
                      )}
                      {beekeeperStore.record?.email && (
                        <div className="flex items-center my-2">
                          <EmailIcon
                            width="16"
                            height="16"
                            className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                          />
                          <div className="truncate">{beekeeperStore.record.email}</div>
                        </div>
                      )}
                      {beekeeperStore.record.livingAddress?.city && (
                        <div className="flex items-center my-2">
                          <HomeIcon
                            width="16"
                            height="16"
                            className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                          />
                          {beekeeperStore.record.livingAddress.addressWithHouseNumber}
                          {', '}
                          {beekeeperStore.record.livingAddress.zipcode}{' '}
                          {beekeeperStore.record.livingAddress.city}
                          {', '}
                          {beekeeperStore.record.livingAddress.country.code}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-full xl:w-2/3 px-3 mb-6">
                <div className="rounded-2xl shadow-ds16">
                  <div className="bg-yellow-40 rounded-t-2xl px-4 pb-4 pt-6">
                    <h2 className="text-primary-120 text-2xl font-bold">{t('galleryHeader')}</h2>
                  </div>
                  <div className="p-4 -mx-2 slider-dots-center">
                    <Slider {...sliderSettings} className="flex">
                      {allPhotos().map(({ photo }, index) => (
                        <div
                          key="all-photos"
                          className="px-2 cursor-pointer"
                          onMouseDown={handleOnMouseDown}
                          onClick={(e) => handleOpenLightbox(e, index)}
                          role="presentation"
                        >
                          <div className="aspect-w-16 aspect-h-12 rounded-xl">
                            <img src={photo} alt="all photos" className="object-cover rounded-xl" />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="bg-white rounded-b-2xl p-4">
                    <div className="flex justify-between">
                      <button
                        className="transition-colors duration-300 outline-none focus:outline-none text-grey-25 hover:text-primary"
                        type="button"
                        onClick={() => downloadAllFiles()}
                      >
                        <DocumentDownloadIcon width="24" height="24" className="fill-current" />
                      </button>
                      <button
                        className="outline-none focus:outline-none inline-flex items-center group"
                        type="button"
                        onClick={() => handleOpenLightboxFromStart()}
                      >
                        <EyeIcon
                          width="24"
                          height="24"
                          className="text-grey-25 stroke-current flex-shrink-0 mr-2 group-hover:text-primary"
                        />
                        <span className="hidden md:inline-block transition-colors duration-300 text-grey-30 text-base group-hover:text-primary">
                          {t('openGallery')}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {beekeeperStore.record.timeLineNotes.length > 0 && (
              <section className="py-8">
                <h2 className="text-grey-50 text-lg md:text-2xl font-bold pb-2 mb-4 border-b border-grey-15">
                  {t('timelineNoteHead')}
                </h2>
                <div className="flex flex-wrap -mx-3">
                  {beekeeperStore.record.timeLineNotes.map((timelineNote) => (
                    <TimelineNote
                      key={timelineNote.id}
                      path={generatePath(TIMELINE_NOTE_PATH, {
                        beekeeperSlug: timelineNote.author.slug || 'slug',
                        timelineNoteSlug: timelineNote.slug
                      })}
                      timelineNote={timelineNote}
                    />
                  ))}
                </div>
              </section>
            )}
          </div>
          <div className="w-full lg:w-4/12 xl:w-1/4 px-3">
            <PRContact />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(BeekeeperScreen);
