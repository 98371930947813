import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { parseBool } from '@tools';
import moment from 'moment';
import SkeletonPressReleasesFeed from './components/Skeleton';
import PressReleasesFeedBox from './components/PressReleasesFeedBox';

function PressReleasesFeed() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.pressReleasesFeed'
  });
  const { pressReleaseStore } = useStores();
  const [fetched, setFetched] = useState(false);

  function fetchPressReleases(page = 1, extendCollection = false) {
    pressReleaseStore
      .fetchAll(
        {
          q: { viewTargetEq: 'company_portal' },
          sortBy: 'published_at desc',
          page
        },
        extendCollection
      )
      .then(() => setFetched(true));
  }

  useEffect(() => {
    fetchPressReleases();
  }, []);

  const pressReleaseWrapper = (pressRelease) => {
    return (
      <PressReleasesFeedBox
        key={pressRelease.id}
        slug={pressRelease.slug}
        date={moment(pressRelease.publishedAt).locale('de').format('DD, MMMM YYYY')}
        title={pressRelease.title}
        abstract={pressRelease.abstract.replace(/<[^>]+>/g, '')}
        photo={pressRelease.photoUrl}
        logo={pressRelease.sourceLogoUrl}
        url={pressRelease.sourceUrl}
        datafor={pressRelease.id.toString()}
      />
    );
  };

  function loadMore() {
    fetchPressReleases(pressReleaseStore.meta.nextPage, true);
  }

  const loadMoreButton = () => {
    if (!parseBool(pressReleaseStore.meta.nextPage)) return <div />;

    return (
      <div className="flex justify-center mt-6">
        <button
          className="inline-flex items-center justify-center relative bg-transparent hover:bg-primary-120 text-primary hover:text-white border border-primary m-0 py-2 px-12 rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
          type="button"
          onClick={() => loadMore()}
        >
          {t('loadMore')}
        </button>
      </div>
    );
  };

  if (!fetched) return <SkeletonPressReleasesFeed />;
  return (
    <section className="py-10">
      <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-1 border-b border-grey-15">
        {t('title')}
      </h2>
      <div className="mt-8">
        <div className="flex flex-wrap -mx-3">
          {pressReleaseStore.collection.map(pressReleaseWrapper)}
        </div>
      </div>
      {loadMoreButton()}
    </section>
  );
}

export default observer(PressReleasesFeed);
