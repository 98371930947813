import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '@images/icons/location-marker.svg';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function ComapnyUserBox({ url, logo, name, address, isNew }) {
  const { t } = useTranslation('components', { keyPrefix: 'uiElements.comapnyUserBox' });
  return (
    <Link to={url} className="text-primary text-sm group">
      <div className="transition-shadow duration-300 rounded-2xl shadow-ds12 group-hover:shadow-ds16 overflow-hidden">
        <div className="bg-white px-4 py-8 md:py-12 flex items-center justify-center relative">
          <div className="py-1">
            <div className="h-24 w-full relative flex justify-center">
              <LazyLoad height={96}>
                <img src={logo} alt={name} className="object-contain h-full" />
              </LazyLoad>
            </div>
          </div>
          {isNew && (
            <div className="absolute px-1 md:px-2 md:py-0.5 top-2 right-2 bg-primary text-white text-[9px] md:text-sm rounded uppercase">
              {t('newLabel')}
            </div>
          )}
        </div>
        <div className="bg-primary-light px-4 py-4">
          <h3 className="text-grey-30 text-sm md:text-base font-bold truncate">{name}</h3>
          <div className="flex items-center mt-2">
            <LocationIcon
              width="21"
              height="21"
              className="text-primary-60 fill-current flex-shrink-0 transform -translate-x-1"
            />
            <div className="text-primary-60 text-xs md:text-sm truncate">{address}</div>
          </div>
          <div className="mt-3 mb-1 text-primary text-xs md:text-sm font-medium flex items-center">
            <span className="flex-shrink-0 mr-2">{t('readMore')}</span>
            <ArrowRightIcon
              width="16"
              height="16"
              className="stroke-current transition-transform group-hover:translate-x-2"
            />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ComapnyUserBox;

ComapnyUserBox.propTypes = {
  url: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  isNew: PropTypes.bool
};

ComapnyUserBox.defaultProps = {
  url: '',
  logo: '',
  name: '',
  address: '',
  isNew: false
};
