import { useTranslation } from 'react-i18next';
import { ReactComponent as StatIcon01 } from '@images/icons/honey-project/01.svg';
import { ReactComponent as StatIcon02 } from '@images/icons/honey-project/02.svg';
import { ReactComponent as StatIcon03 } from '@images/icons/honey-project/03.svg';
import StatBox from './StatBox';

function StatsBelt() {
  const { t } = useTranslation('screens', { keyPrefix: 'honeyProjectScreen.components.statsBelt' });
  return (
    <section className="bg-green-20 py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex space-x-4 md:space-x-2 overflow-x-auto no-scrollbar">
          <StatBox
            head={t('box01.head')}
            number={t('box01.number')}
            icon={<StatIcon01 width="76px" height="76px" className="text-green fill-current" />}
            delay="400"
          />
          <StatBox
            head={t('box02.head')}
            number={t('box02.number')}
            icon={<StatIcon02 width="76px" height="76px" className="text-green fill-current" />}
            delay="600"
          />
          <StatBox
            head={t('box03.head')}
            number={t('box03.number')}
            icon={<StatIcon03 width="76px" height="76px" className="text-green fill-current" />}
            delay="800"
          />
        </div>
      </div>
    </section>
  );
}

export default StatsBelt;
