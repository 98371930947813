import PropTypes from 'prop-types';

function Avatar({ children }) {
  return (
    <div className="w-[40px] h-[40px] rounded-full border border-grey-30 lg:border-white relative">
      {children}
      <span className="w-3 h-3 bg-green absolute top-0 right-0 border border-grey-30 lg:border-white rounded-full" />
    </div>
  );
}

export default Avatar;

Avatar.propTypes = {
  children: PropTypes.element
};

Avatar.defaultProps = {
  children: ''
};
