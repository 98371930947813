import { useTranslation } from 'react-i18next';
import PartnersSection from '@components/UIElements/Partners';
import PartnerLogo01 from '@images/partners/01.png';
import PartnerLogo02 from '@images/partners/02.png';
import PartnerLogo03 from '@images/partners/03.png';
import PartnerLogo04 from '@images/partners/04.png';
import PartnerLogo05 from '@images/partners/05.png';
import PartnerLogo06 from '@images/partners/06.png';
import PartnerLogo07 from '@images/partners/07.png';
import PartnerLogo08 from '@images/partners/08.png';
import PartnerLogo09 from '@images/partners/09.png';
import PartnerLogo10 from '@images/partners/10.png';
import PartnerLogo11 from '@images/partners/11.png';
import PartnerLogo12 from '@images/partners/12.png';
import Photo1 from '@images/partners/photo-1.jpg';
import Photo2 from '@images/partners/photo-2.jpg';
import Photo3 from '@images/partners/photo-3.jpg';
import Photo4 from '@images/partners/photo-4.jpg';
import Photo5 from '@images/partners/photo-5.jpg';
import Photo6 from '@images/partners/photo-6.jpg';
import Photo7 from '@images/partners/photo-7.jpg';
import Photo8 from '@images/partners/photo-8.jpg';
import Photo9 from '@images/partners/photo-9.jpg';
import Photo10 from '@images/partners/photo-10.jpg';
import Photo11 from '@images/partners/photo-11.jpg';
import Photo12 from '@images/partners/photo-12.jpg';
import Person1 from '@images/partners/person-1.jpg';
import Person2 from '@images/partners/person-2.jpg';
import Person3 from '@images/partners/person-3.jpg';
import Person4 from '@images/partners/person-4.jpg';
import Person5 from '@images/partners/person-5.jpg';
import Person6 from '@images/partners/person-6.jpg';
import Person7 from '@images/partners/person-7.jpg';
import Person8 from '@images/partners/person-8.jpg';
import Person9 from '@images/partners/person-9.jpg';
import Person10 from '@images/partners/person-10.jpg';
import Person11 from '@images/partners/person-11.jpg';
import Person12 from '@images/partners/person-12.jpg';

const PARTNERS = [
  {
    id: 1,
    logo: <PartnerLogo01 />,
    photo: <Photo1 />,
    person: <Person1 />,
    color: 'text-primary-60'
  },
  {
    id: 2,
    logo: <PartnerLogo02 />,
    photo: <Photo2 />,
    person: <Person2 />,
    color: 'text-green-60'
  },
  {
    id: 3,
    logo: <PartnerLogo03 />,
    photo: <Photo3 />,
    person: <Person3 />,
    color: 'text-orange-60'
  },
  {
    id: 4,
    logo: <PartnerLogo04 />,
    photo: <Photo4 />,
    person: <Person4 />,
    color: 'text-primary-60'
  },
  {
    id: 5,
    logo: <PartnerLogo05 />,
    photo: <Photo5 />,
    person: <Person5 />,
    color: 'text-green-60'
  },
  {
    id: 6,
    logo: <PartnerLogo06 />,
    photo: <Photo6 />,
    person: <Person6 />,
    color: 'text-orange-60'
  },
  {
    id: 7,
    logo: <PartnerLogo07 />,
    photo: <Photo7 />,
    person: <Person7 />,
    color: 'text-primary-60'
  },
  {
    id: 8,
    logo: <PartnerLogo08 />,
    photo: <Photo8 />,
    person: <Person8 />,
    color: 'text-green-60'
  },
  {
    id: 9,
    logo: <PartnerLogo09 />,
    photo: <Photo9 />,
    person: <Person9 />,
    color: 'text-orange-60'
  },
  {
    id: 10,
    logo: <PartnerLogo10 />,
    photo: <Photo10 />,
    person: <Person10 />,
    color: 'text-primary-60'
  },
  {
    id: 11,
    logo: <PartnerLogo11 />,
    photo: <Photo11 />,
    person: <Person11 />,
    color: 'text-green-60'
  },
  {
    id: 12,
    logo: <PartnerLogo12 />,
    photo: <Photo12 />,
    person: <Person12 />,
    color: 'text-orange-60'
  }
];

function Partners() {
  const { t } = useTranslation('screens', { keyPrefix: 'caseStudiesScreen.partners' });
  return (
    <PartnersSection
      items={PARTNERS}
      title={t('header')}
      subtitle={t('subheader')}
      lead={t('lead')}
      keyPrefix="caseStudiesScreen.partners"
    />
  );
}

export default Partners;
