import { makeAutoObservable, runInAction } from 'mobx';
import { timelineNoteEndpoint } from '@config/endpoints';
import UserStore from '@/stores/GlobalStores/UserStore';
import _ from 'lodash';

class TimelineNoteStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);

    this.authorStore = new UserStore();
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.record = {
        author: {}
      };
      this.meta = {};
    });
  }

  bindMethods() {
    this.assignTimelineNotes = this.assignTimelineNotes.bind(this);
    this.assignTimelineNote = this.assignTimelineNote.bind(this);
  }

  fetchAll(params, extendCollection = false) {
    return timelineNoteEndpoint
      .all(params)
      .then((response) => this.assignTimelineNotes(response, extendCollection));
  }

  fetchOne(id) {
    return timelineNoteEndpoint.one(id).then(this.assignTimelineNote);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
      this.record = {};
      this.meta = {};
    });
  }

  async assignTimelineNotes(response, extendCollection = false) {
    let newCollection = response.data.data.map(({ attributes, relationships: { author } }) => {
      if (!author.data) return attributes;
      return {
        ...attributes,
        authorId: author.data.id
      };
    });
    const authorIds = newCollection.map(({ authorId }) => authorId);

    if (_.uniq(authorIds).length > 0) {
      await this.authorStore.fetchAll({ q: { idIn: _.uniq(authorIds) } });
    }

    newCollection = newCollection.map((record) => {
      const foundAuthor =
        this.authorStore.collection.find(({ id }) => record.authorId === id.toString()) || {};

      return { author: foundAuthor, ...record };
    });

    runInAction(() => {
      if (extendCollection) this.collection = this.collection.concat(newCollection);
      else this.collection = newCollection;
      this.meta = response.data.meta;
    });
  }

  async assignTimelineNote(response) {
    const {
      attributes,
      relationships: { author }
    } = response.data.data;
    let newRecord = attributes;

    if (author.data) {
      await this.authorStore.fetchAll({ q: { idEq: author.data.id } });
      const foundAuthor =
        this.authorStore.collection.find(({ id }) => author.data.id === id.toString()) || {};
      newRecord = { author: foundAuthor, ...attributes };
    }

    runInAction(() => {
      this.record = newRecord;
    });
  }
}

export default TimelineNoteStore;
