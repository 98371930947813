import PropTypes from 'prop-types';

function SocialLink({ href, children }) {
  return (
    <a
      href={href}
      className="text-white transition-opacity duration-300 hover:opacity-80"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      {children}
    </a>
  );
}

export default SocialLink;

SocialLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.element
};

SocialLink.defaultProps = {
  href: '',
  children: ''
};
