import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

function SkeletonPressReleasesFeed() {
  return (
    <section className="py-10">
      <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-1 border-b border-grey-15">
        <Skeleton width="36%" />
      </h2>
      <div className="mt-8">
        <div className="flex flex-wrap -mx-3">
          {_.times(3, () => (
            <div className="w-full md:w-1/3 px-3 mb-6">
              <div className="p-4 bg-white rounded-2xl h-full flex flex-col justify-between group">
                <div>
                  <div className="overflow-hidden rounded-2xl">
                    <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-2xl">
                      <div className="rounded-2xl">
                        <Skeleton className="transition-all duration-300 object-cover absolute inset-0 w-full h-full rounded-2xl group-hover:scale-110" />
                      </div>
                    </div>
                  </div>
                  <div className="text-xs text-primary mb-2 mt-6">
                    <Skeleton width="30%" />
                  </div>
                  <h3 className="text-base text-grey-50 font-bold mb-2">
                    <Skeleton width="80%" />
                  </h3>
                  <div className="text-sm text-grey-30">
                    <Skeleton className="sm:h-20 md:h-40" />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-10">
                  <div>
                    <div className="transition-colors duration-300 outline-none focus:outline-none text-grey-25 hover:text-primary">
                      <Skeleton width={24} height={24} className="fill-current" />
                    </div>
                  </div>
                  <div className="h-8 w-1/2 relative flex justify-end">
                    <div>
                      <Skeleton width={68} height={32} className="object-contain h-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <Skeleton
          width={200}
          className="inline-flex items-center justify-center relative bg-transparent text-primary  border m-0 py-2 px-12 rounded outline-none focus:outline-none box-border"
        />
      </div>
    </section>
  );
}

export default SkeletonPressReleasesFeed;
