import { makeAutoObservable, runInAction } from 'mobx';
import { caseStudySectionEndpoint } from '@config/endpoints';

class CaseStudySectionStore {
  constructor() {
    this.collection = [];
    makeAutoObservable(this);
  }

  fetchAll(params) {
    this.fetched = false;
    return caseStudySectionEndpoint.all(params).then((response) => {
      runInAction(() => {
        this.collection = response.data.data.map(({ attributes, relationships }) => ({
          ...attributes,
          caseStudyCategoryId: relationships.caseStudyCategory.data.id
        }));
      });
    });
  }
}

export default CaseStudySectionStore;
