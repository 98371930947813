import { makeAutoObservable, runInAction } from 'mobx';
import { companyBeekeeperEndpoint } from '@config/endpoints';

class CompanyBeekeeperStore {
  constructor() {
    this.initFields();
    this.bindMethods();
    makeAutoObservable(this);
  }

  initFields() {
    runInAction(() => {
      this.collection = [];
      this.record = {};
    });
  }

  bindMethods() {
    this.assignCompanyBeekeepers = this.assignCompanyBeekeepers.bind(this);
    this.assignCompanyBeekeeper = this.assignCompanyBeekeeper.bind(this);
  }

  fetchAll(params) {
    return companyBeekeeperEndpoint.all(params).then(this.assignCompanyBeekeepers);
  }

  fetchOne(id) {
    return companyBeekeeperEndpoint.one(id).then(this.assignCompanyBeekeeper);
  }

  cleanCollection() {
    runInAction(() => {
      this.collection = [];
    });
  }

  async assignCompanyBeekeepers(response) {
    runInAction(() => {
      this.collection = response.data.data.map(({ attributes }) => attributes);
    });
  }

  assignCompanyBeekeeper(response) {
    runInAction(() => {
      this.record = response.data.data.map(({ attributes }) => attributes);
    });
  }
}

export default CompanyBeekeeperStore;
