import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import sal from 'sal.js';
import 'slick-carousel/slick/slick.css';
import SectionHead from '@components/UIElements/SectionHead';
import { ReactComponent as HowItWorks01 } from '@images/icons/honey-project/how-it-works-01.svg';
import { ReactComponent as HowItWorks02 } from '@images/icons/honey-project/how-it-works-02.svg';
import { ReactComponent as HowItWorks03 } from '@images/icons/honey-project/how-it-works-03.svg';
import { ReactComponent as HowItWorksPath } from '@images/icons/honey-project/how-it-works-path.svg';
import HowItWorksBox from './components/HowItWorksBox';

function StatsBelt() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'honeyProjectScreen.components.howItWorks'
  });

  useEffect(() => {
    sal();
  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 800,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <section className="bg-white py-10 md:py-12">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <SectionHead header={t('header')} subheader={t('subheader')} lead={t('lead')} />
        <div className="lg:px-20 mt-12 md:mt-0 relative">
          <HowItWorksPath
            className="hidden lg:block absolute top-8 left-0 w-full"
            data-sal="fade"
            data-sal-delay="500"
            data-sal-duration="800"
            data-sal-easing="easeOutCubic"
          />
          <div className="relative z-10">
            <div className="slider-box-unslick slider-dots-center slider-even-height">
              <Slider {...sliderSettings}>
                <HowItWorksBox
                  head={t('box01.head')}
                  lead={t('box01.lead')}
                  backgroundColor="bg-green-20"
                >
                  <HowItWorks01 width="130" height="100" className="text-green-120 fill-current" />
                </HowItWorksBox>
                <HowItWorksBox
                  head={t('box02.head')}
                  lead={t('box02.lead')}
                  backgroundColor="bg-primary-20"
                >
                  <HowItWorks02
                    width="130"
                    height="100"
                    className="text-primary-120 fill-current"
                  />
                </HowItWorksBox>
                <HowItWorksBox
                  head={t('box03.head')}
                  lead={t('box03.lead')}
                  backgroundColor="bg-orange-20"
                >
                  <HowItWorks03 width="130" height="100" className="text-orange-120 fill-current" />
                </HowItWorksBox>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsBelt;
