import { useTranslation } from 'react-i18next';
import { useStores } from '@/stores';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';

function LogOutButton() {
  const { t } = useTranslation('components', {
    keyPrefix: 'layouts.basicLayout.header.components.logOutButton'
  });
  const {
    authStore: { logOut }
  } = useStores();
  const { setAlert } = useAlert();

  return (
    <button
      type="submit"
      onClick={() => {
        logOut();
        setAlert({ open: true, message: t('loggedOut') });
      }}
      className="inline-flex items-center justify-center relative bg-transparent text-primary border border-primary m-0 py-2 px-16 w-full lg:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
    >
      {t('logout')}
    </button>
  );
}

export default LogOutButton;
