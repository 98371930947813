import AuthStore from '@/stores/GlobalStores/AuthStore';
import { createContext, useContext } from 'react';
import CompanyApplicationStore from '@/stores/GlobalStores/CompanyApplicationStore';
import CountryStore from '@/stores/GlobalStores/CountryStore';
import RegionStore from '@/stores/GlobalStores/RegionStore';
import ProfileQuestionStore from '@/stores/ProfileStores/ProfileQuestionStore';
import ProfileAnswerStore from '@/stores/ProfileStores/ProfileAnswerStore';
import CompanyFileStore from '@/stores/CompanyPortalStores/CompanyFileStore';
import CompanyFileCategoryStore from '@/stores/CompanyPortalStores/CompanyFileCategoryStore';
import CompanyActivityFeedStore from '@/stores/CompanyPortalStores/CompanyActivityFeedStore';
import PressReleaseStore from '@/stores/CompanyPortalStores/PressReleaseStore';
import EventStore from '@/stores/CompanyPortalStores/EventStore';
import CompanyProfileLogoStore from '@/stores/CompanyPortalStores/CompanyProfileLogoStore';
import ProfilePhotoStore from '@/stores/GlobalStores/ProfilePhotoStore';
import CompanyArticleStore from '@/stores/CompanyPortalStores/CompanyArticleStore';
import TimelineNoteStore from '@/stores/GlobalStores/TimelineNoteStore';
import BeekeeperStore from '@/stores/GlobalStores/BeekeeperStore';
import CompanyStore from '@/stores/GlobalStores/CompanyStore';
import CaseStudyStore from '@/stores/GlobalStores/CaseStudyStore';
import PostStore from '@/stores/GlobalStores/PostStore';
import CaseStudyCategoryStore from '@/stores/GlobalStores/CaseStudyCategoryStore';
import CaseStudySectionStore from '@/stores/GlobalStores/CaseStudySectionStore';
import SupportRequestStore from '@/stores/GlobalStores/SupportRequestStore';

export class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
    this.companyApplicationStore = new CompanyApplicationStore(this);
    this.countryStore = new CountryStore(this);
    this.regionStore = new RegionStore(this);
    this.profileQuestionStore = new ProfileQuestionStore(this);
    this.profileAnswerStore = new ProfileAnswerStore(this);
    this.profilePhotoStore = new ProfilePhotoStore(this);
    this.companyFileStore = new CompanyFileStore(this);
    this.companyFileCategoryStore = new CompanyFileCategoryStore(this);
    this.companyActivityFeedStore = new CompanyActivityFeedStore(this);
    this.pressReleaseStore = new PressReleaseStore(this);
    this.eventStore = new EventStore(this);
    this.companyProfileLogoStore = new CompanyProfileLogoStore(this);
    this.companyArticleStore = new CompanyArticleStore(this);
    this.timelineNoteStore = new TimelineNoteStore(this);
    this.beekeeperStore = new BeekeeperStore(this);
    this.companyStore = new CompanyStore(this);
    this.caseStudyStore = new CaseStudyStore(this);
    this.postStore = new PostStore(this);
    this.caseStudyCategoryStore = new CaseStudyCategoryStore(this);
    this.caseStudySectionStore = new CaseStudySectionStore(this);
    this.supportRequestStore = new SupportRequestStore(this);
  }
}

export const StoresContext = createContext(new RootStore());
export const useStores = () => useContext(StoresContext);
