import { useParams, generatePath, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import getVideoId from 'get-video-id';
import Plyr from 'plyr-react';
import { COMPANY_PROFILE_PATH } from '@constants';
import HeroWithSquarePhoto from '@components/UIElements/HeroWithSquarePhoto';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
import TitleAndDescription from '@components/MetaTags/TitleAndDescription';
import QuoteSection from './components/QuoteSection';
import 'plyr-react/dist/plyr.css';
import FavoriteCaseStudies from './components/FavoriteCaseStudies';

function CaseStudyScreen() {
  const { caseStudySlug } = useParams();
  const [recordFetching, setRecordFetching] = useState(true);
  const { caseStudyStore, caseStudySectionStore, caseStudyCategoryStore } = useStores();
  const caseStudy = caseStudyStore.record.attributes;

  useEffect(async () => {
    await caseStudyStore.fetchAll();
    await caseStudyStore.fetchOne(caseStudySlug);
    await caseStudySectionStore.fetchAll({
      q: { caseStudyIdEq: caseStudyStore.record.id },
      sortBy: 'priority desc'
    });
    const categoryIds = caseStudySectionStore.collection.map(
      ({ caseStudyCategoryId }) => caseStudyCategoryId
    );
    await caseStudyCategoryStore.fetchAll({ q: { idIn: _.uniq(categoryIds).flat() } });
    setRecordFetching(false);
  }, []);

  const videoSrc = (url, poster) => {
    const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
    const urlKind = url.match(youtubeRegex);
    if (urlKind != null) {
      const videoDetails = getVideoId(url);
      return {
        type: 'video',
        sources: [{ src: `${videoDetails.id}`, provider: 'youtube' }]
      };
    }
    return {
      type: 'video',
      sources: [{ src: url, type: 'video/mp4' }],
      poster
    };
  };

  const videoOptions = {
    controls: [
      'rewind',
      'play-large',
      'fast-forward',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen'
    ]
  };

  if (recordFetching)
    return (
      <div className="flex justify-center items-center min-h-[90vh]">
        <LoaderIcon width="34" height="34" className="animate-spin text-primary-60 fill-current" />
      </div>
    );

  return (
    <>
      <TitleAndDescription
        screenName="caseStudyScreen"
        attributes={{ companyName: caseStudy.companyName }}
      />
      <HeroWithSquarePhoto photo={caseStudy.header} alt={caseStudy.companyName}>
        <div className="h-20 w-full relative flex mb-4 md:mb-10 mt-4 lg:mt-0">
          <LazyLoad height={80}>
            <Link
              to={
                caseStudy.companyProfileUrl ||
                generatePath(COMPANY_PROFILE_PATH, {
                  companySlug: caseStudy.slug
                })
              }
            >
              <img
                src={caseStudy.logo}
                alt={caseStudy.companyName}
                className="object-contain h-full"
              />
            </Link>
          </LazyLoad>
        </div>
        <h1 className="text-2xl lg:text-3xl xl:text-4xl text-grey-50 font-bold leading-normal">
          {caseStudy.companyName}
        </h1>
        <div className="text-grey-30 text-base xl:text-xl mt-6 xl:pr-44 mb-10 lg:mb-0">
          {caseStudy.description}
        </div>
      </HeroWithSquarePhoto>

      <QuoteSection
        cover={caseStudy.overview}
        avatar={caseStudy.personImage}
        name={caseStudy.quoteHead}
        position={caseStudy.quoteSubHead}
        quote={caseStudy.quoteBody}
      />

      <section className="bg-white py-10 md:py-12">
        <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
          {caseStudySectionStore.collection.map((section, index) => {
            const category = caseStudyCategoryStore.collection.find(
              ({ id }) => id.toString() === section.caseStudyCategoryId
            );
            const linkTypeWrapper = () => {
              if (!section.additionalUrl && !section.url) {
                return null;
              }
              return (
                <div className="mt-6">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={section.additionalUrl || section.url}
                    className="text-primary text-xs md:text-sm font-medium flex items-center group"
                  >
                    {section.urlText || category?.text}
                    <ArrowRightIcon
                      width="16"
                      height="16"
                      className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
                    />
                  </a>
                </div>
              );
            };

            return (
              <div key={section.id} className="mb-16">
                <div
                  className={`flex flex-wrap items-center -mx-3 poster-${index} ${
                    index % 2 === 0 ? 'flex-row-reverse' : ''
                  }`}
                >
                  <div className="w-full md:w-1/2 px-3">
                    <div className={`${index % 2 === 0 ? 'xl:pl-28' : 'xl:pr-28'}`}>
                      <div className="text-sm md:text-base text-primary font-bold uppercase">
                        {category?.name}
                      </div>
                      <h3 className="text-xl md:text-2xl text-grey-50 font-bold mt-2">
                        {section.head}
                      </h3>
                      <div className="text-sm md:text-base text-grey-30 mt-4">{section.body}</div>
                      {linkTypeWrapper()}
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    {section.sectionType === 'photo' && (
                      <div className="aspect-w-4 aspect-h-3">
                        <LazyLoad>
                          <img
                            src={section.sectionImage}
                            alt={section.head}
                            className="absolute inset-0 w-full h-full object-contain"
                          />
                        </LazyLoad>
                      </div>
                    )}
                    {section.sectionType === 'video' && (
                      <div className="bg-orange-40 rounded-2xl px-6 py-6 case-study-video">
                        <style>
                          {`.poster-${index} .plyr--stopped .plyr__poster {
                                  background-image: url(${section.sectionImage}) !important;
                                  background-color: #FADAAA !important;
                                  opacity: 1 !important;
                              }`}
                        </style>
                        <div className="aspect-w-16 aspect-h-9">
                          <div className="h-full">
                            <Plyr
                              options={videoOptions}
                              source={videoSrc(section.url)}
                              poster={section.sectionImage}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <FavoriteCaseStudies />
    </>
  );
}

export default CaseStudyScreen;
