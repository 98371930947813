import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function Entry({ url, cover, postCategory, date, title, abstract }) {
  const { t } = useTranslation('screens', { keyPrefix: 'postsScreen' });
  return (
    <div className="bg-white overflow-hidden rounded-3xl shadow-ds8">
      <div className="flex flex-wrap">
        <div className="w-full md:w-2/3">
          <div className="lg:min-h-[460px] aspect-w-16 aspect-h-9 h-full relative">
            <Link to={url} reloadDocument>
              <LazyLoad>
                <img
                  src={cover}
                  alt={title}
                  className="w-full h-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </LazyLoad>
            </Link>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="flex flex-col justify-between h-full relative px-6">
            <div>
              <div
                className={`${
                  postCategory === 'News' ? 'bg-primary' : 'bg-green'
                } text-white text-xs font-medium uppercase px-6 py-2 mb-8 inline-flex`}
              >
                {postCategory}
              </div>
              <div className="text-xs text-grey-30">
                {moment(date).locale('de').format('DD.MM.YYYY')}
              </div>
              <h3 className="text-xl md:text-2xl font-bold mt-2 text-grey-50">{title}</h3>
              <div className="text-sm md:text-base text-grey-30 mt-4">{abstract}</div>
            </div>
            <div className="py-8">
              <Link
                to={url}
                reloadDocument
                className="text-primary text-xs md:text-sm font-medium uppercase flex items-center group"
              >
                {t('readMore')}
                <ArrowRightIcon
                  width="16"
                  height="16"
                  className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Entry.propTypes = {
  url: PropTypes.string,
  cover: PropTypes.string,
  postCategory: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  abstract: PropTypes.string
};

Entry.defaultProps = {
  url: '',
  cover: '',
  postCategory: '',
  date: '',
  title: '',
  abstract: ''
};

export default Entry;
