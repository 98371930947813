import { useTranslation, Trans } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import HomeHeroGraph from '@images/more-bees-graph.svg';
import { ReactComponent as Hero } from '@images/hero.svg';
import HexPhoto01a from '@images/hero/hex-01-a.jpg';
import HexPhoto01b from '@images/hero/hex-01-b.jpg';
import HexPhoto02a from '@images/hero/hex-02-a.jpg';
import HexPhoto02b from '@images/hero/hex-02-b.jpg';
import HexPhoto03a from '@images/hero/hex-03-a.jpg';
import HexPhoto03b from '@images/hero/hex-03-b.jpg';
import HexDecoration from './components/HexDecoration';

function HomeHero() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.hero' });
  return (
    <section className="bg-white overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="flex flex-wrap items-center -mx-4 relative z-10">
            <div className="w-full lg:w-5/12 px-4">
              <div className="py-8 xl:py-28 lg:-translate-y-10">
                <h1 className="font-bold text-primary text-3xl lg:text-5xl">
                  <Trans i18nKey="screens:homeScreen.hero.title">
                    <span className="block mb-2 md:mb-5">PROJEKT 2028</span>
                    <span className="block md:inline text-2xl lg:text-4xl text-grey-50">
                      ermöglicht Ihrem Unternehmen ein Engagement für Bienenschutz
                    </span>
                  </Trans>
                </h1>
                <div className="mt-8 text-grey-30 text-base md:text-xl xl:pr-12">{t('lead')}</div>
                <div className="mt-8">
                  <a
                    href="#getFolder"
                    className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-10 w-full md:w-auto rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
                  >
                    {t('getFolder')}
                  </a>
                </div>
                <div className="mt-8 md:mt-16">
                  <LazyLoad>
                    <img
                      src={HomeHeroGraph}
                      alt={t('heroGraph')}
                      className="w-[228px] md:w-[367px] h-[57px] md:h-[92px]"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-7/12 px-4">
              <div className="relative mb-10 lg:mb-0">
                <div className="lg:transform lg:-translate-y-10">
                  <Hero />
                  <div className="hero-hex hero-hex-1">
                    <LazyLoad>
                      <img
                        src={HexPhoto01a}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                      <img
                        src={HexPhoto01b}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                    </LazyLoad>
                  </div>
                  <div className="hero-hex hero-hex-2">
                    <LazyLoad>
                      <img
                        src={HexPhoto02a}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                      <img
                        src={HexPhoto02b}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                    </LazyLoad>
                  </div>
                  <div className="hero-hex hero-hex-3">
                    <LazyLoad>
                      <img
                        src={HexPhoto03a}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                      <img
                        src={HexPhoto03b}
                        alt={t('heroPhoto')}
                        className="absolute top-0 left-0 w-full h-auto"
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HexDecoration />
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
