import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { ROOT_PATH, COMPANY_PORTAL_PATH } from '@constants';
import SignInForm from '@screens/AuthScreen/components/SignInForm';
import AuthScreenBg from '@images/auth-screen/bg.png';
import { ReactComponent as ListBullet } from '@images/auth-screen/list-bullet.svg';
import { ReactComponent as Quote } from '@images/auth-screen/quote.svg';
import Logo2028 from '@images/logo2028-orange.svg';
import { ReactComponent as AvatarMask } from '@images/avatar-mask.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';
import PersonPhoto from '@images/auth-screen/person.jpg';
import HNLogo from '@images/auth-screen/hn-logo.svg';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';

function AuthScreen() {
  const { t } = useTranslation('screens', { keyPrefix: 'authScreen' });
  const { authStore } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.isAuthenticated) navigate(COMPANY_PORTAL_PATH);
  }, [authStore.isAuthenticated]);

  return (
    <section className="min-h-screen relative w-full flex justify-center items-center overflow-hidden">
      <div className="absolute inset-0 h-full w-full">
        <img
          src={AuthScreenBg}
          alt={t('logoProjekt2028Alt')}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-grey-50 bg-opacity-80 absolute inset-0 h-full w-full" />
      <div className="w-screen min-h-screen flex justify-center items-center md:p-4">
        <div className="bg-white rounded-xl relative z-10 w-full max-w-5xl">
          <div className="flex flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-1/2">
              <div className="py-8 lg:py-14 px-4 lg:px-10 bg-white h-full">
                <div className="mb-2 flex items-center justify-center md:fixed top-6 left-6 z-40 text-grey-30 md:text-white text-sm">
                  <Link className="inline-flex items-center group" to={ROOT_PATH}>
                    <ArrowRightIcon
                      width="16"
                      height="16"
                      className="mr-1 stroke-current transition-transform transform rotate-180 group-hover:-translate-x-1"
                    />
                    {t('backToHomePage')}
                  </Link>
                </div>
                <div className="flex md:hidden justify-center mb-4">
                  <Link to={ROOT_PATH}>
                    <img src={Logo2028} alt={t('logoProjekt2028Alt')} width="70" height="48" />
                  </Link>
                </div>
                <h1 className="text-grey-50 text-lg lg:text-2xl text-center font-bold leading-normal mt-4">
                  <Trans i18nKey="screens:authScreen.title">
                    Schon als Partner*in dabei? <br /> Dann hier entlang ...
                  </Trans>
                </h1>
                <div className="mt-8">
                  <SignInForm />
                </div>
                <div className="mt-8 text-center text-grey-30 text-xs">
                  <div>{t('supportedBy')}</div>
                  <div className="flex justify-center mt-2">
                    <img src={HNLogo} alt={t('logoAlt')} width="97" height="39" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="py-8 lg:py-14 px-4 lg:px-10 bg-primary-light h-full">
                <div className="hidden md:flex justify-center mb-4">
                  <Link to={ROOT_PATH}>
                    <img src={Logo2028} alt={t('logoProjekt2028Alt')} width="122px" height="80" />
                  </Link>
                </div>
                <h2 className="text-grey-50 text-lg lg:text-2xl text-center font-bold leading-normal">
                  {t('contactHead')}
                </h2>
                <ul className="text-grey-30 text-sm mt-6">
                  <li className="flex mb-4">
                    <ListBullet
                      width="32"
                      height="32"
                      className="flex-shrink-0 transform -translate-y-1 mr-1"
                    />
                    {t('contactList.01')}
                  </li>
                  <li className="flex mb-4">
                    <ListBullet
                      width="32"
                      height="32"
                      className="flex-shrink-0 transform -translate-y-1 mr-1"
                    />
                    {t('contactList.02')}
                  </li>
                  <li className="flex mb-4">
                    <ListBullet
                      width="32"
                      height="32"
                      className="flex-shrink-0 transform -translate-y-1 mr-1"
                    />
                    {t('contactList.03')}
                  </li>
                </ul>
                <div className="text-grey-30 text-xs md:text-sm flex mt-6 lg:mt-10">
                  <Quote width="40" height="40" className="flex-shrink-0 mr-4" />
                  {t('quote')}
                </div>
                <div className="mt-6 lg:mt-10">
                  <div className="flex items-center justify-center pt-4 md:pt-0 transform">
                    <div className="relative flex-shrink-0 mr-3 text-primary-40">
                      <AvatarMaskBack
                        width="47px"
                        height="40px"
                        className="fill-current absolute -top-1 -left-3"
                      />
                      <img
                        src={PersonPhoto}
                        alt={t('contactPerson')}
                        className="rounded-xl object-cover avatar-mask"
                        width="48px"
                        height="48px"
                      />
                    </div>
                    <div className="transform -translate-y-1">
                      <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                        {t('contactPerson')}
                      </h3>
                      <div className="text-grey-50 text-xs md:text-sm">
                        {t('contactPersonPosition')}
                      </div>
                    </div>
                  </div>
                </div>
                <AvatarMask width="0" height="0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default observer(AuthScreen);
