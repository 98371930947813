import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { ReactComponent as AvatarMask } from '@images/user-hex-info-max.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';

function UserHexBox({ avatar, user, backgroundColor }) {
  return (
    <>
      <div className="flex items-center pt-4 md:pt-0 my-1 md:my-6">
        <div className={`relative flex-shrink-0 mr-3 ${backgroundColor}`}>
          <AvatarMaskBack
            width="118px"
            height="100px"
            className="fill-current absolute -top-1 -left-3"
          />
          <LazyLoad height={120}>
            <img
              src={avatar}
              alt={user}
              className="rounded-xl object-cover avatar-mask-medium"
              width="120px"
              height="120px"
            />
          </LazyLoad>
        </div>
      </div>
      <AvatarMask width="0" height="0" />
    </>
  );
}

export default UserHexBox;

UserHexBox.propTypes = {
  avatar: PropTypes.string,
  user: PropTypes.string,
  backgroundColor: PropTypes.string
};

UserHexBox.defaultProps = {
  avatar: '',
  user: '',
  backgroundColor: ''
};
