import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Select } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import { parseBool } from '@tools';
import { useEffect, useState, useCallback } from 'react';
import { useStores } from '@/stores';
import { ReactComponent as FolderIcon } from '@images/icons/folder-download.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';
// import DefaultProfile from '@images/placeholders/default-profile.png';

function CompanyInformation() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm.components.companyInformation'
  });
  const {
    watch,
    getValues,
    resetField,
    trigger,
    control,
    formState: { errors }
  } = useFormContext();

  const {
    authStore,
    authStore: { user },
    countryStore,
    regionStore
  } = useStores();

  const watchCountry = watch('address.countryId');
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [logoLoaded, setLogoLoaded] = useState(parseBool(user.logo));

  function updateFile(attribute, data, setFileLoaded) {
    const formData = new FormData();

    formData.append(attribute, data);
    authStore.updateFiles(formData).then(() => setFileLoaded());
  }

  const onDrop = useCallback((acceptedFiles) => {
    setLogoLoaded(false);
    updateFile('registration[logo]', acceptedFiles[0], () => setLogoLoaded(true));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles: 1
  });

  const getUserAvatar = () => {
    if (!logoLoaded)
      return (
        <div className="h-[195px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-white">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <LoaderIcon
              width="34"
              height="34"
              className="animate-spin text-primary-60 fill-current"
            />
          </div>
        </div>
      );

    if (isDragActive) {
      return (
        <div className="h-[195px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-primary-light">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <FolderIcon width="40" height="40" />
          </div>
        </div>
      );
    }
    if (parseBool(user.logoFileName)) {
      return (
        <div className="h-[195px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30">
          <img src={user.logo} alt={user.name} className="object-contain w-full h-full" />
        </div>
      );
    }
    return (
      <div className="h-[195px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 hover:bg-grey-5">
        <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
          <FolderIcon width="40" height="40" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (countryStore.collection.length > 0) {
      setCountries(countryStore.collection);
    }
  }, [countryStore.collection]);

  useEffect(() => {
    if (regionStore.collection.length > 0) {
      setRegions(regionStore.collection);
    }
  }, [regionStore.collection]);

  useEffect(() => {
    const countryIdValue = getValues('address.countryId');
    const regionIdValue = getValues('address.regionId');
    const selectedRegion = regionStore.findById(regionIdValue);

    if (parseBool(countryIdValue)) {
      setRegions(regionStore.collection.filter((region) => region.countryId === countryIdValue));

      if (parseBool(selectedRegion) && selectedRegion.countryId !== countryIdValue) {
        resetField('address.regionId', { defaultValue: '' });
        trigger('address.regionId');
      }
    } else setRegions(regionStore.collection);
  }, [watchCountry]);

  useEffect(() => {
    if (parseBool(getValues('address.countryId')) && getValues('address.zipcode').length > 0)
      trigger('address.zipcode');
  }, [watchCountry]);

  function isValidZipCode(value) {
    const countryIdValue = getValues('address.countryId');

    if (countries.length > 0 && parseBool(countryIdValue)) {
      const countryCode = countries.find((country) => country.id === Number(countryIdValue)).code;
      const regexp = ['AT', 'CH'].includes(countryCode) ? /\b\d{4}\b/ : /\b\d{5}\b/;

      return regexp.test(value);
    }

    return null;
  }

  function getOption(option) {
    if (option) return { value: option.id, label: option.name };

    return {};
  }

  return (
    <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
      <div className="pb-4 mb-4 border-b border-grey-10">
        <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">{t('title')}</h2>
        <p className="text-sm md:text-base text-grey-30 max-w-2xl">{t('subtitle')}</p>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 px-3">
          <div className="mb-4 md:mb-8">
            <Controller
              name="companyName"
              control={control}
              render={({ field, ref }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder=""
                  error={errorLabel('companyName', 'user', errors.companyName)}
                  label={translateAttribute('companyName', 'user')}
                  ref={ref}
                />
              )}
            />
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3">
              <div className="mb-4 md:mb-8">
                <Controller
                  name="address.countryId"
                  control={control}
                  render={({ field: { onChange, value }, ref }) => (
                    <>
                      <Select
                        ref={ref}
                        label={translateAttribute('country', 'address')}
                        placeholder={translateAttribute('country', 'address')}
                        options={countries.map((country) => ({
                          value: country.id,
                          label: country.name
                        }))}
                        onChange={(val) => onChange(val.value)}
                        value={getOption(
                          countries.find((c) => c.id.toString() === value.toString())
                        )}
                      />
                      {errorLabel('country', 'address', errors.address?.countryId)}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <div className="mb-4 md:mb-8">
                <Controller
                  name="address.regionId"
                  control={control}
                  render={({ field: { onChange, value }, ref }) => (
                    <>
                      <Select
                        ref={ref}
                        label={translateAttribute('region', 'address')}
                        placeholder={translateAttribute('region', 'address')}
                        options={regions.map((country) => ({
                          value: country.id,
                          label: country.name
                        }))}
                        onChange={(val) => onChange(val.value)}
                        value={getOption(regions.find((c) => c.id.toString() === value.toString()))}
                      />
                      <div className="text-red text-[10px]">
                        {errorLabel('region', 'address', errors.address?.regionId)}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3">
              <div className="mb-4 md:mb-8">
                <Controller
                  name="address.city"
                  control={control}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder=""
                      error={errorLabel('city', 'address', errors.address?.city)}
                      label={translateAttribute('city', 'address')}
                      ref={ref}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <div className="mb-4 md:mb-8">
                <Controller
                  name="address.zipcode"
                  control={control}
                  rules={{ validate: isValidZipCode }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder=""
                      error={errorLabel('zipcode', 'address', errors.address?.zipcode)}
                      label={translateAttribute('zipcode', 'address')}
                      ref={ref}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <div className="mb-4 md:mb-0">
                <Controller
                  name="address.address"
                  control={control}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder=""
                      error={errorLabel('address', 'address', errors.address?.address)}
                      label={translateAttribute('address', 'address')}
                      ref={ref}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-3">
          <span className="input-label">Firmenlogo*</span>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {getUserAvatar()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(CompanyInformation);
