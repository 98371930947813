import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as LocationIcon } from '@images/icons/location-marker.svg';
import { ReactComponent as ArrowRightIcon } from '@images/icons/arrow-right.svg';
import { ReactComponent as AvatarMask } from '@images/user-hex-info-max.svg';
import { ReactComponent as AvatarMaskBack } from '@images/avatar-mask-back.svg';

function CompanyUserBox({ url, user, avatar, backgroundColor, address }) {
  const { t } = useTranslation('components', { keyPrefix: 'uiElements.userHexInfo' });
  return (
    <>
      <div className="flex items-center pt-4 md:pt-0 my-1 md:my-6">
        <Link to={url} reloadDocument className="flex-shrink-0">
          <div className={`relative flex-shrink-0 mr-3 ${backgroundColor}`}>
            <AvatarMaskBack
              width="118px"
              height="100px"
              className="fill-current absolute -top-1 -left-3"
            />
            <LazyLoad height={120}>
              <img
                src={avatar}
                alt={user}
                className="rounded-xl object-cover avatar-mask-medium"
                width="120px"
                height="120px"
              />
            </LazyLoad>
          </div>
        </Link>
        <div className="transform -translate-y-2 truncate flex-grow">
          <h3 className="text-grey-30 text-xs md:text-base font-bold truncate">{user}</h3>
          <div className="text-grey-30 text-xs md:text-sm flex items-center mt-2">
            <LocationIcon width="21" height="21" className="fill-current flex-shrink-0" />
            <div className="truncate">{address}</div>
          </div>
          <div className="mt-3">
            <Link
              reloadDocument
              to={url}
              className="text-primary text-xs md:text-sm font-medium flex items-center group"
            >
              {t('readMore')}
              <ArrowRightIcon
                width="16"
                height="16"
                className=" transition-transform duration-300 fill-current stroke-current flex-shrink-0 ml-1 group-hover:translate-x-1"
              />
            </Link>
          </div>
        </div>
      </div>
      <AvatarMask width="0" height="0" />
    </>
  );
}

export default CompanyUserBox;

CompanyUserBox.propTypes = {
  url: PropTypes.string,
  avatar: PropTypes.string,
  user: PropTypes.string,
  address: PropTypes.string,
  backgroundColor: PropTypes.string
};

CompanyUserBox.defaultProps = {
  url: '',
  avatar: '',
  user: '',
  address: '',
  backgroundColor: ''
};
