import { useEffect } from 'react';
import PropTypes from 'prop-types';
import sal from 'sal.js';

function HowItWorksBox({ head, lead, backgroundColor, classes, children }) {
  useEffect(() => {
    sal();
  }, []);
  return (
    <div className={`w-full md:w-1/3 px-4 lg:px-8 xl:px-10 md:mt-20 ${classes}`}>
      <div
        className={`rounded-2xl shadow-ds12 px-6 py-10 h-full ${backgroundColor}`}
        data-sal="fade"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="easeOutCubic"
      >
        <div>
          <div className="mb-6">{children}</div>
          <h3 className="text-lg md:text-base text-grey-50 font-bold">{head}</h3>
          <div className="text-grey-30 text-sm md:text-base mt-2">{lead}</div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorksBox;

HowItWorksBox.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  backgroundColor: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.element
};

HowItWorksBox.defaultProps = {
  head: '',
  lead: '',
  backgroundColor: '',
  classes: '',
  children: ''
};
