import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right.svg';

function CaseStudyItem({ img, name, url }) {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.caseStudies' });
  return (
    <div className="mx-4 h-[256px] md:h-[355px] relative group">
      <LazyLoad>
        <img
          src={img}
          alt={name}
          className="transition duration-300 max-h-[256px] md:max-h-[355px] shadow-ds12 rounded-md opacity-100 group-hover:opacity-50"
        />
      </LazyLoad>
      <div className="transition duration-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-4 flex justify-center opacity-0 group-hover:opacity-100">
        <a
          href={url}
          className="inline-flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white text-xs border-0 m-0 py-2 px-6 rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
        >
          {t('button')}
          <ArrowRight
            width="16px"
            height="15px"
            className="fill-current stroke-current flex-shrink-0 ml-2 transition-transform duration-300 transform group-hover:translate-x-2"
          />
        </a>
      </div>
    </div>
  );
}

export default CaseStudyItem;

CaseStudyItem.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string
};

CaseStudyItem.defaultProps = {
  img: '',
  name: '',
  url: ''
};
