import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { generatePath } from 'react-router-dom';
import { COMPANY_PORTAL_FILES_PATH, TIMELINE_NOTE_PATH } from '@constants';
import SkeletonCompanyActivityFeed from '@screens/CompanyPortal/CompanyPortalScreen/components/CompanyActivityFeed/components/Skeleton';
import { filesExtension } from '@config/directories';
import ActivityFeedBox from './components/ActivityFeedBox';

function CompanyActivityFeed() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'companyPortal.companyPortalScreen.components.companyActivityFeed'
  });
  const { companyActivityFeedStore, authStore } = useStores();
  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    companyActivityFeedStore
      .fetchAll({
        q: { visibleCompanyActivityFeed: authStore.user.id },
        limit: 7
      })
      .then(() => setFetched(true));
  }, []);

  const activityThumb = (activitable, activitableType) => {
    if (activitableType === 'TimelineNote') {
      const photo =
        activitable.mediumPhotos.length > 0 ? activitable.mediumPhotos[0] : activitable.companyLogo;

      return <img src={photo} alt={activitable.title} className="rounded-md object-cover" />;
    }

    if (activitable.backgroundFileName)
      return (
        <img
          src={activitable.backgroundImageUrl}
          alt={activitable.name}
          className="rounded-md object-cover"
        />
      );

    if (
      activitable.attachmentContentType === 'image/jpg' ||
      activitable.attachmentContentType === 'image/jpeg' ||
      activitable.attachmentContentType === 'image/png' ||
      activitable.type === 'CompanyArticle'
    )
      return (
        <img
          src={activitable.attachmentUrl}
          alt={activitable.name}
          className="rounded-md object-cover"
        />
      );

    return filesExtension[activitable.attachmentContentType]?.small;
  };

  function activityFeedBoxUrl(activitable, activitableType) {
    if (activitableType === 'TimelineNote') {
      return generatePath(TIMELINE_NOTE_PATH, {
        beekeeperSlug: activitable.author.slug,
        timelineNoteSlug: activitable.slug
      });
    }

    return generatePath(COMPANY_PORTAL_FILES_PATH, {
      type: activitable.group
    });
  }

  const companyActivityFeedWrapper = ({ id, activitable, activitableType }) => {
    return (
      <ActivityFeedBox
        key={id}
        thumb={activityThumb(activitable, activitableType)}
        name={activitable?.title || activitable?.description}
        month={activitable.companyFileCategory?.name}
        date={moment(activitable.updatedAt).locale('de').format('DD, MMMM YYYY')}
        personThumb={activitable.author?.avatarUrl || activitable.captionAvatar}
        personName={activitable.author?.firstname || activitable.caption}
        url={activityFeedBoxUrl(activitable, activitableType)}
        urlState={{ categoryName: activitable.companyFileCategory?.name }}
      />
    );
  };
  if (!fetched) return <SkeletonCompanyActivityFeed />;
  return (
    <section className="py-10">
      <h2 className="text-grey-50 text-2xl font-bold pb-2 mb-1 border-b border-grey-15">
        {t('title')}
      </h2>
      <div>{companyActivityFeedStore.collection.map(companyActivityFeedWrapper)}</div>
    </section>
  );
}

export default observer(CompanyActivityFeed);
