import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import _ from 'lodash';
import SkeletonPRContact from '@screens/CompanyPortal/CompanyPortalScreen/components/PRContact/Skeleton';

function BeekeeperScreenSkeleton() {
  return (
    <div className="pb-10">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-8/12 xl:w-3/4 px-3">
            <section className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/2 xl:w-1/3 px-3 ">
                <div className="rounded-2xl h-full transform translate-y-0 ">
                  <div className="rounded-t-2xl">
                    <div className="flex items-end justify-between p-4">
                      <h3 className=" w-40 h-10 text-lg md:text-2xl text-primary-120 font-bold mb-1 md:mb-3">
                        <Skeleton className="w-10 h-10" />
                      </h3>
                      <div className="w-16 h-16 border-white rounded-full relative flex-shrink-0">
                        <Skeleton
                          width="100%"
                          height="100%"
                          borderRadius={50}
                          className="w-full h-full object-cover absolute inset-0 rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="text-xs text-primary">
                        <Skeleton width={100} />
                      </div>
                      <ul className="flex items-center">
                        <Skeleton />
                      </ul>
                    </div>
                    <div className="text-grey-30 text-sm mt-4">
                      {_.times(3, (i) => (
                        <div key={i} className="flex items-center my-2">
                          <Skeleton
                            className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2"
                            containerClassName="w-full"
                          />
                          <div className="truncate">
                            <Skeleton />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-full xl:w-2/3 px-3 mb-6">
                <div className="rounded-2xl">
                  <div className="rounded-t-2xl px-4 pb-4 pt-6">
                    <h2 className="text-primary-120 text-2xl font-bold">
                      <Skeleton width="40%" className="mt-8 md:mt-0" />
                    </h2>
                  </div>
                  <div className="p-4 -mx-2 slider-dots-center mb-8">
                    <div className="block md:flex">
                      {_.times(3, (i) => (
                        <div key={i} className="px-2 w-full hidden md:block">
                          <div className="aspect-w-16 aspect-h-12 rounded-xl">
                            <div className="object-cover rounded-xl">
                              <Skeleton width={204} height={152} />
                            </div>
                          </div>
                        </div>
                      ))}
                      <Skeleton className="block w-1/2 md:hidden h-72 md:h-0" />
                    </div>
                  </div>
                  <div className="bg-white rounded-b-2xl p-4">
                    <div className="flex justify-between">
                      <div className="transition-colors duration-300 outline-none focus:outline-none text-grey-25 hover:text-primary">
                        <Skeleton width={24} height={24} className="fill-current" />
                      </div>
                      <div className="outline-none focus:outline-none inline-flex items-center group">
                        <Skeleton
                          width={24}
                          height={24}
                          className="text-grey-25 fill-current stroke-current flex-shrink-0 mr-2 group-hover:text-primary"
                        />
                        <span className="hidden md:inline-block transition-colors duration-300 text-grey-30 text-base group-hover:text-primary">
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="py-8">
              <h2 className="text-grey-50 text-lg md:text-2xl font-bold pb-2 mb-4 border-b border-grey-15">
                <Skeleton width="70%" />
              </h2>
              <div className="flex flex-wrap -mx-3">
                {_.times(3, (i) => (
                  <div key={i} className="w-full md:w-1/2 xl:w-1/3 px-3 my-3">
                    <div className="p-4 bg-white rounded-2xl  h-full flex flex-col justify-between">
                      <div className="h-full flex flex-col justify-between">
                        <div>
                          <div key="photo">
                            <div className="aspect-w-4 aspect-h-3">
                              <div>
                                <Skeleton className="transition-opacity duration-300 object-cover absolute inset-0 w-full h-full rounded-2xl group-hover:opacity-90" />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between mt-8">
                            <div className="flex items-center pl-2 pt-4">
                              <div className="relative flex-shrink-0 mr-3 text-primary-40">
                                <Skeleton
                                  className="rounded-xl object-cover avatar-mask"
                                  width={48}
                                  height={48}
                                />
                              </div>
                              <div className="transform -translate-y-1">
                                <h3 className="text-grey-50 text-xs md:text-sm font-bold">
                                  <Skeleton width={100} />
                                </h3>
                                <div className="text-grey-50 text-xs md:text-sm">
                                  <Skeleton />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h3 className="text-base text-grey-50 font-bold mt-4">
                              <Skeleton />
                            </h3>
                            <div className="my-2">
                              <div className="text-grey-30 text-sm h-14 md:h-20">
                                <Skeleton height="100%" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-primary text-xs my-4">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <Skeleton width={0} height={0} />
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="w-full lg:w-4/12 xl:w-1/4 px-3">
            <SkeletonPRContact />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BeekeeperScreenSkeleton;
