import { ReactComponent as ThumbUpIcon } from '@images/icons/thumb-up.svg';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';
import { useAlert } from '@components/UIElements/SystemAlert/Context/SystemAlertContext';

export default function SystemAlert() {
  const {
    alert: { open, message },
    setAlert
  } = useAlert();
  return (
    <div
      className={`md:sticky fixed bottom-2 left-0 bottom:4 px-4 md:pl-14 md:bottom-14 w-full h-1
    table-caption z-10 ${open ? 'block' : 'hidden'}`}
    >
      <div className="max-w-screen-container mx-auto px-0 2xl:px-0">
        <div className="bg-green relative bottom-16 rounded-md md:h-16 h-14 w-full md:w-80	text-white text-xs inline-flex items-center justify-between px-4 py-2 min-w-[314px]">
          <div className="flex items-center">
            <ThumbUpIcon width="24" height="24" className="fill-current flex-shrink-0 mr-2" />
            {message}
          </div>
          <button
            className="outline-none focus:outline-none flex-shrink-0"
            type="button"
            onClick={() => setAlert({ open: false, message: '' })}
          >
            <CloseIcon width="16" height="16" className="fill-current stroke-current" />
          </button>
        </div>
      </div>
    </div>
  );
}
