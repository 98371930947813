import { useTranslation, Trans } from 'react-i18next';
import Photo01 from '@images/b2b/01.jpg';
import Photo02 from '@images/b2b/02.jpg';
import Photo03 from '@images/b2b/03.jpg';
import B2BBox from './components/B2BBox';

function B2B() {
  const { t } = useTranslation('screens', { keyPrefix: 'homeScreen.b2b' });
  return (
    <section className="bg-orange-20 py-10 md:py-14 overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="md:text-center">
          <span className="block text-primary text-sm md:text-base font-bold uppercase">
            {t('subheader')}
          </span>
          <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50">
            <Trans i18nKey="screens:homeScreen.b2b.header">
              Ihr Unternehmen und <span className="block md:inline"> Artenschutz authentisch</span>
              <span className="block md:inline"> verknüpfen</span>
            </Trans>
          </h2>
          <div className="text-sm md:text-base text-grey-30 mt-4 max-w-xl mr-8 md:mr-auto md:mx-auto">
            {t('lead')}
          </div>
        </div>
        <div className="mt-14">
          <div className="flex flex-wrap justify-center xl:justify-between md:px-4">
            <B2BBox
              photo={Photo01}
              head={t('boxes.box01.head')}
              lead={t('boxes.box01.lead')}
              bgColor="text-green-60"
              iconColor="text-green"
              delay="100"
            />
            <B2BBox
              photo={Photo02}
              head={t('boxes.box02.head')}
              lead={t('boxes.box02.lead')}
              bgColor="text-red-60"
              iconColor="text-red-80"
              delay="200"
            />
            <B2BBox
              photo={Photo03}
              head={t('boxes.box03.head')}
              lead={t('boxes.box03.lead')}
              bgColor="text-primary-60"
              iconColor="text-primary"
              delay="300"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default B2B;
