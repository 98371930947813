import { makeAutoObservable } from 'mobx';
import { companyApplicationEndpoint } from '@config/endpoints';

class CompanyApplicationStore {
  constructor() {
    makeAutoObservable(this);
  }

  // eslint-disable-next-line class-methods-use-this
  create(params) {
    return companyApplicationEndpoint.create(params);
  }
}

export default CompanyApplicationStore;
