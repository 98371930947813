import SocialShare from '@components/UIElements/SocialShare';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import { useParams, generatePath } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';
import { PRESS_RELEASE_PATH } from '@constants';

function PressReleaseScreen() {
  const { slug } = useParams();
  const [pressReleaseLoading, setPressReleaseLoading] = useState(true);
  const { pressReleaseStore } = useStores();
  const pressRelease = pressReleaseStore.record;

  useEffect(() => {
    pressReleaseStore.fetchOne(slug).then(() => setPressReleaseLoading(false));
  }, []);

  if (pressReleaseLoading) return <div />;

  const shareLink = (pressReleaseSlug) => {
    return window.location.origin + generatePath(PRESS_RELEASE_PATH, { slug: pressReleaseSlug });
  };

  return (
    <section className="py-6">
      <div className="max-w-screen-md mx-auto px-4 2xl:px-14">
        <div className="flex items-center justify-between">
          <div className="text-primary text-base font-bold">
            {moment(pressRelease.publishedAt).locale('de').format('DD, MMMM YYYY')}
          </div>
          <div className="flex items-center space-x-10 md:space-x-12 text-grey-30">
            <SocialShare
              url={shareLink(pressRelease.slug)}
              title={pressRelease.title}
              body={pressRelease.abstract || pressRelease.body}
            />
          </div>
        </div>
        <h1 className="text-lg md:text-4xl text-grey-50 font-bold my-6">{pressRelease.title}</h1>
        <div>
          <div className="aspect-w-16 aspect-h-9">
            <LazyLoad>
              <img
                src={pressRelease.photoUrl}
                alt={pressRelease.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </LazyLoad>
          </div>
        </div>
        <div className="text-grey-30 text-sm md:text-base text-entry my-6">{pressRelease.body}</div>
      </div>
    </section>
  );
}

export default observer(PressReleaseScreen);
