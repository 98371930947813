import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ROOT_PATH } from '@constants';
import { ReactComponent as ApplicationFormPopUpPhoto } from '@images/application-form/popup.svg';

function CompanyApplicationFormDoneScreen() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { t } = useTranslation('screens', { keyPrefix: 'CompanyApplicationFormDoneScreen' });
  return (
    <div className="min-h-screen w-screen bg-primary-20 fixed inset-0 z-[1000] flex justify-center items-center">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="flex flex-col justify-center items-center max-w-lg mx-auto py-16 px-10 bg-white rounded-lg shadow-md">
          <ApplicationFormPopUpPhoto width="286" />
          <h3 className="text-lg md:text-2xl text-grey-50 font-bold mt-6 mb-2">{t('title')}</h3>
          <div className="lg:px-8">
            <p className="text-sm md:text-base text-grey-30 text-center">{t('body')}</p>
          </div>
          <div className="w-full mt-8">
            <button
              type="button"
              onClick={goBack}
              className="flex items-center justify-center relative bg-primary hover:bg-primary-120 text-white border-0 m-0 py-2 px-16 w-full rounded outline-none box-border cursor-pointer transition-colors ease-in-out duration-300 focus:outline-none"
            >
              {t('button')}
            </button>
          </div>
          <div className="w-full mt-4">
            <Link
              to={ROOT_PATH}
              className="w-full flex items-center justify-center relative bg-transparent hover:bg-primary-120 text-primary hover:text-white border border-primary m-0 py-2 px-12 rounded outline-none focus:outline-none box-border cursor-pointer transition-colors ease-in-out duration-300"
            >
              {t('buttonHomePage')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyApplicationFormDoneScreen;
