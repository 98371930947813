import { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Input } from 'hn-ui';
import { errorLabel, translateAttribute } from '@helpers/translations';
import { useStores } from '@/stores';
import { parseBool } from '@tools';
import { ReactComponent as FolderIcon } from '@images/icons/folder-download.svg';
import { ReactComponent as LoaderIcon } from '@images/icons/loader.svg';

function ContactPersonInformation() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'profileScreen.components.companyProfileForm.components.contactPersonInformation'
  });
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { authStore } = useStores();

  function updateFile(attribute, data, setFileLoaded) {
    const formData = new FormData();

    formData.append(attribute, data);
    authStore.updateFiles(formData).then(() => setFileLoaded());
  }
  const [avatarLoaded, setAvatarLoaded] = useState(parseBool(authStore.user.logo));

  const onDrop = useCallback((acceptedFiles) => {
    setAvatarLoaded(false);
    updateFile('registration[avatar]', acceptedFiles[0], () => setAvatarLoaded(true));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles: 1
  });

  const getUserAvatar = () => {
    if (!avatarLoaded)
      return (
        <div className="h-[200px] w-[200px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-white">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <LoaderIcon
              width="34"
              height="34"
              className="animate-spin text-primary-60 fill-current"
            />
          </div>
        </div>
      );

    if (isDragActive) {
      return (
        <div className="h-[200px] w-[200px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 bg-primary-light">
          <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
            <FolderIcon width="40" height="40" />
          </div>
        </div>
      );
    }
    if (parseBool(authStore.user.avatarFileName)) {
      return (
        <div className="h-[200px] w-[200px] rounded-lg flex flex-col items-center justify-center text-xs text-grey-30">
          <img
            src={authStore.user.avatar}
            alt={authStore.user.name}
            className="object-cover w-full h-full rounded-lg"
          />
        </div>
      );
    }
    return (
      <div className="h-[200px] w-[200px] border border-grey-25 border-dashed rounded-lg p-6 flex flex-col items-center justify-center text-xs text-grey-30 hover:bg-grey-5">
        <div className="w-24 h-24 bg-primary-20 rounded-full flex justify-center items-center">
          <FolderIcon width="40" height="40" />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-2xl p-4 md:p-6 mb-6 md:mb-10">
      <div className="pb-4 mb-4 border-b border-grey-10">
        <h2 className="text-lg md:text-2xl text-grey-50 font-bold mb-2">{t('title')}</h2>
        <p className="text-sm md:text-base text-grey-30 max-w-2xl">{t('subtitle')}</p>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          <div className="mb-4 md:mb-8">
            <Controller
              name="roleInCompany"
              control={control}
              render={({ field, ref }) => (
                <Input
                  {...field}
                  type="input"
                  placeholder=""
                  error={errorLabel('roleInCompany', 'user', errors.name)}
                  label={translateAttribute('roleInCompany', 'user')}
                  ref={ref}
                />
              )}
            />
          </div>
          <div className="mb-4 md:mb-8">
            <Controller
              name="name"
              control={control}
              render={({ field, ref }) => (
                <Input
                  {...field}
                  type="input"
                  placeholder=""
                  error={errorLabel('name', 'user', errors.name)}
                  label={translateAttribute('name', 'user')}
                  ref={ref}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4">
          <div className="flex flex-col md:justify-center md:items-center">
            <span className="input-label inline-block mb-1">{t('addPhotoLabel')}</span>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {getUserAvatar()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ContactPersonInformation);
