import { useTranslation, Trans } from 'react-i18next';
import Slider from 'react-slick';
import Logo2028 from '@images/2028-logo-primary.svg';
import { ReactComponent as Project2028Icon01 } from '@images/icons/honey-project/project2028/01.svg';
import { ReactComponent as Project2028Icon02 } from '@images/icons/honey-project/project2028/02.svg';
import { ReactComponent as Project2028Icon03 } from '@images/icons/honey-project/project2028/03.svg';
import { ReactComponent as Project2028Icon04 } from '@images/icons/honey-project/project2028/04.svg';
import { ReactComponent as Project2028Icon05 } from '@images/icons/honey-project/project2028/05.svg';
import { ReactComponent as Project2028Icon06 } from '@images/icons/honey-project/project2028/06.svg';
import IconBox from './components/IconBox';
import 'slick-carousel/slick/slick.css';
import HexDecoration from './components/HexDecorations';

function Project2028() {
  const { t } = useTranslation('screens', {
    keyPrefix: 'honeyProjectScreen.components.project2028'
  });

  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 800,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: true,
          centerPadding: '7%'
        }
      }
    ]
  };

  return (
    <section className="bg-white overflow-hidden">
      <div className="max-w-screen-container mx-auto px-4 2xl:px-14">
        <div className="relative">
          <div className="relative z-10 pt-10 md:pt-32 pb-6">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-5/12 px-4">
                <div className="mb-16">
                  <div className="mb-8">
                    <img
                      src={Logo2028}
                      alt={t('logo')}
                      className="w-[88px] h-[60px] md:w-[148px] md:h-[100px]"
                    />
                  </div>
                  <span className="block text-primary text-sm md:text-base font-bold uppercase">
                    {t('subheader')}
                  </span>
                  <h2 className="text-2xl md:text-4xl font-bold mt-2 md:mt-4 text-grey-50 leading-normal">
                    <Trans i18nKey="screens:honeyProjectScreen.components.project2028.header">
                      Give-Aways +<span className="block md:inline">Beitrag zur Mission =</span>
                      <span className="block md:inline">PROJEKT 2028-Projekthonig</span>
                    </Trans>
                  </h2>
                  <div className="text-sm md:text-base text-grey-30 mt-4 xl:pr-24">
                    <p className="mb-6">{t('lead')}</p>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-7/12 px-4">
                <div className="md:-mt-20 -mx-4 md:mx-auto">
                  <div className="slider-box-unslick pl-4">
                    <Slider {...sliderSettings}>
                      <IconBox
                        head={t('iconBoxes.box01.head')}
                        lead={t('iconBoxes.box01.lead')}
                        classes="text-green-20"
                      >
                        <Project2028Icon01
                          width="86"
                          height="86"
                          className="text-green-120 fill-current"
                        />
                      </IconBox>
                      <IconBox
                        head={t('iconBoxes.box02.head')}
                        lead={t('iconBoxes.box02.lead')}
                        classes="text-primary-20"
                      >
                        <Project2028Icon02
                          width="86"
                          height="86"
                          className="text-primary-120 fill-current"
                        />
                      </IconBox>
                      <IconBox
                        head={t('iconBoxes.box03.head')}
                        lead={t('iconBoxes.box03.lead')}
                        classes="text-orange-20"
                      >
                        <Project2028Icon03
                          width="86"
                          height="86"
                          className="text-orange-120 fill-current"
                        />
                      </IconBox>
                      <IconBox
                        head={t('iconBoxes.box04.head')}
                        lead={t('iconBoxes.box04.lead')}
                        classes="text-orange-20"
                      >
                        <Project2028Icon04
                          width="86"
                          height="86"
                          className="text-orange-120 fill-current"
                        />
                      </IconBox>
                      <IconBox
                        head={t('iconBoxes.box05.head')}
                        lead={t('iconBoxes.box05.lead')}
                        classes="text-green-20"
                      >
                        <Project2028Icon05
                          width="86"
                          height="86"
                          className="text-green-120 fill-current"
                        />
                      </IconBox>
                      <IconBox
                        head={t('iconBoxes.box06.head')}
                        lead={t('iconBoxes.box06.lead')}
                        classes="text-primary-20"
                      >
                        <Project2028Icon06
                          width="86"
                          height="86"
                          className="text-primary-120 fill-current"
                        />
                      </IconBox>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HexDecoration />
        </div>
      </div>
    </section>
  );
}

export default Project2028;
