import { ReactComponent as EmailIllustration } from '@images/email-popup.svg';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import Modal from '@components/UIElements/Modal';

function LimitedAccessModal(_, ref) {
  const { t } = useTranslation('components', { keyPrefix: 'modals.limitedAccessModal' });

  return (
    <Modal ref={ref}>
      <div className="bg-white rounded-2xl p-6 md:p-10 w-full max-w-[466px] max-h-[90vh] overflow-y-auto">
        <div className="flex flex-col justify-center items-center">
          <div>
            <EmailIllustration width="276" />
          </div>
          <h3 className="text-center text-lg md:text-2xl text-grey-50 font-bold mt-6 mb-2">
            {t('title')}
          </h3>
          <div className="mt-2 w-full text-center text-grey-30 text-base">{t('lead')}</div>
          <div className="mt-2 w-full text-center">
            <a
              href={`mailto:${t('limitedAccessModalEmail')}`}
              className="text-primary hover:underline"
            >
              {t('email')}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default forwardRef(LimitedAccessModal);
